import React from "react";
import style from '../../../../Purchase.module.scss'
import cx from 'classnames';
import { formatNumber2 } from "../../../../../../static/js/commonFunc";
import RecycleIcon from "../../../../../../shared/ui/icons/Recycle.jsx";
import EditValue from "./edit.jsx";

const Row = ({ data, products, remove, edit }) => {

    const getProductValue = (id, type) => {
        const res = products.filter(item => item.id === id)

        if (res[0][type]) {

            if (type === 'photo_url') {
                return <img src={res[0][type]} />
            }

            else {
                return res[0][type]
            }

        }

        else {
            return null
        }

    }


    return (

        data.map((item, index) =>

            <div className={style.row} key={index}>
                <div className={style.xScroll}>

                    <div style={{ width: '80px' }} className={cx(style.column, style.center)}>
                        {item.product}
                    </div>

                    <div style={{ width: '60px' }} className={cx(style.column, style.center)}>
                        {getProductValue(item.product, 'photo_url')}
                    </div>

                    <div style={{ width: '566px' }} className={cx(style.column, style.start)}>
                        {getProductValue(item.product, 'name')}
                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        0
                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        0
                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        <EditValue
                            value={item.price}
                            column="price"
                            convertFunction={(value) => formatNumber2(value)}
                            action={edit}
                            id={item.product}
                        />

                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        <EditValue
                            value={item.count}
                            column="count"
                            action={edit}
                            id={item.product}
                        />
                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        {formatNumber2(item.price * item.count)}
                    </div>

                    <div style={{ width: '60px' }} className={cx(style.column, style.header, style.end)}>

                        <span onClick={() => remove(item.product)}><RecycleIcon /></span>

                    </div>

                </div >
            </div>

        )


    )

}

export default Row