// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dXN1tvEmDmuC_ODPSRMO {\n  width: 30px;\n  height: 16px;\n  border-radius: 10px;\n  border: 1px solid #DCE7F1;\n  background: transparent;\n  padding: 1px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.dXN1tvEmDmuC_ODPSRMO span {\n  width: 14px;\n  height: 14px;\n  border-radius: 100%;\n  transition: 0.15s;\n  background: #DCE7F1;\n  display: block;\n}\n.dXN1tvEmDmuC_ODPSRMO.T7rGlo9miIbHUJHwdl48 {\n  border-color: #429EFF;\n}\n.dXN1tvEmDmuC_ODPSRMO.T7rGlo9miIbHUJHwdl48 span {\n  transform: translateX(15px);\n  background: #429EFF;\n}", "",{"version":3,"sources":["webpack://./src/views/Answering/AnsweringEdit/ui/Notices/Switch/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AACR;AAEI;EACI,qBAAA;AAAR;AACQ;EACI,2BAAA;EACA,mBAAA;AACZ","sourcesContent":[".switch {\n    width: 30px;\n    height: 16px;\n    border-radius: 10px;\n    border: 1px solid #DCE7F1;\n    background: transparent;\n    padding: 1px;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n\n    span {\n        width: 14px;\n        height: 14px;\n        border-radius: 100%;\n        transition: .15s;\n        background: #DCE7F1;\n        display: block;\n    }\n\n    &.active {\n        border-color: #429EFF;\n        span {\n            transform: translateX(15px);\n            background: #429EFF;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "dXN1tvEmDmuC_ODPSRMO",
	"active": "T7rGlo9miIbHUJHwdl48"
};
export default ___CSS_LOADER_EXPORT___;
