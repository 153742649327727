import React from 'react';
import styles from './styles.module.scss';
import Item from './ui/Item/index.jsx';
import { API_URL, getData, postData } from '../../../../static/js/request';
import { useParams } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { createNotification } from '../../../../static/js/commonFunc';
import ChangeTitle from '../../../../components/custom/ChangeTitle/index.jsx';

function EditPage() {
  const params = useParams();

  const [title, setTitle] = React.useState('Редактирование Группа 1');

  const [selectedItems, setSelected] = React.useState([]);

  const [items, setItems] = React.useState([]);

  const [visible, setVisible] = React.useState(false);

  const [allProducts, setAllProducts] = React.useState([]);

  const [days, setDays] = React.useState(0);

  const [deafultValues, setDefaultValue] = React.useState({
    title: '',
    days: 0,
    items: [],
  });

  const clickRemove = async (id) => {
    if (selectedItems.length > 0) {
      setItems([...items.filter((item) => !selectedItems.includes(item.id))]);
      setSelected([]);
    } else {
      setItems([...items.filter((item) => item.id !== id)]);
    }
    setVisible(true);
  };

  const selectAll = () => {
    setSelected(
      items.length === selectedItems.length
        ? []
        : [...items.map((item) => item.id)]
    );
  };

  const addProduct = async (product) => {
    try {
      const repsonse = await postData(
        `${API_URL}/api/v1/deliverypalner/product-groups/${params.id}/`,
        { product_id: product.id }
      );
      setItems([...items, product]);
      setAllProductsModal(false);
      changeAddInput('');
      createNotification(response);
    } catch (error) {}
  };

  const getGroupInfo = async () => {
    try {
      const response = await getData(
        `${API_URL}/api/v1/deliverypalner/product-groups/${params.id}/`
      );
      setDays(response.delivery_time_from_supplier);
      setItems(response.products);
      setTitle(response.name);
      setDefaultValue({
        title: response.name,
        days: response.products.length,
        items: [...response.products],
      });
    } catch (error) {}
  };

  const getAllProducts = async () => {
    try {
      const response = await getData(`${API_URL}/api/v1/general/user/products/`);
      setAllProducts(response);
    } catch (error) {}
  };

  const returnChanges = () => {
    setDays(deafultValues.days);
    setItems(deafultValues.items);
    setTitle(deafultValues.title);
    setVisible(false);
  };

  const postChanges = async () => {
    try {
      const response = await postData(
        `${API_URL}/api/v1/deliverypalner/update-product-group/${params.id}/`,
        {
          name: title,
          delivery_time_from_supplier: days,
          products: [
            ...items.map((item) => {
              return {
                ...item,
                repackaging_period: item.repackaging_period
                  ? item.repackaging_period
                  : 0,
              };
            }),
          ],
        }
      );
      Store.addNotification({
        title: 'Сохранено',
        message: 'Группа успешно сохранена',
        type: 'success',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
        },
      });
      setVisible(false);
    } catch (error) {
      Store.addNotification({
        title: 'Ошибка',
        message: 'При сохранении что-то пошло не так',
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  React.useEffect(() => {
    getGroupInfo();
    getAllProducts();
  }, []);

  const onChangeDays = (text, id) => {
    setVisible(true);
    if (selectedItems.length > 0) {
      setItems([
        ...items.map((item) => {
          return {
            ...item,
            repackaging_period:
              selectedItems.includes(item.id) || item.id === id ? text : null,
          };
        }),
      ]);
    } else {
      setItems([
        ...items.map((item) => {
          if (id === item.id) {
            return { ...item, repackaging_period: text };
          }
          return item;
        }),
      ]);
    }
  };

  const [allProductsModal, setAllProductsModal] = React.useState(false);

  const [addInput, changeAddInput] = React.useState('');

  React.useEffect(() => {
    if (addInput.length > 0) {
      setAllProductsModal(true);
    }
  }, [addInput]);

  return (
    <div className={styles.content}>
      <div className={styles.top}>
        <ChangeTitle
          title={title}
          onChange={(text) => {
            setVisible(true);
            setTitle(text);
          }}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.blokTop}>
          <div className={styles.topInputs}>
            <p className={styles.inputTitle}>Логитстика от постовщика</p>
            <input
              type="text"
              value={`${days}`}
              onChange={(e) => {
                setVisible(true);
                setDays(e.target.value.replace(/\D/, ''));
              }}
            />
            <p className={styles.inputTitle}>Количество товаров</p>
            <input type="text" value={items.length} disabled />
          </div>
        </div>
        <div className={styles.tableTop}>
          <div className={styles.topCol}>
            <div className={styles.checkbox} onClick={selectAll}>
              {items.length === selectedItems.length && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path d="M3.5 8.90005L6.2 11.6L12.5 5.30005" fill="white" />
                  <path
                    d="M3.5 8.90005L6.2 11.6L12.5 5.30005"
                    stroke="#429EFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
          <div className={styles.topCol}>Фото</div>
          <div className={styles.topCol}>Артикул</div>
          <div className={styles.topCol}>Название</div>
          <div className={styles.topCol}>
            Количество дней
            <div className={styles.info}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M6.99936 5.4356C7.14204 5.43519 7.27875 5.37833 7.37964 5.27744C7.48052 5.17656 7.53738 5.03984 7.53779 4.89717V4.35874C7.53779 4.21594 7.48106 4.07899 7.38009 3.97802C7.27912 3.87704 7.14216 3.82031 6.99936 3.82031C6.85657 3.82031 6.71961 3.87704 6.61864 3.97802C6.51767 4.07899 6.46094 4.21594 6.46094 4.35874V4.89717C6.46135 5.03984 6.5182 5.17656 6.61909 5.27744C6.71998 5.37833 6.85669 5.43519 6.99936 5.4356Z"
                  fill="#429EFF"
                />
                <path
                  d="M5.92124 7.58955H6.45967V10.6408C6.45967 10.7836 6.5164 10.9205 6.61737 11.0215C6.71834 11.1225 6.8553 11.1792 6.99809 11.1792C7.14089 11.1792 7.27785 11.1225 7.37882 11.0215C7.47979 10.9205 7.53652 10.7836 7.53652 10.6408V7.05112C7.53611 6.90845 7.47925 6.77174 7.37837 6.67085C7.27748 6.56996 7.14077 6.5131 6.99809 6.5127H5.92124C5.77844 6.5127 5.64149 6.56942 5.54051 6.6704C5.43954 6.77137 5.38281 6.90832 5.38281 7.05112C5.38281 7.19392 5.43954 7.33088 5.54051 7.43185C5.64149 7.53283 5.77844 7.58955 5.92124 7.58955Z"
                  fill="#429EFF"
                />
                <path
                  d="M7 14.5C8.38447 14.5 9.73785 14.0895 10.889 13.3203C12.0401 12.5511 12.9373 11.4579 13.4672 10.1788C13.997 8.8997 14.1356 7.49224 13.8655 6.13437C13.5954 4.7765 12.9287 3.52922 11.9497 2.55026C10.9708 1.57129 9.7235 0.904603 8.36563 0.634506C7.00776 0.36441 5.6003 0.503033 4.32121 1.03285C3.04213 1.56266 1.94888 2.45987 1.17971 3.61101C0.410543 4.76215 0 6.11553 0 7.5C0.00202552 9.3559 0.740174 11.1352 2.05249 12.4475C3.3648 13.7598 5.1441 14.498 7 14.5ZM7 1.50473C8.18575 1.50473 9.34488 1.85635 10.3308 2.51512C11.3167 3.17388 12.0851 4.11022 12.5389 5.20571C12.9927 6.3012 13.1114 7.50665 12.8801 8.66962C12.6487 9.83259 12.0777 10.9008 11.2393 11.7393C10.4008 12.5778 9.33259 13.1487 8.16962 13.3801C7.00665 13.6114 5.8012 13.4927 4.70571 13.0389C3.61021 12.5851 2.67388 11.8167 2.01511 10.8308C1.35634 9.84488 1.00473 8.68575 1.00473 7.5C1.00593 5.91033 1.63796 4.38611 2.76203 3.26204C3.8861 2.13797 5.41032 1.50594 7 1.50473Z"
                  fill="#429EFF"
                />
              </svg>
              <p className={styles.text}>
                введите кол-во дней необходимое для переупаковки 100 шт
              </p>
            </div>
          </div>
          <div className={styles.filter}>
            <input
              onFocus={() => setAllProductsModal(addInput.length > 0)}
              value={addInput}
              onChange={(e) => changeAddInput(e.target.value)}
              type="text"
              placeholder={'Артикул или название товара'}
            />
            {allProductsModal && (
              <div className={styles.productsModal}>
                {allProducts
                  .filter(
                    (temp) =>
                      temp.name?.includes(addInput) ||
                      temp.vendorCode?.includes(addInput)
                  )
                  ?.map((product) => (
                    <>
                      <span
                        className={styles.allBg}
                        onClick={() => setAllProductsModal(false)}
                      />
                      <div
                        className={styles.product}
                        id={'item'}
                        onClick={() => addProduct(product)}
                      >
                        {product.photo_url && (
                          <img src={product.photo_url} alt="" />
                        )}
                        <div className={styles.info}>
                          <p className={styles.productName}>{product.name}</p>
                          <p className={styles.vendorCode}>
                            {product.vendorCode}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.table}>
          {items.map((item) => (
            <Item
              setVisible={() => setVisible(true)}
              onChange={onChangeDays}
              item={item}
              allItems={items.map((item) => item.id)}
              remove={clickRemove}
              selected={selectedItems}
              onClick={setSelected}
              id={item.id}
            />
          ))}
        </div>
      </div>
      {visible && (
        <div className={styles.saveBlock}>
          <p>Сохранение изменений</p>
          <div className={styles.buttons}>
            <button className={styles.closeChange} onClick={returnChanges}>
              Отменить
            </button>
            <button className={styles.postChanges} onClick={postChanges}>
              Сохранить
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditPage;
