import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import style from '../../../Purchase.module.scss'
import PlusIcon from "../../../../../shared/ui/icons/Plus.jsx";
import ChainIcon from "../../../../../shared/ui/icons/Chain.jsx";
import { NewCustomInput } from "../../../../../components/custom/NewCustomInput/NewCustomInput.jsx";
import SingleDateSelection from "../../../../../feature/SingleDateSelection/index.jsx";
import CustomSelectNew from "../../../../../feature/SelectWithLabel/index.jsx";
import { Checkbox } from "@mui/material";
import cx from 'classnames';
import AcceptanceList from "./acceptance.jsx";
import PaysList from "./pays.jsx";

const TopRow = ({ itemParams, setItemParams }) => {

    const [calendarOpen, setOpenCalendar] = useState(false);
    const [savedDate, setSavedDate] = useState(null);
    const [showPays, togglePays] = useState(false)
    const [showAcceptance, toggleAcceptance] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate();

    return (
        <div className={style.topRow}>

            <div className={style.left}>

                <div className={style.column}>
                    <div className={style.pageTitle}><span>{itemParams.formTitle}</span></div>
                </div>

                <div className={style.column}>
                    <NewCustomInput
                        type={'text'}
                        value={itemParams.id}
                        onChange={(value) => setItemParams(prev => ({
                            ...prev,
                            id: value
                        }))}
                        width={'80px'}
                        placeholder="№"
                        numeric

                    />
                </div>

                <div className={cx(style.column, style.date)}>
                    <SingleDateSelection
                        calendarOpen={calendarOpen}
                        setOpenCalendar={setOpenCalendar}
                        selectedDate={itemParams.actionDate}
                        setSelectedDate={(value) => setItemParams(prev => ({
                            ...prev,
                            actionDate: value
                        }))}
                        savedDate={itemParams.actionDate ? itemParams.actionDate : savedDate}
                        setSavedDate={setSavedDate}
                    />
                </div>



                <div className={style.column}>
                    <CustomSelectNew
                        options={[
                            { name: 'Заказ выполнен', style: 'success' },
                            { name: 'Частично выполнен', style: 'warning' },
                            { name: 'Не выполнен', style: 'error' },
                            { name: 'В пути', style: 'onWay' },
                        ]}
                        defaultOption={{ name: 'Статус' }}
                        onOptionChange={(value) => setItemParams(prev => ({
                            ...prev,
                            status: value.name
                        }))}
                        fieldName={'status'}
                        value={itemParams.status}
                        externalStyle={style}
                    />
                </div>



                <div className={style.column}>
                    <Checkbox
                        checked={itemParams.complete}
                        color="primary"
                        size="large"
                        onChange={() => setItemParams(prev => ({
                            ...prev,
                            completed: !prev.completed
                        }))}
                    />
                    <span>Проведено</span>

                </div>

            </div>

            <div className={style.rigth}>

                {itemParams.formTitle === 'Заказ поставщику'
                    &&
                    id
                    &&
                    <button onClick={() => navigate('/purchase/acceptance/item/')} className={style.newInstock}>
                        <PlusIcon />
                        <span>Создать приемку от заказа</span>
                    </button>
                }

                {
                    itemParams.formTitle === 'Заказ поставщику'
                    &&
                    id
                    &&
                    <button onClick={() => toggleAcceptance(prev => !prev)} className={style.chain}>
                        <ChainIcon />
                        <span>Приемки</span>
                    </button>
                }

                {id
                    &&
                    <button onClick={() => togglePays(prev => !prev)} className={style.chain}>
                        <ChainIcon />
                        <span>Платежи</span>
                    </button>
                }

            </div>

            {id
                &&
                showAcceptance
                &&
                <AcceptanceList id={id} show={showAcceptance} toggle={(val) => toggleAcceptance(val) } />
            }

            {id
                &&
                showPays
                &&
                <PaysList id={id} show={showPays} name={itemParams.itemType} toggle={(val) => togglePays(val) } />
            }

        </div>
    )
}

export default TopRow