// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eeFdK7ffJ7_vDu2zBBAn {\n  background: rgb(234, 245, 255);\n  border: 1px solid rgb(234, 245, 255);\n  border-radius: 8px;\n  padding: 12px 20px;\n  width: 100%;\n  display: inline-flex;\n  justify-content: space-between;\n  font-size: 14px;\n  line-height: 1;\n  align-items: center;\n  box-sizing: border-box;\n  height: 40px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/fsd/entities/select-expense/ui/select-expense.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,oCAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,oBAAA;EACA,8BAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;AACJ","sourcesContent":[".container {\n    background: rgb(234, 245, 255);\n    border: 1px solid rgb(234, 245, 255);\n    border-radius: 8px;\n    padding: 12px 20px;\n    width: 100%;\n    display: inline-flex;\n    justify-content: space-between;\n    font-size: 14px;\n    line-height: 1;\n    align-items: center;\n    box-sizing: border-box;\n    height: 40px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "eeFdK7ffJ7_vDu2zBBAn"
};
export default ___CSS_LOADER_EXPORT___;
