// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IuaYMQG7he1MkSxLv6OB {\n  border-radius: 8px;\n  border: 1px solid var(--color-gray-light, #dce7f1);\n  padding: 13px 20px !important;\n  box-sizing: border-box;\n  color: var(--color-black, #1E1E1E);\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: 0.32px;\n  width: 100px;\n  transition: 0.3s;\n}\n.IuaYMQG7he1MkSxLv6OB:focus {\n  outline: none;\n  border-color: #429eff;\n}", "",{"version":3,"sources":["webpack://./src/components/custom/NewCustomInput/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kDAAA;EACA,6BAAA;EACA,sBAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,qBAAA;AACJ","sourcesContent":[".input {\n  border-radius: 8px;\n  border: 1px solid var(--color-gray-light, #dce7f1);\n  padding: 13px 20px !important;\n  box-sizing: border-box;\n  color: var(--color-black, #1E1E1E);\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: 0.32px;\n  width: 100px;\n  transition: .3s;\n\n  &:focus {\n    outline: none;\n    border-color: #429eff;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "IuaYMQG7he1MkSxLv6OB"
};
export default ___CSS_LOADER_EXPORT___;
