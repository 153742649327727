import React from "react";

const ShowMore = _ => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3 8.5H13M8 13.5V3.5" />
        </svg>
    )
}

export default ShowMore