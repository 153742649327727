import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import icon1 from '../../Landing/images/🤓3.svg';
import icon2 from '../../Landing/images/🤓2.svg';
import icon3 from '../../Landing/images/🤓.svg';
import icon4 from '../../Landing/images/🤓custom.svg';
import { NewCustomInput } from '../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import sortBy from 'lodash/sortBy';

function SettingsPreset({ onClose }) {
  const [presets, setPresets] = useState([
    {
      id: 0,
      name: 'Новичок',
      settings: [
        {
          id: 0,
          name: 'Характеристики',
          checked: [0, 2, 4],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
        {
          id: 1,
          name: 'Закупки',
          checked: [0, 2, 1],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
      ],
    },
    {
      id: 1,
      name: 'Продвинутый',
      settings: [
        {
          id: 0,
          name: 'Характеристики',
          checked: [0, 3, 4],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
        {
          id: 1,
          name: 'Закупки',
          checked: [0, 2],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
      ],
    },
    {
      id: 2,
      name: 'Мегамозг',
      settings: [
        {
          id: 0,
          name: 'Характеристики',
          checked: [0, 5, 4],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
        {
          id: 1,
          name: 'Закупки',
          checked: [0],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
      ],
    },
  ]);

  const [activePreset, setActivePreset] = useState({
    id: 0,
    name: 'Название',
    settings: [
      {
        id: 0,
        name: 'Характеристики',
        checked: [0, 2, 4],
        values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
      },
      {
        id: 1,
        name: 'Закупки',
        checked: [0, 2, 1],
        values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
      },
    ],
  });

  const [name, changeName] = useState('');

  useEffect(() => {
    changeName(activePreset.name);
  }, [activePreset]);

  const selectCheck = (checkId, settingId) => {
    const newPreset = {
      ...activePreset,
      settings: activePreset.settings.map((temp) => {
        if (settingId === temp.id) {
          return {
            ...temp,
            checked: temp.checked.includes(checkId)
              ? [...temp.checked.filter((check) => check !== checkId)]
              : [...temp.checked, checkId],
          };
        }
        return temp;
      }),
    };

    setActivePreset(newPreset);
    setPresets(
      sortBy(
        [
          ...presets.filter((temp) => temp.id !== activePreset.id),
          { ...newPreset },
        ],
        'id'
      )
    );
  };

  const selectAll = (settingId) => {
    setActivePreset({
      ...activePreset,
      settings: activePreset.settings.map((temp) => {
        if (temp.id === settingId) {
          if (temp.values.length !== temp.checked.length) {
            return {
              ...temp,
              checked: temp.values.map((value, index) => index),
            };
          }
          return { ...temp, checked: [] };
        }
        return {
          ...temp,
        };
      }),
    });
    setPresets(
      sortBy(
        [
          ...presets.filter((temp) => temp.id !== activePreset.id),
          {
            ...activePreset,
            settings: activePreset.settings.map((temp) => {
              if (temp.id === settingId) {
                if (temp.values.length !== temp.checked.length) {
                  return {
                    ...temp,
                    checked: temp.values.map((value, index) => index),
                  };
                }
                return { ...temp, checked: [] };
              }
              return {
                ...temp,
              };
            }),
          },
        ],
        'id'
      )
    );
  };

  const addNewPreset = () => {
    setPresets([
      ...presets,
      {
        id: presets[presets.length - 1].id + 1,
        name: '',
        settings: [
          {
            id: 0,
            name: 'Характеристики',
            checked: [],
            values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
          },
          {
            id: 1,
            name: 'Закупки',
            checked: [],
            values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
          },
        ],
      },
    ]);
    setActivePreset({
      id: presets[presets.length - 1].id + 1,
      name: '',
      settings: [
        {
          id: 0,
          name: 'Характеристики',
          checked: [],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
        {
          id: 1,
          name: 'Закупки',
          checked: [],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
      ],
    });
  };

  const nameChange = () => {
    setPresets([
      ...presets.filter((temp) => temp.id !== activePreset.id),
      {
        id: presets[presets.length - 1].id + 1,
        name: name,
        settings: [
          {
            id: 0,
            name: 'Характеристики',
            checked: [],
            values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
          },
          {
            id: 1,
            name: 'Закупки',
            checked: [],
            values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
          },
        ],
      },
    ]);
    setActivePreset({
      id: presets[presets.length - 1].id + 1,
      name: name,
      settings: [
        {
          id: 0,
          name: 'Характеристики',
          checked: [],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
        {
          id: 1,
          name: 'Закупки',
          checked: [],
          values: ['Баркод', 'Наименование', 'Состав', 'Размер', 'Цвет'],
        },
      ],
    });
  };

  const removePreset = (id) => {
    setPresets((state) => [...state.filter((temp) => temp.id !== id)]);
    setActivePreset(presets[0]);
  };

  return (
    <>
      <span className={styles.bgClose} onClick={onClose} />
      <div className={styles.content}>
        <h2 className={styles.title}>Настройки таблицы</h2>
        <div className={styles.presets}>
          {presets.map((item) => (
            <button
              onClick={(e) => {
                if (e.target.getAttribute('id') !== 'remove') {
                  setActivePreset(item);
                } else {
                }
              }}
              className={`${styles.preset} ${
                activePreset.id === item.id ? styles.active : ''
              }`}
            >
              {item.name && (
                <span className={styles.hoverName}>{item.name}</span>
              )}
              {item.id > 2 && activePreset.id === item.id && (
                <button
                  id="remove"
                  onClick={() => removePreset(item.id)}
                  className={styles.remove}
                >
                  <svg
                    id="remove"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      id="remove"
                      d="M6.68711 6.00006L9.60746 3.07956C9.79751 2.88959 9.79751 2.58244 9.60746 2.39247C9.4175 2.20251 9.11036 2.20251 8.92039 2.39247L5.99996 5.31297L3.07961 2.39247C2.88955 2.20251 2.5825 2.20251 2.39254 2.39247C2.20249 2.58244 2.20249 2.88959 2.39254 3.07956L5.31289 6.00006L2.39254 8.92055C2.20249 9.11052 2.20249 9.41767 2.39254 9.60764C2.48721 9.7024 2.61168 9.75 2.73607 9.75C2.86046 9.75 2.98485 9.7024 3.07961 9.60764L5.99996 6.68714L8.92039 9.60764C9.01515 9.7024 9.13954 9.75 9.26393 9.75C9.38832 9.75 9.5127 9.7024 9.60746 9.60764C9.79751 9.41767 9.79751 9.11052 9.60746 8.92055L6.68711 6.00006Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}
              {item.id === 0 ? (
                <img src={icon1} alt="" className={styles.icon} />
              ) : item.id === 1 ? (
                <img src={icon2} alt="" className={styles.icon} />
              ) : item.id === 2 ? (
                <img src={icon3} alt="" className={styles.icon} />
              ) : (
                <img src={icon4} alt="" className={styles.icon} />
              )}
            </button>
          ))}
          <button onClick={addNewPreset} className={styles.addPreset}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M1.73693 8.73693H7.26307V14.2631C7.26307 14.4585 7.34071 14.646 7.47891 14.7842C7.61712 14.9224 7.80456 15 8 15C8.19545 15 8.38289 14.9224 8.52109 14.7842C8.65929 14.646 8.73693 14.4585 8.73693 14.2631V8.73693H14.2631C14.4585 8.73693 14.646 8.65929 14.7842 8.52109C14.9224 8.38289 15 8.19545 15 8C15 7.80455 14.9224 7.61711 14.7842 7.47891C14.646 7.34071 14.4585 7.26307 14.2631 7.26307H8.73693V1.73693C8.73693 1.54148 8.65929 1.35404 8.52109 1.21584C8.38289 1.07764 8.19545 1 8 1C7.80456 1 7.61712 1.07764 7.47891 1.21584C7.34071 1.35404 7.26307 1.54148 7.26307 1.73693V7.26307H1.73693C1.54148 7.26307 1.35404 7.34071 1.21584 7.47891C1.07764 7.61711 1 7.80455 1 8C1 8.19545 1.07764 8.38289 1.21584 8.52109C1.35404 8.65929 1.54148 8.73693 1.73693 8.73693Z"
                fill="#429EFF"
              />
            </svg>
          </button>
        </div>
        <NewCustomInput
          onBlur={nameChange}
          disabled={activePreset.id < 3}
          onChange={changeName}
          value={name}
          width={'100%'}
          placeholder={'Введите название'}
        />
        <div className={styles.settings}>
          {activePreset.settings.map((item) => (
            <div className={styles.block}>
              <div className={styles.top}>
                <p className={styles.blockTitle}>{item.name}</p>
                <span />
                <button
                  onClick={() => selectAll(item.id)}
                  className={styles.selectAll}
                >
                  Выбрать все
                  <div
                    className={`${styles.checkbox} ${
                      item.checked.length === item.values.length
                        ? styles.active
                        : ''
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                    >
                      <path
                        d="M4.33464 9.58301L0.167969 5.44968L1.49297 4.16634L4.33464 6.95801L10.5096 0.833008L11.8346 2.14967L4.33464 9.58301Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </button>
              </div>
              {item.values.map((temp, index) => (
                <div className={styles.check}>
                  <button
                    onClick={() => selectCheck(index, item.id)}
                    className={`${styles.checkbox} ${
                      item.checked.includes(index) ? styles.active : ''
                    }`}
                  >
                    {item.checked.includes(index) && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                      >
                        <path
                          d="M4.33464 9.58301L0.167969 5.44968L1.49297 4.16634L4.33464 6.95801L10.5096 0.833008L11.8346 2.14967L4.33464 9.58301Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </button>
                  {temp}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <button className={styles.save}>Сохранить</button>
          <button className={styles.resetChanges} onClick={onClose}>
            Сбросить
          </button>
        </div>
      </div>
    </>
  );
}

export default SettingsPreset;
