import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { StaffStore } from '../../store/Staff/StaffStore';
import InputWithLabel from '../../components/custom/InputWithLabel.jsx';
import useOnClickOutside from '../../core/Hooks/useOnClickOutside';
import CustomSelect from '../../components/custom/CustomSelect/CustomSelect.js';
import { ProductRow } from '../Analytics/Projects/AddProjectModal/ProductRow/index.jsx';
import { API_URL, delCashbox, getData } from '../../static/js/request';
import cx from 'classnames';
import { createNotification, formatNumber2 } from '../../static/js/commonFunc';
import { InputWrap } from '../Account/Lc/Account/Account.jsx';
import CheckboxList from '../Account/Lc/AccountStaff/CheckboxList/index.jsx';
import PaymentHistory from '@fsd/feature/payment-history/ui/payment-history';

const EmployeeCard = observer(() => {
    const [state] = useState(() => new StaffStore());
    const [allProducts, setAllProducts] = useState([]);
    const [addedProducts, setAddedProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchValue, changeSearchValue] = useState('');
    const [showAllProducts, setShowAllProducts] = useState(false);
    const searchRef = useRef();
    const [activeTab, setActiveTab] = useState('TabOne');
    const [showAddedKpi, setShowAddedKpi] = useState(false);
    const [isChangeKpi, setIsChangeKpi] = useState(false);
    const AddedKpiRef = useRef(null);
    const [calculation_method_id, setCalculation_method_id] = useState('');
    const [kpi_value, setKpi_value] = useState('');
    const [kpi_size, setKpi_size] = useState('');
    const [kpiId, setKpiId] = useState('');
    const [isShowKpiValue, setIsShowKpiValue] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = (id) => {
        setActiveDropdown(activeDropdown === id ? null : id);
    };

    const getProducts = async () => {
        const response = await getData(API_URL + '/api/v1/general/user/products/');
        setAllProducts([...response]);
    };

    const selectAll = (list, func) => {
        func([...list]);
    };

    const addAll = () => {
        if (selectedProducts.length > 0) {
            setAllProducts([
                ...allProducts.filter((item) => !selectedProducts.includes(item.id)),
            ]);
            setAddedProducts([
                ...addedProducts,
                ...allProducts.filter((item) => selectedProducts.includes(item.id)),
            ]);
            setSelectedProducts([]);
        }
    };

    let show = true;

    const outsideClick = (e) => {
        if (show) {
            if (e.target.getAttribute('id') !== 'noCloseModal') {
                setShowAllProducts(false);
                show = false;
            } else {
                searchRef.current.focus();
            }
        }
    };

    useEffect(() => {
        if (showAllProducts) {
            document.addEventListener('click', outsideClick);
        } else {
            document.removeEventListener('click', outsideClick);
        }
    }, [showAllProducts]);

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActiveDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const togleAddedKpi = (isOpen) => {
        if (isOpen) {
            setIsChangeKpi(state.isChange);
            state.isChange = showAddedKpi;
        } else {
            state.isChange = isChangeKpi;
        }
        setShowAddedKpi(!showAddedKpi);
        setCalculation_method_id('');
        setKpi_value('');
        setKpi_size('');
        setKpiId('');
        setAddedProducts([]);
    };

    useOnClickOutside(AddedKpiRef, () => togleAddedKpi(false));

    const handleOptionChange = (option, fieldName) => {
        console.log(option, fieldName)
        state.newField.staff[fieldName] = option;
        if (fieldName === 'position') {
            state.newField.staff['divisions'] = state.positions.find(item => item.name === option.name).division.name;
        }
        state.isChange = true;
    };

    const handleOptionCalculationMethod = (option) => {
        setCalculation_method_id(option.id);
        setIsShowKpiValue(option.kpi_value);
    };

    const handleInputStaffChange = (value, name) => {
        state.newField.staff[name] = value;
        state.isChange = true;
        if (name === 'phone') {
            state.newField.staff[name] = value.replace(/\D/g, '');
        }
    };

    const handleKassaChange = (e) => {
        state.isChange = true;
        let value = e.target.value;
        const regex = /^(\d{1,3}(,\d{3})*(\.\d{0,2})?|\d+(\.\d{0,2})?)$/;
        value = value.replace(/,/g, '.'); // замена запятых на точки

        if (regex.test(value) || value === '') {
            state.newField.wage.cash = value;
        }
    };

    const handleRsChange = (e) => {
        state.isChange = true;
        let value = e.target.value;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(value) || value === '') {
            state.newField.wage.payment_account = value;
        }
    };

    useEffect(() => {
        const kassa = state.newField.wage.cash
            ? parseInt(state.newField.wage.cash.replace(/\s/g, ''), 10)
            : 0;
        const rs = state.newField.wage.payment_account
            ? parseInt(state.newField.wage.payment_account.replace(/\s/g, ''), 10)
            : 0;
        state.newField.wage.salary = kassa + rs;
    }, [state.newField.wage.cash, state.newField.wage.payment_account]);

    const addedKpi = () => {
        setAddedProducts(allProducts.filter((item) => selectedProducts.includes(item.id)));
        const newKpi = {
            articles: selectedProducts.length === 0 ? addedProducts : [...addedProducts, ...allProducts.filter((item) => selectedProducts.includes(item.id))],
            calculation_method_id: calculation_method_id,
            kpi_value: kpi_value,
            kpi_size: kpi_size,
        };
        console.log(newKpi);
        if (state.newField.kpi[kpiId]) {
            state.newField.kpi[kpiId].articles = newKpi.articles;
            state.newField.kpi[kpiId].calculation_method_id =
                newKpi.calculation_method_id;
            state.newField.kpi[kpiId].kpi_value = newKpi.kpi_value;
            state.newField.kpi[kpiId].kpi_size = newKpi.kpi_size;
        } else {
            state.newField.kpi.push(newKpi);
        }

        setCalculation_method_id('');
        setKpi_value('');
        setKpi_size('');
        setKpiId('');
        setShowAddedKpi(false);
        setSelectedProducts([]);
        state.isChange = true;
    };

    const addedEmployee = () => {
        navigate('/staff/');
        state.createEmployees(false, organisations);
        state.isChange = false;
    };

    const navigate = useNavigate();

    const addedEmployeeReset = () => {
        state.createEmployees(true);
        navigate('/staff/card/');
        state.isChange = false;
        state.newField.wage.cash = 0;
        state.newField.wage.payment_account = 0;
    };

    const removeKpi = (index) => {
        const fieldId = state.newField.kpi[index].id;

        delCashbox(API_URL + `/api/v1/employees/kpi-delete/${fieldId}/`).then(
            (data) => {
                createNotification(data);
                if (data.ok) {
                    state.newField.kpi.splice(index, 1);
                }
            }
        );
    };

    const changeKpi = (index) => {
        setShowAddedKpi(!showAddedKpi);
        state.isChange = false;
        console.log(state.newField.kpi[index].articles);
        setCalculation_method_id(
            state.newField.kpi[index].calculation_method_id ||
            state.newField.kpi[index].calculation_method
        );
        setKpi_value(state.newField.kpi[index].kpi_value);
        setKpi_size(state.newField.kpi[index].kpi_size);
        setKpiId(index);
        setAddedProducts(state.newField.kpi[index].articles);
        setIsShowKpiValue(
            state.calculationMethod.find(
                (obj) => obj.id === state.newField.kpi[index].calculation_method_id
            ).kpi_value
        );
        calculatePosition();
    };

    const handleKpiSizeChange = (e) => {
        const value = e.target.value;
        setKpi_size(value);
    };

    const inputRef = useRef(null);
    const [inputLength, setInputLength] = useState('');

    const calculatePosition = () => {
        if (inputRef.current) {
            setInputLength(kpi_size.length * 8.5 + 30);
        }
    };

    useEffect(() => {
        calculatePosition();
    }, [kpi_size]);


    const [organisations, setOrganisations] = useState([]);

    useEffect(() => {
        setOrganisations(state.newField.staff.organisation ? [state.newField.staff.organisation] : []);
    }, [state.newField.staff.organisation]);
    
    const changeTab = (tab) => {
        const queryParameters = new URLSearchParams(window.location.search);
        const id = queryParameters.get('id');
        
        navigate(`/staff/card/?id=${id}&tab=${tab}`);
    };

    const location = useLocation();
    
    useLayoutEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const tab = queryParameters.get('tab');

        setActiveTab(tab || 'TabOne');
    }, [location]);

    if (!state.newField.staff.id) {
        return <div className={style.loading}>Загрузка данных сотрудника...</div>;
    }

    return (
        <section>
            <div className={style.header}>
                <div className={style.headerTitle}>
                    <NavLink
                        to={'/staff/'}
                        className={({ isActive }) => (isActive ? style['active'] : '')}
                    >
                        <svg
                            width="17"
                            height="13"
                            viewBox="0 0 17 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16 7.25C16.4142 7.25 16.75 6.91421 16.75 6.5C16.75 6.08579 16.4142 5.75 16 5.75L16 7.25ZM0.46967 5.96967C0.176777 6.26256 0.176777 6.73744 0.46967 7.03033L5.24264 11.8033C5.53553 12.0962 6.01041 12.0962 6.3033 11.8033C6.59619 11.5104 6.59619 11.0355 6.3033 10.7426L2.06066 6.5L6.3033 2.25736C6.59619 1.96447 6.59619 1.48959 6.3033 1.1967C6.01041 0.903806 5.53553 0.903806 5.24264 1.1967L0.46967 5.96967ZM16 5.75L1 5.75L1 7.25L16 7.25L16 5.75Z"
                                fill="#429EFF"
                            />
                        </svg>
                    </NavLink>
                    <div>
                        <span>Карточка сотрудника</span>
                    </div>
                </div>
            </div>

            <div className={style.cardContainer}>
                <div className={style.cardContainerLeft}>
                    <div className={style.cardContainerImg}>
                        <div>
                            <svg
                                width="95"
                                height="95"
                                viewBox="0 0 95 95"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M81.5022 87.0833C81.5022 71.7645 66.2626 59.375 47.5001 59.375C28.7376 59.375 13.498 71.7645 13.498 87.0833M47.5001 47.5C52.7492 47.5 57.7833 45.4148 61.495 41.7031C65.2066 37.9915 67.2918 32.9574 67.2918 27.7083C67.2918 22.4592 65.2066 17.4251 61.495 13.7135C57.7833 10.0018 52.7492 7.91663 47.5001 7.91663C42.2511 7.91663 37.217 10.0018 33.5053 13.7135C29.7937 17.4251 27.7085 22.4592 27.7085 27.7083C27.7085 32.9574 29.7937 37.9915 33.5053 41.7031C37.217 45.4148 42.2511 47.5 47.5001 47.5Z"
                                    stroke="#DCE7F1"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={style.cardContainerInfo}>
                    <div className={style.cardContainerInfoTabs}>
                        <button
                            className={`${style.cardContainerInfoTab} ${
                                activeTab === 'TabOne' ? style.cardContainerInfoTabActive : ''
                            }`}
                            onClick={() => {
                                changeTab('TabOne');
                            }}
                        >
                            Информация
                        </button>
                        <button
                            className={`${style.cardContainerInfoTab} ${
                                activeTab === 'TabTwo' ? style.cardContainerInfoTabActive : ''
                            }`}
                            onClick={() => changeTab('TabTwo')}
                        >
                            Заработная плата
                        </button>
                        <button
                            className={`${style.cardContainerInfoTab} ${
                                activeTab === 'TabThree' ? style.cardContainerInfoTabActive : ''
                            }`}
                            onClick={() => changeTab('TabThree')}
                        >
                            KPI
                        </button>
                        <button
                            className={`${style.cardContainerInfoTab} ${
                                activeTab === 'TabFour' ? style.cardContainerInfoTabActive : ''
                            }`}
                            onClick={() => changeTab('TabFour')}
                        >
                            История выплат
                        </button>
                    </div>
                    {activeTab === 'TabFour' && <PaymentHistory staffId={state.newField.staff.id} />}
                    <div className={style.cardContainerInfoContent}>
                        {/*TODO: добавить звездочку на обязательные поля*/}
                        {/*TODO: добавить проверку перед отправкой на заполнение этих полей*/}
                        {activeTab === 'TabOne' && (
                            <div className={style.oneTab}>
                                <div className={style.staffFio}>
                                    <InputWithLabel
                                        containerStyle={style.containerInput}
                                        fieldName="Фамилия"
                                        name="last_name"
                                        value={state.newField.staff.last_name || ''}
                                        type="text"
                                        placeholder="Введите фамилию"
                                        onInputChange={handleInputStaffChange}

                                    />
                                    <InputWithLabel
                                        containerStyle={style.containerInput}
                                        fieldName="Имя"
                                        name="first_name"
                                        value={state.newField.staff.first_name || ''}
                                        type="text"
                                        placeholder="Введите имя"
                                        onInputChange={handleInputStaffChange}
                                    />
                                    <InputWithLabel
                                        containerStyle={style.containerInput}
                                        fieldName="Отчество"
                                        name="middle_name"
                                        value={state.newField.staff.middle_name || ''}
                                        type="text"
                                        placeholder="Введите отчество"
                                        onInputChange={handleInputStaffChange}
                                    />
                                </div>

                                <div className={style.orgsChecks}>
                                    <p className={style.orgsTitle}>
                                        Организации
                                    </p>
                                    <CheckboxList activeList={state.organizations2.filter(item => organisations.includes(item.id)).map(item => item.name)} onChange={(names) => {
                                        setOrganisations(names.map(name => (
                                            state.organizations2.find(item => item.name === name).id
                                        )));
                                    }} noActiveStyles values={state.organizations2.map(item => item.name)} title={
                                        organisations.length > 0
                                            ? state.organizations2.find(item => item.id === organisations[0])?.name
                                            : 'Выберите организацию'
                                    }/>
                                </div>

                                <div className={style.oneTabTwoItem}>
                                    <InputWrap
                                        title={
                                            <>
                                                Подразделение
                                            </>
                                        }
                                        value={state.newField.staff.divisions || ''}
                                        placeholder="Подразделение"
                                        allValues={[]}
                                        width="100%"
                                        disabled
                                        textAlign="left"
                                    />

                                    <CustomSelect
                                        options={state.positions}
                                        defaultOption={{ name: 'Выберите должность' }}
                                        title="Должность"
                                        onOptionChange={handleOptionChange}
                                        width={'100%'}
                                        fieldName="position"
                                        value={state.newField.staff.position || ''}
                                    />
                                </div>

                                <div className={style.oneTabTwoItem}>
                                    <InputWithLabel
                                        containerStyle={style.containerInput}
                                        fieldName="Телефон"
                                        name="phone"
                                        fieldType="phone"
                                        value={state.newField.staff.phone || ''}
                                        placeholder="Введите номер"
                                        onInputChange={handleInputStaffChange}
                                    />
                                    <InputWithLabel
                                        containerStyle={style.containerInput}
                                        fieldName="Электронная почта"
                                        name="email"
                                        fieldType="email"
                                        value={state.newField.staff.email || ''}
                                        type="text"
                                        placeholder="Введите электронную почту"
                                        onInputChange={handleInputStaffChange}
                                    />
                                </div>
                            </div>
                        )}
                        {activeTab === 'TabTwo' && (
                            <div className={style.TabTwo}>
                                <div className={style.staffPayment}>
                                    <InputWithLabel
                                        containerStyle={style.containerInput}
                                        fieldName="Оклад"
                                        name="salary"
                                        disabled={true}
                                        type="text"
                                        value={formatNumber2(state.newField.wage.salary)}
                                    />
                                    <span className={style.sign}>=</span>
                                    <div className={style.containerInput}>
                                        <label>Касса</label>
                                        <input
                                            name="cash"
                                            type="text"
                                            placeholder="Введите долю кассы"
                                            /* value={cashValue || state.newField.wage.cash.replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace(/\./, ',')}*/
                                            value={state.newField.wage.cash || ''}
                                            onChange={handleKassaChange}
                                        />
                                    </div>

                                    <span className={style.sign}>+</span>

                                    <div className={style.containerInput}>
                                        <label>Расчетный счет</label>
                                        <input
                                            name="cash"
                                            type="text"
                                            placeholder="Введите долю РС"
                                            value={state.newField.wage.payment_account || ''}
                                            onChange={handleRsChange}
                                        />
                                    </div>
                                </div>

                                <div className={style.explanation}>
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.49936 6.43584C8.64204 6.43543 8.77875 6.37857 8.87964 6.27769C8.98052 6.1768 9.03738 6.04009 9.03779 5.89741V5.35898C9.03779 5.21618 8.98106 5.07923 8.88009 4.97826C8.77912 4.87728 8.64216 4.82056 8.49936 4.82056C8.35657 4.82056 8.21961 4.87728 8.11864 4.97826C8.01767 5.07923 7.96094 5.21618 7.96094 5.35898V5.89741C7.96135 6.04009 8.0182 6.1768 8.11909 6.27769C8.21998 6.37857 8.35669 6.43543 8.49936 6.43584Z"
                                            fill="#8496AF"
                                        />
                                        <path
                                            d="M7.42319 8.58955H7.96162V11.6408C7.96162 11.7836 8.01835 11.9205 8.11932 12.0215C8.2203 12.1225 8.35725 12.1792 8.50005 12.1792C8.64285 12.1792 8.7798 12.1225 8.88077 12.0215C8.98175 11.9205 9.03848 11.7836 9.03848 11.6408V8.05112C9.03807 7.90845 8.98121 7.77174 8.88032 7.67085C8.77943 7.56996 8.64272 7.5131 8.50005 7.5127H7.42319C7.28039 7.5127 7.14344 7.56942 7.04247 7.6704C6.94149 7.77137 6.88477 7.90832 6.88477 8.05112C6.88477 8.19392 6.94149 8.33088 7.04247 8.43185C7.14344 8.53283 7.28039 8.58955 7.42319 8.58955Z"
                                            fill="#8496AF"
                                        />
                                        <path
                                            d="M8.5 15.5C9.88447 15.5 11.2378 15.0895 12.389 14.3203C13.5401 13.5511 14.4373 12.4579 14.9672 11.1788C15.497 9.8997 15.6356 8.49224 15.3655 7.13437C15.0954 5.7765 14.4287 4.52922 13.4497 3.55026C12.4708 2.57129 11.2235 1.9046 9.86563 1.63451C8.50776 1.36441 7.1003 1.50303 5.82121 2.03285C4.54213 2.56266 3.44888 3.45987 2.67971 4.61101C1.91054 5.76215 1.5 7.11553 1.5 8.5C1.50203 10.3559 2.24017 12.1352 3.55249 13.4475C4.8648 14.7598 6.6441 15.498 8.5 15.5ZM8.5 2.50473C9.68575 2.50473 10.8449 2.85635 11.8308 3.51512C12.8167 4.17388 13.5851 5.11022 14.0389 6.20571C14.4927 7.3012 14.6114 8.50665 14.3801 9.66962C14.1487 10.8326 13.5777 11.9008 12.7393 12.7393C11.9008 13.5778 10.8326 14.1487 9.66962 14.3801C8.50665 14.6114 7.3012 14.4927 6.20571 14.0389C5.11021 13.5851 4.17388 12.8167 3.51511 11.8308C2.85634 10.8449 2.50473 9.68575 2.50473 8.5C2.50593 6.91033 3.13796 5.38611 4.26203 4.26204C5.3861 3.13797 6.91032 2.50594 8.5 2.50473Z"
                                            fill="#8496AF"
                                        />
                                    </svg>
                                    Оклад высчитывается из суммы полей «Касса» и «Расчетный счет»
                                </div>
                            </div>
                        )}
                        {activeTab === 'TabThree' && (
                            <div className={style.kpi}>
                                {showAddedKpi && (
                                    <div className={style.addedKpiBlock} ref={AddedKpiRef}>
                                        <div className={style.addedKpiBlockField}>
                                            <CustomSelect
                                                options={state.calculationMethod}
                                                defaultOption={{ name: 'Выберите метод расчета' }}
                                                title="Метод расчета"
                                                onOptionChange={handleOptionCalculationMethod}
                                                width={'100%'}
                                                fieldName="calculation_method_id"
                                                value={calculation_method_id || ''}
                                            />
                                            {isShowKpiValue && (
                                                <InputWithLabel
                                                    containerStyle={style.containerInput}
                                                    fieldName="Целевой показатель"
                                                    name="kpi_value"
                                                    value={kpi_value || ''}
                                                    type="text"
                                                    placeholder="-"
                                                    onInputChange={(value) => setKpi_value(value)}
                                                />
                                            )}
                                            <div className={style.containerInput}>
                                                <label>Процент KPI</label>
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    <input
                                                        ref={inputRef}
                                                        name="kpi_size"
                                                        type="text"
                                                        placeholder="Введите процент"
                                                        value={kpi_size || ''}
                                                        onChange={handleKpiSizeChange}
                                                    />
                                                    {kpi_size && (
                                                        <span
                                                            className={style.absolutePercent}
                                                            style={{ left: `${inputLength}px` }}
                                                        >
                                                            %
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.products}>
                                            <p className={style.productTitle}>
                                                Товары <span>{addedProducts.length}</span>
                                            </p>
                                            <div className={style.inputWrap}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M14.9595 16.2565L14.9604 16.2565C15.0525 16.2567 15.1436 16.238 15.2282 16.2013C15.3124 16.1648 15.3883 16.1114 15.4512 16.0444C15.5783 15.9119 15.6491 15.7349 15.6491 15.551C15.6491 15.3667 15.578 15.1895 15.4505 15.0569L15.4505 15.0569L15.4491 15.0555L11.1147 10.6657C12.1118 9.50981 12.6473 8.01811 12.6135 6.48214C12.5786 4.89865 11.9413 3.38935 10.8335 2.26807C9.72568 1.14675 8.23258 0.499759 6.66447 0.462093C5.09637 0.424426 3.5745 0.998998 2.41517 2.06591C1.25589 3.13278 0.548326 4.60982 0.439056 6.18986C0.329786 7.76988 0.827171 9.33192 1.82821 10.5513C2.82929 11.7706 4.25714 12.5536 5.81516 12.7367C7.32956 12.9148 8.85316 12.5128 10.0866 11.6133L14.4719 16.0458C14.5351 16.1115 14.6106 16.1639 14.694 16.2001L14.7537 16.0625L14.694 16.2001C14.7779 16.2365 14.8681 16.2556 14.9595 16.2565ZM1.71228 6.53989C1.7123 5.58328 1.993 4.64828 2.51869 3.85313C3.04439 3.05796 3.79143 2.43845 4.66515 2.07268C5.53885 1.70692 6.50015 1.61125 7.42756 1.79769C8.35498 1.98413 9.20709 2.44438 9.87604 3.12047C10.545 3.79658 11.0008 4.65819 11.1854 5.59643C11.3701 6.53468 11.2753 7.50718 10.9131 8.39089C10.5509 9.27459 9.93773 10.0296 9.15126 10.5607C8.36485 11.0918 7.44046 11.3752 6.49497 11.3752C5.22739 11.374 4.01173 10.8646 3.11492 9.95826C2.21806 9.05183 1.71342 7.82245 1.71228 6.53989Z"
                                                        fill="#8496AF"
                                                        stroke="#8496AF"
                                                        strokeWidth="0.3"
                                                    />
                                                </svg>
                                                <input
                                                    id={'noCloseModal'}
                                                    className={style.searchInput}
                                                    ref={searchRef}
                                                    style={{ paddingLeft: '47px' }}
                                                    onClick={() => {
                                                        setShowAllProducts(true);
                                                    }}
                                                    type="text"
                                                    placeholder={'Поиск товаров'}
                                                    value={searchValue}
                                                    onChange={(e) => changeSearchValue(e.target.value)}
                                                />
                                            </div>
                                            <div
                                                className={style.productsContent}
                                                id={'noCloseModal'}
                                            >
                                                {showAllProducts ? (
                                                    <>
                                                        <div
                                                            id={'noCloseModal'}
                                                            className={style.searchBlock}
                                                        >
                                                            <div
                                                                id={'noCloseModal'}
                                                                className={style.contentTop}
                                                            >
                                                                <p
                                                                    id={'noCloseModal'}
                                                                    className={style.topTitle}
                                                                >
                                                                    Поиск
                                                                </p>
                                                                {/*<button*/}
                                                                {/*    id={'noCloseModal'}*/}
                                                                {/*    className={style.selectAll}*/}
                                                                {/*    onClick={() =>*/}
                                                                {/*        selectAll(*/}
                                                                {/*            allProducts*/}
                                                                {/*                .filter(*/}
                                                                {/*                    (item) =>*/}
                                                                {/*                        (item.name*/}
                                                                {/*                            ?.toLowerCase()*/}
                                                                {/*                            ?.includes(*/}
                                                                {/*                                searchValue.toLowerCase()*/}
                                                                {/*                            ) ||*/}
                                                                {/*                            item.vendorCode*/}
                                                                {/*                                .toLowerCase()*/}
                                                                {/*                                .includes(*/}
                                                                {/*                                    searchValue.toLowerCase()*/}
                                                                {/*                                )) &&*/}
                                                                {/*                        !addedProducts*/}
                                                                {/*                            .map((temp) => temp.id)*/}
                                                                {/*                            .includes(item.id)*/}
                                                                {/*                )*/}
                                                                {/*                .map((item) => item.id),*/}
                                                                {/*            setSelectedProducts*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*>*/}
                                                                {/*    Выбрать все*/}
                                                                {/*</button>*/}
                                                                {/*<button*/}
                                                                {/*    id={'noCloseModal'}*/}
                                                                {/*    className={style.deleteSelected}*/}
                                                                {/*    onClick={addAll}*/}
                                                                {/*>*/}
                                                                {/*    Добавить выбранные*/}
                                                                {/*</button>*/}
                                                            </div>
                                                            <div
                                                                className={style.header_table}
                                                                style={{
                                                                    position: 'sticky',
                                                                    top: '0',
                                                                    background: 'white',
                                                                    zIndex: 10,
                                                                    padding: '10px'
                                                            }}
                                                            >
                                                                <p
                                                                    id={'noCloseModal'}
                                                                    style={{ width: '20px', margin: '0px 10px' }}
                                                                    // className={style.topTitle}
                                                                >
                                                                    Фото
                                                                </p>
                                                                <p
                                                                    id={'noCloseModal'}
                                                                    // className={style.topTitle}
                                                                    style={{ width: '600px' }}
                                                                >
                                                                    Название товара
                                                                </p>
                                                                <p
                                                                    id={'noCloseModal'}
                                                                    // className={style.topTitle}
                                                                    style={{ textAlign: 'center' }}
                                                                >
                                                                    Артикул
                                                                </p>
                                                                <p
                                                                    id={'noCloseModal'}
                                                                    style={{ textAlign: 'center' }}
                                                                    // className={style.topTitle}
                                                                >
                                                                    Размер
                                                                </p>
                                                                <p
                                                                    id={'noCloseModal'}
                                                                    // className={style.topTitle}
                                                                >
                                                                    Действие
                                                                </p>
                                                            </div>
                                                            {allProducts
                                                                .filter(
                                                                    (item) =>
                                                                        (item.name
                                                                                ?.toLowerCase()
                                                                            .includes(searchValue?.toLowerCase()) ||
                                                                            item.vendorCode
                                                                                ?.toLowerCase()
                                                                                .includes(
                                                                                    searchValue?.toLowerCase()
                                                                                )) &&
                                                                        !addedProducts
                                                                            .map((temp) => temp.id)
                                                                            .includes(item.id)
                                                                )
                                                                ?.map((item) => (
                                                                    <ProductRow
                                                                        clickEvent={() =>
                                                                            setAddedProducts([...addedProducts, item])
                                                                        }
                                                                        setSelect={() =>
                                                                            setSelectedProducts([
                                                                                ...selectedProducts,
                                                                                item.id,
                                                                            ])
                                                                        }
                                                                        setUnselected={() =>
                                                                            setSelectedProducts([
                                                                                ...selectedProducts.filter(
                                                                                    (id) => id !== item.id
                                                                                ),
                                                                            ])
                                                                        }
                                                                        select={selectedProducts.includes(item.id)}
                                                                        key={item.id}
                                                                        item={item}
                                                                    />
                                                                ))}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {addedProducts.length > 0 ? (
                                                            addedProducts.map((item) => (
                                                                <ProductRow
                                                                    short
                                                                    clickEvent={() =>
                                                                        setAddedProducts([
                                                                            ...addedProducts.filter(
                                                                                (temp) => temp.id ? temp.id !== item.id : temp !== item
                                                                            ),
                                                                        ])
                                                                    }
                                                                    added
                                                                    key={item.id}
                                                                    item={typeof item === 'number' ? allProducts.find(product => product.id === item) : item}
                                                                />
                                                            ))
                                                        ) : (
                                                            <div className={style.empty}>Добавьте товары</div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className={style.saveKpi} onClick={addedKpi}>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.5 12.9L10.2 15.6L16.5 9.3M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                                    stroke="#429EFF"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            Сохранить
                                        </div>
                                    </div>
                                )}

                                <div
                                    className={cx(
                                        style.btnAddedBlock,
                                        showAddedKpi ? style.none : ''
                                    )}
                                    onClick={() => togleAddedKpi(true)}
                                >
                                    + Добавить KPI
                                </div>
                                {!showAddedKpi && (
                                    <div className={style.kpiList} ref={dropdownRef}>
                                        {state.newField.kpi.map((item, index) => (
                                            <div key={index} className={style.kpiListItem}>
                                                <div className={style.kpiListItemField}>
                                                    <div className={style.kpiListItemFieldSubtitle}>
                                                        Метод расчета
                                                    </div>
                                                    <div className={style.kpiListItemFieldvalue}>
                                                        {
                                                            state.calculationMethod.find(
                                                                (obj) =>
                                                                    obj.id === item.calculation_method_id ||
                                                                    item.calculation_method
                                                            )?.name
                                                        }
                                                    </div>
                                                </div>
                                                <div className={style.kpiListItemField}>
                                                    <div className={style.kpiListItemFieldSubtitle}>
                                                        Целевой показатель
                                                    </div>
                                                    <div className={style.kpiListItemFieldvalue}>
                                                        {item.kpi_value
                                                            ? formatNumber2(item.kpi_value)
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className={style.kpiListItemField}>
                                                    <div className={style.kpiListItemFieldSubtitle}>
                                                        Процент
                                                    </div>
                                                    <div className={style.kpiListItemFieldvalue}>
                                                        {item.kpi_size} %
                                                    </div>
                                                </div>
                                                <div className={style.kpiListItemField}>
                                                    <div className={style.kpiListItemFieldSubtitle}>
                                                        Товары
                                                    </div>
                                                    <div className={style.kpiListItemFieldvalue}>
                                                        {item.articles.length}
                                                    </div>
                                                </div>

                                                <span
                                                    onClick={() => toggleDropdown(index)}
                                                    className={style.optionButton}
                                                >
                                                    ...
                                                </span>
                                                {activeDropdown === index && (
                                                    <div className={style.dropdownItem}>
                                                        <button
                                                            onClick={() => {
                                                                changeKpi(index);
                                                            }}
                                                            className={style.edit}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <g clipPath="url(#clip0_2216_16800)">
                                                                    <path
                                                                        d="M4.5804 15.102L4.58033 15.102L0.781218 15.8056C0.617924 15.8359 0.449955 15.7839 0.332246 15.6662C0.214662 15.5486 0.162561 15.3807 0.19282 15.2172L0.241985 15.2263L0.19282 15.2172L0.896348 11.4178C0.89635 11.4178 0.896353 11.4178 0.896356 11.4178L0.896361 11.4177L0.945525 11.4268L4.5804 15.102ZM4.5804 15.102C4.67792 15.0838 4.77342 15.0346 4.84541 14.9625M4.5804 15.102L4.84541 14.9625M4.84541 14.9625C4.84542 14.9625 4.84542 14.9625 4.84542 14.9625L4.84541 14.9625ZM14.0494 4.32977L4.4884 13.8914L2.10685 11.5097L11.6678 1.94805L11.6325 1.9127L11.6678 1.94805L12.2186 1.39726C12.4872 1.12861 12.9243 1.12858 13.193 1.39726L14.6001 2.80446C14.8694 3.0738 14.8694 3.50961 14.6001 3.77899L14.6355 3.81434L14.6001 3.77899L14.0494 4.32977ZM3.45269 14.2838L1.31946 14.6789L1.7145 12.5455L3.45269 14.2838Z"
                                                                        fill="#1E1E1E"
                                                                        stroke="#1E1E1E"
                                                                        strokeWidth="0.1"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2216_16800">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Изменить
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                removeKpi(index);
                                                            }}
                                                            className={style.remove}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="14"
                                                                height="15"
                                                                viewBox="0 0 14 15"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z"
                                                                    fill="#FF5E5B"
                                                                />
                                                                <path
                                                                    d="M5.21702 12.5267C5.35526 12.5263 5.48772 12.4723 5.58547 12.3764C5.68323 12.2806 5.73832 12.1507 5.73873 12.0151V6.13124C5.73873 5.99555 5.68376 5.86542 5.58592 5.76948C5.48808 5.67353 5.35539 5.61963 5.21702 5.61963C5.07866 5.61963 4.94596 5.67353 4.84812 5.76948C4.75028 5.86542 4.69531 5.99555 4.69531 6.13124V12.0151C4.69572 12.1507 4.75082 12.2806 4.84857 12.3764C4.94632 12.4723 5.07878 12.5263 5.21702 12.5267Z"
                                                                    fill="#FF5E5B"
                                                                />
                                                                <path
                                                                    d="M8.52171 12.5267C8.65995 12.5263 8.79242 12.4723 8.89017 12.3764C8.98792 12.2806 9.04301 12.1507 9.04342 12.0151V6.13124C9.04342 5.99555 8.98845 5.86542 8.89061 5.76948C8.79277 5.67353 8.66008 5.61963 8.52171 5.61963C8.38335 5.61963 8.25065 5.67353 8.15281 5.76948C8.05497 5.86542 8 5.99555 8 6.13124V12.0151C8.00041 12.1507 8.05551 12.2806 8.15326 12.3764C8.25101 12.4723 8.38347 12.5263 8.52171 12.5267Z"
                                                                    fill="#FF5E5B"
                                                                />
                                                            </svg>
                                                            Удалить
                                                        </button>
                                                        <button onClick={() => toggleDropdown(index)}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M10.4031 8.91615L6.19523 12.8099C5.92153 13.0634 5.47898 13.0634 5.20528 12.8099C4.93157 12.5567 4.93157 12.1471 5.20528 11.8939L9.41315 7.99994L5.20528 4.10614C4.93157 3.85274 4.93157 3.44333 5.20528 3.19005C5.47898 2.93665 5.92153 2.93665 6.19523 3.19005L10.4031 7.08385C10.4031 7.08385 11 7.59251 11 7.99994C11 8.40737 10.4031 8.91615 10.4031 8.91615Z"
                                                                    fill="#1E1E1E"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {state.isChange && (
                <div className={style.saveEmployee}>
                    <div className={style.saveEmployeeBtns}>
                        <div className={style.btnSave} onClick={addedEmployee}>
                            Сохранить
                        </div>
                        <NavLink to={'/staff/'} className={style.btnCancel}>
                            Отменить
                        </NavLink>
                    </div>
                    <div className={style.btnSave} onClick={addedEmployeeReset}>
                        Сохранить и добавить еще
                    </div>
                </div>
            )}
        </section>
    );
});

export default EmployeeCard;
