import React from 'react'
import styles from './styles.module.scss'
import MarketplacesSwitch from '../../ui/MarketplacesSwitch/index.jsx';
import Row from './ui/Row/index.jsx';
import { API_URL, delData, getData, postData } from '../../../../static/js/request';
import { Store } from 'react-notifications-component';

function SettingsPage() {

    const [activeMarket, setActiveMarket] = React.useState(1);

    const markets = [
        'ALL', 'WB', 'OZON'
    ]

    const [warehouses, setWarehouses] = React.useState([])
    const [groups, setGroups] = React.useState([])

    const [buttonVsible, setVisible] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const [addGroupModal, setAddGroupModal] = React.useState(false)

    const [modalItem, changeModalItem] = React.useState({
        name: '',
        delivery_time_from_supplier: ''
    })

    const getWarehouses = async () => {
        try {
            setLoading(true)
            const response = await getData(`${API_URL}/api/v1/deliverypalner/warehouses/${markets[activeMarket]}/`)
            setWarehouses(response)
            setLoading(false)
        } catch (error) {

        }
    }

    const getGroups = async () => {
        try {
            const response = await getData(`${API_URL}/api/v1/deliverypalner/product-groups/`)
            setGroups(response)
        } catch (error) {

        }
    }

    const onChangeInput = (text, item) => {
        setWarehouses([...warehouses.map(ware => {
            if (ware.warehouse === item.warehouse) {
                ware.logistics_to_mp = text
            }
            return ware
        })])
        setVisible(true)
    }

    const postChanges = async () => {
        try {
            await postData(`${API_URL}/api/v1/deliverypalner/warehouses/${markets[activeMarket]}/`, [...warehouses.map(item => {
                return {
                    ...item,
                    logistics_to_mp: item.logistics_to_mp ? item.logistics_to_mp : null
                }
            })])
            setVisible(false)
            Store.addNotification({
                title: "Успешно",
                message: "Изменения сохранены",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                }
            });
        } catch (error) {
            Store.addNotification({
                title: "Успешно",
                message: "Что-то пошло не так",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                }
            });
        }
    }

    const deleteGroup = async (id) => {
        try {
            const response = await delData(`${API_URL}/api/v1/deliverypalner/product-groups/${id}/`)
            setGroups([...groups.filter(item => item.id !== id)])
        } catch (error) {

        }
    }

    const addGroup = async () => {
        try {
            const response = await postData(`${API_URL}/api/v1/deliverypalner/product-groups/`, {
                ...modalItem
            })
            response.json().then(temp => {
                setGroups([...groups, {...temp, product_count: 0}])
            })
            setAddGroupModal(false)
            Store.addNotification({
                title: "Успешно",
                message: "Группа добавлена",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                }
            });
        } catch (error) {
            Store.addNotification({
                title: "Успешно",
                message: "Что-то пошло не так",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                }
            });
        }
    }

    React.useEffect(() => {
        getWarehouses()
        getGroups()
    }, [activeMarket])

    React.useEffect(() => {
        console.log(groups)
    }, [groups])

    return (
        <div className={styles.content}>
            {
                addGroupModal &&
                <div className={styles.addModal}>
                    <span className={styles.bg} onClick={() => setAddGroupModal(false)} />
                    <div className={styles.modalContent}>
                        <h2 className={styles.modalTitle}>Добавление группы</h2>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Название</p>
                                <input value={modalItem.name} onChange={(e) => changeModalItem({...modalItem, name: e.target.value})} type="text" className={styles.input} placeholder="Введите название" />
                            </div>
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Логистика</p>
                                <input value={modalItem.delivery_time_from_supplier} onChange={(e) => changeModalItem({...modalItem, delivery_time_from_supplier: e.target.value.replace(/\D/, '')})} type="text" className={styles.input} placeholder="N дней" />
                            </div>
                        </div>
                        <div className={styles.btns}>
                            <button className={styles.saveGroup} onClick={addGroup} disabled={modalItem.name.length === 0 || modalItem.delivery_time_from_supplier.length === 0}>Сохранить</button>
                            <button className={styles.close} onClick={() => setAddGroupModal(false)}>Отменить</button>
                        </div>
                    </div>
                </div>
            }
            <div className={styles.top}>
                <h2 className={styles.title}>
                    <svg onClick={() => history.back()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20 12.75C20.4142 12.75 20.75 12.4142 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25L20 12.75ZM4.46967 11.4697C4.17678 11.7626 4.17678 12.2374 4.46967 12.5303L9.24264 17.3033C9.53553 17.5962 10.0104 17.5962 10.3033 17.3033C10.5962 17.0104 10.5962 16.5355 10.3033 16.2426L6.06066 12L10.3033 7.75736C10.5962 7.46447 10.5962 6.98959 10.3033 6.6967C10.0104 6.40381 9.53553 6.40381 9.24264 6.6967L4.46967 11.4697ZM20 11.25L5 11.25L5 12.75L20 12.75L20 11.25Z" fill="#429EFF" />
                    </svg>
                    Настройки
                </h2>
                <MarketplacesSwitch active={activeMarket} setActive={setActiveMarket} all={false} />
            </div>
            <div className={styles.block}>
                <div className={styles.topBtns}>
                    <button className={styles.add} onClick={() => setAddGroupModal(true)} style={{marginRight: !buttonVsible ? '0' : ''}}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 12H19.5M12 19.5V4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Добавить группу
                    </button>
                    {
                        buttonVsible &&
                        <button className={styles.save} onClick={postChanges}>
                            Сохранить изменения
                        </button>
                    }
                </div>
                <div className={styles.blockTitle}>
                    <div className={styles.titleTop}>
                        <div className={styles.fristCol}>
                            <div className={styles.part}>Склад</div>
                            <div className={styles.part}>Логистика<br />в днях</div>
                        </div>
                        <div className={styles.wrap}>
                            {
                                !loading &&
                                warehouses?.map((item, i) => {
                                    return (
                                        <div className={styles.col} key={i}>
                                            <div className={styles.part}>{item.warehouse}</div>
                                            <div className={styles.part}>
                                                <input value={item.logistics_to_mp} onChange={(e) => onChangeInput(e.target.value.replace(/\D/, ''), item)} type="text" placeholder="N дней" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <div className={styles.col}>Название группы</div>
                        <div className={styles.col}>Дней на складе (переупаковка)</div>
                        <div className={styles.col}>Количество товаров, шт</div>
                    </div>
                    <div className={styles.rowsWrap} style={{paddingRight: groups.length === 0 ? '0' : ''}}>
                        {
                            groups.length > 0 ?
                                groups?.map(item => <Row onDelete={deleteGroup} item={item} key={item.id} />) :
                                <div className={styles.emptyTable}>
                                    <p>Добавьте группу</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsPage;