import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import YearSelect from "../../../Balance/YearSelect/index.jsx";
import { API_URL, getData, postData } from "../../../../static/js/request";
import { ProductRow } from "./ProductRow/index.jsx";
import dayjs from "dayjs";
import { Store } from "react-notifications-component";
import { createNotification } from "../../../../static/js/commonFunc";
import { Calendar } from 'react-date-range';
import 'react-calendar/dist/Calendar.css';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../../../store/Login/useUserStore';

export const AddProjectModal = observer(({ onClose, onAdd }) => {

    const [allSeasons, setAllSeasons] = useState(['Зима', 'Весна', 'Лето', 'Осень', 'Демисезон']);
    const [allProducts, setAllProducts] = useState([]);
    const [allOrg, setAllOrg] = useState([]);
    const [addedProducts, setAddedProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedAddedProducts, setSelectedAddedProducts] = useState([]);

    const {userInfo} = userStore;

    const [startDateCalendar, setStartDateCalendar] = useState(new Date());
    const [showStartCalendar, setShowStartCalendar] = useState(false);

    const [endDateCalendar, setEndDateCalendar] = useState(new Date());
    const [showEndCalendar, setShowEndCalendar] = useState(false);

    const [selectResponsible, setSelectResponsible] = useState('');
    const [name, setName] = useState('')
    const [season, setSeason] = useState(null);

    const checkboxValues = ['Wildberries', 'Ozon'];

    const [marketPlace, setMarketPlace] = useState([]);

    const [startDate, changeStartDate] = useState('');
    const [endDate, changeEndDate] = useState('');

    const [searchValue, changeSearchValue] = useState('');

    const [showAllProducts, setShowAllProducts] = useState(false);

    const searchRef = useRef();

    const [organisation, setOrganisation] = useState(null);

    const [addedItems, setAddedItems] = useState([]);

    useEffect(() => {
        setSelectResponsible(userInfo.user)
    }, [userInfo])

    const marketPlaceClick = (item) => {
        if (marketPlace.includes(item)) {
            setMarketPlace([...marketPlace.filter(mp => mp !== item)]);
        } else {
            setMarketPlace([...marketPlace, item]);
        }
    };

    const handleInput = (str, oldValue, change) => {
        if (str.length === oldValue.length + 1) {
            if (str.length === 2 || str.length === 5) {
                str += '.';
            }
        }

        if (str.length <= 10) {
            change(str);
        }
    };

    const getProducts = async () => {
        const response = await getData(API_URL + '/api/v1/general/user/products/');
        setAllProducts([...response]);
    };

    const getOrganistations = async () => {
        const response = await getData(API_URL + '/api/v1/organisation/');
        setAllOrg([...response]);
    };

    useEffect(() => {
        getProducts();
        getOrganistations();
    }, []);

    const selectAll = (list, func) => {
        func([...list]);
    };

    const addAll = () => {
        if (selectedProducts.length > 0) {
            setAllProducts([...allProducts.filter(item => !selectedProducts.includes(item.id))]);
            setAddedProducts([...addedProducts, ...allProducts.filter(item => selectedProducts.includes(item.id))]);
            setSelectedProducts([]);
        }
    };

    const removeAll = () => {
        if (selectedAddedProducts.length > 0) {
            setAllProducts([...allProducts, ...addedProducts.filter(item => selectedAddedProducts.includes(item.id))]);
            setAddedProducts([...addedProducts.filter(item => !selectedAddedProducts.includes(item.id))]);
            setSelectedAddedProducts([]);
        }
    };

    let show = true;

    const outsideClick = (e) => {
        if (show) {
            if (e.target.getAttribute('id') !== 'noCloseModal') {
                setShowAllProducts(false);
                show = false;
            } else {
                searchRef.current.focus();
            }
        }
    };

    useEffect(() => {
        if (showAllProducts) {
            document.addEventListener('click', outsideClick);
        } else {
            document.removeEventListener('click', outsideClick);
        }
    }, [showAllProducts]);

    const addProject = async () => {
        try {
            let [day, month, year] = startDate.split('.');
            const newStartDate = new Date(year, month - 1, day);
            [day, month, year] = endDate.split('.');
            const newEndDate = new Date(year, month - 1, day);
            const response = await postData(API_URL + '/api/v1/projects/', {
                organisation: allOrg.filter(item => item.name === organisation)[0].id,
                name: name,
                marketplaces: marketPlace.length > 1 ? 'ALL' : marketPlace[0] === 'Ozon' ? 'OZON' : 'WB',
                products: addedProducts.map(item => item.id),
                start_date: dayjs(newStartDate).format('YYYY-MM-DD'),
                end_date: endDate ? dayjs(newEndDate).format('YYYY-MM-DD') : null,
                season: season,
                responsible: selectResponsible
            });
            createNotification(response);
            onAdd();
            onClose();
        } catch (e) {
            console.log(e);
        }

    }
    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    return (
        <div className={styles.modal}>
            <span className={styles.bg} onClick={onClose} />
            <div className={styles.content}>
                <button className={styles.close} onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M13.2826 12.5001L18.7339 7.04851C19.0887 6.6939 19.0887 6.12056 18.7339 5.76595C18.3793 5.41135 17.806 5.41135 17.4514 5.76595L11.9999 11.2176L6.5486 5.76595C6.19384 5.41135 5.62067 5.41135 5.26607 5.76595C4.91131 6.12056 4.91131 6.6939 5.26607 7.04851L10.7174 12.5001L5.26607 17.9517C4.91131 18.3063 4.91131 18.8797 5.26607 19.2343C5.44279 19.4111 5.67514 19.5 5.90734 19.5C6.13953 19.5 6.37172 19.4111 6.5486 19.2343L11.9999 13.7827L17.4514 19.2343C17.6283 19.4111 17.8605 19.5 18.0927 19.5C18.3249 19.5 18.557 19.4111 18.7339 19.2343C19.0887 18.8797 19.0887 18.3063 18.7339 17.9517L13.2826 12.5001Z" fill="#1E1E1E" />
                    </svg>
                </button>
                <h2 className={styles.title}>Добавление проекта</h2>
                <div className={styles.cols}>
                    <div className={styles.col}>
                        <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}>Название</p>
                            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={'Введите название'} />
                        </div>
                        <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}>Ответственный</p>
                            <YearSelect textAlign={'left'} width={'100%'} input value={selectResponsible} onChange={setSelectResponsible} allValues={[userInfo.user]} placeholder={'Введите ответственного'}/>
                        </div>
                        <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}>Сезонность</p>
                            <YearSelect textAlign={'left'} placeholder={'Выберите сезон'} value={season} onChange={setSeason} width={'100%'} allValues={allSeasons} />
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}>Организация</p>
                            <YearSelect input textAlign={'left'} placeholder={'Выбрать организацию'} value={organisation} onChange={setOrganisation} width={'100%'} allValues={allOrg.map(item => item.name)} />
                        </div>
                        <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}>Маркетплейс</p>
                            <div className={styles.checkboxWrap}>
                                {
                                    checkboxValues.map(item => (
                                        <div className={styles.checkbox} key={item}>
                                            <span className={marketPlace.includes(item) ? styles.checked : ''} onClick={() => marketPlaceClick(item)}>
                                                {
                                                    marketPlace.includes(item) &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#429eff" d="m9.55 18l-5.7-5.7l1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4L9.55 18Z" /></svg>
                                                }
                                            </span>
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={styles.datesInputs}>
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Дата старта</p>
                                <input onFocus={() => setShowStartCalendar(true)} type="text" placeholder={'Введите дату'} onChange={(e) => handleInput(e.target.value, startDate, changeStartDate)} value={startDate} />
                                {
                                    showStartCalendar &&
                                    <>
                                        <Calendar className={`${styles.calendar} calendar`} rangeColors={['#3d91ff']}
                                            onChange={(ranges) => {
                                                changeStartDate(dayjs(new Date(ranges)).format('MM.DD.YYYY'))
                                                setStartDateCalendar(new Date(ranges))
                                                setShowStartCalendar(false)
                                            }
                                            }
                                            value={startDateCalendar}
                                            months={1}
                                            fixedHeight={true}
                                            locale={customLocale}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            direction="horizontal"
                                            showDateDisplay={false}
                                            defaultValue={startDateCalendar}
                                        />
                                        <span onClick={() => setShowStartCalendar(false)} className={styles.calendarCloseBg} />
                                    </>

                                }
                            </div>
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Дата окончания</p>
                                <input onFocus={() => setShowEndCalendar(true)} type="text" placeholder={'Введите дату'} onChange={(e) => handleInput(e.target.value, endDate, changeEndDate)} value={endDate} />
                                {
                                    showEndCalendar &&
                                    <>
                                        <Calendar className={`${styles.calendarEnd} calendar`} rangeColors={['#3d91ff']}
                                            onChange={(ranges) => {
                                                changeEndDate(dayjs(new Date(ranges)).format('MM.DD.YYYY'))
                                                setEndDateCalendar(new Date(ranges))
                                                setShowEndCalendar(false)
                                            }
                                            }
                                            value={endDateCalendar}
                                            months={1}
                                            fixedHeight={true}
                                            locale={customLocale}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            direction="horizontal"
                                            showDateDisplay={false}
                                            defaultValue={endDateCalendar}
                                        />
                                        <span onClick={() => setShowEndCalendar(false)} className={styles.calendarCloseBg} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.products}>
                    <p className={styles.productTitle}>Товары <span>{addedProducts.length}</span></p>
                    <div className={styles.inputWrap}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M14.9595 16.2565L14.9604 16.2565C15.0525 16.2567 15.1436 16.238 15.2282 16.2013C15.3124 16.1648 15.3883 16.1114 15.4512 16.0444C15.5783 15.9119 15.6491 15.7349 15.6491 15.551C15.6491 15.3667 15.578 15.1895 15.4505 15.0569L15.4505 15.0569L15.4491 15.0555L11.1147 10.6657C12.1118 9.50981 12.6473 8.01811 12.6135 6.48214C12.5786 4.89865 11.9413 3.38935 10.8335 2.26807C9.72568 1.14675 8.23258 0.499759 6.66447 0.462093C5.09637 0.424426 3.5745 0.998998 2.41517 2.06591C1.25589 3.13278 0.548326 4.60982 0.439056 6.18986C0.329786 7.76988 0.827171 9.33192 1.82821 10.5513C2.82929 11.7706 4.25714 12.5536 5.81516 12.7367C7.32956 12.9148 8.85316 12.5128 10.0866 11.6133L14.4719 16.0458C14.5351 16.1115 14.6106 16.1639 14.694 16.2001L14.7537 16.0625L14.694 16.2001C14.7779 16.2365 14.8681 16.2556 14.9595 16.2565ZM1.71228 6.53989C1.7123 5.58328 1.993 4.64828 2.51869 3.85313C3.04439 3.05796 3.79143 2.43845 4.66515 2.07268C5.53885 1.70692 6.50015 1.61125 7.42756 1.79769C8.35498 1.98413 9.20709 2.44438 9.87604 3.12047C10.545 3.79658 11.0008 4.65819 11.1854 5.59643C11.3701 6.53468 11.2753 7.50718 10.9131 8.39089C10.5509 9.27459 9.93773 10.0296 9.15126 10.5607C8.36485 11.0918 7.44046 11.3752 6.49497 11.3752C5.22739 11.374 4.01173 10.8646 3.11492 9.95826C2.21806 9.05183 1.71342 7.82245 1.71228 6.53989Z" fill="#8496AF" stroke="#8496AF" strokeWidth="0.3" />
                        </svg>
                        <input id={'noCloseModal'} ref={searchRef} style={{ paddingLeft: '47px' }} onClick={() => {
                            setShowAllProducts(true);
                        }} type="text" placeholder={'Поиск товаров'} value={searchValue} onChange={(e) => changeSearchValue(e.target.value)} />
                    </div>
                    <div className={styles.productsContent} id={'noCloseModal'}>
                        {
                            showAllProducts ?
                                <>
                                    <div id={'noCloseModal'} className={styles.searchBlock}>
                                        <div id={'noCloseModal'} className={styles.contentTop}>
                                            <p id={'noCloseModal'} className={styles.topTitle}>Поиск</p>
                                            <button id={'noCloseModal'} className={styles.selectAll} onClick={() => selectAll(allProducts.filter(item => ((item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.vendorCode.toLowerCase().includes(searchValue.toLowerCase())) && !addedProducts.map(temp => temp.id).includes(item.id))).map(item => item.id), setSelectedProducts)}>Выбрать все</button>
                                            <button id={'noCloseModal'} className={styles.deleteSelected} onClick={addAll}>Добавить выбранные</button>
                                        </div>
                                        {
                                            allProducts.filter(item => ((item.name?.toLowerCase().includes(searchValue?.toLowerCase()) || item.vendorCode?.toLowerCase().includes(searchValue?.toLowerCase())) && !addedProducts.map(temp => temp.id).includes(item.id)))?.map(item => <ProductRow clickEvent={() => setAddedProducts([...addedProducts, item])} setSelect={() => setSelectedProducts([...selectedProducts, item.id])} setUnselected={() => setSelectedProducts([...selectedProducts.filter(id => id !== item.id)])} select={selectedProducts.includes(item.id)} key={item.id} item={item} />)
                                        }
                                    </div>
                                </> :
                                <>
                                    {
                                        addedProducts.length > 0 ?
                                            addedProducts.map(item => (
                                                <ProductRow short clickEvent={() => setAddedProducts([...addedProducts.filter(temp => temp.id !== item.id)])} added key={item.id} item={item} />
                                            )) :
                                            <p className={styles.empty}>Добавьте товары</p>
                                    }
                                </>
                        }
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button className={styles.save} disabled={selectResponsible.length === 0} onClick={addProject}>Сохранить</button>
                    <button className={styles.closeBtn} onClick={onClose}>Отмена</button>
                </div>
            </div>
        </div>
    );
});
