import React, {useState, useEffect} from 'react';
import mainStyle from '../../../static/css/mainStyle.module.scss';
import style from './Signup.module.scss'
import AccountStyle from '../Account.module.scss'
import {getData, postData, API_URL} from "../../../static/js/request";
import cx from "classnames";


const Signup = () => {
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [isWrong, setIsWrong] = useState(false);

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }
    function handleNameChange(event) {
        setName(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password.length < 8) {
            setIsWrong(true);
            return;
        }
        setIsWrong(false);

        const userData = {
            username: name,
            password: password,
        };

        postData(API_URL + '/auth/users/', userData)
            .then(data => {
                console.log(data.ok);
                if (data.ok) {
                    postData(API_URL + '/auth/token/login', userData)
                        .then(response => response.json())
                        .then(data => {
                            if (data['auth_token']) {
                                localStorage.setItem('token', data['auth_token']);
                                location.href = '/';
                            }
                        }).catch(error => {
                        console.log(error)
                    })
                }
            })
    }

    useEffect(() => {
        
    }, [])

    return (
        <main className={mainStyle.main}>
            <form className={AccountStyle.mainForm} onSubmit={handleSubmit}>
                <div className={AccountStyle.mainFormTitle}>
                    Регистрация
                </div>
                <div className={AccountStyle.inputContainer}>
                    <input type="text" name='username'
                           onChange={handleNameChange}
                           className={AccountStyle.input} />

                    <input type="password" name='password'
                           onChange={handlePasswordChange}
                           className={ cx(AccountStyle.input, isWrong ? AccountStyle.isWrong : '')  }
                    />
                </div>
                <button className={AccountStyle.AccountBtn}>Регистрация</button>
            </form>
        </main>
    );
};

export default Signup;