// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JgWDALavuVqw0nwJ5ZwD {\n  color: var(--color-black, #1e1e1e);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.64px;\n  margin-bottom: 5px;\n  margin-top: 0;\n  padding-left: 10px;\n}\n\n.n_xCIAg0qsX3eITjssOD {\n  padding: 20px;\n  border-radius: 10px;\n  border: 0.5px solid var(--color-gray-light, #dce7f1);\n  background: var(--white, #fff);\n  height: calc(100vh - 160px);\n}\n\n.oyMbToRqiHqZ5Zn4j5xc .PW0rQF3zjW3voOLD4Wze {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n.oyMbToRqiHqZ5Zn4j5xc .PW0rQF3zjW3voOLD4Wze .BG0C_qQNCpBzcOkXiTqg {\n  margin-right: 10px;\n  margin-top: 0;\n  margin-bottom: 0;\n}", "",{"version":3,"sources":["webpack://./src/views/Balance/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,oDAAA;EACA,8BAAA;EACA,2BAAA;AACF;;AAGE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,aAAA;EACA,gBAAA;AAAN","sourcesContent":[".title {\n  color: var(--color-black, #1e1e1e);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.64px;\n  margin-bottom: 5px;\n  margin-top: 0;\n  padding-left: 10px;\n}\n\n.content {\n  padding: 20px;\n  border-radius: 10px;\n  border: 0.5px solid var(--color-gray-light, #dce7f1);\n  background: var(--white, #fff);\n  height: calc(100vh - 160px);\n}\n\n.section {\n  .top {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n\n    .topYear {\n      margin-right: 10px;\n      margin-top: 0;\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "JgWDALavuVqw0nwJ5ZwD",
	"content": "n_xCIAg0qsX3eITjssOD",
	"section": "oyMbToRqiHqZ5Zn4j5xc",
	"top": "PW0rQF3zjW3voOLD4Wze",
	"topYear": "BG0C_qQNCpBzcOkXiTqg"
};
export default ___CSS_LOADER_EXPORT___;
