import {API_URL, getData} from "../static/js/request";

export const getCashboxes = () => {
	return getData(API_URL + '/api/v1/cashbox/');
};

export const getCashbox = (id) => {
	return getData(API_URL + `/api/v1/operations/${id}/`);
};

export const getCashboxMonth = (id, month) => {
	return getData(API_URL + `/api/v1/operations/${id}/?months=${month}`);
};