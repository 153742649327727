import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

function CheckboxList({ activeList, onChange, values, title, id = 'noClose', noActiveStyles }) {
    const [open, setOpen] = useState(false);

    const outsideClick = (e) => {
        const currentId = e.target.getAttribute('id');

        if (currentId !== id) {
            setOpen(false);
        }
    };

    const [localActiveList, localOnChange] = useState([...activeList]);

    useEffect(() => {
        localOnChange([...activeList]);
    }, [activeList]);

    const saveChanges = () => {
        onChange([...localActiveList]);
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('click', outsideClick);
        } else {
            saveChanges();
            document.removeEventListener('click', outsideClick);
        }
    }, [open]);

    return (
        <div className={styles.content}>
            <button
                id={id}
                onClick={() => {
                    if (open) {
                        saveChanges();
                    }
                    setOpen(!open);
                }}
                className={`${styles.select} ${open ? styles.opened : ''} ${
                    localActiveList.length > 0 && !noActiveStyles ? styles.active : noActiveStyles ? styles.noActive : ''
                }`}
            >
                {title}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M8.91615 10.4031L12.8099 6.19523C13.0634 5.92153 13.0634 5.47898 12.8099 5.20528C12.5567 4.93157 12.1471 4.93157 11.8939 5.20528L7.99994 9.41315L4.10614 5.20528C3.85274 4.93157 3.44333 4.93157 3.19005 5.20528C2.93665 5.47898 2.93665 5.92153 3.19005 6.19523L7.08385 10.4031C7.08385 10.4031 7.59251 11 7.99994 11C8.40737 11 8.91615 10.4031 8.91615 10.4031Z"
                        fill="#8496AF"
                    />
                </svg>
            </button>
            {open && (
                <div className={styles.listContent} id={id}>
                    {values.length > 0 ? (
                        values.map((item, index) => (
                            <div id={id} key={index} className={styles.listItem}>
                                <button
                                    id={id}
                                    className={`${styles.checkbox} ${
                                        localActiveList.includes(item) ? styles.active : ''
                                    }`}
                                    onClick={() =>
                                        localOnChange(
                                            localActiveList.includes(item)
                                                ? [...localActiveList.filter((temp) => temp !== item)]
                                                : [...localActiveList, item]
                                        )
                                    }
                                >
                                    {localActiveList.includes(item) && (
                                        <svg
                                            id={id}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="11"
                                            viewBox="0 0 14 11"
                                            fill="none"
                                        >
                                            <path
                                                id={id}
                                                d="M5 10.5L0 5.54L1.59 4L5 7.35L12.41 0L14 1.58L5 10.5Z"
                                                fill="white"
                                            />
                                        </svg>
                                    )}
                                </button>
                                <p id={id} className={styles.itemValue} title={item}>
                                    {item}
                                </p>
                            </div>
                        ))
                    ) : (
                        <p className={styles.listItem} style={{ fontSize: '12px' }}>
                            Ничего не найдено
                        </p>
                    )}
                </div>
            )}
        </div>
    );
}

export default CheckboxList;
