import React from "react";
import FilterIcon from "../icons/filter.jsx";
import style from '../PlanFact.module.scss'
import cx from 'classnames'

const ColumnName = ({ name, filter }) => {

    return (
        <div className={cx(style.headColumn, filter ? null : style.center)}>
            <span>{name}</span>
            {filter && <FilterIcon />}
        </div>
    )

}

export default ColumnName