import React, { useState } from 'react'
import style from '../../../Purchase.module.scss'
import { NewCustomInput } from "../../../../../components/custom/NewCustomInput/NewCustomInput.jsx";
import cx from 'classnames';
import PlusIcon from "../../../../../shared/ui/icons/Plus.jsx";
import Search from './search.jsx';

const Add = ({ productList, setProductList, setItemParams }) => {

    const [count, setCount] = useState('')
    const [price, setPrice] = useState('')


    const addProduct = () => {

        setItemParams((prev) => {

            const selectedProducts = productList
                .filter(item => item.checked)
                .map(item => ({
                    "product": item.id,
                    "count": count,
                    "price": price
                }));

            return {
                ...prev,
                products: [
                    ...selectedProducts
                ]
            };

        });

        setCount('')
        setPrice('')
    }

    return (

        <div className={style.filter}>

            <div className={cx(style.topRow, style.product)}>

                <div className={style.left}>

                    <div className={style.column}>
                        <div className={style.title}>Добавление товара</div>
                    </div>

                    <Search
                        productList={productList}
                        setProductList={setProductList}
                    />

                    <div className={style.column}>
                        <NewCustomInput
                            type={'text'}
                            value={count}
                            onChange={(value) => setCount(value)}
                            width={'160px'}
                            placeholder="Кол-во, шт"
                            numeric
                        />
                    </div>
                    <div className={style.column}>
                        <NewCustomInput
                            type={'text'}
                            value={price}
                            onChange={(value) => setPrice(value)}
                            width={'160px'}
                            placeholder="Цена, руб"
                            numeric
                        />
                    </div>

                </div>

                <div className={style.rigth}>

                    <button className={style.chain} onClick={() => addProduct()}>
                        <PlusIcon />
                        <span>Добавить товар</span>
                    </button>

                </div>

            </div>
        </div>

    )
}

export default Add