import React, { useEffect, useState } from 'react';
import {Calendar, DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ru } from 'date-fns/locale';
import s from './DatePicker.module.scss';
import useDatePickerStore from '../../../store/Setting/datePickerStore';
import {NewCustomInput} from '../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import styles from "../../Financing/Loans/CreateModal/styles.module.scss";
import dayjs from "dayjs";

export default function DatePicker({
    handleDatePicker,
    selectedProduct,
    updatePriceHistory,
    disabled
}) {
    const {
        startDateInput,
        setStartDateInput,
        endDateInput,
        setEndDateInput,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        price,
        setPrice,
        handleSelect,
        handleInput,
        handleReset,
        handleSubmit,
    } = useDatePickerStore(selectedProduct, updatePriceHistory);

    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    return (
        <section className={s.dp_block} onSubmit={handleSubmit}>
            <div className={s.dp_header}>
                <h4>Добавить себестоимость</h4>
                <button onClick={handleReset}>Сбросить</button>
            </div>
            <div className={s.cost_price}>
                <h4>Себестоимость</h4>
                <NewCustomInput
                    type={'text'}
                    value={price}
                    onChange={setPrice}
                    placeholder="Цена ₽"
                    numeric
                />
            </div>

            <div className={s.calendar_block}>
                <div className={s.period}>
                    <input
                        type="text"
                        placeholder="Начало периода"
                        value={startDateInput}
                        onChange={(e) => {
                            handleInput(e.target.value, setStartDateInput, setStartDate, startDateInput);
                        }}
                    />
                </div>

                <div className={s.calendar}>
                    <Calendar
                        className={`${styles.calendar} calendar`}
                        onChange={(ranges) => {
                            handleSelect(ranges)
                        }}
                        date={startDate}
                        months={1}
                        fixedHeight={true}
                        locale={customLocale}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        direction="horizontal"
                        showDateDisplay={false}
                        defaultValue={startDate}
                    />
                </div>
            </div>

            <button disabled={!price || !startDateInput || price === '0'} className={s.submit} type="submit" onClick={() => {
                handleSubmit();
                handleDatePicker();
            }}>
                Сохранить
            </button>
        </section>
    );
}
