import React from 'react'
import styles from './styles.module.scss'
import { useState, useEffect } from 'react'

function DaysSelect({ title, days, select, onChange, id }) {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(false)
    }, [select])

    const closePopup = (e) => {
        if(e.target.getAttribute('id') !== id) {
            setOpen(false)
            document.body.removeEventListener('click', closePopup)
        }
    } 

    const onClickSelect = () => {
        document.body.addEventListener('click', closePopup)
        setOpen(!open)
    }

    return (
        <div className={styles.select}>
            <p className={styles.title}>{title}</p>
            <div className={styles.selectWrap}>
                <button id={id} onClick={onClickSelect} className={styles.select}>
                    {select ? `${select} дней` : 'N дней'}
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M1 1L5 5L9 1" stroke="#8496AF" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                {
                    open &&
                    <div className={styles.content}>
                        {
                            days.map(item => (
                                <button key={item} id={id} onClick={() => onChange(item)} className={styles.dayItem}>{item} дней</button>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default DaysSelect;