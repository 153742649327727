import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import BodyTableLine from '../../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../../components/custom/BodyTableLineItem.jsx';
import ArticleStore from '../../../store/Financing/ArticleStore';
import {
    applyPriceNoSymbolCurrency,
    createNotification,
} from '../../../static/js/commonFunc';
import style from '../Financing.module.scss';
import commonStyle from '../../../static/css/commonStyle.module.scss';
import { API_URL, postData } from '../../../static/js/request';
import { action } from 'mobx';

const BanksBody = observer(({ state }) => {
    const [reload, setReload] = useState('');
    const [searchResults, updateSearchResults] = useState([]);
    const [currentField, setCurrentField] = useState('');
    const [filteredResults, updateFilteredResults] = useState(
        Object.entries(ArticleStore.fields),
    );
    const searchRef = useRef(null);
    const selectRef = useRef(null);

    const handleClick = action((id) => {
        state.fields.data.forEach((x) => {
            x.showBlock = x.id === id;
        });
        setReload(id);
        setCurrentField(id);
    });

    const updateSearch = (value) => {
        updateFilteredResults(
            searchResults.filter((result) => {
                if (!result[1]) {
                    return;
                }
                return result[1].match(new RegExp(value, 'gi'));
            }),
        );
    };

    useEffect(() => {
        const getSearchResults = () => {
            const searchResultsResponse = Object.entries(ArticleStore.fields);
            updateSearchResults(searchResultsResponse);
        };

        getSearchResults();

        const handleClickOutside = (evt) => {
            if (evt.target.getAttribute('id') !== 'noClose') {
                state.fields.data.some((x) => {
                    // Используем action для изменения showBlock
                    action(() => {
                        x.showBlock = false;
                    });
                });
                setReload(evt);
            }
        };

        document.addEventListener('click', (evt) => {
            handleClickOutside(evt);
        });

        return () => {
            document.removeEventListener('click', (evt) => {
                handleClickOutside(evt);
            });
        };
    }, [reload]);

    const handleSelectArticle = (event) => {
        const target = event.target;
        searchRef.current.querySelectorAll('div').forEach((el) => {
            el.classList.remove('active');
        });
        target.classList.add('active');
        selectRef.current.value = target.dataset.artText;
        selectRef.current.dataset.id = target.dataset.art;

        let data;
        state.fields.data.some((x) => {
            x.showBlock = false;
            if (x.id === currentField) {
                x.article = target.dataset.art;
                x.article_name = target.dataset.artText;
                data = x;
            }
        });

        postData(API_URL + '/api/v1/bank/operations/add-article/', data).then((response) => {
            createNotification(response);
            setReload(prev => prev + 1); // Обновляем состояние
        });
    };

    const SearchResults = () => {
        const Message = ({ text }) => (
            <div id={'noClose'} className="message">
                <div id={'noClose'}>{text}</div>
            </div>
        );

        const render = () => {
            return filteredResults.map((article, index) => (
                article[1] && (
                    <div
                        key={index}
                        data-art={article[0]}
                        data-art-text={article[1]}
                        onClick={() => {
                            handleSelectArticle(event);
                        }}
                    >
                        {article[1]}
                    </div>
                )
            ));
        };

        if (!searchResults.length) {
            return <Message text="Загрузка результатов поиска" />;
        }

        return render();
    };

    return (
        <div className={style.banksWrap} style={{ height: 'calc(100vh - 360px)' }}>
            {state.fields.data.map((field, index) => (
                <div key={index} className={style.test}>
                    <BodyTableLine items="banks">
                        <BodyTableLineItem value={field.executed} />

                        <BodyTableLineItem
                            value={
                                field.category === 'Credit'
                                    ? applyPriceNoSymbolCurrency(field.amount)
                                    : ''
                            }
                            additionalClass="alightAmount"
                        />

                        <BodyTableLineItem
                            value={
                                field.category === 'Debit'
                                    ? applyPriceNoSymbolCurrency(field.amount)
                                    : ''
                            }
                            additionalClass="alightAmount"
                        />

                        <BodyTableLineItem value={field.account} />

                        <BodyTableLineItem
                            value={
                                <div className={commonStyle.hoveredText}>
                                    {field.contragentName}
                                </div>
                            }
                            additionalClass="lineClamp"
                        />

                        {field.showBlock ? (
                            <BodyTableLineItem
                                id={'noClose'}
                                value={
                                    <div
                                        id={'noClose'}
                                        className={style.customAutocomplete}
                                        ref={searchRef}
                                    >
                                        <div
                                            id={'noClose'}
                                            className={style.customAutocompleteContainer}
                                        >
                                            <input
                                                id={'noClose'}
                                                className={style.customAutocompleteInput}
                                                ref={selectRef}
                                                type="text"
                                                placeholder="Введите текст..."
                                                onFocus={() => updateSearch('')}
                                                autoFocus={true}
                                                onKeyUp={(evt) => updateSearch(evt.target.value)}
                                            />
                                            <div
                                                id={'noClose'}
                                                className={style.customAutocompleteResult}
                                            >
                                                <SearchResults />
                                            </div>
                                        </div>
                                    </div>
                                }
                                additionalClass={style.customAutocomplete}
                            />
                        ) : (
                            <BodyTableLineItem
                                id={'noClose'}
                                value={field.article_name ? field.article_name : 'Выберите статью!'}
                                event={() => {
                                    updateSearch('');
                                    handleClick(field.id);
                                }}
                                additionalClass={`${field.article_name
                                    ? 'selectArt'
                                    : 'selectArtUnselected'
                                    }`}
                            />
                        )}

                        <BodyTableLineItem
                            value={
                                <div
                                    style={{ fontSize: 12 }}
                                    className={commonStyle.hoveredText}
                                >
                                    {field.paymentPurpose}
                                </div>
                            }
                            additionalClass="lineClamp"
                        />
                    </BodyTableLine>
                </div>
            ))}
        </div>
    );
});

export default BanksBody;
