import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { RowImage } from '../../../../CostPrice/RowImage.jsx';
import { formatNumber2 } from '../../../../../static/js/commonFunc';

export const ProductRow = ({
    item,
    select,
    setSelect,
    setUnselected,
    added,
    clickEvent,
    short,
    article,
    miniRemove,
    plans,
    plans_change_amount,
    plans_change_qty,
    line,
    longTitle,
    checkboxes,
    noSize,
}) => {
    const handleClick = () => {
        if (select) {
            setUnselected();
        } else {
            setSelect();
        }
    };

    return (
        <div
            style={{ borderTop: line ? '0.5px solid #DCE7F1' : '' }}
            id={!short ? 'noCloseModal' : ''}
            className={styles.item}
        >
            {/*{(!short || checkboxes) && (*/}
            {/*    <span*/}
            {/*        id={!short || checkboxes ? 'noCloseModal' : ''}*/}
            {/*        className={`${styles.check} ${select ? styles.active : ''}`}*/}
            {/*        onClick={handleClick}*/}
            {/*    >*/}
            {/*        {select && (*/}
            {/*            <svg*/}
            {/*                id={!short || checkboxes ? 'noCloseModal' : ''}*/}
            {/*                xmlns="http://www.w3.org/2000/svg"*/}
            {/*                width="14"*/}
            {/*                height="11"*/}
            {/*                viewBox="0 0 14 11"*/}
            {/*                fill="none"*/}
            {/*            >*/}
            {/*                <path*/}
            {/*                    id={!short || checkboxes ? 'noCloseModal' : ''}*/}
            {/*                    d="M5 10.5L0 5.54L1.59 4L5 7.35L12.41 0L14 1.58L5 10.5Z"*/}
            {/*                    fill="#429EFF"*/}
            {/*                />*/}
            {/*            </svg>*/}
            {/*        )}*/}
            {/*    </span>*/}
            {/*)}*/}
            <div className={styles.imgWrap}>
                <RowImage item={item} key={item.id} />
            </div>
            {article && (
                <p
                    id={!short ? 'noCloseModal' : ''}
                    style={{ width: '80px' }}
                    className={styles.title}
                >
                    {item.article_WB
                        ? item.article_WB
                        : item.article_OZON
                            ? item.article_OZON
                            : 'Артикул товара '}
                </p>
            )}
            <p
                id={!short ? 'noCloseModal' : ''}
                style={{ width: miniRemove ? '400px' : longTitle ? '750px' : '' }}
                className={styles.title}
            >
                {item.name ? item.name : 'Название товара '}
            </p>
            {item.vendorCode ? (
                <p id={!short ? 'noCloseModal' : ''} className={styles.vendorCode}>
                    {item.vendorCode}
                </p>
            ) : (
                <p id={!short ? 'noCloseModal' : ''} className={styles.vendorCode}>
                    {''}
                </p>
            )}
            {noSize ? (
                <p
                    id={!short ? 'noCloseModal' : ''}
                    style={{ width: miniRemove ? '100px' : '', textAlign: 'center' }}
                    className={styles.size}
                >
                    {item.size}
                </p>
            ) : (
                <p
                    id={!short ? 'noCloseModal' : ''}
                    style={{ width: miniRemove ? '100px' : '', textAlign: 'center' }}
                    className={styles.size}
                >
                    {item.size}
                </p>
            )}
            {plans && (
                <>
                    <input
                        onFocus={() =>
                            plans_change_amount(item.planned_sales_amount.replace(' ', ''))
                        }
                        onBlur={() =>
                            plans_change_amount(formatNumber2(item.planned_sales_amount))
                        }
                        type="text"
                        value={item.planned_sales_amount}
                        onChange={(e) => plans_change_amount(e.target.value)}
                    />
                    <input
                        onFocus={() =>
                            plans_change_qty(item.planned_sales_qty.replace(' ', ''))
                        }
                        onBlur={() =>
                            plans_change_qty(formatNumber2(item.planned_sales_qty))
                        }
                        type="text"
                        value={item.planned_sales_qty}
                        onChange={(e) => plans_change_qty(e.target.value)}
                    />
                </>
            )}
            {added ? (
                <button
                    id={!short ? 'noCloseModal' : ''}
                    onClick={clickEvent}
                    className={styles.remove}
                >
                    {!miniRemove ? (
                        'Удалить'
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            fill="none"
                        >
                            <path
                                d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z"
                                fill="#8496AF"
                            />
                            <path
                                d="M5.21702 12.5262C5.35526 12.5258 5.48772 12.4718 5.58547 12.3759C5.68323 12.2801 5.73832 12.1502 5.73873 12.0146V6.13075C5.73873 5.99506 5.68376 5.86493 5.58592 5.76899C5.48808 5.67304 5.35539 5.61914 5.21702 5.61914C5.07866 5.61914 4.94596 5.67304 4.84812 5.76899C4.75028 5.86493 4.69531 5.99506 4.69531 6.13075V12.0146C4.69572 12.1502 4.75082 12.2801 4.84857 12.3759C4.94632 12.4718 5.07878 12.5258 5.21702 12.5262Z"
                                fill="#8496AF"
                            />
                            <path
                                d="M8.52171 12.5262C8.65995 12.5258 8.79242 12.4718 8.89017 12.3759C8.98792 12.2801 9.04301 12.1502 9.04342 12.0146V6.13075C9.04342 5.99506 8.98845 5.86493 8.89061 5.76899C8.79277 5.67304 8.66008 5.61914 8.52171 5.61914C8.38335 5.61914 8.25065 5.67304 8.15281 5.76899C8.05497 5.86493 8 5.99506 8 6.13075V12.0146C8.00041 12.1502 8.05551 12.2801 8.15326 12.3759C8.25101 12.4718 8.38347 12.5258 8.52171 12.5262Z"
                                fill="#8496AF"
                            />
                        </svg>
                    )}
                </button>
            ) : (
                <button
                    id={!short ? 'noCloseModal' : ''}
                    onClick={clickEvent}
                    className={styles.add}
                >
                    Добавить
                </button>
            )}
        </div>
    );
};

// <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m20.475 23.3l-2.3-2.3H4q-.825 0-1.413-.588T2 19V7q0-.825.588-1.413T4 5h1.025v2.85L.675 3.5L2.1 2.075l19.8 19.8l-1.425 1.425ZM22 19.125l-5.525-5.55q.125-1.05-.213-2.037T15.176 9.8q-.725-.725-1.713-1.063t-2.037-.212L7.5 4.625L9 3h6l1.85 2H20q.825 0 1.413.588T22 7v12.125ZM12 17.5q.575 0 1.113-.125t1.037-.4L8.025 10.85q-.275.5-.4 1.038T7.5 13q0 1.875 1.313 3.188T12 17.5Zm0-2q-1.05 0-1.775-.725T9.5 13q0-.5.188-.963t.537-.812l3.55 3.55q-.35.35-.812.538T12 15.5Z"></path></svg>
