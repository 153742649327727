"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPaymentDetailsByMonth = exports.fetchPaymentHistoryByMonth = void 0;
const request_1 = require("@/static/js/request");
const fetchWithAuth = async (url) => {
    const token = localStorage.getItem('token');
    if (!token)
        throw new Error("Токен отсутствует! Авторизуйтесь снова.");
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok)
        throw new Error(`HTTP ${response.status}: ${response.statusText}`);
    const contentType = response.headers.get("Content-Type");
    if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Сервер вернул не JSON!");
    }
    return response.json();
};
const fetchPaymentHistoryByMonth = async (staffId) => {
    if (!staffId)
        throw new Error("staffId не передан!");
    const url = `${request_1.API_URL}/api/v1/fot/staff/history/for-staff-by-month/?staff_id=${staffId}`;
    const data = await fetchWithAuth(url);
    if (!data || !data.operations)
        throw new Error("Пустой ответ от сервера");
    return data;
};
exports.fetchPaymentHistoryByMonth = fetchPaymentHistoryByMonth;
const fetchPaymentDetailsByMonth = async (staffId, month, year) => {
    if (!staffId || !month || !year)
        throw new Error("staffId, month и year обязательны!");
    const url = `${request_1.API_URL}/api/v1/fot/salary/history/by_staff/?staff_id=${staffId}&month=${month}&year=${year}`;
    const data = await fetchWithAuth(url);
    if (!data || !data.operations)
        throw new Error("Пустой ответ от сервера");
    return data;
};
exports.fetchPaymentDetailsByMonth = fetchPaymentDetailsByMonth;
