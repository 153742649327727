import React, { useEffect, useState } from 'react';
import List from '../../view/list/index.jsx';
import DataArray from './data.json';
import { API_URL, postData, delData } from '../../../../static/js/request.js';
import { transformFilters } from '../../../../static/js/transformFilters.jsx';
import { popupNotification } from '../../../../static/js/popupNotification.jsx';

const ShipmentList = () => {
  const [columns, editColumns] = useState({
    id: {
      label: '№',
      isShow: true,
      value: 'id',
      type: 'id',
      noSort: true,
      width: '80px',
    },
    created_at: {
      label: 'Дата',
      isShow: true,
      value: 'created_at',
      filterValue: 'created_at',
      type: 'date',
      width: '150px',
    },
    organisation: {
      label: 'Организация',
      isShow: true,
      value: 'organisation',
      filterValue: 'organisation',
      type: 'object',
      width: '240px',
    },
    counterparty: {
      label: 'Контрагент',
      isShow: true,
      value: 'counterparty',
      filterValue: 'counterparty',
      type: 'object',
      width: '300px',
      needSearch: true,
    },
    shipmentproduct: {
      label: 'Товары',
      filterValue: 'shipmentproduct',
      needSearch: true,
    },
    transport_invoice_number: {
      label: '№ поставки',
      isShow: true,
      value: 'transport_invoice_number',
      filterValue: 'transport_invoice_number',
      type: 'string',
      width: '200px',
    },
    shipping_method: {
      label: 'Способ доставки',
      isShow: true,
      value: 'shipping_method',
      filterValue: 'shipping_method',
      type: 'string',
      width: '200px',
    },
    count: {
      label: 'Отгрузили, шт',
      isShow: true,
      value: 'count',
      type: 'count',
      width: '170px',
    },
    /*sending: { label: 'Отправка план', isShow: true, value: 'sending', type: 'sending', width: "200px" },
        arrival: { label: 'Приемка план', isShow: true, value: 'arrival', type: 'arrival', width: "200px" },
        delivery_date: { label: 'Приемка факт', isShow: true, value: 'delivery_date', type: 'delivery_date', width: "200px" },  */
    status: {
      label: 'Статус',
      isShow: true,
      value: 'status',
      filterValue: 'status',
      type: 'status',
      width: '160px',
    },
    sum: {
      label: 'Сумма',
      isShow: true,
      value: 'sum',
      type: 'pay',
      width: '140px',
    },
    plan: {
      label: 'План',
      isShow: true,
      value: 'plan',
      type: 'plan',
      width: '120px',
    },
    comment: {
      label: 'Комментарий',
      isShow: true,
      value: 'comment',
      filterValue: 'comment',
      type: 'string',
      width: '270px',
    },
  });

  const [filters, setFilters] = useState([]);

  const [sortingBy, setSortingBy] = useState({
    key: '',
    value: '',
  });

  const [data, setData] = useState(null);

  const getData = async () => {
    try {
      const transformedFilters = transformFilters(filters);

      const requestData = {
        sorted: sortingBy.key
          ? [{ key: sortingBy.key, value: sortingBy.value }]
          : [],
        filters: transformedFilters,
      };
      
      delete requestData.id
      delete requestData.user

      requestData.shipping_method = String(requestData.shipping_method) 

      await postData(API_URL + `/api/v1/purchase/shipment/list/`, requestData)
        .then((response) => response.json())
        .then((response) => {
          setData(response.data.length > 0 && response.data);
        });
    } catch (error) {
      console.log('Не удалось получить данные', error);
    }
  };

  const removeItem = async (id) => {
    await delData(API_URL + '/api/v1/purchase/shipment/', { id: id })
      .then((response) => {
        popupNotification(response, 'delete');
      })
      .then(getData());
  };

  useEffect(() => {
    getData();
  }, [sortingBy, filters]);

  return (
    <List
      title="Отгрузки"
      presetTable="shipmentList"
      newItemLink={{
        name: 'Создать отгрузку',
        value: '/purchase/shipment/item/',
      }}
      filters={filters}
      setFilters={(value) => setFilters(value)}
      columns={columns}
      editColumns={(value) => editColumns(value)}
      data={data}
      setSortingBy={(item) => setSortingBy(item)}
      removeItem={(value) => removeItem(value)}
    />
  );
};

export default ShipmentList;
