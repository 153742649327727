import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import Table from '../../../components/custom/Table.jsx';
import HeaderTable from '../../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../../components/custom/HeaderTableItem.jsx';
import BodyTable from '../../../components/custom/BodyTable.jsx';
import BodyTableLine from '../../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../../components/custom/BodyTableLineItem.jsx';
import LineGraph from '../Graph/LineGraph.jsx';
import usePriceHistoryStore from '../../../store/Setting/priceHistoryStore';
import format from 'date-fns/format';
import BasicDateRangeCalendar from '../DatePicker/DatePickerAdd.jsx';
import BasicDateRangeCalendarEdit from '../DatePicker/DatePickerEdit.jsx';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import { API_URL, delData } from '../../../static/js/request';
import { Store } from 'react-notifications-component';
import {
    createNotification,
    formatNumber2,
} from '../../../static/js/commonFunc';
import { array } from 'prop-types';

function Item({ active, setActive, item }) {
    const [disabledDates, setDisabledDates] = React.useState([]);
    const [editPicker, setEditPicker] = React.useState(false);

    const [editDate, setEditDate] = React.useState();

    const {
        priceHistory,
        datePicker,
        handleDatePicker,
        datePickerRef,
        updatePriceHistory,
        fetchPriceHistory,
    } = usePriceHistoryStore(item);

    const getDaysArray = (from, to) => {
        if (
            to &&
      dayjs(new Date(from)).format('YYYY-MM-DD') !==
        dayjs(new Date(to)).format('YYYY-MM-DD') &&
      new Date(to).getFullYear() !== 1970
        ) {
            let dateToPlus = from;
            const arr = [];
            while (
                dayjs(new Date(dateToPlus)).format('YYYY-MM-DD') !==
        dayjs(new Date(to)).format('YYYY-MM-DD')
            ) {
                dateToPlus = addDays(new Date(dateToPlus), 1);
                arr.push(dateToPlus);
            }
            return [from, ...arr, to];
        } else {
            return [from];
        }
    };

    React.useEffect(() => {
        setDisabledDates([]);
        const data = [
            ...priceHistory.map((item) => {
                if (item.end_date) {
                    return [
                        ...getDaysArray(new Date(item.start_date), new Date(item.end_date)),
                    ];
                } else {
                    return new Date(item.start_date);
                }
            }),
        ];
      
        let arr = [];
        data.map((item, index) => {
            if (index > 0 && Array.isArray(item)) {
                item?.map((temp) => arr.push(temp));
            } else {
                arr.push(item);
            }
        });
        setDisabledDates(arr);
    }, [priceHistory]);

    const data = priceHistory
        ?.map((item) => ({
            date: item.start_date,
            value: item.purchase_price,
            percent: item.price_change
                ? (item.purchase_price / (item.price_change / 100)).toFixed(0)
                : 0,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

    const deleteItem = (cost) => {
        delData(
            `${API_URL}/api/v1/products/costs/history/`,
            {
                product_cost_id: cost.id
            }
        ).then((response) => {
            updatePriceHistory();
            createNotification(response);
        });
    };

    return (
        <div className={`${styles.modal} ${active ? styles.active : ''}`}>
            <span className={styles.bg} onClick={() => setActive(false)} />
            <div className={styles.content}>
                <button className={styles.close} onClick={() => setActive(false)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M9.37422 8.00011L15.2149 2.15911C15.595 1.77918 15.595 1.16488 15.2149 0.78495C14.835 0.405017 14.2207 0.405017 13.8408 0.78495L7.99991 6.62595L2.15921 0.78495C1.77911 0.405017 1.165 0.405017 0.785077 0.78495C0.404974 1.16488 0.404974 1.77918 0.785077 2.15911L6.62578 8.00011L0.785077 13.8411C0.404974 14.221 0.404974 14.8353 0.785077 15.2153C0.974417 15.4048 1.22337 15.5 1.47214 15.5C1.72092 15.5 1.96969 15.4048 2.15921 15.2153L7.99991 9.37428L13.8408 15.2153C14.0303 15.4048 14.2791 15.5 14.5279 15.5C14.7766 15.5 15.0254 15.4048 15.2149 15.2153C15.595 14.8353 15.595 14.221 15.2149 13.8411L9.37422 8.00011Z"
                            fill="#1E1E1E"
                        />
                    </svg>
                </button>
                <div className={styles.wrap}>
                    {item && (
                        <>
                            <h2 className={styles.title}>Карточка товара</h2>
                            <div className={styles.info}>
                                <div className={styles.imgWrap}>
                                    <img src={item?.photo_url} alt="" className={styles.img} />
                                </div>
                                <div className={styles.description}>
                                    <p className={styles.name}>{item?.name}</p>
                                    <p className={styles.infoItem}>
                                        Артикул WB:<span>{item?.vendorCode}</span>
                                    </p>
                                    <p className={styles.infoItem}>
                                        Размер:<span>{item?.size ? `${item?.size}` : '-'}</span>
                                    </p>
                                    <p className={styles.infoItem}>
                                        Текущая цена:
                                        <span>
                                            {item?.purchase_price
                                                ? `${formatNumber2(item?.purchase_price)} ₽`
                                                : '-'}
                                        </span>
                                    </p>
                                    <p className={styles.infoItem}>
                                        WB:
                                        <span style={{ fontSize: '14px' }}>
                                            {item?.barcode_set.length > 0
                                                ? `${item?.barcode_set
                                                    .map((temp) => temp.code)
                                                    .join(', ')}`
                                                : '-'}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className={styles.graph}>
                                <p className={styles.graphTitle}>
                                    График изменение себестоимости
                                </p>
                                {priceHistory && priceHistory?.length > 0 ? (
                                    <LineGraph data={data} />
                                ) : (
                                    <p className={styles.empty}>Добавьте закупку</p>
                                )}
                            </div>
                            <div className={styles.history}>
                                <div className={styles.top}>
                                    <h3 className={styles.topTitle}>История цены</h3>
                                    <button className={styles.add} onClick={handleDatePicker}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M18.188 4.4013H17.2789V3.70315C17.2789 3.31204 16.9619 2.995 16.5708 2.995C16.1796 2.995 15.8626 3.31204 15.8626 3.70315V4.4013H12.6733V3.70315C12.6733 3.31204 12.3562 2.995 11.9651 2.995C11.574 2.995 11.257 3.31204 11.257 3.70315V4.4013H8.10282V3.70315C8.10282 3.31204 7.78579 2.995 7.39468 2.995C7.00357 2.995 6.68653 3.31204 6.68653 3.70315V4.4013H5.81259C4.25897 4.4013 2.995 5.66526 2.995 7.21889V18.188C2.995 19.7416 4.25897 21.0056 5.81259 21.0056H18.188C19.7416 21.0056 21.0056 19.7416 21.0056 18.188V7.21889C21.0056 5.66526 19.7416 4.4013 18.188 4.4013ZM5.81259 5.81759H6.68653V6.51574C6.68653 6.90685 7.00357 7.22389 7.39468 7.22389C7.78579 7.22389 8.10282 6.90685 8.10282 6.51574V5.81759H11.257V6.51574C11.257 6.90685 11.574 7.22389 11.9651 7.22389C12.3562 7.22389 12.6733 6.90685 12.6733 6.51574V5.81759H15.8626V6.51574C15.8626 6.90685 16.1796 7.22389 16.5708 7.22389C16.9619 7.22389 17.2789 6.90685 17.2789 6.51574V5.81759H18.188C18.9607 5.81759 19.5893 6.44622 19.5893 7.21889V18.188C19.5893 18.9607 18.9607 19.5893 18.188 19.5893H5.81259C5.03992 19.5893 4.4113 18.9607 4.4113 18.188V7.21889C4.4113 6.44622 5.03992 5.81759 5.81259 5.81759Z"
                                                fill="#1E1E1E"
                                                stroke="white"
                                                strokeWidth="0.01"
                                            />
                                            <path
                                                d="M16.5703 11.086C16.9587 11.086 17.2735 10.7712 17.2735 10.3828C17.2735 9.9945 16.9587 9.67969 16.5703 9.67969C16.182 9.67969 15.8672 9.9945 15.8672 10.3828C15.8672 10.7712 16.182 11.086 16.5703 11.086Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M13.5117 11.086C13.9001 11.086 14.2149 10.7712 14.2149 10.3828C14.2149 9.9945 13.9001 9.67969 13.5117 9.67969C13.1234 9.67969 12.8086 9.9945 12.8086 10.3828C12.8086 10.7712 13.1234 11.086 13.5117 11.086Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M10.4531 14.1446C10.8415 14.1446 11.1563 13.8298 11.1563 13.4414C11.1563 13.0531 10.8415 12.7383 10.4531 12.7383C10.0648 12.7383 9.75 13.0531 9.75 13.4414C9.75 13.8298 10.0648 14.1446 10.4531 14.1446Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M13.5117 14.1446C13.9001 14.1446 14.2149 13.8298 14.2149 13.4414C14.2149 13.0531 13.9001 12.7383 13.5117 12.7383C13.1234 12.7383 12.8086 13.0531 12.8086 13.4414C12.8086 13.8298 13.1234 14.1446 13.5117 14.1446Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M13.5117 17.2032C13.9001 17.2032 14.2149 16.8884 14.2149 16.5C14.2149 16.1117 13.9001 15.7969 13.5117 15.7969C13.1234 15.7969 12.8086 16.1117 12.8086 16.5C12.8086 16.8884 13.1234 17.2032 13.5117 17.2032Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M16.5703 14.1446C16.9587 14.1446 17.2735 13.8298 17.2735 13.4414C17.2735 13.0531 16.9587 12.7383 16.5703 12.7383C16.182 12.7383 15.8672 13.0531 15.8672 13.4414C15.8672 13.8298 16.182 14.1446 16.5703 14.1446Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M16.5703 17.2032C16.9587 17.2032 17.2735 16.8884 17.2735 16.5C17.2735 16.1117 16.9587 15.7969 16.5703 15.7969C16.182 15.7969 15.8672 16.1117 15.8672 16.5C15.8672 16.8884 16.182 17.2032 16.5703 17.2032Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M7.39455 11.086C7.78289 11.086 8.0977 10.7712 8.0977 10.3828C8.0977 9.9945 7.78289 9.67969 7.39455 9.67969C7.00622 9.67969 6.69141 9.9945 6.69141 10.3828C6.69141 10.7712 7.00622 11.086 7.39455 11.086Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M7.39455 14.1446C7.78289 14.1446 8.0977 13.8298 8.0977 13.4414C8.0977 13.0531 7.78289 12.7383 7.39455 12.7383C7.00622 12.7383 6.69141 13.0531 6.69141 13.4414C6.69141 13.8298 7.00622 14.1446 7.39455 14.1446Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M7.39455 17.2033C7.78289 17.2033 8.0977 16.8885 8.0977 16.5001C8.0977 16.1118 7.78289 15.797 7.39455 15.797C7.00622 15.797 6.69141 16.1118 6.69141 16.5001C6.69141 16.8885 7.00622 17.2033 7.39455 17.2033Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M10.4531 17.2032C10.8415 17.2032 11.1563 16.8884 11.1563 16.5C11.1563 16.1117 10.8415 15.7969 10.4531 15.7969C10.0648 15.7969 9.75 16.1117 9.75 16.5C9.75 16.8884 10.0648 17.2032 10.4531 17.2032Z"
                                                fill="#1E1E1E"
                                            />
                                            <path
                                                d="M10.4531 11.086C10.8415 11.086 11.1563 10.7712 11.1563 10.3828C11.1563 9.9945 10.8415 9.67969 10.4531 9.67969C10.0648 9.67969 9.75 9.9945 9.75 10.3828C9.75 10.7712 10.0648 11.086 10.4531 11.086Z"
                                                fill="#1E1E1E"
                                            />
                                        </svg>
                                        Добавить себестоимость
                                    </button>
                                </div>
                                <Table>
                                    <HeaderTable items="productCard">
                                        <HeaderTableItem name="Дата изменения" />
                                        <HeaderTableItem name="Себестоимость" />
                                        {/*TODO: добавить столбец кол-во*/}
                                        {/*<HeaderTableItem name="Кол-во" />*/}
                                        <HeaderTableItem name="" />
                                    </HeaderTable>
                                    <div className={styles.scrollWrap}>
                                        {datePicker ? (
                                            <div ref={datePickerRef} className={styles.date_picker}>
                                                <BasicDateRangeCalendar
                                                    handleDatePicker={handleDatePicker}
                                                    selectedProduct={item}
                                                    updatePriceHistory={updatePriceHistory}
                                                />
                                            </div>
                                        ) : null}
                                        {editPicker ? (
                                            <div ref={datePickerRef} className={styles.date_picker}>
                                                <span
                                                    onClick={() => setEditPicker(false)}
                                                    className={styles.editPickerClose}
                                                />
                                                <BasicDateRangeCalendarEdit
                                                    item={editDate}
                                                    handleDatePicker={handleDatePicker}
                                                    selectedProduct={item}
                                                    updatePriceHistory={updatePriceHistory}
                                                />
                                            </div>
                                        ) : null}
                                        <BodyTable>
                                            {priceHistory && priceHistory.length > 0 ? (
                                                priceHistory.map((temp) => {
                                                    return (
                                                        <BodyTableLine
                                                            key={temp.id}
                                                            event={() => {}}
                                                            items="productCard"
                                                        >
                                                            <BodyTableLineItem
                                                                value={format(
                                                                    new Date(temp.start_date),
                                                                    'dd/MM/yyyy'
                                                                )}
                                                            />
                                                            <BodyTableLineItem
                                                                value={
                                                                    formatNumber2(temp.purchase_price) + ' ₽'
                                                                }
                                                            />
                                                            {/*TODO: добавить столбец кол-во*/}
                                                            {/*<BodyTableLineItem*/}
                                                            {/*    value={*/}
                                                            {/*        formatNumber2(temp.purchase_price) + ' ₽'*/}
                                                            {/*    }*/}
                                                            {/*/>*/}
                                                            <BodyTableLineItem
                                                                value={
                                                                    <div className={styles.itemBtns}>
                                                                        <button
                                                                            onClick={() => {
                                                                                setEditDate(temp);
                                                                                setEditPicker(true);
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 16 16"
                                                                                fill="none"
                                                                            >
                                                                                <g clipPath="url(#clip0_23_2379)">
                                                                                    <path
                                                                                        d="M4.58235 15.102L4.58229 15.102L0.783171 15.8056C0.619877 15.8359 0.451908 15.7839 0.3342 15.6662C0.216615 15.5486 0.164514 15.3807 0.194773 15.2172L0.243938 15.2263L0.194774 15.2172L0.898301 11.4178C0.898304 11.4178 0.898306 11.4178 0.898309 11.4178L0.898314 11.4177L0.947479 11.4268L4.58235 15.102ZM4.58235 15.102C4.67987 15.0838 4.77537 15.0346 4.84737 14.9625M4.58235 15.102L4.84737 14.9625M4.84737 14.9625C4.84737 14.9625 4.84737 14.9625 4.84737 14.9625L4.84737 14.9625ZM14.0514 4.32977L4.49035 13.8914L2.1088 11.5097L11.6698 1.94805L11.6344 1.9127L11.6698 1.94805L12.2205 1.39726C12.4891 1.12861 12.9263 1.12858 13.195 1.39726L14.6021 2.80446C14.8714 3.0738 14.8714 3.50961 14.6021 3.77899L14.6374 3.81434L14.6021 3.77899L14.0514 4.32977ZM3.45464 14.2838L1.32141 14.6789L1.71645 12.5455L3.45464 14.2838Z"
                                                                                        fill="#8496AF"
                                                                                        stroke="#8496AF"
                                                                                        strokeWidth="0.1"
                                                                                    />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_23_2379">
                                                                                        <rect
                                                                                            width="16"
                                                                                            height="16"
                                                                                            fill="white"
                                                                                        />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </button>
                                                                        <button onClick={() => deleteItem(temp)}>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="14"
                                                                                height="15"
                                                                                viewBox="0 0 14 15"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z"
                                                                                    fill="#8496AF"
                                                                                />
                                                                                <path
                                                                                    d="M5.21702 12.5267C5.35526 12.5263 5.48772 12.4723 5.58547 12.3764C5.68323 12.2806 5.73832 12.1507 5.73873 12.0151V6.13124C5.73873 5.99555 5.68376 5.86542 5.58592 5.76948C5.48808 5.67353 5.35539 5.61963 5.21702 5.61963C5.07866 5.61963 4.94596 5.67353 4.84812 5.76948C4.75028 5.86542 4.69531 5.99555 4.69531 6.13124V12.0151C4.69572 12.1507 4.75082 12.2806 4.84857 12.3764C4.94632 12.4723 5.07878 12.5263 5.21702 12.5267Z"
                                                                                    fill="#8496AF"
                                                                                />
                                                                                <path
                                                                                    d="M8.52171 12.5267C8.65995 12.5263 8.79242 12.4723 8.89017 12.3764C8.98792 12.2806 9.04301 12.1507 9.04342 12.0151V6.13124C9.04342 5.99555 8.98845 5.86542 8.89061 5.76948C8.79277 5.67353 8.66008 5.61963 8.52171 5.61963C8.38335 5.61963 8.25065 5.67353 8.15281 5.76948C8.05497 5.86542 8 5.99555 8 6.13124V12.0151C8.00041 12.1507 8.05551 12.2806 8.15326 12.3764C8.25101 12.4723 8.38347 12.5263 8.52171 12.5267Z"
                                                                                    fill="#8496AF"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                }
                                                            />
                                                        </BodyTableLine>
                                                    );
                                                })
                                            ) : (
                                                <div className={styles.empty}>
                                                    Добавьте закупку
                                                </div>
                                            )}
                                        </BodyTable>
                                    </div>
                                </Table>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Item;
