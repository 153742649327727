import React from 'react'

const FilterIcon = _ => {

    return (
        <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5921_36148)">
                <path d="M19.7176 2.51381L7.80205 2.51381C7.47446 1.54084 6.55417 0.837891 5.47215 0.837891C4.39013 0.837891 3.46985 1.54084 3.14226 2.51381L1.28233 2.51381C0.850277 2.51381 0.5 2.86408 0.5 3.29614C0.5 3.7282 0.850277 4.07848 1.28233 4.07848H3.14231C3.4699 5.05144 4.39018 5.75439 5.4722 5.75439C6.55423 5.75439 7.47451 5.05144 7.8021 4.07848L19.7176 4.07848C20.1497 4.07848 20.5 3.7282 20.5 3.29614C20.5 2.86408 20.1497 2.51381 19.7176 2.51381V2.51381ZM5.47215 4.18972C4.97944 4.18972 4.57857 3.78886 4.57857 3.29614C4.57857 2.80343 4.97944 2.40256 5.47215 2.40256C5.96487 2.40256 6.36574 2.80343 6.36574 3.29614C6.36574 3.78886 5.96487 4.18972 5.47215 4.18972Z" fill="#35373A" />
                <path d="M19.7176 9.21889H17.8576C17.53 8.24592 16.6097 7.54297 15.5277 7.54297C14.4457 7.54297 13.5255 8.24592 13.1979 9.21889L1.28233 9.21889C0.850277 9.21889 0.5 9.56916 0.5 10.0012C0.5 10.4333 0.850277 10.7836 1.28233 10.7836L13.1979 10.7836C13.5255 11.7565 14.4458 12.4595 15.5278 12.4595C16.6097 12.4595 17.5301 11.7565 17.8577 10.7836H19.7176C20.1497 10.7836 20.5 10.4333 20.5 10.0012C20.5 9.56916 20.1497 9.21889 19.7176 9.21889ZM15.5278 10.8948C15.035 10.8948 14.6342 10.4939 14.6342 10.0012C14.6342 9.50851 15.035 9.10764 15.5278 9.10764C16.0205 9.10764 16.4213 9.50851 16.4213 10.0012C16.4213 10.4939 16.0205 10.8948 15.5278 10.8948Z" fill="#35373A" />
                <path d="M19.7176 15.9201L11.1539 15.9201C10.8263 14.9471 9.90606 14.2441 8.82404 14.2441C7.74202 14.2441 6.82173 14.9471 6.49414 15.9201H1.28233C0.850277 15.9201 0.5 16.2703 0.5 16.7024C0.5 17.1344 0.850277 17.4847 1.28233 17.4847H6.49414C6.82173 18.4577 7.74202 19.1606 8.82404 19.1606C9.90606 19.1606 10.8263 18.4577 11.1539 17.4847L19.7176 17.4847C20.1497 17.4847 20.5 17.1344 20.5 16.7024C20.5 16.2703 20.1497 15.9201 19.7176 15.9201ZM8.82404 17.596C8.33132 17.596 7.93046 17.1952 7.93046 16.7024C7.93046 16.2097 8.33132 15.8089 8.82404 15.8089C9.31675 15.8089 9.71762 16.2097 9.71762 16.7024C9.71762 17.1951 9.31675 17.596 8.82404 17.596V17.596Z" fill="#35373A" />
            </g>
            <defs>
                <clipPath id="clip0_5921_36148">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )

}

export default FilterIcon


