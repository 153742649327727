import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import YearSelect from '../../../../../Balance/YearSelect/index.jsx';
import { getData, API_URL } from '../../../../../../static/js/request';
import dayjs from 'dayjs';
import { userStore } from '../../../../../../store/Login/useUserStore';
import { Calendar } from 'react-date-range';
import 'react-calendar/dist/Calendar.css';
import { ru } from 'date-fns/locale';

const СustomInputWrap = ({ title, placeholder, input, value, onChange }) => {

    return (
        <div className={styles.inputWrap}>
            <p className={styles.inputTitle}>{title}</p>
            {
                input ? input : <input placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
            }
        </div>
    )
}

function MainSettings({ info, setChanged, changeInfo }) {

    const [projectInfo, setProjectInfo] = useState(info)
    const [allOrg, setAllOrg] = useState([])
    const [marketPlace, setMarketPlace] = useState([]);
    const [orgLoading, setOrgLoading] = useState(true)
    const [startDate, changeStartDate] = useState('');
    const [endDate, changeEndDate] = useState('');
    const [selectedOrg, setSelectedOrg] = useState()
    const [allSeasons, setAllSeasons] = useState(['Зима', 'Весна', 'Лето', 'Осень', 'Демисезон']);
    const [season, setSeason] = useState(info?.season ?? '');
    const checkboxValues = ['Wildberries', 'Ozon'];
    const [startDateCalendar, setStartDateCalendar] = useState(new Date());
    const [showStartCalendar, setShowStartCalendar] = useState(false);

    const [endDateCalendar, setEndDateCalendar] = useState(new Date());
    const [showEndCalendar, setShowEndCalendar] = useState(false);

    const { userInfo } = userStore

    const marketPlaceClick = (item) => {
        
        if (marketPlace.includes(item)) {
            setMarketPlace([...marketPlace.filter(mp => mp !== item)]);
        } else {
            setMarketPlace([...marketPlace, item]);
        }
    };

    const getOrganistations = async (id) => {
        const response = await getData(API_URL + '/api/v1/organisation/');
        setSelectedOrg(response.find(item => item.id === id)?.name)
        setAllOrg([...response]);
        setOrgLoading(false)
    };

    const handleInput = (str, oldValue, change) => {
        
        if (str.length === oldValue.length + 1) {
            if (str.length === 2 || str.length === 5) {
                str += '.';
            }
        }

        if (str.length <= 10) {
            change(str);
        }
    };

    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    useEffect(() => {
        if (info) {
            getOrganistations(info.organisation)
            setProjectInfo(info)
            setSeason(info.season)
            setMarketPlace(info.marketplaces === 'ALL' ? ['Wildberries', 'Ozon'] : info.marketplaces === 'WB' ? ['Wildberries'] : ['Ozon'])
            changeStartDate(dayjs(new Date(info.start_date)).format('DD.MM.YYYY'))
            changeEndDate(info.end_date ? dayjs(new Date(info.end_date)).format('DD.MM.YYYY') : '')
        }
    }, [info])

    useEffect(() => {
        changeInfo(!endDate ? {
            ...projectInfo,
            season,
            marketplaces: marketPlace.length > 1 ? 'ALL' : marketPlace[0] === 'Wildberries' ? 'WB' : 'OZON',
            start_date: dayjs(startDate).format('YYYY-DD-MM'),
        } : {
            ...projectInfo,
            season,
            marketplaces: marketPlace.length > 1 ? 'ALL' : marketPlace[0] === 'Wildberries' ? 'WB' : 'OZON',
            start_date: dayjs(startDate).format('YYYY-DD-MM'),
            end_date: endDate
        })
    }, [projectInfo, season, marketPlace, startDate, endDate])

    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <h2 className={styles.title}>Настройки проекта</h2>
                <YearSelect width={'150px'} allValues={['Завершен', 'Активный']} value={projectInfo?.status} onChange={(text) => {
                    setProjectInfo({ ...projectInfo, status: text })
                    
                }} />
            </div>
            <СustomInputWrap value={projectInfo?.name} onChange={(text) => {
                setProjectInfo({ ...projectInfo, name: text })
                
            }} title={'Название'} placeholder={'Введите название'} />
            {
                (userInfo && projectInfo) &&
                <СustomInputWrap input={<YearSelect textAlign={'left'} input width={'100%'} allValues={[userInfo.user]} placeholder={'Введите ответсвенного'} value={projectInfo.responsible} onChange={(text) => {
                    if (text) {
                        setProjectInfo({ ...projectInfo, responsible: text })
                        
                    }
                }} />} title={'Ответственный'} />
            }
            <СustomInputWrap input={
                <div className={styles.checkboxWrap}>
                    {
                        checkboxValues.map(item => (
                            <div className={styles.checkbox} key={item}>
                                <span className={marketPlace.includes(item) ? styles.checked : ''} onClick={() => marketPlaceClick(item)}>
                                    {
                                        marketPlace.includes(item) &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#429eff" d="m9.55 18l-5.7-5.7l1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4L9.55 18Z" /></svg>
                                    }
                                </span>
                                <p>{item}</p>
                            </div>
                        ))
                    }
                </div>
            } title={'Мерктплейс'} />
            <СustomInputWrap title={'Сезонность'} input={<YearSelect textAlign={'left'} width={'100%'} onChange={(season) => {
                setSeason(season)
                
            }} value={season} placeholder={'Выберите сезон'} allValues={allSeasons} />} />
            {
                !orgLoading &&
                <СustomInputWrap title={'Организация'} input={<YearSelect textAlign={'left'} width={'100%'} onChange={(item) => {
                    setSelectedOrg(item)
                    
                }} value={selectedOrg} placeholder={'Выберите организацию'} input allValues={allOrg.map(item => item.name)} />} />
            }
            <div className={styles.datesInputs}>
                <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Дата старта</p>
                    <input onFocus={() => setShowStartCalendar(true)} type="text" placeholder={'Введите дату'} onChange={(e) => {
                        handleInput(e.target.value, startDate, changeStartDate)
                        
                    }} value={startDate} />
                    {
                        showStartCalendar &&
                        <>
                            <Calendar className={`${styles.calendar} calendar`} rangeColors={['#3d91ff']}
                                onChange={(ranges) => {
                                    changeStartDate(dayjs(new Date(ranges)).format('MM.DD.YYYY'))
                                    setStartDateCalendar(new Date(ranges))
                                    setShowStartCalendar(false)
                                    
                                }
                                }
                                value={startDateCalendar}
                                months={1}
                                fixedHeight={true}
                                locale={customLocale}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                direction="horizontal"
                                showDateDisplay={false}
                                defaultValue={startDateCalendar}
                            />
                            <span onClick={() => setShowStartCalendar(false)} className={styles.calendarCloseBg} />
                        </>

                    }
                </div>
                <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Дата окончания</p>
                    <input onFocus={() => setShowEndCalendar(true)} type="text" placeholder={'Введите дату'} onChange={(e) => {
                        handleInput(e.target.value, endDate, changeEndDate)
                        
                    }} value={endDate} />
                    {
                        showEndCalendar &&
                        <>
                            <Calendar className={`${styles.calendarEnd} calendar`} rangeColors={['#3d91ff']}
                                onChange={(ranges) => {
                                    changeEndDate(dayjs(new Date(ranges)).format('MM.DD.YYYY'))
                                    setEndDateCalendar(new Date(ranges))
                                    setShowEndCalendar(false)
                                    
                                }
                                }
                                value={endDateCalendar}
                                months={1}
                                fixedHeight={true}
                                locale={customLocale}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                direction="horizontal"
                                showDateDisplay={false}
                                defaultValue={endDateCalendar}
                            />
                            <span onClick={() => setShowEndCalendar(false)} className={styles.calendarCloseBg} />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default MainSettings;