import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import KeyWordProduct from './KeyWordProduct/index.jsx';
import { useParams } from 'react-router-dom';
import { API_URL, getData } from '../../../../../static/js/request';

function KeyWords() {

    const params = useParams();

    const [keyProducts, setKeyProducts] = useState([])

    const getKeyWords = async () => {
        try {
            const response = await getData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/keywords/`);
            setKeyProducts(response);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getKeyWords()
    }, [])

    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <button className={styles.export}>Выгрузить Excel</button>
                <input type="file" id='upload' hidden />
                <label for="upload" className={styles.import}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M6.61425 0.46103C6.52875 0.39053 6.4155 0.36128 6.306 0.38153L0.306 1.50653C0.12825 1.53953 0 1.69403 0 1.87478V10.1248C0 10.3048 0.12825 10.46 0.306 10.493L6.306 11.618C6.3285 11.6225 6.3525 11.6248 6.375 11.6248C6.46125 11.6248 6.54675 11.5948 6.61425 11.5385C6.7005 11.4673 6.75 11.3608 6.75 11.2498V0.74978C6.75 0.63803 6.7005 0.53228 6.61425 0.46103ZM6 10.7975L0.75 9.81354V2.18603L6 1.20203V10.7975Z" fill="white" />
                        <path d="M11.625 1.87476H6.375C6.168 1.87476 6 2.04276 6 2.24976C6 2.45676 6.168 2.62476 6.375 2.62476H11.25V9.37476H6.375C6.168 9.37476 6 9.54276 6 9.74976C6 9.95676 6.168 10.1248 6.375 10.1248H11.625C11.832 10.1248 12 9.95676 12 9.74976V2.24976C12 2.04276 11.832 1.87476 11.625 1.87476Z" fill="white" />
                        <path d="M7.875 3.37476H6.375C6.168 3.37476 6 3.54276 6 3.74976C6 3.95676 6.168 4.12475 6.375 4.12475H7.875C8.082 4.12475 8.25 3.95676 8.25 3.74976C8.25 3.54276 8.082 3.37476 7.875 3.37476Z" fill="white" />
                        <path d="M7.875 4.87476H6.375C6.168 4.87476 6 5.04276 6 5.24976C6 5.45676 6.168 5.62476 6.375 5.62476H7.875C8.082 5.62476 8.25 5.45676 8.25 5.24976C8.25 5.04276 8.082 4.87476 7.875 4.87476Z" fill="white" />
                        <path d="M7.875 6.37476H6.375C6.168 6.37476 6 6.54276 6 6.74976C6 6.95676 6.168 7.12476 6.375 7.12476H7.875C8.082 7.12476 8.25 6.95676 8.25 6.74976C8.25 6.54276 8.082 6.37476 7.875 6.37476Z" fill="white" />
                        <path d="M7.875 7.87476H6.375C6.168 7.87476 6 8.04276 6 8.24976C6 8.45676 6.168 8.62476 6.375 8.62476H7.875C8.082 8.62476 8.25 8.45676 8.25 8.24976C8.25 8.04276 8.082 7.87476 7.875 7.87476Z" fill="white" />
                        <path d="M10.125 3.37476H9.375C9.168 3.37476 9 3.54276 9 3.74976C9 3.95676 9.168 4.12475 9.375 4.12475H10.125C10.332 4.12475 10.5 3.95676 10.5 3.74976C10.5 3.54276 10.332 3.37476 10.125 3.37476Z" fill="white" />
                        <path d="M10.125 4.87476H9.375C9.168 4.87476 9 5.04276 9 5.24976C9 5.45676 9.168 5.62476 9.375 5.62476H10.125C10.332 5.62476 10.5 5.45676 10.5 5.24976C10.5 5.04276 10.332 4.87476 10.125 4.87476Z" fill="white" />
                        <path d="M10.125 6.37476H9.375C9.168 6.37476 9 6.54276 9 6.74976C9 6.95676 9.168 7.12476 9.375 7.12476H10.125C10.332 7.12476 10.5 6.95676 10.5 6.74976C10.5 6.54276 10.332 6.37476 10.125 6.37476Z" fill="white" />
                        <path d="M10.125 7.87476H9.375C9.168 7.87476 9 8.04276 9 8.24976C9 8.45676 9.168 8.62476 9.375 8.62476H10.125C10.332 8.62476 10.5 8.45676 10.5 8.24976C10.5 8.04276 10.332 7.87476 10.125 7.87476Z" fill="white" />
                        <path d="M5.15765 7.25304L2.53265 4.25303C2.39465 4.09628 2.1584 4.08128 2.00315 4.21778C1.84715 4.35428 1.8314 4.59128 1.9679 4.74653L4.5929 7.74654C4.66715 7.83129 4.77065 7.87479 4.8749 7.87479C4.96265 7.87479 5.0504 7.84404 5.1224 7.78179C5.2784 7.64529 5.29415 7.40904 5.15765 7.25304Z" fill="white" />
                        <path d="M5.10526 3.82933C4.94176 3.70108 4.70626 3.73183 4.57876 3.89458L1.95376 7.26958C1.82701 7.43308 1.85626 7.66933 2.01976 7.79608C2.08876 7.84933 2.16976 7.87483 2.25001 7.87483C2.36101 7.87483 2.47201 7.82533 2.54551 7.73083L5.17051 4.35583C5.29801 4.19158 5.26876 3.95608 5.10526 3.82933Z" fill="white" />
                    </svg>
                    Загрузить Excel
                </label>
            </div>
            <div className={styles.wrap}>
                {
                    keyProducts.length > 0 ?
                    keyProducts.map(item => (
                        <KeyWordProduct key={item.id} item={item}/>
                    )) :
                    <p className={styles.empty}>Добавьте товары в настройках</p>
                }
            </div>
        </div>
    );
}

export default KeyWords;