import React from "react"

const RecycleIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.25" y="0.25" width="23.5" height="23.5" rx="3.75" fill="white" />
            <rect x="0.25" y="0.25" width="23.5" height="23.5" rx="3.75" stroke="#DCE7F1" strokeWidth="0.5" />
            <path d="M5.52171 8.51099H6.56512V18.4884C6.56553 18.624 6.62063 18.7539 6.71838 18.8497C6.81613 18.9456 6.94859 18.9996 7.08683 19H16.6522C16.7904 18.9996 16.9229 18.9456 17.0207 18.8497C17.1184 18.7539 17.1735 18.624 17.1739 18.4884V8.51099H18.4783C18.6167 8.51099 18.7494 8.45709 18.8472 8.36114C18.945 8.2652 19 8.13507 19 7.99938C19 7.86369 18.945 7.73356 18.8472 7.63762C18.7494 7.54167 18.6167 7.48777 18.4783 7.48777H15.9913C15.9728 6.61733 15.6368 5.78221 15.0444 5.1338C14.3218 4.38376 13.2696 4 11.9044 4C10.5393 4 9.5128 4.38376 8.86094 5.14265C8.32764 5.80761 8.06709 6.64407 8.13047 7.48777H5.52171C5.4532 7.48777 5.38536 7.501 5.32206 7.52671C5.25876 7.55242 5.20125 7.59011 5.1528 7.63762C5.10436 7.68512 5.06593 7.74152 5.03971 7.8036C5.01349 7.86567 5 7.93219 5 7.99938C5 8.06657 5.01349 8.13309 5.03971 8.19517C5.06593 8.25724 5.10436 8.31364 5.1528 8.36114C5.20125 8.40865 5.25876 8.44634 5.32206 8.47205C5.38536 8.49776 5.4532 8.51099 5.52171 8.51099ZM9.64362 5.79939C10.0871 5.27914 10.8436 5.02344 11.8958 5.02344C12.9566 5.02344 13.7567 5.29641 14.2698 5.82509C14.6825 6.28669 14.9191 6.87441 14.9393 7.48799H9.17388C9.10978 6.88733 9.27736 6.28491 9.64362 5.79939ZM16.1305 8.51099V17.9766H7.60788V8.51099H16.1305Z" fill="#35373A" />
            <path d="M10.2174 16.5262C10.3556 16.5258 10.4881 16.4718 10.5858 16.3759C10.6836 16.2801 10.7387 16.1502 10.7391 16.0146V10.1308C10.7391 9.99506 10.6841 9.86493 10.5863 9.76899C10.4884 9.67304 10.3558 9.61914 10.2174 9.61914C10.079 9.61914 9.94632 9.67304 9.84848 9.76899C9.75064 9.86493 9.69568 9.99506 9.69568 10.1308V16.0146C9.69609 16.1502 9.75118 16.2801 9.84893 16.3759C9.94668 16.4718 10.0791 16.5258 10.2174 16.5262Z" fill="#35373A" />
            <path d="M13.522 16.5262C13.6602 16.5258 13.7927 16.4718 13.8904 16.3759C13.9882 16.2801 14.0433 16.1502 14.0437 16.0146V10.1308C14.0437 9.99506 13.9887 9.86493 13.8909 9.76899C13.793 9.67304 13.6603 9.61914 13.522 9.61914C13.3836 9.61914 13.2509 9.67304 13.1531 9.76899C13.0552 9.86493 13.0002 9.99506 13.0002 10.1308V16.0146C13.0007 16.1502 13.0558 16.2801 13.1535 16.3759C13.2513 16.4718 13.3837 16.5258 13.522 16.5262Z" fill="#35373A" />
        </svg>
    )
}

export default RecycleIcon


