import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const MyDateRangePicker = ({ onDateRangeSelected }) => {
    const [state, setState] = useState([
        {
            endDate: null,
            key: 'selection'
        }
    ]);

    return (
        <div>
            <DateRangePicker
                onChange={(item) => {
                    setState([item.selection]);
                    if (onDateRangeSelected) {
                        onDateRangeSelected(item.selection.startDate, item.selection.endDate);
                    }
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                ranges={state}
                months={1}
                direction="horizontal"
                locale={ru}
            />
        </div>
    );
}

export default MyDateRangePicker;
