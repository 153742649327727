import React, {useEffect, useState} from 'react';
import styles from './row.module.scss';
const Row = ({ item, level = 1, setOpenedRows, openedRows }) => {

    const nestedEl = item[1].nestedEl || null;
    const title = item[0] || null;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(!!openedRows?.find(val => val.level === level && val.title === title));
    }, [openedRows]);

    return (
        <div className={styles.row}>
            <div className={styles.top} onClick={() => {
                setOpenedRows(state => open ? state.filter(item => item.level !== level && item.title !== title) : [...state, { level, title }]);
            }} style={{padding: `10px 0px 10px ${10 * level}px`, cursor: nestedEl && Object.keys(nestedEl).length > 0 ? 'pointer' : 'default'}}>
                {
                    nestedEl && Object.keys(nestedEl).length > 0 ?
                        <svg style={{transform: `rotate(${!open ? '0deg' : '90deg'})`}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M11.4031 8.08385L7.19523 4.19005C6.92153 3.93665 6.47898 3.93665 6.20528 4.19005C5.93157 4.44333 5.93157 4.85286 6.20528 5.10614L10.4132 9.00006L6.20528 12.8939C5.93157 13.1473 5.93157 13.5567 6.20528 13.8099C6.47898 14.0634 6.92153 14.0634 7.19523 13.8099L11.4031 9.91615C11.4031 9.91615 12 9.40748 12 9.00006C12 8.59263 11.4031 8.08385 11.4031 8.08385Z" fill="#35373A"/>
                        </svg> : <span style={{width: '18px'}}/>
                }
                <p>{title}</p>
            </div>
            {
                open && nestedEl &&
                Object.keys(nestedEl).map((key) => (
                    <Row setOpenedRows={setOpenedRows} item={[key, nestedEl[key]]} level={level + 1} openedRows={openedRows} />
                ))
            }
        </div>
    );
};
export default Row;
