import React from 'react'
import styles from './styles.module.scss'

function MarketplacesSwitch({ all = true, active, setActive, values }) {

    if(values) {
        return (
            <div className={styles.switch}>
                {
                    values.map(item => (
                        <button style={{overflow: 'hidden', width: 'auto', padding: '14px 20px', background: active === item.id ? '#fff' : ''}} onClick={() => setActive(item.id)} className={`${styles.market} ${active === item.id ? styles.active : ''}`}>{item.name}</button>
                    ))
                }
            </div>
        )
    }

    return (
        <div className={styles.switch}>
            <span className={styles.activeBg} style={{transform: all ? `translateX(${140 * active}px)` : `translateX(${140 * (active - 1)}px)`}}/>
            {
                all &&
                <button onClick={() => setActive(0)} className={`${styles.market} ${active === 0 ? styles.active : ''}`}>ОБЩАЯ</button>
            }
            <button onClick={() => setActive(1)} className={`${styles.market} ${active === 1 ? styles.active : ''}`}>WB</button>
            <button onClick={() => setActive(2)} className={`${styles.market} ${active === 2 ? styles.active : ''}`}>OZON</button>
        </div>
    );
}

export default MarketplacesSwitch;