import React from 'react';

const DownArrow = () => {
    return (
        <>
            <svg id="noClose" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                <path id="noClose" d="M1 1L5 5L9 1" stroke="#8496AF" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default DownArrow;