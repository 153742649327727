import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Switch from './Switch/index.jsx';
import { NewCustomInput } from '../../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import { API_URL, getData, postData, putData } from '../../../../../static/js/request';
import { useParams } from 'react-router-dom';
import { createNotification } from '../../../../../static/js/commonFunc';

function Notices() {

    const [status, setStatus] = useState(false);

    const [defaultInfo, setDefaultInfo] = useState(null)

    const allScores = ['1 звезда', 'до 2 звезд', 'до 3 звезд'];

    const params = useParams();

    const [activeScore, setActiveScore] = useState(2);

    const [groupId, changeGroupId] = useState('');

    const getInfo = async () => {
        try {
            const response = await getData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/notification/`);
            const index = 0;
            setDefaultInfo(response)
            if (response[index]) {
                setStatus(response[index].status);
                setActiveScore(response[index].estimation);
                changeGroupId(response[index].tg_group_id.toString())
            }
        } catch (error) {
            console.log(error)
        }
    }

    const saveInfo = async (localStatus) => {
        try {
            const response = await putData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/notification/`, {
                notification_id: defaultInfo[0].id,
                status: localStatus ? localStatus.status : status,
                estimation: activeScore,
                tg_group_id: parseInt(groupId)
            });
            createNotification(response);
            getInfo();
        } catch (error) {
            console.log(error)
        }
    }

    const createInfo = async () => {
        try {
            const response = await postData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/notification/`, {
                status,
                estimation: activeScore,
                tg_group_id: parseInt(groupId)
            })
            createNotification(response);
            getInfo();
        } catch (error) {
            console.log(error)
        }
    }

    const switchStatus = (status) => {
        if(!status && defaultInfo) {
            saveInfo({status: false})
        }
        setStatus(status)
    }

    const saveClick = () => {
        if (defaultInfo.length === 0 || !defaultInfo) {
            createInfo();
        } else {
            saveInfo();
        }
    }

    useEffect(() => {
        getInfo();
    }, [])

    return (
        <div className={styles.content}>
            <div className={styles.status}>
                <p className={styles.statusText}>Активность:</p>
                <Switch active={status} setActive={(value) => switchStatus(value)} />
            </div>
            <div className={styles.wrap}>
                <div className={styles.scores}>
                    <p className={styles.scoresTitle}>Какие оценки приписывать к негативным:</p>
                    {
                        allScores.map((item, index) => (
                            <button onClick={() => setActiveScore(index + 1)} className={styles.scoreItem}>
                                <div className={styles.scoreCheck}>
                                    {
                                        activeScore === index + 1 &&
                                        <span />
                                    }
                                </div>
                                <p className={styles.scoreText}>{item}</p>
                            </button>
                        ))
                    }
                </div>
                <div className={styles.group}>
                    <p className={styles.groupTitle}>В какую группу присылать уведомления:</p>
                    <NewCustomInput numeric placeholder={'id группы'} value={groupId} onChange={changeGroupId} width={'10%'} />
                    <button onClick={saveClick} disabled={groupId.trim().length === 0} className={styles.saveId}>Сохранить изменения</button>
                </div>
                <div className={styles.tip}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M6.99936 4.9356C7.14204 4.93519 7.27875 4.87833 7.37964 4.77744C7.48052 4.67656 7.53738 4.53984 7.53779 4.39717V3.85874C7.53779 3.71594 7.48106 3.57899 7.38009 3.47802C7.27912 3.37704 7.14216 3.32031 6.99936 3.32031C6.85657 3.32031 6.71961 3.37704 6.61864 3.47802C6.51767 3.57899 6.46094 3.71594 6.46094 3.85874V4.39717C6.46135 4.53984 6.5182 4.67656 6.61909 4.77744C6.71998 4.87833 6.85669 4.93519 6.99936 4.9356Z" fill="#429EFF" />
                        <path d="M5.92124 7.08955H6.45967V10.1408C6.45967 10.2836 6.5164 10.4205 6.61737 10.5215C6.71834 10.6225 6.8553 10.6792 6.99809 10.6792C7.14089 10.6792 7.27785 10.6225 7.37882 10.5215C7.47979 10.4205 7.53652 10.2836 7.53652 10.1408V6.55112C7.53611 6.40845 7.47925 6.27174 7.37837 6.17085C7.27748 6.06996 7.14077 6.0131 6.99809 6.0127H5.92124C5.77844 6.0127 5.64149 6.06942 5.54051 6.1704C5.43954 6.27137 5.38281 6.40832 5.38281 6.55112C5.38281 6.69392 5.43954 6.83088 5.54051 6.93185C5.64149 7.03283 5.77844 7.08955 5.92124 7.08955Z" fill="#429EFF" />
                        <path d="M7 14C8.38447 14 9.73785 13.5895 10.889 12.8203C12.0401 12.0511 12.9373 10.9579 13.4672 9.67878C13.997 8.3997 14.1356 6.99224 13.8655 5.63437C13.5954 4.2765 12.9287 3.02922 11.9497 2.05026C10.9708 1.07129 9.7235 0.404603 8.36563 0.134506C7.00776 -0.13559 5.6003 0.00303298 4.32121 0.532846C3.04213 1.06266 1.94888 1.95987 1.17971 3.11101C0.410543 4.26215 0 5.61553 0 7C0.00202552 8.8559 0.740174 10.6352 2.05249 11.9475C3.3648 13.2598 5.1441 13.998 7 14ZM7 1.00473C8.18575 1.00473 9.34488 1.35635 10.3308 2.01512C11.3167 2.67388 12.0851 3.61022 12.5389 4.70571C12.9927 5.8012 13.1114 7.00665 12.8801 8.16962C12.6487 9.33259 12.0777 10.4008 11.2393 11.2393C10.4008 12.0778 9.33259 12.6487 8.16962 12.8801C7.00665 13.1114 5.8012 12.9927 4.70571 12.5389C3.61021 12.0851 2.67388 11.3167 2.01511 10.3308C1.35634 9.34488 1.00473 8.18575 1.00473 7C1.00593 5.41033 1.63796 3.88611 2.76203 2.76204C3.8861 1.63797 5.41032 1.00594 7 1.00473Z" fill="#8496AF" />
                    </svg>
                    <div className={styles.info}>
                        <p className={styles.infoTitle}>Подсказка: Для определения id канала или группы можно воспользоваться ботом @getmyid_bot.</p>
                        <p className={styles.infoSubtitle}>Порядок действий:</p>
                        <p className={styles.listnItem}>1. Найти бота</p>
                        <p className={styles.listnItem}>2. Добавить бота в список контактов</p>
                        <p className={styles.listnItem}>3. Нажать start</p>
                        <p className={styles.listnItem}>4. Получить id текущего чата с ботом</p>
                        <p className={styles.listnItem}>5. Переслать любое сообщение из нужного чата</p>
                        <p className={styles.listnItem}>6. В ответ бот пришлет id этого чата</p>
                    </div>
                </div>
                {
                    !status &&
                    <span className={styles.unactiveBlock} />
                }
            </div>
        </div>
    );
}

export default Notices;