// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cKZK6zdHA6TYejJrXWnr {\n  padding: 30px 10px;\n  max-width: 980px;\n  width: 100%;\n  margin: 0 auto 100px;\n}\n.cKZK6zdHA6TYejJrXWnr .Un0RBY2VuEySWZszR6a0 {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  color: #8496AF;\n}\n.cKZK6zdHA6TYejJrXWnr .Un0RBY2VuEySWZszR6a0 a {\n  color: #35373A;\n}\n.cKZK6zdHA6TYejJrXWnr .m_uMgt3DIQTniQBw7QBA {\n  padding: 20px;\n  background: #fff;\n  border-radius: 8px;\n  margin-top: 20px;\n}\n.cKZK6zdHA6TYejJrXWnr .m_uMgt3DIQTniQBw7QBA .hO7DRTo91eL25s3twQZp {\n  font-size: 24px;\n  margin-bottom: 30px;\n}\n.cKZK6zdHA6TYejJrXWnr .m_uMgt3DIQTniQBw7QBA .MHAYV5gh726ZCL5EMu_Q {\n  max-width: 770px;\n  width: 100%;\n  margin: 30px auto;\n  font-size: 16px;\n  line-height: 140%;\n}", "",{"version":3,"sources":["webpack://./src/views/FAQ/ui/ArticleContent/ArticleContent.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;AACJ;AACI;EACE,cAAA;AACN;AAGE;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AADJ;AAGI;EACE,eAAA;EACA,mBAAA;AADN;AAII;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAFN","sourcesContent":[".content {\n  padding: 30px 10px;\n  max-width: 980px;\n  width: 100%;\n  margin: 0 auto 100px;\n\n  .path {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    color: #8496AF;\n\n    a {\n      color: #35373A;\n    }\n  }\n\n  .article {\n    padding: 20px;\n    background: #fff;\n    border-radius: 8px;\n    margin-top: 20px;\n\n    .articleTitle {\n      font-size: 24px;\n      margin-bottom: 30px;\n    }\n\n    .articleText {\n      max-width: 770px;\n      width: 100%;\n      margin: 30px auto;\n      font-size: 16px;\n      line-height: 140%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "cKZK6zdHA6TYejJrXWnr",
	"path": "Un0RBY2VuEySWZszR6a0",
	"article": "m_uMgt3DIQTniQBw7QBA",
	"articleTitle": "hO7DRTo91eL25s3twQZp",
	"articleText": "MHAYV5gh726ZCL5EMu_Q"
};
export default ___CSS_LOADER_EXPORT___;
