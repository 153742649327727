import React from "react"
import RigthArrow from '../../shared/ui/icons/RigthArrow.jsx';
import style from './FinancingReport.module.scss';
import cx from 'classnames';

const TableHead = ({ dateList, action, headRef, currentMonth }) => {

    return (
        <div className={style.row}>
            <div
                className={style.title}
            >

            </div>
            <div
                className={style.xScroll}
                ref={headRef}

            >
                {dateList.map((item, index) => (
                    <div
                        key={index}
                        className={cx(style.column,  !item[1].includes('нед') ? style.dates : style.week, currentMonth===item[1] ? style.active : null)}
                        data-year={item[0]}
                        onClick={() => {
                            if (item[1] !== 'Итог' && !item[1].includes('нед') && action) {
                                action(currentMonth===item[1] ? null : item[1], item[0])
                            }
                        }}

                    >
                        {item[1]}
                        {item[1] !== 'Итог' && !item[1].includes('нед') && (
                            <RigthArrow />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )

}

export default TableHead