"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CabinetApi = void 0;
const api_1 = require("@/fsd/shared/api");
class CabinetApi {
    static url = `${api_1.API_BASE_URL}/account/cabinet/`;
    static getToken() {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('Token is missing in localStorage');
        }
        return token;
    }
    static async fetchData(url, method, body) {
        try {
            const response = await fetch(url, {
                method,
                mode: 'cors',
                headers: {
                    Authorization: `Token ${this.getToken()}`,
                    'Content-Type': 'application/json',
                },
                body: body ? JSON.stringify(body) : undefined,
            });
            if (!response.ok) {
                throw new Error(`HTTP error ${response.status} ${response.statusText}`);
            }
            return await response.json();
        }
        catch (error) {
            throw Error(`Failed to ${method} data: ${error}`);
        }
    }
    static async create(name) {
        return CabinetApi.fetchData(CabinetApi.url, 'POST', {
            name,
        });
    }
    static async load() {
        return CabinetApi.fetchData(CabinetApi.url, 'GET');
    }
    static async current() {
        const url = `${api_1.API_BASE_URL}/account/current/cabinet/`;
        return CabinetApi.fetchData(url, 'GET');
    }
    static async remove(id) {
        return CabinetApi.fetchData(`${this.url}?id=${id}`, 'DELETE');
    }
    static async rename(id, name) {
        return CabinetApi.fetchData(`${this.url}`, 'PUT', {
            name,
            id,
        });
    }
    static async switch(cabinet_id) {
        return CabinetApi.fetchData(`${this.url}switch/`, 'POST', { cabinet_id });
    }
}
exports.CabinetApi = CabinetApi;
