import React, { useEffect, useState, useRef } from 'react';
import style from './PlanFact.module.scss'
import Filter from './filter/filter.jsx'
import TableData from './table/table.jsx'
import TableSettings from './filter/tableSettins.jsx';
import planFactProductList from './plan_fact_product_list.json';


const PlanFact = () => {

    const [data, setData] = useState(null)
    const [tableSettings, setTableSettings] = useState(false)

    const showSettingsButtonRef = useRef(null)

    const [filterHeight, setFilterHeight] = useState(0)

    const [filter, setFilter] = useState({
        marketPlace: [],
        manager: [],
        tags: [],
        dateRange: [null, null]
    })

    const [columnsVisibility, setColumnsVisibility] = useState({
        orders_count: true,
        orders_sum: true,
        sales_count: true,
        sales_sum: true,
        stock: true,
    });

    const toggleSelection = (key, value) => {
        setFilter((prevFilter) => {
            const currentValues = prevFilter[key];
            if (currentValues.includes(value)) {
                return {
                    ...prevFilter,
                    [key]: currentValues.filter((item) => item !== value)
                };
            } else {
                return {
                    ...prevFilter,
                    [key]: [...currentValues, value]
                };
            }
        });
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setFilter((prevFilter) => ({
            ...prevFilter,
            dateRange: [start, end]
        }));
    };

    const removeFilter = (key, value) => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            [key]: prevFilter[key].filter((item) => item !== value)
        }));
    };

    const resetAll = () => {
        setFilter({
            marketPlace: [],
            manager: [],
            tags: [],
            dateRange: [null, null]
        })
    }

    const allColumnsSelected = Object.values(columnsVisibility).every(Boolean);

    const toggleColumnVisibility = (column) => {
        setColumnsVisibility((prev) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };

    const toggleSelectAllColumns = () => {
        const newVisibility = !allColumnsSelected;
        const updatedColumnsVisibility = Object.keys(columnsVisibility).reduce(
            (acc, column) => ({
                ...acc,
                [column]: newVisibility,
            }),
            {}
        );
        setColumnsVisibility(updatedColumnsVisibility);
    };


    useEffect(() => {
        const getData = async () => {

            try {
                setData(planFactProductList)
                // const response = await fetch('http://localhost:4001/plan.json')
                // const jsonData = await response.json();
                // setData(jsonData);

            } catch (error) {
                console.log(error)
            }

        }

        getData()
    }, [])

    return (
        <>
            <h1 className={style.title}>План / Факт</h1>
            <main className={style.main}>

                <Filter
                    filter={filter}
                    setFilterHeight={(val) => setFilterHeight(val)}
                    changeFilter={(key, value) => toggleSelection(key, value)}
                    changeDates={(value) => handleDateChange(value)}
                    removeFilter={(key, value) => removeFilter(key, value)}
                    resetAll={() => resetAll()}
                    showSetings={(value) => setTableSettings(value)}
                    showSettingsButtonRef={showSettingsButtonRef}
                />

                {data && <TableData data={data.data} columnsVisibility={columnsVisibility} filterHeight={filterHeight} />}

                <TableSettings
                    show={tableSettings}
                    showSettings={(value) => setTableSettings(value)}
                    showSettingsButtonRef={showSettingsButtonRef}
                    columnsVisibility={columnsVisibility}
                    allColumnsSelected={allColumnsSelected}
                    toggleSelectAllColumns={() => toggleSelectAllColumns()}
                    toggleColumnVisibility={(value) => toggleColumnVisibility(value)}
                />

            </main>
        </>

    )
}

export default PlanFact;