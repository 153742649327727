import React, { useEffect, useRef, useState } from 'react';
import style from './header.module.scss';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useUserStore } from '../../../../store/Login/useUserStore';
import { observer } from 'mobx-react-lite';
import { API_URL, getData } from '../../../../static/js/request';
import SearchBar from '../../../../views/FAQ/ui/SearchBar/SearchBar.jsx';
import { FaqIcon, LinkAnother, Logout, MainLogo, NotificationIcon, Settings, StarIcons, User } from '@/fsd/shared/ui/icons';
import { FavoriteButton } from '@/fsd/entities/favorite-button';

import styles from './header.module.scss';
import { ToggleCabinet } from '@/fsd/feature/toggle-cabinet';

export const Header = observer(({ full }) => {
    const menuData = {
        "Финансы": {
            isActive: reportIsActive,
            items: {
                "Закупки": [
                    { to: "/purchase/orders/", label: "Заказы", icon: <StarIcons.hollow /> },
                    { to: "/purchase/acceptance/", label: "Приемки", icon: <StarIcons.hollow /> },
                    { to: "/purchase/shipment/", label: "Отгрузки", icon: <StarIcons.hollow /> }
                ],
                "Отчеты": [
                    { to: "/reports/", label: "ОПиУ (P&L)", icon: <StarIcons.hollow /> },
                    { to: "/financing/dds/", label: "ДДС (Cashflow)", icon: <StarIcons.hollow /> },
                    { to: "/reports/balance", label: "Баланс", icon: <StarIcons.hollow /> },
                    // { to: "/financing/planfact", label: "План/Факт", icon: <StarIcons.hollow /> },
                    // { to: "/reports/advertisement/", label: "Реклама", icon: <StarIcons.hollow /> }
                ],
                "Деньги": [
                    { to: "/financing/banks/", label: "Банки", icon: <StarIcons.hollow /> },
                    { to: "/financing/rules/", label: "Правила", icon: <StarIcons.hollow /> },
                    { to: "/financing/cashbox", label: "Кассы", icon: <StarIcons.hollow /> },
                    { to: "/staff", label: "ФОТ", icon: <StarIcons.hollow /> },
                    { to: "/financing/loans/", label: "Займы", icon: <StarIcons.hollow /> }
                ]
            }
        },
        // "Аналитика": {
        //     isActive: analyticIsActive,
        //     items: {
        //         "Основное": [
        //             { to: "/analytics/projects/", label: "Проекты", icon: <StarIcons.hollow /> },
        //             { to: "/reports/advertisement/", label: "Реклама", icon: <StarIcons.hollow /> },
        //             { to: "/economy/sell", label: "Unit-экономика", icon: <StarIcons.hollow /> }
        //         ]
        //     }
        // },
        "Автоматизация": {
            isActive: SchedulerIsActive,
            items: {
                "Инструменты": [
                    // { to: "/scheduler/logistics", label: "Планировщик поставок", icon: <StarIcons.hollow /> },
                    { to: "/scheduler/answering", label: "Автоответчик на отзывы", icon: <StarIcons.hollow /> }
                ]
            }
        }
    };

    const faqLinks = [
        { to: '/faq/categories', label: 'Категории' },
        { to: '/faq/videos', label: 'Видео' },
        { to: '/faq/webinars', label: 'Вебинары' },
        { to: '/faq/req', label: 'Обратная связь' },
        { to: '/', label: 'Перейти в сервис', icon: <LinkAnother />, external: true }
    ];

    const navigate = useNavigate();
    const userStore = useUserStore();

    const [userData, setUserData] = useState('');
    const [reportIsActive, setReportIsActive] = useState(false);
    const [purchaseIsActive, setPurchaseIsActive] = useState(false);
    const [analyticIsActive, setAnalyticIsActive] = useState(false);
    const [SchedulerIsActive, setSchedulerActive] = useState(false);
    const menuRef = useRef(null);

    const location = useLocation();

    const loading = useRef(null);
    if (userData === '') {
        loading.current = true;
    } else {
        loading.current = false;
    }

    useEffect(() => {
        if (location.pathname.includes('financing')) {
            setReportIsActive(true);
            setSchedulerActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        } else if (location.pathname.includes('scheduler')) {
            setSchedulerActive(true);
            setReportIsActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        } else if (location.pathname.includes('purchase')) {
            setSchedulerActive(false);
            setPurchaseIsActive(true);
            setReportIsActive(false);
            setAnalyticIsActive(false);
        } else if (location.pathname.includes('report')) {
            setReportIsActive(true);
            setSchedulerActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        } else if (
            location.pathname.includes('analytics') ||
            location.pathname.includes('economy')
        ) {
            setReportIsActive(false);
            setSchedulerActive(false);
            setAnalyticIsActive(true);
            setPurchaseIsActive(false);
        } else {
            setSchedulerActive(false);
            setReportIsActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        }
    }, [location]);

    useEffect(() => {
        if (
            !userStore.isLogged &&
            location.pathname !== '/signup/' &&
            location.pathname !== '/policy'
        ) {
            navigate('/login');
        }
        if (!userData) {
            getData(API_URL + '/api/v1/account/').then((data) => {
                if (data.detail === 'Недопустимый токен.') {
                    handleOut();
                }
                
                setUserData(data);
            });
        }
    }, [userStore.isLogged, navigate]);

    const handleOut = () => {
        userStore.handleOut().then(() => {
            navigate('/login');
        });
    };

    const [faqSearch, setFaqSearch] = useState(false);


    const childRef = useRef(null);
    const [parentWidth, setParentWidth] = useState('auto');

    useEffect(() => {
        if (childRef.current) {
            const childWidth = childRef.current.offsetWidth;
            setParentWidth(`${childWidth}px`);
        }
    }, []);

    return (
        <header className={style.header}>
            <div className={`${style.faqSearch} ${faqSearch ? style.active : ''}`}>
                <SearchBar active={faqSearch} onClose={() => setFaqSearch(false)} />
            </div>
            <div
                className={style['header-nav']}
                style={{ maxWidth: full ? '100vw' : '' }}
            >
                {location.pathname.includes('faq') ? (
                    <div className={styles.content} style={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                        <Link to={'/'} className={styles.logo}>
                            <MainLogo />
                            <p style={{
                                textAlign: 'center',
                                fontSize: '10px',
                                color: '#429EFF',
                                textTransform: 'uppercase',
                                marginTop: '-3px'
                            }}>
                                База знаний
                            </p>
                        </Link>
                        <nav className={styles.nav} style={{ display: 'flex', alignItems: 'center', gap: '40px', fontWeight: 500 }}>
                            {
                                faqLinks.map((link, index) => {
                                    if (link.external) {
                                        return (
                                            <Link
                                                to={link.to}
                                                key={index}
                                                rel="noreferrer"
                                                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                                            >
                                                {link.label}
                                                {link.icon && link.icon}
                                            </Link>
                                        );
                                    } else {
                                        return (
                                            <NavLink
                                                to={link.to}
                                                key={index}
                                            >
                                                {link.label}
                                            </NavLink>
                                        );
                                    }
                                })
                            }
                        </nav>
                    </div>
                ) : (
                    <div
                        className={cx(
                            style['flex'],
                            style['jc-sb'],
                            style['ai-c'],
                            style['gap-20']
                        )}
                    >
                        <div
                            className={cx(
                                style['header-nav__item'],
                                style['header-nav__item--logo']
                            )}
                        >
                            <Link
                                to="/"
                                className={style['header__logo_link']}
                            >
                                <MainLogo />
                            </Link>
                        </div>

                        {userStore.isLogged && (
                            <>
                                <FavoriteButton />
                                {
                                    Object.keys(menuData).map((menuKey) => {
                                        const { isActive, items } = menuData[menuKey];
                                        return (
                                            <div className={cx(style['header-nav__item'], style['header-nav__item-menu'])} key={menuKey} ref={menuRef}>
                                                <span className={isActive ? style['activeItem'] : ''}>{menuKey}</span>
                                                <div className={style['sub-menu']}>
                                                    {Object.keys(items).map((subMenuKey) => (
                                                        <div className={style['sub-menu__item']} key={subMenuKey}>
                                                            <div className={style['sub-menu__item_title']}>{subMenuKey}</div>
                                                            {items[subMenuKey].map((item, index) => (
                                                                <NavLink
                                                                    to={item.to}
                                                                    className={({ isActive }) => isActive ? style.active : ''}
                                                                    key={index}
                                                                >
                                                                    <span className={style['sub-menu__item_link']}>
                                                                        {item.icon && item.icon}
                                                                        {item.label}
                                                                    </span>
                                                                </NavLink>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </>
                        )}
                    </div>
                )}


                {!userData && (
                    <div className={`${style.skeleton} ${style['user-skeleton']}`}></div>
                )}

                {userData && userStore.isLogged && (
                    <div className={cx(style['header-nav__item'], style.relative)}>
                        <ToggleCabinet />
                        <NotificationIcon />
                        <Link to="/faq" className={style.faq}>
                            <FaqIcon />
                        </Link>
                        {location.pathname.includes('/faq') &&
                            location.pathname.length > 5 && (
                                <div
                                    onClick={() => setFaqSearch(true)}
                                    className={style.inputWrap}
                                >
                                    <svg
                                        id={'noClose'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                    >
                                        <path
                                            id={'noClose'}
                                            d="M14.9595 16.256L14.9604 16.256C15.0525 16.2563 15.1436 16.2375 15.2282 16.2008C15.3124 16.1643 15.3883 16.1109 15.4512 16.0439C15.5783 15.9114 15.6491 15.7344 15.6491 15.5505C15.6491 15.3663 15.578 15.189 15.4505 15.0564L15.4505 15.0564L15.4491 15.055L11.1147 10.6652C12.1118 9.50932 12.6473 8.01763 12.6135 6.48165C12.5786 4.89817 11.9413 3.38886 10.8335 2.26759C9.72568 1.14626 8.23258 0.499271 6.66447 0.461605C5.09637 0.423938 3.5745 0.998509 2.41517 2.06542C1.25589 3.13229 0.548326 4.60934 0.439056 6.18937C0.329786 7.7694 0.827171 9.33143 1.82821 10.5508C2.82929 11.7702 4.25714 12.5531 5.81516 12.7362C7.32956 12.9143 8.85316 12.5123 10.0866 11.6128L14.4719 16.0454C14.5351 16.111 14.6106 16.1635 14.694 16.1996L14.7537 16.062L14.694 16.1996C14.7779 16.236 14.8681 16.2551 14.9595 16.256ZM1.71228 6.5394C1.7123 5.58279 1.993 4.64779 2.51869 3.85264C3.04439 3.05748 3.79143 2.43796 4.66515 2.07219C5.53885 1.70643 6.50015 1.61076 7.42756 1.7972C8.35498 1.98364 9.20709 2.44389 9.87604 3.11998C10.545 3.79609 11.0008 4.6577 11.1854 5.59594C11.3701 6.53419 11.2753 7.50669 10.9131 8.3904C10.5509 9.2741 9.93773 10.0291 9.15126 10.5603C8.36485 11.0913 7.44046 11.3747 6.49497 11.3747C5.22739 11.3736 4.01173 10.8641 3.11492 9.95777C2.21806 9.05134 1.71342 7.82196 1.71228 6.5394Z"
                                            fill="#8496AF"
                                            stroke="#8496AF"
                                            strokeWidth="0.3"
                                        />
                                    </svg>
                                    Поиск
                                </div>
                            )}


                        <div style={{ position: 'relative' }} >
                            <div className={style.widgetLKPre}>
                                <User.Fallback />
                            </div>
                            <div className={style.subMenu}>
                                <div className={style.subMenuContent}>                                    <div className={style.widgetLKItem}>
                                    <User.Default />
                                    <NavLink to="/settings/account/">Учетная запись </NavLink>
                                </div>
                                    <div className={style.widgetLKItem}>
                                        <Settings />
                                        <NavLink to="/settings/account/"> Настройки </NavLink>
                                    </div>
                                    <div style={{
                                        border: '0.5px solid #DCE7F1',
                                        width: '100%',
                                        margin: '10px 0'
                                    }} />
                                    <a
                                        href="#"
                                        className={style.widgetLKItem}
                                        onClick={handleOut}
                                    >
                                        <Logout />
                                        Выйти
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </header >
    );
});

