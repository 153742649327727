import React, {useEffect, useState} from 'react';
import styles from './MainContent.module.scss';
import SearchBar from '../SearchBar/SearchBar.jsx';
import InterestingCard from '../InterestingCard/InterestingCard.jsx';
import {API_URL, getData} from '../../../../static/js/request';

const MainContent = () => {

    const [data, setData] = useState([]);

    const getCards = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/knowbase/sections/');
            setData(response);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getCards();
    }, []);

    return (
        <>
            <div className={styles.content}>
                <h1 className={styles.title}>Как работать с Busyboard</h1>
                <SearchBar/>
            </div>
            <div className={styles.interesting}>
                <h2 className={styles.smallTitle}>Возможно вас заинтересует</h2>
                <div className={styles.cards}>
                    {
                        data.map(item => (
                            <InterestingCard key={item.id} item={item}/>
                        ))
                    }
                </div>
            </div>
        </>

    );
};
export default MainContent;
