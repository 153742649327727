import React,{ useState, useEffect } from "react";
import TableLine from "./tableLine.jsx";
import plan_fact_product_detail from '../paln_fact_product_detail.json'

const Sizes = ({columnsVisibility, scrollContainerRef}) => {
    
    const [data, setData] = React.useState(null);
    
    useEffect(() => {
        const getData = async () => {
            
            try {
                
                // const response = await fetch('http://localhost:4001/detail.json')
                // const jsonData = await response.json();
                // setData(jsonData);
                setData(plan_fact_product_detail);

            } catch (error) {
                console.log(error)
            }
            
        }
        
        getData()
    }, [])
    
    return(
        data && data.map((item, productIndex) =>
            item && <TableLine key={productIndex} data={item} isChild={true} scrollContainerRef={scrollContainerRef} columnsVisibility={columnsVisibility} />
        )
    )
}

export default Sizes;