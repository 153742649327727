import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './SearchBar.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import debounce from 'lodash.debounce';
import {API_URL, getData} from '../../../../static/js/request';

const SearchBar = ({active, onClose}) => {

    const [activeSearch, setActiveSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const clickOutside = (e) => {
        const id = e.target.getAttribute('id');

        if(id !== 'noClose') {
            setActiveSearch(false);
            if(onClose) {
                onClose();
            }
        }
    };

    const onKeyDown = (e) => {
        if(e.key === 'Escape') {
            setActiveSearch(false);
            inputRef.current?.blur();
            if(onClose) {
                onClose();
            }
        }
    };

    useEffect(() => {
        if(activeSearch || active) {
            document.addEventListener('mousedown', clickOutside);
            document.addEventListener('keydown', onKeyDown);
        } else {
            document.removeEventListener('mousedown', clickOutside);
            document.removeEventListener('keydown', onKeyDown);
        }

        return () => {
            document.removeEventListener('mousedown', clickOutside);
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [activeSearch, active]);

    const inputRef = useRef();

    useEffect(() => {
        if(active) {
            inputRef.current?.focus();
        }
    }, [active]);

    const [searchResults, setSearchResults] = useState(null);

    const getSearch = async (value) => {
        try {
            const response = await getData(API_URL + `/api/v1/knowbase/search/?q=${value}`);
            setSearchResults([...response.content_matching_articles, ...response.title_matching_articles]);
        } catch (e) {
            console.log(e);
        }
    };

    const debounceFn = useCallback(debounce(getSearch, 1000), []);

    const onChangeInput = (event) => {
        setSearchValue(event.target.value);
        setSearchResults(null)
        debounceFn(event.target.value);
    };

    const navigate = useNavigate()

    const onKeyDownLocal = (event) => {
        if(event.key === 'Enter') {
            setSearchResults(null);
            setSearchValue('');
            navigate('/faq/categories/null?search=' + searchValue);
            try {
                onClose();
            } catch (e) {

            }
        }
    }

    return (
        <div className={`${styles.search} ${active !== undefined && !active ? styles.unactive : ''}`}>
            {
                <span className={`${styles.bg} ${activeSearch || active ? styles.active : ''}`}/>
            }
            <svg id={'noClose'} xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path id={'noClose'} d="M14.9595 16.256L14.9604 16.256C15.0525 16.2563 15.1436 16.2375 15.2282 16.2008C15.3124 16.1643 15.3883 16.1109 15.4512 16.0439C15.5783 15.9114 15.6491 15.7344 15.6491 15.5505C15.6491 15.3663 15.578 15.189 15.4505 15.0564L15.4505 15.0564L15.4491 15.055L11.1147 10.6652C12.1118 9.50932 12.6473 8.01763 12.6135 6.48165C12.5786 4.89817 11.9413 3.38886 10.8335 2.26759C9.72568 1.14626 8.23258 0.499271 6.66447 0.461605C5.09637 0.423938 3.5745 0.998509 2.41517 2.06542C1.25589 3.13229 0.548326 4.60934 0.439056 6.18937C0.329786 7.7694 0.827171 9.33143 1.82821 10.5508C2.82929 11.7702 4.25714 12.5531 5.81516 12.7362C7.32956 12.9143 8.85316 12.5123 10.0866 11.6128L14.4719 16.0454C14.5351 16.111 14.6106 16.1635 14.694 16.1996L14.7537 16.062L14.694 16.1996C14.7779 16.236 14.8681 16.2551 14.9595 16.256ZM1.71228 6.5394C1.7123 5.58279 1.993 4.64779 2.51869 3.85264C3.04439 3.05748 3.79143 2.43796 4.66515 2.07219C5.53885 1.70643 6.50015 1.61076 7.42756 1.7972C8.35498 1.98364 9.20709 2.44389 9.87604 3.11998C10.545 3.79609 11.0008 4.6577 11.1854 5.59594C11.3701 6.53419 11.2753 7.50669 10.9131 8.3904C10.5509 9.2741 9.93773 10.0291 9.15126 10.5603C8.36485 11.0913 7.44046 11.3747 6.49497 11.3747C5.22739 11.3736 4.01173 10.8641 3.11492 9.95777C2.21806 9.05134 1.71342 7.82196 1.71228 6.5394Z" fill="#8496AF" stroke="#8496AF" strokeWidth="0.3"/>
            </svg>
            <input onKeyDown={onKeyDownLocal} ref={inputRef} value={searchValue} onChange={onChangeInput} autoComplete={'off'} id={'noClose'} onFocus={() => setActiveSearch(true)} type="text" placeholder="Поиск" />
            {
                searchResults && searchValue.trim().length > 0 &&
                <div id={'noClose'}
                    className={`${styles.content} ${(activeSearch && searchValue.trim().length > 0) ? styles.active : ''}`}>
                    <div id={'noClose'} className={styles.articles}>
                        <p id={'noClose'} className={styles.contentTitle}>Статьи</p>
                        {
                            searchResults.length > 0 ?
                                searchResults.map((item, index) => (
                                    <Link onClick={() => onClose()} key={item.id} id={'noClose'} to={'/faq/article/' + item.id}
                                        className={styles.searchCard}>
                                        <p id={'noClose'} className={styles.title}>{item.title}</p>
                                        <p id={'noClose'} className={styles.description}>{item.short_description}</p>
                                    </Link>
                                )) : <p>Ничего не найдено</p>
                        }
                    </div>
                </div>}
            <button id={'noClose'} onClick={() => {
                setSearchValue('');
                inputRef.current?.focus();
            }} className={`${styles.deleteSearch} ${searchValue.trim().length > 0 ? styles.active : ''}`}>
                <svg id={'noClose'} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path id={'noClose'} d="M5.91615 5.00007L9.80995 1.10608C10.0633 0.852787 10.0633 0.443255 9.80995 0.189966C9.55667 -0.0633221 9.14714 -0.0633221 8.89386 0.189966L4.99994 4.08396L1.10614 0.189966C0.85274 -0.0633221 0.443335 -0.0633221 0.190051 0.189966C-0.0633505 0.443255 -0.0633505 0.852787 0.190051 1.10608L4.08385 5.00007L0.190051 8.89407C-0.0633505 9.14736 -0.0633505 9.55689 0.190051 9.81018C0.316278 9.93653 0.482246 10 0.648097 10C0.813947 10 0.979797 9.93653 1.10614 9.81018L4.99994 5.91618L8.89386 9.81018C9.0202 9.93653 9.18605 10 9.3519 10C9.51775 10 9.6836 9.93653 9.80995 9.81018C10.0633 9.55689 10.0633 9.14736 9.80995 8.89407L5.91615 5.00007Z" fill="#8496AF"/>
                </svg>
            </button>
        </div>
    );
};
export default SearchBar;
