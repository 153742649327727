"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortData = void 0;
const MONTH_ORDER = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];
const sortData = (data, column, order) => {
    return [...data].sort((a, b) => {
        if (column === 'month') {
            const indexA = MONTH_ORDER.indexOf(a.month);
            const indexB = MONTH_ORDER.indexOf(b.month);
            const adjustedIndexA = indexA === -1 ? MONTH_ORDER.length : indexA;
            const adjustedIndexB = indexB === -1 ? MONTH_ORDER.length : indexB;
            return order === 'asc'
                ? adjustedIndexA - adjustedIndexB
                : adjustedIndexB - adjustedIndexA;
        }
        const valueA = a[column];
        const valueB = b[column];
        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return order === 'asc' ? valueA - valueB : valueB - valueA;
        }
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return order === 'asc'
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
        }
        return 0;
    });
};
exports.sortData = sortData;
