import React from 'react';
import styles from './ServerError.module.scss';
import {Link} from 'react-router-dom';

const ServerError = () => {
    return (
        <div className={styles.page}>
            <h1>500</h1>
            <h3>Ошибка!</h3>
            <p>К сожалению, сервер временно недоступен</p>
            <Link to={'/'}>Вернуться на главную</Link>
        </div>
    );
};
export default ServerError;
