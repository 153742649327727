import React from 'react'
import CategoriesContent from "./ui/CategoriesContent/CategoriesContent.jsx";

const Categories = () => {
    return (
        <div style={{overflowY: 'auto'}} >
            <CategoriesContent/>
        </div>
    )
}
export default Categories
