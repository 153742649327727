import React from "react";
import style from '../../Purchase.module.scss'

const Confirm = ({action, message, show, toggle}) => {
    return(
        show

        &&

        <div className={style.modalWrap}>
            <div className={style.content}>

                <div className={style.message}>
                    <div className={style.head}>
                        Подтвердите действие
                    </div>

                    <div>{message}</div>
                </div>

                <div className={style.buttons}>
                    <button className={style.create} onClick={() => {toggle(false);action()}}>Сохранить</button>
                    <button className={style.cancel} onClick={() => toggle(false)}>Отменить</button>
                </div>

            </div>
        </div>
    )
}

export default Confirm