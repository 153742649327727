import React, { useEffect } from "react";
import style from '../../../Purchase.module.scss'
import CustomSelectNew from "../../../../../feature/SelectWithLabel/index.jsx";
import { NewCustomInput } from "../../../../../components/custom/NewCustomInput/NewCustomInput.jsx";
import TopRow from "./topRow.jsx";
import { API_URL, getData } from "../../../../../static/js/request";

const Params = ({ itemParams, setItemParams }) => {

    const getOrganizationData = async () => {

        getData(API_URL + '/api/v1/organisation/').then((data) => {
            setItemParams(prev => ({
                ...prev,
                columns: {
                    ...prev.columns,
                    organisation: {
                        ...prev.columns.organisation,
                        options: data
                    }
                }
            }))
        });

    }

    useEffect(() => {

        getOrganizationData()

    }, [])

    return (

        <div className={style.filter}>

            <TopRow itemParams={itemParams} setItemParams={setItemParams} />

            <div className={style.bottomRow}>

                <div className={style.column}>

                    {Object.values(itemParams.columns).map((item, index) =>

                        item.isInput ?

                            <div key={index} className={style.input} style={{ width: itemParams.columnWidth }}>

                                <div className={style.title}>
                                    {item.label}
                                    {item.required && <span className={style.required}>*</span>}
                                </div>

                                <NewCustomInput
                                    value={item.value}
                                    required={item.required}
                                    onChange={(value) => setItemParams(prev => ({
                                        ...prev,
                                        columns: {
                                            ...prev.columns,
                                            [item.alias]: {
                                                ...prev.columns[item.alias],
                                                value: value
                                            }
                                        }
                                    }))}
                                />

                            </div>


                            :

                            <CustomSelectNew
                                key={index}
                                required={item.required}
                                options={item.options}
                                defaultOption={{  name: item.placeholder }}
                                onOptionChange={(value) => setItemParams(prev => ({
                                    ...prev,
                                    columns: {
                                        ...prev.columns,
                                        [item.alias]: {
                                            ...prev.columns[item.alias],
                                            value: value.id
                                        }
                                    }
                                }))}
                                fieldName={item.alias}
                                title={item.label}
                                value={item.value}
                                width={itemParams.columnWidth}
                                externalStyle={style}
                            />
                    )}

                </div>



            </div>

        </div>

    )
}

export default Params