import React, { useState, useEffect, useRef } from 'react';
import style from './style.module.scss';
import cx from 'classnames';
import DownArrow from '../../../shared/ui/icons/DownArrow.jsx';

const CustomSelect = ({ options, defaultOption, title, onOptionChange, width, fieldName, value }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const selectRef = useRef();


    const handleOutsideClick = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (value) {
            const option = options.find(opt => opt.name === value);
            if (option) {
                setSelectedOption(option);
            }
        }
    }, [options, value]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if(onOptionChange){
            onOptionChange(option, fieldName); // вызов функции обратного вызова с выбранным значением

        }
    };

    return (
        <div ref={selectRef} className={style.customSelect} style={{width: width ? width : ''}}>
            <div className={style.title}>{title}</div>

            <div className={cx(style.customSelectContent, isOpen ? style.active: '')}>
                <div className={cx(style.button, isOpen ? style.active: '')} onClick={() => setIsOpen(!isOpen)}>
                    {selectedOption.name || defaultOption.name}
                    <DownArrow />
                    {/*<svg id="noClose" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">*/}
                    {/*    <path id="noClose" d="M1 1L5 5L9 1" stroke="#8496AF" strokeLinecap="round" strokeLinejoin="round" />*/}
                    {/*</svg>*/}
                </div>
                {isOpen && (
                    <div className={cx(style.optionList, isOpen ? style.active: '')}>
                        {options.map((option, index) => (
                            <div

                                className={style.option}
                                key={index}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSelect;