import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {onMainScroll} from '../../../Advertisement/index.jsx';

const Index = () => {

    const [openedCols, setOpenedCols] = useState([]);

    const [openRow, setOpenRow] = useState(false);

    return (
        <div className={styles.table}>
            <div className={styles.tableTop}>
                <div className={styles.name}>
                    Товар
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                        <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                    </svg>
                </div>
                <div className={styles.tableMainContent} id={'scrollableOfMain'} onScroll={onMainScroll}>
                    <div className={styles.col}>Категория
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col}>Наименование
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col} onClick={() => setOpenedCols(state => !state.includes('Размер') ? [...state, 'Размер'] : [...state.filter(item => item !== 'Размер')])}>
                        Размер
                        <div className={styles.iconsWrap}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                            </svg>
                            <svg style={{transition: '.15s', transform: `rotate(${openedCols.includes('Размер') ? 180 : 0}deg)`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M10.4031 8.91615L6.19523 12.8099C5.92153 13.0634 5.47898 13.0634 5.20528 12.8099C4.93157 12.5567 4.93157 12.1471 5.20528 11.8939L9.41315 7.99994L5.20528 4.10614C4.93157 3.85274 4.93157 3.44333 5.20528 3.19005C5.47898 2.93665 5.92153 2.93665 6.19523 3.19005L10.4031 7.08385C10.4031 7.08385 11 7.59252 11 7.99994C11 8.40737 10.4031 8.91615 10.4031 8.91615Z" fill="#35373A"/>
                            </svg>
                        </div>
                    </div>
                    {
                        openedCols.includes('Размер') &&
                        <>
                            <div style={{borderColor: '#429EFF'}} className={styles.col}>Размер 2
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                    <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                                </svg>
                            </div>
                            <div style={{borderColor: '#429EFF'}} className={styles.col}>Размер 3
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                    <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                                </svg>
                            </div>
                            <div style={{borderColor: '#429EFF'}} className={styles.col}>Размер 4
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                    <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                                </svg>
                            </div>
                        </>
                    }
                    <div className={styles.col}>Продажа, руб
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col}>Услуги маркетплейса, руб
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col}>Маржинальная прибыль, руб
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col}>Постоянные расходы, руб
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col}>EBITDA, руб
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col}>Наименование
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                    <div className={styles.col}>Наименование
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                            <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                        </svg>
                    </div>
                </div>
                <div className={styles.sum}>Чистая прибыль, руб
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                        <path d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z" fill="#8496AF"/>
                    </svg>
                </div>
            </div>
            <div className={styles.tableContent}>
                <div className={styles.row}>
                    <div className={styles.name}>
                        <div className={styles.product}>
                            <img src={'https://basket-11.wb.ru/vol1625/part162542/162542769/images/big/1.jpg'} alt=""/>
                            <div className={styles.info}>
                                <p className={styles.article}>dsm22_черный_белый</p>
                                <p className={styles.barcode}><span>ШК:</span> 123123123123</p>
                                {/*<div className={styles.tags}>*/}
                                {/*    */}
                                {/*</div>*/}
                            </div>
                            <button className={styles.openBtn} onClick={() => setOpenRow(!openRow)}>
                                {
                                    openRow ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.91615 8.00007L12.8099 4.10608C13.0633 3.85279 13.0633 3.44325 12.8099 3.18997C12.5567 2.93668 12.1471 2.93668 11.8939 3.18997L7.99994 7.08396L4.10614 3.18997C3.85274 2.93668 3.44333 2.93668 3.19005 3.18997C2.93665 3.44325 2.93665 3.85279 3.19005 4.10608L7.08385 8.00007L3.19005 11.8941C2.93665 12.1474 2.93665 12.5569 3.19005 12.8102C3.31628 12.9365 3.48225 13 3.6481 13C3.81395 13 3.9798 12.9365 4.10614 12.8102L7.99994 8.91618L11.8939 12.8102C12.0202 12.9365 12.1861 13 12.3519 13C12.5178 13 12.6836 12.9365 12.8099 12.8102C13.0633 12.5569 13.0633 12.1474 12.8099 11.8941L8.91615 8.00007Z" fill="#8496AF"/>
                                        </svg> :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M10.4031 7.08385L6.19523 3.19005C5.92153 2.93665 5.47898 2.93665 5.20528 3.19005C4.93157 3.44333 4.93157 3.85286 5.20528 4.10614L9.41315 8.00006L5.20528 11.8939C4.93157 12.1473 4.93157 12.5567 5.20528 12.8099C5.47898 13.0634 5.92153 13.0634 6.19523 12.8099L10.4031 8.91615C10.4031 8.91615 11 8.40748 11 8.00006C11 7.59263 10.4031 7.08385 10.4031 7.08385Z" fill="#8496AF"/>
                                        </svg>
                                }

                            </button>
                        </div>
                    </div>
                    <div className={styles.tableMainContent} id={'scrollableOfMain'} onScroll={onMainScroll}>
                        <div className={styles.col}>Название категории
                        </div>
                        <div className={styles.col}>Название товара

                        </div>
                        <div className={styles.col} onClick={() => setOpenedCols(state => !state.includes('Размер') ? [...state, 'Размер'] : [...state.filter(item => item !== 'Размер')])}>
                            2 шт
                        </div>
                        {
                            openedCols.includes('Размер') &&
                            <>
                                <div className={styles.col}>2 шт
                                </div>
                                <div className={styles.col}>2 шт
                                </div>
                                <div className={styles.col}>2 шт
                                </div>
                            </>
                        }
                        <div className={styles.col}>
                            100 000
                        </div>
                        <div className={styles.col}>100 000
                        </div>
                        <div className={styles.col}>100 000
                        </div>
                        <div className={styles.col}>100 000
                        </div>
                        <div className={styles.col}>100 000
                        </div>
                        <div className={styles.col}>Название товара
                        </div>
                        <div className={styles.col}>Название товара
                        </div>
                    </div>
                    <div className={styles.sum}> 100 000
                    </div>
                </div>
                {
                    openRow &&
                    <>
                        <div className={styles.subRows}>
                            <div className={styles.row}>
                                <div className={styles.name}>
                                    <p className={styles.barcode}><span>ШК:</span> 123123123123</p>
                                </div>
                                <div className={styles.tableMainContent} id={'scrollableOfMain'} onScroll={onMainScroll}>
                                    <div className={styles.col}>Название категории
                                    </div>
                                    <div className={styles.col}>Название товара

                                    </div>
                                    <div className={styles.col} onClick={() => setOpenedCols(state => !state.includes('Размер') ? [...state, 'Размер'] : [...state.filter(item => item !== 'Размер')])}>
                                        XS
                                    </div>
                                    {
                                        openedCols.includes('Размер') &&
                                        <>
                                            <div className={styles.col}>XS
                                            </div>
                                            <div className={styles.col}>XS
                                            </div>
                                            <div className={styles.col}>XS
                                            </div>
                                        </>
                                    }
                                    <div className={styles.col}>
                                        100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>Название товара
                                    </div>
                                    <div className={styles.col}>Название товара
                                    </div>
                                </div>
                                <div className={styles.sum}> 100 000
                                </div>
                            </div>
                        </div>
                        <div className={styles.subRows}>
                            <div className={styles.row}>
                                <div className={styles.name}>
                                    <p className={styles.barcode}><span>ШК:</span> 123123123123</p>
                                </div>
                                <div className={styles.tableMainContent} id={'scrollableOfMain'} onScroll={onMainScroll}>
                                    <div className={styles.col}>Название категории
                                    </div>
                                    <div className={styles.col}>Название товара

                                    </div>
                                    <div className={styles.col} onClick={() => setOpenedCols(state => !state.includes('Размер') ? [...state, 'Размер'] : [...state.filter(item => item !== 'Размер')])}>
                                        S
                                    </div>
                                    {
                                        openedCols.includes('Размер') &&
                                        <>
                                            <div className={styles.col}>S
                                            </div>
                                            <div className={styles.col}>S
                                            </div>
                                            <div className={styles.col}>S
                                            </div>
                                        </>
                                    }
                                    <div className={styles.col}>
                                        100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>100 000
                                    </div>
                                    <div className={styles.col}>Название товара
                                    </div>
                                    <div className={styles.col}>Название товара
                                    </div>
                                </div>
                                <div className={styles.sum}> 100 000
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};
export default Index;
