import { API_URL, getData } from '../../../static/js/request.js';
import LeftSide from './LeftSide/index.jsx';
import RightSide from './RightSide/index.jsx';
import styles from './styles.module.scss';
import React, { useEffect } from 'react';
import { onMainScroll } from '../../Advertisement/index.jsx';

export const onVerticalScroll = (event, scrollId) => {
    const scrollPosition = event.currentTarget.scrollTop;
    document
        .querySelectorAll(scrollId || '#scrollableOfMain')
        .forEach((scroll) => {
            scroll.scrollTo(0, scrollPosition);
        });
};

function Table({ year, setYears }) {
    const [tabs, setTabs] = React.useState();
    const [dates, setDates] = React.useState([]);

    React.useEffect(() => {
        getData(
            API_URL + `/api/v1/report/balance/${year ? `?year=${year}` : ''}`
        ).then((response) => {
            setTabs(response.data);
            setYears(response.years);
            setDates(response.date);
        });
    }, [year]);

    const [openTabs, setOpen] = React.useState([]);

    const [hoveredRow, setHoveredRow] = React.useState('');

    return (
        <>
            <div className={styles.topTable}>
                {
                    tabs &&
                    <div className={`${styles.row} ${styles.left}`}>
                        Управленческий баланс
                    </div>
                }
                <div className={`${styles.row} ${styles.right}`} id="scrollableOfMain">
                    {dates?.map((date) => (
                        <div className={`${styles.col} ${styles.noBorder}`}>
                            {[...date].reverse().join(' ')}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.table}>
                <LeftSide
                    tabs={tabs}
                    setOpen={setOpen}
                    openTabs={openTabs}
                    hovered={hoveredRow}
                    setHoveredRow={setHoveredRow}
                />
                <RightSide
                    tabs={tabs}
                    setOpen={setOpen}
                    openTabs={openTabs}
                    hovered={hoveredRow}
                    setHoveredRow={setHoveredRow}
                />
            </div>
            <div
                className={styles.mainScroll}
                style={{ maxWidth: `${190 * dates?.length}px` }}
                onScroll={onMainScroll}
                id="scrollableOfMain"
            >
                <span style={{ width: `${190 * dates?.length}px` }} />
            </div>
        </>
    );
}

export default Table;
