import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import BodyTableLine from '../../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../../components/custom/BodyTableLineItem.jsx';
import ArticleStore from '../../../store/Financing/ArticleStore';
import {
    applyPriceNoSymbolCurrency,
    createNotification,
} from '../../../static/js/commonFunc';
import style from '../Financing.module.scss';
import commonStyle from '../../../static/css/commonStyle.module.scss';
import { API_URL, postData } from '../../../static/js/request';
import { action } from 'mobx';

import { SelectExpense } from '@fsd/entities/select-expense';
import { TableRowArticleField } from '@/fsd/entities/table-row-article-field';
import { NetProfitWithdrawalModal } from '@/fsd/feature/modals/net-profit-withdrawal';

import { TaxDetailModal } from '@fsd/feature/modals/tax-detail'

const BanksBody = observer(({ state }) => {
    const [reload, setReload] = useState('');
    const [searchResults, updateSearchResults] = useState([]);
    const [currentField, setCurrentField] = useState('');
    const [filteredResults, updateFilteredResults] = useState(
        Object.entries(ArticleStore.fields),
    );
    const searchRef = useRef(null);
    const selectRef = useRef(null);

    const handleClick = action((id) => {
        state.forEach((x) => {
            x.showBlock = x.id === id;
        });
        setReload(id);
        setCurrentField(id);
    });

    const updateSearch = (value) => {
        updateFilteredResults(
            searchResults.filter((result) => {
                if (!result[1]) {
                    return;
                }
                return result[1].match(new RegExp(value, 'gi'));
            }),
        );
    };

    useEffect(() => {
        const getSearchResults = () => {
            const searchResultsResponse = Object.entries(ArticleStore.fields);
            updateSearchResults(searchResultsResponse);
        };

        getSearchResults();

        const handleClickOutside = (evt) => {
            if (evt.target.getAttribute('id') !== 'noClose') {
                const field = state.filter(item => item.showBlock);
                field.some((x) => {
                    x.showBlock = false;
                });
                setReload(evt);
            }
        };

        document.addEventListener('click', (evt) => {
            handleClickOutside(evt);
        });

        return () => {
            document.removeEventListener('click', (evt) => {
                handleClickOutside(evt);
            });
        };
    }, [reload]);

    const handleSelectArticle = (event) => {
        const target = event.target;
        searchRef.current.querySelectorAll('div').forEach((el) => {
            el.classList.remove('active');
        });
        target.classList.add('active');
        selectRef.current.value = target.dataset.artText;
        selectRef.current.dataset.id = target.dataset.art;

        let data;
        state.some((x) => {
            x.showBlock = false;
            if (x.id === currentField) {
                x.article = target.dataset.art;
                x.article_name = target.dataset.artText;
                data = x;
            }
        });

        postData(API_URL + '/api/v1/bank/operations/add-article/', data).then((response) => {
            createNotification(response);
            setReload(prev => prev + 1);
        });
    };

    const SearchResults = () => {
        const Message = ({ text }) => (
            <div id={'noClose'} className="message">
                <div id={'noClose'}>{text}</div>
            </div>
        );

        const render = () => {
            return filteredResults.map((article, index) => (
                article[1] && (
                    <div
                        key={index}
                        data-art={article[0]}
                        data-art-text={article[1]}
                        onClick={() => {
                            handleSelectArticle(event);
                        }}
                    >
                        {article[1]}
                    </div>
                )
            ));
        };

        if (!searchResults.length) {
            return <Message text="Загрузка результатов поиска" />;
        }

        return render();
    };

    return (
        <>
            {
                state.map((field, index) => {
                    return <BodyTableLine key={index} items="banks">

                        <BodyTableLineItem value={field.executed} />

                        <BodyTableLineItem
                            style={{ whiteSpace: 'nowrap' }}
                            value={field.category === 'Credit' && applyPriceNoSymbolCurrency(field.amount)}
                            additionalClass="alightAmount"
                        />

                        <BodyTableLineItem
                            style={{ whiteSpace: 'nowrap' }}
                            value={field.category === 'Debit' && applyPriceNoSymbolCurrency(field.amount)}
                            additionalClass="alightAmount"
                        />

                        <BodyTableLineItem value={field.account} />

                        <BodyTableLineItem
                            value={
                                <div style={{
                                    maxHeight: "4.5em",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 2,
                                    overflow: "hidden",
                                    textOverflow: 'ellipsis',
                                }} className={commonStyle.hoveredText}>
                                    {field.contragentName}
                                </div>
                            }
                            data-label={field.contragentName}
                            additionalClass="lineClamp"
                        />


                        <BodyTableLineItem
                            id={'noClose'}
                            value={
                                <div style={{ position: 'relative' }}>
                                    {field.article_name ? (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <TableRowArticleField onClick={(event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                updateSearch('');
                                                handleClick(field.id)
                                            }}>{field.article_name}</TableRowArticleField>
                                            {field.article_name === 'Вывод чистой прибыли' && <NetProfitWithdrawalModal operationId={field.id} amount={field.amount} />}
                                        </div>
                                    ) : <SelectExpense textContent='Выберите статью!'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            updateSearch('');
                                            handleSelectArticle(event)
                                            handleClick(field.id);
                                        }} />}
                                    {field.showBlock && (
                                        <div
                                            id={'noClose'}
                                            ref={searchRef}
                                            style={{
                                                position: 'absolute',
                                                zIndex: 999,
                                                top: "-50%",
                                            }}
                                        >
                                            <input
                                                id={'noClose'}
                                                className={style.customAutocompleteInput}
                                                ref={selectRef}
                                                type="text"
                                                placeholder="Введите текст..."
                                                onFocus={() => updateSearch('')}
                                                autoFocus={true}
                                                onKeyUp={(evt) => updateSearch(evt.target.value)}
                                            />
                                            <div
                                                id={'noClose'}
                                                className={style.customAutocompleteResult}
                                            >
                                                <SearchResults />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        />

                        <BodyTableLineItem
                            value={
                                <div style={{
                                    fontSize: "12px",
                                    maxHeight: "17px",
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: '2',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxHeight: '4.5em',
                                    lineHeight: '1.5em',
                                }}
                                    className={commonStyle.hoveredText}
                                >
                                    {field.paymentPurpose}
                                </div>
                            }
                            data-label={field.paymentPurpose}
                        />
                    </BodyTableLine >
                }
                )}
        </>
    );
});

export default BanksBody;
