import React from "react";
import style from "../../static/css/commonStyle.module.scss";
import cx from "classnames";

export const BodyTable = (props) => {
	return (
		<div className={style.BodyTable}>
			{props.children}
		</div>
	);
};

export default BodyTable;