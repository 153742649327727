import cx from 'classnames';
import React from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './components/layout/footer/footer.jsx';
import Account from './views/Account/Lc/Account/Account.jsx';
import AccountStaff from './views/Account/Lc/AccountStaff/AccountStaff.jsx';
import Api from './views/Account/Lc/Api/Api.jsx';
import Articles from './views/Account/Lc/Articles/Articles.jsx';
import Notices from './views/Account/Lc/Notices/Notices.jsx';
import Login from './views/Account/Login/Login.jsx';
import Signup from './views/Account/Signup/Signup.jsx';
import Advertisement from './views/Advertisement/index.jsx';
import { Projects } from './views/Analytics/Projects/index.jsx';
import { ProjectItem } from './views/Analytics/Projects/ProjectItem/index.jsx';
import AnsweringEdit from './views/Answering/AnsweringEdit/index.jsx';
import Answering from './views/Answering/index.jsx';
import Balance from './views/Balance/index.jsx';
import CostPrice from './views/CostPrice/index.jsx';
import Economy from './views/Economy/Economy.jsx';
import Article from './views/FAQ/Article.jsx';
import Categories from './views/FAQ/Categories.jsx';
import Faq from './views/FAQ/Faq.jsx';
import Banks from './views/Financing/Banks/Banks.jsx';
import Cashboxes from './views/Financing/Cashboxes/Cashboxes.jsx';
import CashboxItem from './views/Financing/Cashboxes/CashboxItem.jsx';
import DDS from './views/Financing/DDS/DDS.jsx';
import Financing from './views/Financing/Financing.jsx';
import Loans from './views/Financing/Loans/Loans.jsx';
import Rules from './views/Financing/Rules/Rules.jsx';
import FinancingReport from './views/FinancingReport/FinancingReport.jsx';
import Home from './views/Home/Home.jsx';
import Landing from './views/Landing/index.jsx';
import Policy from './views/Landing/Policy.jsx';
import Logistics from './views/Logistics/index.jsx';
import EditPage from './views/Logistics/InnerPages/EditPage/index.jsx';
import SettingsPage from './views/Logistics/InnerPages/SettingsPage/index.jsx';
import NotFound from './views/NotFound/NotFound.jsx';
import Sails from './views/Sails/index.jsx';
import ServerError from './views/ServerError/ServerError.jsx';
import EmployeeCard from './views/Staff/EmployeeCard.jsx';
import Staff from './views/Staff/Staff.jsx';
//import PurchaseOrder from './views/Purchase/PurchaseOrder/index.jsx';
//import PurchaseOrderDetail from './views/Purchase/PurchaseOrderDetail/index.jsx';
import PlanFact from './views/Financing/PlanFact/index.jsx';
import AcceptanceItem from './views/Purchase/Acceptance/Item/index.jsx';
import AcceptanceList from './views/Purchase/Acceptance/List/index.jsx';
import OrderItem from './views/Purchase/Order/Item/index.jsx';
import OrderList from './views/Purchase/Order/List/index.jsx';
import ShipmentItem from './views/Purchase/Shipment/Item/index.jsx';
import ShipmentList from './views/Purchase/Shipment/List/index.jsx';

import { Header } from '@/fsd/widgets/header';

import mainStyle from './static/css/mainStyle.module.scss';

const LoggedInRoute = () => {
  const token = localStorage.getItem('token');
  return token ? <Outlet /> : <Navigate to="/login" replace />;
};

const LoggedOutRoute = () => {
  const token = localStorage.getItem('token');
  return token ? <Navigate to="/reports/" replace /> : <Outlet />;
};

function App() {
  const location = useLocation();

  const [fullContainer, setContainer] = React.useState(false);

  const fullContainerPages = [
    '/reports/balance',
    '/reports/',
    '/financing/dds/',
    '/financing/banks/',
    '/financing/rules/',
    '/financing/cashbox',
    '/financing/loans/',
    '/financing/cashbox/item/',
    '/analytics/projects/',
    '/scheduler/logistics',
    '/cost-price',
    '/analytics/projects/item/',
    '/',
    '/staff',
    '/staff/card/',
  ];

  React.useEffect(() => {
    if (
      fullContainerPages.includes(location.pathname) ||
      location.pathname.includes('/analytics/projects/item/')
    ) {
      setContainer(true);
    } else {
      setContainer(false);
    }
  }, [location]);

  return (
    <>
      {!['/', '/policy'].includes(location.pathname) && (
        <Header full={fullContainer}></Header>
      )}
      <div
        className={cx(mainStyle['container-xxl'], mainStyle['page-content'])}
        style={{
          maxWidth: fullContainer ? '100vw' : '100vw',
          padding: location.pathname === '/' ? '0' : '',
        }}
      >
        <Routes>
          <Route path="/" element={<LoggedOutRoute />}>
            <Route index element={<Landing />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
          </Route>

          <Route path="/" element={<LoggedInRoute />}>
            <Route index element={<Home />} />
            <Route
              path="/reports"
              element={<FinancingReport setFull={setContainer} />}
            />
            <Route
              path="/reports/balance"
              element={<Balance setFull={setContainer} />}
            />
            <Route path="/financing/" element={<Financing />} />
            <Route path="/financing/banks/" element={<Banks />} />
            <Route path="/financing/cashbox/" element={<Cashboxes />} />
            <Route path="/financing/cashbox/item/" element={<CashboxItem />} />
            <Route path="/financing/rules/" element={<Rules />} />
            <Route path="/financing/loans/" element={<Loans />} />
            <Route path="/financing/planfact/" element={<PlanFact />} />

            <Route path="/purchase/orders/" element={<OrderList />} />
            <Route path="/purchase/orders/item/:id?" element={<OrderItem />} />
            <Route path="/purchase/acceptance/" element={<AcceptanceList />} />
            <Route
              path="/purchase/acceptance/item/:id?"
              element={<AcceptanceItem />}
            />
            <Route path="/purchase/shipment/" element={<ShipmentList />} />
            <Route
              path="/purchase/shipment/item/:id?"
              element={<ShipmentItem />}
            />

            {/*<Route path="/purchase/orders/" element={<PurchaseOrder />} />
            <Route path="/purchase/orders/detail/" element={<PurchaseOrderDetail />} />*/}

            <Route path="/scheduler/logistics" element={<Logistics />} />
            <Route
              path="/scheduler/logistics/settings"
              element={<SettingsPage />}
            />
            <Route
              path="/scheduler/logistics/edit/:id"
              element={<EditPage />}
            />
            <Route path="/scheduler/answering/" element={<Answering />} />
            <Route
              path="/scheduler/answering/item/:id/:type"
              element={<AnsweringEdit />}
            />
            <Route path="/settings/account/" element={<Account />} />
            <Route path="/settings/staff/" element={<AccountStaff />} />
            <Route path="/settings/notices/" element={<Notices />} />
            <Route path="/settings/articles/" element={<Articles />} />
            <Route path="/settings/account/api/" element={<Api />} />
            {/*Старый вариант Себестоимости*/}
            {/*<Route path="/cost-price/" element={<CostPrice />} />*/}
            {/*<Route path="/cost-price/" element={<Purchase />} />*/}
            <Route path="/financing/DDS/" element={<DDS />} />
            <Route path="/analytics/projects/" element={<Projects />} />
            <Route
              path="/analytics/projects/item/:id"
              element={<ProjectItem />}
            />
            <Route path="/staff/" element={<Staff />} />
            <Route path="/staff/card/" element={<EmployeeCard />} />
            <Route path="/reports/advertisement/" element={<Advertisement />} />
            <Route path="/economy/:type" element={<Economy />} />
            <Route path="/sails" element={<Sails />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/faq/article/:id" element={<Article />} />
            <Route path="/faq/categories/:id" element={<Categories />} />
            <Route path="/500" element={<ServerError />} />
            <Route path="/*" element={<NotFound />} />
          </Route>
        </Routes>
        {location.pathname !== '/' &&
        location.pathname !== '/policy' &&
        !location.pathname.includes('faq') ? (
          <Footer />
        ) : null}
      </div>
    </>
  );
}

export default App;
