import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { popupNotification } from "../../../../static/js/popupNotification.jsx";
import { API_URL, getData, postData, putDataNew } from "../../../../static/js/request.js";
import Item from "../../view/item/index.jsx";
import { popupNotificationV2 } from "@/static/js/popupNotification_v2.jsx";

const OrderItem = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [orderParams, setOrderParams] = useState({
        id: '',
        user: '',
        itemType: "purchase",
        formTitle: 'Заказ поставщику',
        dialogMessage: 'Сохранить заказ и перейти к созданию приёмки на основе текущего заказа',
        actionDate: '',
        status: '',
        columnWidth: '356px',
        columns: {
            /*warehouse: {
                label: 'Склад',
                alias: 'warehouse',
                placeholder: 'Выберите склад',
                value: '',
                options: [
                    { name: 'Москва', id: 1 },
                    { name: 'Тверь', id: 11 }
                ]
            },*/
            organisation: {
                label: 'Организация',
                alias: 'organisation',
                placeholder: 'Выберите организацию',
                value: '',
                required: true,
                options: []
            },
            counterparty: {
                label: 'Контрагент',
                alias: 'counterparty',
                placeholder: 'Выберите контрагента',
                value: '',
                required: true,
                options: [
                ],
                needSearch: true
            }

        },
        comment: '',
        products: [],
        conducted: false

    })

    const itemAction = async () => {

        const requestData = {
            organisation: orderParams.columns.organisation.value,
            status: orderParams.status,
            counterparty: orderParams.columns.counterparty.value,
            purchase_date: orderParams.actionDate || new Date().toISOString(),
            comment: orderParams.comment,
            products: orderParams.products,
            conducted: orderParams.conducted
        }

        if (id !== undefined) {
            await putDataNew(API_URL + '/api/v1/purchase/detail/', requestData)
                .then((response) => {
                    popupNotification(response, 'update')
                })
        } else {

            if (!requestData.products.length){
                delete requestData.status
            }


            await postData(API_URL + '/api/v1/purchase/list/create/', requestData)
                .then((response) => {
                
                    if (response.ok) {
                        popupNotification(response, 'create')
                        navigate(`/purchase/orders`)
                        return response.json();
                    } else {
                        response.json().then(r=>popupNotificationV2(r.message, 'danger', 'Ошибка'))
                    }
                })
                .then((response) => {
                    if (response?.id) {
                        setOrderParams(prev => ({
                            ...prev,
                            id: response.id
                        }))
                    }
                })
        }
    }

    useEffect(() => {

        if (id) {
            getData(API_URL + '/api/v1/purchase/detail/?id=' + id)
                .then((data) => {
                    setOrderParams(prev => ({
                        ...prev,
                        comment: data[0]['comment'],
                        id: data[0]['id'],
                        user: data[0]['user'],
                        status: data[0]['status'],
                        actionDate: data[0]['purchase_date'],
                        columns: {
                            ...prev.columns,
                            organisation: {
                                ...prev.columns.organisation,
                                value: data[0]['organisation']['id']
                            },
                            counterparty: {
                                ...prev.columns.counterparty,
                                value: data[0]['counterparty']['id']
                            }
                        },
                        products: data[0]['products'],
                        conducted: data[0]['conducted']
                    }))
                });
        }

        else {
            getData(API_URL + '/api/v1/account/').then((data) => {
                setOrderParams(prev => ({
                    ...prev,
                    user: data['id']
                }))
            });
        }
    }, [])

    return (
        <Item
            title={id ? "Изменение заказа" : "Создание заказа"}
            itemParams={orderParams}
            setItemParams={(item) => setOrderParams(item)}
            itemAction={() => itemAction()}
        />
    )
}

export default OrderItem