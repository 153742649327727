import React from "react";

const LineSVG = () => {

    return (
        <svg width="98" height="1" viewBox="0 0 98 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="0.5" x2="98" y2="0.5" stroke="#DCE7F1" />
        </svg>
    )

}

export default LineSVG;