import React, { useRef } from "react";
import style from '../PlanFact.module.scss'
import TableHead from "./tableHead.jsx";
import TableRow from "./tableRow.jsx";


const TableData = ({ data, columnsVisibility, filterHeight}) => {

    const scrollContainerRef = useRef(null);   
    
    const yScrollStyle = {
        maxHeight: (645 - filterHeight) + 'px'
      };

    const getHeaders = (data) => {
        if (data.length > 0 && data[0].product && data[0].product.days) {
            const days = data[0].product.days;
            return Object.keys(days).map((year) =>
                Object.keys(days[year]).map((month) =>
                    Object.keys(days[year][month]).map((day) => ({
                        day,
                        month,
                        year
                    }))
                )
            ).flat(2);
        }
        return [];
    };

    return (
        <div className={style.table}>

            <TableHead
                scrollContainerRef={scrollContainerRef}
                columnsVisibility={columnsVisibility}
                dateHeaders={getHeaders(data)}
            />

            <div style={yScrollStyle} className={style.yScroll}>
                {data.map((item, productIndex) =>
                    item.product

                    &&

                    <TableRow
                        key={item.product.nm + productIndex}
                        data={item.product}                        
                        index={productIndex}
                        columnsVisibility={columnsVisibility}
                        scrollContainerRef={scrollContainerRef}
                    />
                )}
            </div>

        </div>
    );
};

export default TableData;