import React from 'react'
import styles from './styles.module.scss'
import ProductItem from './ProductItem/index.jsx';
import { API_URL, getData } from '../../../../static/js/request';

function DropDownOrg({ item, analysis, purchase, mp }) {

    const [open, setOpen] = React.useState(false);

    const [groups, setGroups] = React.useState([])

    const getItemInfo = async () => {
        try {
            const response = await getData(`${API_URL}/api/v1/deliverypalner/?id=${item.id}&mp=${mp}&purchase=${purchase}&analysis=${analysis}`)
            setGroups([...response.organisations.find(org => org.id === item.id).value])
        } catch (error) {

        }
    }

    React.useEffect(() => {
        if (open) {
            getItemInfo()
        }
    }, [open, analysis, mp, purchase])

    return (
        <div className={`${styles.item} ${open ? styles.open : ''}`} style={{ height: open ? 'auto' : '42px' }}>
            <div className={styles.rowTop} onClick={() => setOpen(!open)}>
                <p className={styles.orgName}>{item.name}</p>
                <button className={`${styles.close} ${open ? styles.active : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M6.71739 5.29586L1.26607 10.9064C0.91131 11.2713 0.91131 11.8614 1.26607 12.2263C1.62067 12.5912 2.194 12.5912 2.5486 12.2263L8.00008 6.61579L13.4514 12.2263C13.8062 12.5912 14.3793 12.5912 14.7339 12.2263C15.0887 11.8614 15.0887 11.2713 14.7339 10.9064L9.28261 5.29586C9.28261 5.29586 8.57048 4.5 8.00008 4.5C7.42969 4.5 6.71739 5.29586 6.71739 5.29586Z" fill="#1E1E1E" />
                    </svg>
                </button>
            </div>
            {
                groups.length > 0 ?
                    <div className={styles.items}>
                        {
                            groups.map((group, i) => <ProductItem key={group.product_name + i} item={group} />)
                        }
                    </div> :
                    <p className={styles.empty}>Товары не найдены</p>
            }

        </div>
    );
}

export default DropDownOrg;