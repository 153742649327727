import { makeAutoObservable } from 'mobx';
import { getData, postData, API_URL } from '../../static/js/request.js';

class CostPriceStore {
    isSidebarOpen = false;
    editingProductId = null;
    editValue = '';
    productsWithoutCost = [];
    productsWithCost = [];
    selectedProduct = null;
    currentFilter = 'Все';
    priceHistory = [];
    isLineGraph = true;

    constructor() {
        makeAutoObservable(this);
    }

    fetchData = async (mode) => {
        const response = await getData(
            `${API_URL}/api/v1/products/costs/${mode !== 'None' ? `?mp=${mode}` : ''}`
        );
        this.productsWithoutCost = response.products_without_cost;
        this.productsWithCost = response.products_with_cost;
    };

    handleEdit = (id, price) => {
        this.editingProductId = id;
        this.editValue = price || '';
    };

    handleChange = (event) => {
        this.editValue = event.target.value;
    };

    updateCost = async (barcode, value) => {
        const data = {
            product: barcode,
            purchase_price: value,
        };

        const updatedProduct = await postData(
            `${API_URL}/api/v1/products/costs/`,
            data
        );

        const updateProduct = (products) => {
            return products.map((product) =>
                product.barcode === barcode ? updatedProduct : product
            );
        };

        if (this.productsWithCost.find((product) => product.barcode === barcode)) {
            this.productsWithCost = updateProduct(this.productsWithCost);
        } else if (
            this.productsWithoutCost.find((product) => product.barcode === barcode)
        ) {
            this.productsWithoutCost = updateProduct(this.productsWithoutCost);
        }

        this.editingProductId = null;
    };

    handleRowClick = (product) => {
        this.isSidebarOpen = true;
        this.selectedProduct = product;
    };

    closeSidebar = () => {
        this.isSidebarOpen = false;
    };

    handleKeyDown = (event, product) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.updateCost(product.barcode, this.editValue);
        }
    };

    handleSubmit = (event, product) => {
        event.preventDefault();
        this.updateCost(product.barcode, this.editValue);
    };

    setCurrentFilter = (filter) => {
        this.currentFilter = filter;
    };

    get productsToShow() {
        switch (this.currentFilter) {
        case 'Все':
            return [...this.productsWithCost, ...this.productsWithoutCost];
        case 'Заполненная СС':
            return this.productsWithCost;
        case 'Незаполненная СС':
            return this.productsWithoutCost;
        default:
            return [];
        }
    }
}
export default new CostPriceStore();
