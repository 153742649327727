"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDialog = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const CustomDialog = ({ open, handleCloseModal, title, contentText, actions, }) => {
    return ((0, jsx_runtime_1.jsxs)(material_1.Dialog, { open: open, onClose: handleCloseModal, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", PaperProps: {
            sx: {
                minWidth: '310px',
            },
        }, children: [(0, jsx_runtime_1.jsx)(material_1.DialogTitle, { id: "alert-dialog-title", style: { textAlign: 'center' }, children: title }), !!contentText.toString().length && (0, jsx_runtime_1.jsx)(material_1.DialogContent, { children: (0, jsx_runtime_1.jsx)(material_1.DialogContentText, { id: "alert-dialog-description", children: contentText }) }), (0, jsx_runtime_1.jsx)(material_1.DialogActions, { style: { padding: '10px' }, children: actions })] }));
};
exports.CustomDialog = CustomDialog;
