import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { NewCustomInput } from '../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import YearSelect from '../../Balance/YearSelect/index.jsx';
import sortBy from 'lodash.sortby';
import { API_URL, postData } from '../../../static/js/request';

const InputWrap = (props) => {
  const { title, select, getInfo } = props;

  return (
    <div className={styles.inputWrap} style={{ width: props.width }}>
      <p className={styles.inputTitle}>{title}</p>
      {select ? <YearSelect {...props} /> : <NewCustomInput {...props} />}
    </div>
  );
};

function AddModal({ onClose }) {
  const [mpList, setMpList] = useState([]);
  const [name, changeName] = useState('');
  const [length, changeLength] = useState('');
  const [width, changeWidth] = useState('');
  const [height, changeheight] = useState('');
  const [weight, changeWeight] = useState('');
  const [categoryOzon, setCategoryOzon] = useState(null);
  const [ozonBrandName, changeOzonBrandName] = useState('');
  const [categoryWb, setCategoryWb] = useState(null);
  const [wbBrandName, changeWbBrandName] = useState('');
  const [barcodeSettings, changeBarcodeSettings] = useState([
    {
      id: 0,
      color: '',
      size: '',
      barcode_set: [],
      articleWB: '',
      articleOzon: '',
    },
  ]);
  const [barcodeInput, changeBarcodeInput] = useState(['']);
  const [price, changePrice] = useState('');
  const [priceBefore, changePriceBefore] = useState('');
  const [discount, changeDiscount] = useState('');
  const [result, changeResult] = useState('');

  const checkClick = (mp) => {
    setMpList(
      mpList.includes(mp)
        ? [...mpList.filter((item) => item !== mp)]
        : [...mpList, mp]
    );
  };

  const inputBarcodeSettings = (id, newValue, field) => {
    changeBarcodeSettings((state) => {
      let newSetting = state.find((temp) => temp.id === id);
      newSetting[field] = newValue;

      return [...state.filter((temp) => temp.id !== id), newSetting];
    });
  };

  const addBarcode = (id, index) => {
    changeBarcodeSettings((state) => {
      let newSetting = state.find((temp) => temp.id === id);
      newSetting.barcode_set = [...newSetting.barcode_set, barcodeInput[index]];
      changeBarcodeInput((state) =>
        sortBy(
          state.map((temp, tempIndex) => {
            return tempIndex === index ? '' : temp;
          }),
          'id'
        )
      );

      return sortBy(
        [...state.filter((temp) => temp.id !== id), newSetting],
        'id'
      );
    });
  };

  const addClick = async () => {
    try {
      const response = await postData(API_URL + '/api/v1/unit/', {
        name,
        length: +length,
        width: +width,
        height: +height,
        weight: +weight,
        ozon: mpList.includes('Ozon'),
        category_OZON: categoryOzon,
        brand_OZON: ozonBrandName === '' ? null : ozonBrandName,
        wb: mpList.includes('Wildberries'),
        brand_WB: wbBrandName === '' ? null : wbBrandName,
        category_WB: categoryWb,
        sizes_colors: {
          color: barcodeSettings[0].color,
          size: barcodeSettings[0].size,
          code: +barcodeInput[0],
          article_OZON: barcodeSettings[0].articleOzon,
          vendorCode: barcodeSettings[0].articleWB,
        },
        purchase_price: +price,
        price_before_discount: +priceBefore,
        discount: +discount,
        price_after_discount: +result,
      });
      getInfo();
    } catch (error) {
      console.log(error);
    }
  };

  const addSetting = () => {
    changeBarcodeSettings((state) => {
      return [
        ...state,
        {
          id: state[state.length - 1].id + 1,
          color: '',
          size: '',
          barcode_set: [],
          articleWB: '',
          articleOzon: '',
        },
      ];
    });
    changeBarcodeInput((state) => [...state, '']);
  };

  useEffect(() => {
    console.log(barcodeSettings);
  }, [barcodeSettings]);

  const [barcodeBlockViewIndex, setBarcodeViewIndex] = useState(null);

  return (
    <div className={styles.modal}>
      <span className={styles.bgClose} onClick={onClose} />
      <div className={styles.content}>
        <button className={styles.closeBtn} onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M8.14519 7.00009L13.0124 2.13259C13.3292 1.81598 13.3292 1.30407 13.0124 0.987458C12.6958 0.670847 12.1839 0.670847 11.8673 0.987458L6.99993 5.85496L2.13268 0.987458C1.81592 0.670847 1.30417 0.670847 0.987564 0.987458C0.670812 1.30407 0.670812 1.81598 0.987564 2.13259L5.85481 7.00009L0.987564 11.8676C0.670812 12.1842 0.670812 12.6961 0.987564 13.0127C1.14535 13.1707 1.35281 13.25 1.56012 13.25C1.76743 13.25 1.97475 13.1707 2.13268 13.0127L6.99993 8.14523L11.8673 13.0127C12.0253 13.1707 12.2326 13.25 12.4399 13.25C12.6472 13.25 12.8545 13.1707 13.0124 13.0127C13.3292 12.6961 13.3292 12.1842 13.0124 11.8676L8.14519 7.00009Z"
              fill="#35373A"
            />
          </svg>
        </button>
        <h2 className={styles.title}>Добавление товара</h2>
        <p className={styles.subTitle}>Основная информация</p>
        <div className={styles.checkboxes}>
          <div className={styles.boxWrap}>
            <div
              onClick={() => checkClick('Wildberries')}
              className={`${styles.checkbox} ${
                mpList.includes('Wildberries') ? styles.active : ''
              }`}
            >
              {mpList.includes('Wildberries') && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                >
                  <path
                    d="M4.33464 9.58301L0.167969 5.44968L1.49297 4.16634L4.33464 6.95801L10.5096 0.833008L11.8346 2.14967L4.33464 9.58301Z"
                    fill="white"
                  ></path>
                </svg>
              )}
            </div>
            <p>Wildberries</p>
          </div>
          <div className={styles.boxWrap}>
            <div
              onClick={() => checkClick('Ozon')}
              className={`${styles.checkbox} ${
                mpList.includes('Ozon') ? styles.active : ''
              }`}
            >
              {mpList.includes('Ozon') && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                >
                  <path
                    d="M4.33464 9.58301L0.167969 5.44968L1.49297 4.16634L4.33464 6.95801L10.5096 0.833008L11.8346 2.14967L4.33464 9.58301Z"
                    fill="white"
                  ></path>
                </svg>
              )}
            </div>
            <p>Ozon</p>
          </div>
        </div>
        <InputWrap
          title={'Наименование'}
          value={name}
          onChange={changeName}
          placeholder={'Введите название'}
          width="100%"
        />
        <div className={styles.sizes}>
          <InputWrap
            title={'Длинна'}
            value={length}
            onChange={changeLength}
            placeholder={'Введите длинну'}
            width="100%"
            numeric
          />
          <InputWrap
            title={'Ширина'}
            value={width}
            onChange={changeWidth}
            placeholder={'Введите ширину'}
            width="100%"
            numeric
          />
          <InputWrap
            title={'Высота'}
            value={height}
            onChange={changeheight}
            placeholder={'Введите высоту'}
            width="100%"
            numeric
          />
          <InputWrap
            title={'Вес'}
            value={weight}
            onChange={changeWeight}
            placeholder={'Введите вес'}
            width="100%"
            numeric
          />
        </div>
        <div className={styles.categoryInfo}>
          {mpList.includes('Ozon') && (
            <div className={styles.wrap}>
              <p className={styles.infoTitle}>Ozon</p>
              <div className={styles.ozon}>
                <InputWrap
                  title={
                    <p>
                      Категория
                      <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                    </p>
                  }
                  placeholder={'Выберите категорию'}
                  textAlign="left"
                  value={categoryOzon}
                  onChange={setCategoryOzon}
                  allValues={['Категория1', 'Категория2', 'Категория3']}
                  width={'100%'}
                  select
                />
                <InputWrap
                  title={'Бренд'}
                  value={ozonBrandName}
                  onChange={changeOzonBrandName}
                  placeholder={'Введите название'}
                  width="100%"
                />
              </div>
            </div>
          )}
          {mpList.includes('Wildberries') && (
            <div className={styles.wrap}>
              <p className={styles.infoTitle}>Wildberries</p>
              <div className={styles.wildberries}>
                <InputWrap
                  title={
                    <p>
                      Категория
                      <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                    </p>
                  }
                  placeholder={'Выберите категорию'}
                  textAlign="left"
                  value={categoryWb}
                  onChange={setCategoryWb}
                  allValues={['Категория1', 'Категория2', 'Категория3']}
                  width={'100%'}
                  select
                />
                <InputWrap
                  title={'Бренд'}
                  value={wbBrandName}
                  onChange={changeWbBrandName}
                  placeholder={'Введите название'}
                  width="100%"
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.barcode}>
          <p className={styles.subTitle}>Баркод и размер</p>
          {barcodeSettings.map((item, index) => (
            <div className={styles.row} key={item.id}>
              <p className={styles.number}>{index + 1}</p>
              <InputWrap
                title={
                  index === 0 && (
                    <p>
                      Цвет
                      <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                    </p>
                  )
                }
                placeholder={'Введите цвет'}
                value={item.color}
                onChange={(newValue) =>
                  inputBarcodeSettings(item.id, newValue, 'color')
                }
                width={'100%'}
              />
              <InputWrap
                title={
                  index === 0 && (
                    <p>
                      Размер
                      <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                    </p>
                  )
                }
                placeholder={'Введите размер'}
                value={item.size}
                onChange={(newValue) =>
                  inputBarcodeSettings(item.id, newValue, 'size')
                }
                width={'100%'}
              />
              <div
                className={`${styles.inputWrap} ${styles.barcodeInput}`}
                style={{ width: '100%' }}
              >
                <p className={styles.inputTitle}>{index === 0 && 'Баркод'}</p>
                {barcodeInput[index].trim().length > 0 &&
                  !item.barcode_set.includes(
                    barcodeInput[index].trim().toLowerCase()
                  ) && (
                    <button
                      className={styles.addBarcode}
                      onClick={() => addBarcode(item.id, index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M2.23693 8.73693H7.76307V14.2631C7.76307 14.4585 7.84071 14.646 7.97891 14.7842C8.11712 14.9224 8.30456 15 8.5 15C8.69545 15 8.88289 14.9224 9.02109 14.7842C9.15929 14.646 9.23693 14.4585 9.23693 14.2631V8.73693H14.7631C14.9585 8.73693 15.146 8.65929 15.2842 8.52109C15.4224 8.38289 15.5 8.19545 15.5 8C15.5 7.80455 15.4224 7.61711 15.2842 7.47891C15.146 7.34071 14.9585 7.26307 14.7631 7.26307H9.23693V1.73693C9.23693 1.54148 9.15929 1.35404 9.02109 1.21584C8.88289 1.07764 8.69545 1 8.5 1C8.30456 1 8.11712 1.07764 7.97891 1.21584C7.84071 1.35404 7.76307 1.54148 7.76307 1.73693V7.26307H2.23693C2.04148 7.26307 1.85404 7.34071 1.71584 7.47891C1.57764 7.61711 1.5 7.80455 1.5 8C1.5 8.19545 1.57764 8.38289 1.71584 8.52109C1.85404 8.65929 2.04148 8.73693 2.23693 8.73693Z"
                          fill="#429EFF"
                        />
                      </svg>
                    </button>
                  )}
                <NewCustomInput
                  value={barcodeInput[index]}
                  onChange={(value) =>
                    changeBarcodeInput((state) =>
                      state.map((temp, tempIndex) => {
                        return tempIndex === index ? value : temp;
                      })
                    )
                  }
                  width={'100%'}
                  placeholder={'Введите баркод'}
                  onFocus={() => setBarcodeViewIndex(index)}
                  onBlur={() => {
                    setBarcodeViewIndex();
                    // if (barcodeInput[index].trim().length === 0) {
                    //   changeBarcodeInput(
                    //     item.barcode_set[item.barcode_set.length - 1]
                    //   );
                    // }
                  }}
                />
                {barcodeBlockViewIndex === index &&
                item.barcode_set.length > 0 ? (
                  <div className={styles.barcodesBlock}>
                    {item.barcode_set.map((code) => (
                      <div className={styles.code}>{code}</div>
                    ))}
                  </div>
                ) : null}
              </div>

              <InputWrap
                title={
                  index === 0 && (
                    <p>
                      Артикул WB
                      <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                    </p>
                  )
                }
                placeholder={'Введите артикул'}
                value={item.articleWB}
                onChange={(newValue) =>
                  inputBarcodeSettings(item.id, newValue, 'articleWB')
                }
                width={'100%'}
              />
              <InputWrap
                title={
                  index === 0 && (
                    <p>
                      Артикул Ozon
                      <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                    </p>
                  )
                }
                placeholder={'Введите артикул'}
                value={item.articleOzon}
                onChange={(newValue) =>
                  inputBarcodeSettings(item.id, newValue, 'articleOzon')
                }
                width={'100%'}
              />
              {index > 0 && (
                <button className={styles.removeSetting}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                  >
                    <path
                      d="M1 5.16688H17M2.77778 5.16688V17.6113C2.77778 18.5932 3.57372 19.3891 4.55556 19.3891H13.4444C14.4263 19.3891 15.2222 18.5932 15.2222 17.6113V5.16688M5.44444 5.16688V3.38911C5.44444 2.40727 6.24038 1.61133 7.22222 1.61133H10.7778C11.7596 1.61133 12.5556 2.40727 12.5556 3.38911V5.16688"
                      stroke="#FF5E5B"
                      strokeWdth="1.5"
                      strokeLnecap="round"
                      strokeLnejoin="round"
                    />
                    <path
                      d="M10.7773 9.61133V14.9447"
                      stroke="#FF5E5B"
                      strokeWdth="1.5"
                      strokeLnecap="round"
                      strokeLnejoin="round"
                    />
                    <path
                      d="M7.22266 9.61133V14.9447"
                      stroke="#FF5E5B"
                      strokeWdth="1.5"
                      strokeLnecap="round"
                      strokeLnejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          ))}
          <button onClick={addSetting} className={styles.addRow}>
            <span className={styles.plusIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M1.73693 8.73693H7.26307V14.2631C7.26307 14.4585 7.34071 14.646 7.47891 14.7842C7.61712 14.9224 7.80456 15 8 15C8.19545 15 8.38289 14.9224 8.52109 14.7842C8.65929 14.646 8.73693 14.4585 8.73693 14.2631V8.73693H14.2631C14.4585 8.73693 14.646 8.65929 14.7842 8.52109C14.9224 8.38289 15 8.19545 15 8C15 7.80455 14.9224 7.61711 14.7842 7.47891C14.646 7.34071 14.4585 7.26307 14.2631 7.26307H8.73693V1.73693C8.73693 1.54148 8.65929 1.35404 8.52109 1.21584C8.38289 1.07764 8.19545 1 8 1C7.80456 1 7.61712 1.07764 7.47891 1.21584C7.34071 1.35404 7.26307 1.54148 7.26307 1.73693V7.26307H1.73693C1.54148 7.26307 1.35404 7.34071 1.21584 7.47891C1.07764 7.61711 1 7.80455 1 8C1 8.19545 1.07764 8.38289 1.21584 8.52109C1.35404 8.65929 1.54148 8.73693 1.73693 8.73693Z"
                  fill="#429EFF"
                />
              </svg>
            </span>
            Добавить
          </button>
        </div>
        <div className={styles.calc}>
          <p className={styles.subTitle}>Стоимость</p>
          <div className={styles.calcBlock}>
            <InputWrap
              title={'Закупочная цена'}
              value={price}
              onChange={changePrice}
              placeholder={'Введите цену'}
              width="100%"
            />
            <InputWrap
              title={'Цена до скидки'}
              value={priceBefore}
              onChange={changePriceBefore}
              placeholder={'Введите цену'}
              width="100%"
            />
            <InputWrap
              title={'Скидка'}
              value={discount}
              onChange={changeDiscount}
              placeholder={'Введите скидку'}
              width="100%"
            />
            <InputWrap
              title={'Цена после скидки'}
              value={result}
              onChange={changeResult}
              placeholder={'='}
              width="100%"
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <button onClick={onClose} className={styles.resetChanges}>
            Отмена
          </button>
          <button className={styles.saveChanges} onClick={addClick}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddModal;
