import React from "react";
import style from "../../static/css/commonStyle.module.scss";
import cx from "classnames";

export const HeaderTable = (props) => {
	const {
		items,
	} = props;

	return (
		<div className={cx(style.headerTable, style[`lineItems-${items}`] )}>
			{props.children}
		</div>
	);
};

export default HeaderTable;