import React from "react"

const Arrow = () => {
    return (
        <>

            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.91615 10.4031L12.8099 6.19523C13.0634 5.92153 13.0634 5.47898 12.8099 5.20528C12.5567 4.93157 12.1471 4.93157 11.8939 5.20528L7.99994 9.41315L4.10614 5.20528C3.85274 4.93157 3.44333 4.93157 3.19005 5.20528C2.93665 5.47898 2.93665 5.92153 3.19005 6.19523L7.08385 10.4031C7.08385 10.4031 7.59251 11 7.99994 11C8.40737 11 8.91615 10.4031 8.91615 10.4031Z" fill="#8496AF" />
            </svg>
            
        </>
    )
}

export default Arrow