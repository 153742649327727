import React, { useRef, useState, useEffect } from "react";
import Head from "./head.jsx";
import Bottom from "./bottom.jsx";
import Row from "./row.jsx";
import style from '../../../../Purchase.module.scss'
import cx from 'classnames';
import ScrollBar from "../../../../../../components/custom/ScrollBar/index.jsx";

const Table = ({ itemParams, setItemParams, products, setProductList }) => {

    const removeProduct = (id) => {
        setItemParams((prev) => ({
            ...prev,
            products: prev.products.filter(item => item.product !== id)
        }));

        setProductList((prev) =>
            prev.map((product) =>
                product.id === id ? { ...product, checked: false } : product
            )
        );

    };

    const editValue = (value, id, column) => {
        setItemParams((prev) => {
            const updatedProducts = prev.products.map(product => {
                if (product.product === id) {
                    return { ...product, [column]: value };
                }
                return product;
            });

            return {
                ...prev,
                products: updatedProducts
            };
        });

    }

    const bodyRef = useRef(null);

    return (

        itemParams.products.length > 0
        &&
        <section className={cx(style.section, style.product)}>

            <Head />

            <div className={style.body} >

                <div ref={bodyRef} className={style.yScroll} >

                    <Row
                        data={itemParams.products}
                        products={products}
                        remove={(value) => removeProduct(value)}
                        edit={(value, id, column) => editValue(value, id, column)}
                    />

                </div>

            </div>

            <Bottom data={itemParams} />

            <ScrollBar targetRef={bodyRef} height={"185px"} offset={575} />

        </section>


    )

}

export default Table