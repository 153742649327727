import { useState, useEffect, useRef } from 'react';
import { delData, getData, API_URL, postData } from '../../static/js/request.js';

export default function usePriceHistoryStore(selectedProduct) {
    const [priceHistory, setPriceHistory] = useState([]);
    const [isLineGraph, setIsLineGraph] = useState(true);
    const [datePicker, setDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [price, setPrice] = useState(null);
    const datePickerRef = useRef();

    useEffect(() => {
        fetchPriceHistory();
    }, [selectedProduct]);
    //
    // const fetchPriceHistory = async () => {
    //     if (selectedProduct) {
    //         const url = `${API_URL}/api/v1/products/costs/history/?barcode=${selectedProduct.barcode_set[0].code}`;
    //         const response = await getData(url);
    //         setPriceHistory(response);
    //     }
    // };
    const fetchPriceHistory = async () => {
        if (selectedProduct) {
            const url = `${API_URL}/api/v1/purchase/product/`;
            const data = {
                paginator: {
                    page_current: 1,
                    page_size: 100
                },
                product_id: selectedProduct.id
            }
            const response = await postData(url, data).then(response => response.json())
            // Проверяем, есть ли ключ 'data' в ответе
            if (response && response.data) {
                const priceHistoryData = response.data;
                setPriceHistory(priceHistoryData);
            } else {
                // Обработка случая, когда ключ 'data' отсутствует
                console.error("Ключ 'data' отсутствует в ответе.");
            }
        }
    };

    const updatePriceHistory = async () => {
        const url = `${API_URL}/api/v1/products/costs/history/?barcode=${selectedProduct.barcode_set[0].code}`;
        const response = await getData(url);
        setPriceHistory(response);
    };

    const handleDatePicker = (history = null) => {
        event.stopPropagation();
        setDatePicker(!datePicker);
        if (history) {
            const start = new Date(history.start_date);
            const end = new Date(history.end_date);
            setStartDate(start);
            setEndDate(end);
            setPrice(history.purchase_price);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (datePicker && !datePickerRef.current.contains(event.target)) {
                setDatePicker(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datePicker]);

    //   const deletePriceHistory = async (barcode) => {
    //     try {
    //       await delData(
    //         `${API_URL}/api/v1/products/costs/history/${selectedProduct.barcode}/`
    //       );
    //       fetchPriceHistory();
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //   };

    return {
        priceHistory,
        isLineGraph,
        setIsLineGraph,
        datePicker,
        setDatePicker,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        price,
        setPrice,
        handleDatePicker,
        datePickerRef,
        updatePriceHistory,
    // deletePriceHistory,
    };
}
