import React, { useRef, useEffect } from 'react';
import MultiSelect from '../modules/select.jsx';
import DatePeriod from '../modules/dateperiod.jsx';
import style from '../PlanFact.module.scss'
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import CloseIcon from '../icons/close.jsx';
import SettingsIcon from '../../../../shared/ui/icons/Settings.jsx';

const marketPlacesArr = [
    { name: 'ozone', value: 'ozone' },
    { name: 'wb', value: 'wb' }
]

const managersArr = [
    { name: 'Лена', value: '1' },
    { name: 'Таня', value: '2' },
]

const tagsArr = [
    { name: 'Мужская одежда', value: '1' },
    { name: 'Мужская обувь', value: '2' },
    { name: 'Женская одежда', value: '3' },
    { name: 'Женская обувь', value: '4' }
]

const Filter = ({ filter, setFilterHeight, changeFilter, changeDates, removeFilter, resetAll, showSetings, showSettingsButtonRef }) => {    

    const filterRef = useRef(null);

    useEffect(() => {
        if (filterRef.current) {            
            const height = filterRef.current.offsetHeight;
            setFilterHeight(height)
        }
    }, [filter]);

    return (

        <div className={style.filter}>

            <div className={style.topRow}>

                <div className={style.left}>

                    <DatePeriod
                        changeDates={changeDates}
                        values={filter.dateRange}
                    />

                    <MultiSelect
                        title="Маркетплейс"
                        alias="marketPlace"
                        data={marketPlacesArr}
                        action={changeFilter}
                        filter={filter.marketPlace}

                    />

                    <MultiSelect
                        title="Менеджер"
                        alias="manager"
                        data={managersArr}
                        action={changeFilter}
                        filter={filter.manager}

                    />

                    <MultiSelect
                        title="Теги"
                        alias="tags"
                        data={tagsArr}
                        action={changeFilter}
                        filter={filter.tags}

                    />

                    <button className={style.clear} onClick={resetAll}>Сбросить все</button>

                </div>

                <div className={style.rigth}>

                    <button ref={showSettingsButtonRef} onClick={() => showSetings(prev => !prev)} className={style.setting}><SettingsIcon /></button>

                </div>

            </div>

            <div ref={filterRef} className={style.bottomRow}>

                {filter.marketPlace.map((place) =>
                    <span key={place} className={style.tag}>
                        {marketPlacesArr.find(item => item === place).name}
                        <button onClick={() => removeFilter('marketPlace', place)}><CloseIcon /></button>
                    </span>
                )}

                {filter.manager.map((manager) => (
                    <span key={manager} className={style.tag}>
                        {managersArr.find(item => item === manager).name}
                        <button onClick={() => removeFilter('manager', manager)}><CloseIcon /></button>
                    </span>
                ))}

                {filter.tags.map((tag) => (
                    <span key={tag} className={style.tag}>
                        {tagsArr.find(item => item === tag).name}
                        <button onClick={() => removeFilter('tags', tag)}><CloseIcon /></button>
                    </span>
                ))}

            </div>


        </div>

    )

}

export default Filter