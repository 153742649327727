import React from 'react'

const CupIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5045 15.0784L13.7552 16.0433L13.761 16.0657C13.761 16.0658 13.761 16.0659 13.761 16.0661C13.7592 16.0592 13.7573 16.0516 13.7551 16.0433C13.7255 15.9291 13.658 15.6693 13.5045 15.0784ZM10.241 16.0433C10.2389 16.0516 10.2369 16.0592 10.2351 16.0661C10.2351 16.0659 10.2351 16.0658 10.2351 16.0657L10.2409 16.0433L10.4917 15.0784C10.3381 15.6693 10.2707 15.9291 10.241 16.0433Z" fill="#429EFF" stroke="#429EFF" stroke-width="2.6936" />
            <path d="M17.0579 2.66559C17.0579 2.29805 16.7599 2 16.3923 2H7.607C7.23945 2 6.94141 2.29805 6.94141 2.66559V8.15644C6.94141 9.87509 7.45259 11.4395 8.38073 12.5615C9.31501 13.6908 10.6003 14.3129 11.9997 14.3129C13.3991 14.3129 14.6843 13.6909 15.6186 12.5615C16.5467 11.4395 17.0579 9.87509 17.0579 8.15644V2.66559Z" fill="#429EFF" />
            <path d="M6.30575 9.49004C5.39497 9.15112 4.97558 8.36506 4.97558 7.05832V4.08546H6.20689V2.75435H4.31012C3.94251 2.75435 3.64453 3.05233 3.64453 3.41994V7.05826C3.64453 9.27785 4.7077 10.6299 6.66258 10.9457C6.50197 10.4838 6.38171 9.99657 6.30575 9.49004Z" fill="#429EFF" />
            <path d="M7.607 17.3744C7.23945 17.3744 6.94141 17.6724 6.94141 18.04V21.3345C6.94141 21.702 7.23945 22.0001 7.607 22.0001H16.3924C16.7599 22.0001 17.058 21.702 17.058 21.3345V18.04C17.058 17.6724 16.7599 17.3744 16.3924 17.3744H7.607Z" fill="#429EFF" />
            <path d="M19.6846 2.75433H17.7877V4.08545H19.019V7.05824C19.019 8.36498 18.5996 9.15104 17.6889 9.48996C17.6129 9.99649 17.4927 10.4838 17.332 10.9457C19.2869 10.6299 20.3501 9.27784 20.3501 7.05824V3.41992C20.3501 3.05231 20.0522 2.75433 19.6846 2.75433Z" fill="#429EFF" />
        </svg>
    )
}

export default CupIcon