import React, { useRef, useState, useEffect } from "react"
import cx from 'classnames'
import styles from './styles.modules.scss'

import useSyncScroll from "../../../static/js/useSyncScroll.jsx"

const ScrollBar = ({ targetRef, horizontal = false, offset, width=false, height=false }) => {

    const elementRef = useRef(null);

    const { scrollSize } = useSyncScroll(targetRef, elementRef, { horizontal });    

    return (
        <div
            className={cx(styles.scrollbarContainer, horizontal ? styles.horizontal : styles.vertical)}
            ref={elementRef}
            style={{
                marginLeft: horizontal ? `${offset}px` : 'auto',
                top: horizontal ? 'auto' : `${offset}px`,
                height: horizontal ? 'null' : `${height}`
            }}
        >
            <div
                style={{
                    width: horizontal ? `${scrollSize}px` : null,
                    height: horizontal ? 'null' : `${scrollSize}px`
                }}
                className={styles.scrollbarContent}>

            </div>
        </div>
    )

}

export default ScrollBar