import React from 'react';
import styles from './NotFound.module.scss';
import {Link} from 'react-router-dom';

const NotFound = () => {
    return (
        <div className={styles.page}>
            <svg xmlns="http://www.w3.org/2000/svg" width="279" height="107" viewBox="0 0 279 107" fill="none">
                <path d="M0.332301 75.028L2.21074 63.2678L33.7581 42.2148C36.4567 40.3266 38.7423 38.6043 40.6147 37.0479C42.5122 35.4956 44.0141 33.9187 45.1205 32.3173C46.2309 30.6908 46.9486 28.8598 47.2737 26.8244C47.635 24.5628 47.4308 22.533 46.6612 20.7351C45.8956 18.912 44.6909 17.4182 43.0469 16.2536C41.4069 15.064 39.4559 14.2885 37.1939 13.9272C34.8313 13.5498 32.6941 13.6981 30.7823 14.3719C28.8704 15.0458 27.2945 16.1985 26.0544 17.8301C24.8144 19.4617 23.9977 21.5088 23.6043 23.9714L8.10955 21.4965C8.91631 16.4456 10.7603 12.2433 13.6415 8.88957C16.5226 5.53585 20.1867 3.17046 24.6337 1.79341C29.0807 0.416365 33.9935 0.157397 39.3721 1.0165C44.9014 1.8997 49.5579 3.6485 53.3416 6.2629C57.1545 8.8562 59.9295 12.0568 61.6668 15.8648C63.404 19.6727 63.9174 23.8006 63.207 28.2484C62.7414 31.1633 61.7038 33.9482 60.0941 36.6031C58.5095 39.262 55.9825 42.0925 52.5131 45.0945C49.0478 48.0715 44.2782 51.5488 38.2044 55.5264L25.168 64.77L25.0837 65.2977L57.5059 70.4764L55.3746 83.8198L0.332301 75.028Z" fill="#8496AF"/>
                <path d="M95.3388 78.1429L103.864 24.7695L117.361 26.9253L108.835 80.2987L95.3388 78.1429ZM78.5804 55.0178L80.7358 41.5236L134.119 50.0505L131.964 63.5446L78.5804 55.0178Z" fill="#8496AF"/>
                <path d="M143.554 97.9045L145.432 86.1442L176.98 65.0913C179.678 63.2031 181.964 61.4808 183.836 59.9244C185.734 58.3721 187.236 56.7952 188.342 55.1938C189.452 53.5673 190.17 51.7363 190.495 49.7009C190.856 47.4393 190.652 45.4095 189.883 43.6115C189.117 41.7884 187.912 40.2946 186.268 39.1301C184.628 37.9404 182.677 37.165 180.415 36.8036C178.053 36.4263 175.916 36.5745 174.004 37.2484C172.092 37.9223 170.516 39.075 169.276 40.7066C168.036 42.3382 167.219 44.3853 166.826 46.8479L151.331 44.373C152.138 39.3221 153.982 35.1198 156.863 31.7661C159.744 28.4123 163.408 26.0469 167.855 24.6699C172.302 23.2928 177.215 23.0339 182.594 23.893C188.123 24.7762 192.779 26.525 196.563 29.1394C200.376 31.7327 203.151 34.9333 204.888 38.7413C206.625 42.5492 207.139 46.6771 206.428 51.1249C205.963 54.0398 204.925 56.8247 203.315 59.4796C201.731 62.1385 199.204 64.9689 195.735 67.971C192.269 70.948 187.5 74.4253 181.426 78.4029L168.389 87.6465L168.305 88.1742L200.727 93.3529L198.596 106.696L143.554 97.9045Z" fill="#8496AF"/>
                <path d="M224.472 65.2941L226.615 51.8753L278.679 60.1914L276.536 73.6101L224.472 65.2941ZM220.474 90.3223L222.617 76.9035L274.681 85.2196L272.538 98.6383L220.474 90.3223Z" fill="#8496AF"/>
            </svg>
            <h1>404</h1>
            <h3>Ошибка!</h3>
            <p>К сожалению, запрашиваемая Вами страница не найдена </p>
            <Link to={'/'}>Вернуться на главную</Link>
        </div>
    );
};
export default NotFound;
