import { useState, useEffect } from "react"

const useSyncScroll = (targetRef, elementRef, { horizontal = true } = {}) => {

    const [scrolling, setScrolling] = useState(0);
    const [scrollSize, setScrollSize] = useState(0);

    useEffect(() => {

        const scrollContainer = targetRef.current;
        const element = elementRef.current;

        const updateScrollSize = () => {
            const realScrollSize = horizontal
                ? scrollContainer?.scrollWidth || 0
                : scrollContainer?.scrollHeight || 0;
            setScrollSize(realScrollSize);
        };

        //updateScrollSize();

        const syncScroll = (source, target) => {
            if (source && target) {
                if (horizontal) {
                    target.scrollLeft = source.scrollLeft;
                } else {
                    target.scrollTop = source.scrollTop;
                }
            }
        };

        const handleScroll = (e) => {

            //const scrollPosition = horizontal ? e.target.scrollLeft : e.target.scrollTop;
            //setScrolling(scrollPosition);

            const isPrimary = e.target === scrollContainer;
            syncScroll(isPrimary ? scrollContainer : element, isPrimary ? element : scrollContainer);
            
        };

        if (element && scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            element.addEventListener('scroll', handleScroll);

            horizontal
                ? (element.scrollLeft = scrollContainer.scrollLeft)
                : (element.scrollTop = scrollContainer.scrollTop)
            
        }

        const observer = new ResizeObserver(() => {
            updateScrollSize();
        });

        if (scrollContainer) {
            observer.observe(scrollContainer);
        }

        return () => {
            if (element && scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
                element.removeEventListener('scroll', handleScroll);
            }

            if (scrollContainer) {
                observer.unobserve(scrollContainer);
            }
        };
    }, [targetRef, scrolling, horizontal]);

    return { scrollSize };

}

export default useSyncScroll;