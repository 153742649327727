"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTotals = void 0;
const react_1 = require("react");
const useTotals = (yearData) => {
    return (0, react_1.useMemo)(() => {
        if (!yearData) {
            return {
                total_payment_amount: 0,
                total_bank_amount: 0,
                total_cashbox_amount: 0,
                total_kpi_amount: 0,
                expected_salary: 0,
                salary_difference: 0,
            };
        }
        return Object.values(yearData).reduce((acc, values) => ({
            total_payment_amount: (acc.total_payment_amount ?? 0) + (values.total_payment_amount ?? 0),
            total_bank_amount: (acc.total_bank_amount ?? 0) + (values.total_bank_amount ?? 0),
            total_cashbox_amount: (acc.total_cashbox_amount ?? 0) + (values.total_cashbox_amount ?? 0),
            total_kpi_amount: (acc.total_kpi_amount ?? 0) + (values.total_kpi_amount ?? 0),
            expected_salary: (acc.expected_salary ?? 0) + (values.expected_salary ?? 0),
            salary_difference: (acc.salary_difference ?? 0) + (values.salary_difference ?? 0),
        }), {
            total_payment_amount: 0,
            total_bank_amount: 0,
            total_cashbox_amount: 0,
            total_kpi_amount: 0,
            expected_salary: 0,
            salary_difference: 0,
        });
    }, [yearData]);
};
exports.useTotals = useTotals;
