import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom';
import { API_URL, delData } from '../../../../static/js/request';
import { createNotification } from '../../../../static/js/commonFunc';

function AnswerRow({item, onRemove}) {

    const navigate = useNavigate()

    const [edit, setEdit] = useState(false);

    const outsideClick = (e) => {
        const id = e.target.getAttribute('id');

        if (!id?.includes('noClose')) {
            setEdit(false)
        }
    }

    useEffect(() => {
        if (edit) {
            document.addEventListener('click', outsideClick);
        } else {
            document.removeEventListener('click', outsideClick);
        }
    }, [edit])

    const rowClick = (e) => {
        const id = e.target.getAttribute('id');

        if (!id?.includes('noClose')) {
            navigate(`/scheduler/answering/item/${item.id}/contructor`)
        }
    }


    return (
        <div onClick={rowClick} className={styles.answer}>
            <p className={styles.name}>{item?.name}</p>
            {
                item?.constructor_blocks.map(block => (
                    <p className={styles.col}>{block.num_block_texts}</p>
                ))
            }
            <button id={`noClose`} className={styles.editOpen} onClick={() => setEdit(`true`)}>
                <svg id={`noClose`} xmlns="http://www.w3.org/2000/svg" width="4" height="12" viewBox="0 0 4 12" fill="none">
                    <circle id={`noClose`} cx="2.2513" cy="1.33333" r="1.33333" fill="#1E1E1E" />
                    <circle id={`noClose`} cx="2.2513" cy="5.99996" r="1.33333" fill="#1E1E1E" />
                    <circle id={`noClose`} cx="2.2513" cy="10.6667" r="1.33333" fill="#1E1E1E" />
                </svg>
            </button>
            {
                edit &&
                <div id={`noClose`} className={styles.editModal}>
                    <button onClick={onRemove} id={`noClose`} className={styles.remove}>
                        <svg id={`noClose`} xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <path id={`noClose`} d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z" fill="#FF5E5B" />
                            <path id={`noClose`} d="M5.21702 12.5267C5.35526 12.5263 5.48772 12.4723 5.58547 12.3764C5.68323 12.2806 5.73832 12.1507 5.73873 12.0151V6.13124C5.73873 5.99555 5.68376 5.86542 5.58592 5.76948C5.48808 5.67353 5.35539 5.61963 5.21702 5.61963C5.07866 5.61963 4.94596 5.67353 4.84812 5.76948C4.75028 5.86542 4.69531 5.99555 4.69531 6.13124V12.0151C4.69572 12.1507 4.75082 12.2806 4.84857 12.3764C4.94632 12.4723 5.07878 12.5263 5.21702 12.5267Z" fill="#FF5E5B" />
                            <path id={`noClose`} d="M8.52171 12.5267C8.65995 12.5263 8.79242 12.4723 8.89017 12.3764C8.98792 12.2806 9.04301 12.1507 9.04342 12.0151V6.13124C9.04342 5.99555 8.98845 5.86542 8.89061 5.76948C8.79277 5.67353 8.66008 5.61963 8.52171 5.61963C8.38335 5.61963 8.25065 5.67353 8.15281 5.76948C8.05497 5.86542 8 5.99555 8 6.13124V12.0151C8.00041 12.1507 8.05551 12.2806 8.15326 12.3764C8.25101 12.4723 8.38347 12.5263 8.52171 12.5267Z" fill="#FF5E5B" />
                        </svg>
                    </button>
                    <button id={`noClose`} onClick={() => setEdit(false)} className={styles.closeModal}>
                        <svg id={`noClose`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path id={`noClose`} d="M10.4031 7.08385L6.19523 3.19005C5.92153 2.93665 5.47898 2.93665 5.20528 3.19005C4.93157 3.44333 4.93157 3.85286 5.20528 4.10614L9.41315 8.00006L5.20528 11.8939C4.93157 12.1473 4.93157 12.5567 5.20528 12.8099C5.47898 13.0634 5.92153 13.0634 6.19523 12.8099L10.4031 8.91615C10.4031 8.91615 11 8.40749 11 8.00006C11 7.59263 10.4031 7.08385 10.4031 7.08385Z" fill="#1E1E1E" />
                        </svg>
                    </button>
                </div>
            }
        </div>
    );
}

export default AnswerRow;