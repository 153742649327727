// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eJpVudecpbIF2aX1pnTj {\n  width: 100vw;\n  position: relative;\n  left: -15px;\n  top: -5px;\n}\n.eJpVudecpbIF2aX1pnTj * {\n  box-sizing: border-box;\n  font-size: 14px;\n  margin: 0;\n  padding: 0;\n}\n.eJpVudecpbIF2aX1pnTj a {\n  text-decoration: none;\n  color: inherit;\n}", "",{"version":3,"sources":["webpack://./src/views/FAQ/Faq.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AACF;AAAE;EACE,sBAAA;EACA,eAAA;EACA,SAAA;EACA,UAAA;AAEJ;AACE;EACE,qBAAA;EACA,cAAA;AACJ","sourcesContent":[".faq {\n  width: 100vw;\n  position: relative;\n  left: -15px;\n  top: -5px;\n  * {\n    box-sizing: border-box;\n    font-size: 14px;\n    margin: 0;\n    padding: 0;\n  }\n\n  a {\n    text-decoration: none;\n    color: inherit;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faq": "eJpVudecpbIF2aX1pnTj"
};
export default ___CSS_LOADER_EXPORT___;
