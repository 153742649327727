import React, { createRef, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CashboxesStore } from '../../../store/Financing/CashboxesStore.js';
import BodyTableLine from '../../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../../components/custom/BodyTableLineItem.jsx';
import style from '../Financing.module.scss';
import {
    applyPriceNoSymbolCurrency,
    componentConfirmAlert,
    formatNumber2,
} from '../../../static/js/commonFunc';

const CashboxesBody = observer(() => {
    const [state] = useState(() => new CashboxesStore());

    const myRefs = state.fields.map(() => createRef());

    const cashboxesBodyItemOption = (index) => {
        if (myRefs[index].current.classList.contains(style.active)) {
            myRefs[index].current.classList.remove(style.active);
            return;
        }
        myRefs.forEach((ref) => {
            ref.current.classList.remove(style.active);
        });
        myRefs[index].current.classList.add(style.active);
    };

    document.addEventListener('click', (evt) => {
        if (
            !evt.target.closest(`.${[style.dropdownItem]}`) &&
      !evt.target.closest('.js__open-menu')
        ) {
            myRefs.forEach((ref) => {
                if (ref.current) {
                    ref.current.classList.remove(style.active);
                }
            });
        }
    });

    if (state.fields.length === 0) {
        return (
            <p style={{marginTop: '30px'}} className={style.empty}>Нет данных</p>
        );
    }

    return state.fields.map((field, index) => (
        <div ref={myRefs[index]} className={style.CashboxLine} key={index}>
            <BodyTableLine items="9" href={`/financing/cashbox/item/?id=${field.id}`}>
                <BodyTableLineItem additionalClass="cashBoxName" value={field.name} />

                <BodyTableLineItem
                    additionalClass="responsible"
                    value={field.responsible}
                />

                <BodyTableLineItem
                    value={formatNumber2(field.incoming_balance) || 0}
                    additionalClass="alightAmount"
                />

                <BodyTableLineItem
                    value={formatNumber2(field.outgoing_balance) || 0}
                    additionalClass="alightAmount"
                />

                <BodyTableLineItem
                    additionalClass="date"
                    value={field.last_change_date}
                />
            </BodyTableLine>
            <span
                onClick={() => cashboxesBodyItemOption(index)}
                className={`js__open-menu ${style.spanAbsolute}`}
            >
                ...
            </span>
            <div className={style.dropdownItem}>
                <button onClick={() => componentConfirmAlert(state, index)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                    >
                        <path
                            d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z"
                            fill="#FF5E5B"
                        />
                        <path
                            d="M5.21751 12.5267C5.35575 12.5263 5.48821 12.4723 5.58596 12.3764C5.68371 12.2806 5.73881 12.1507 5.73921 12.0151V6.13124C5.73921 5.99555 5.68425 5.86542 5.58641 5.76948C5.48857 5.67353 5.35587 5.61963 5.21751 5.61963C5.07914 5.61963 4.94644 5.67353 4.84861 5.76948C4.75077 5.86542 4.6958 5.99555 4.6958 6.13124V12.0151C4.69621 12.1507 4.7513 12.2806 4.84906 12.3764C4.94681 12.4723 5.07927 12.5263 5.21751 12.5267Z"
                            fill="#FF5E5B"
                        />
                        <path
                            d="M8.52171 12.5267C8.65995 12.5263 8.79242 12.4723 8.89017 12.3764C8.98792 12.2806 9.04301 12.1507 9.04342 12.0151V6.13124C9.04342 5.99555 8.98845 5.86542 8.89061 5.76948C8.79277 5.67353 8.66008 5.61963 8.52171 5.61963C8.38335 5.61963 8.25065 5.67353 8.15281 5.76948C8.05497 5.86542 8 5.99555 8 6.13124V12.0151C8.00041 12.1507 8.05551 12.2806 8.15326 12.3764C8.25101 12.4723 8.38347 12.5263 8.52171 12.5267Z"
                            fill="#FF5E5B"
                        />
                    </svg>
                    Удалить
                </button>
                {/*<button>Изменить статью</button>*/}
            </div>
        </div>
       
    ));
});

export default CashboxesBody;
