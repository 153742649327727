import React from "react";

const SettingsIcon = _ => {

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.8941 9.87458L19.4595 9.56269C19.3347 9.18074 19.1802 8.80818 18.9977 8.44929L19.792 7.21384C19.9338 6.99309 19.9027 6.70328 19.7172 6.51785L17.4822 4.28279C17.2967 4.09736 17.0069 4.06617 16.7862 4.20802L15.5507 5.00227C15.1918 4.81983 14.8193 4.66531 14.4373 4.54056L14.1254 3.10587C14.0697 2.84952 13.8427 2.66666 13.5804 2.66666H10.4196C10.1573 2.66666 9.9303 2.84952 9.87461 3.10587L9.56272 4.54056C9.18077 4.66531 8.80821 4.81983 8.44932 5.00227L7.21387 4.20802C6.99312 4.06617 6.70331 4.09736 6.51788 4.28279L4.28282 6.51785C4.09739 6.70328 4.0662 6.99309 4.20805 7.21384L5.0023 8.44929C4.81987 8.80818 4.66534 9.18074 4.54059 9.56269L3.1059 9.87458C2.84955 9.93041 2.66669 10.1573 2.66669 10.4196V13.5804C2.66669 13.8427 2.84955 14.0696 3.1059 14.1254L4.54059 14.4373C4.66534 14.8192 4.81987 15.1918 5.0023 15.5507L4.20805 16.7861C4.0662 17.0069 4.09739 17.2967 4.28282 17.4821L6.51788 19.7172C6.70331 19.9026 6.99312 19.9338 7.21387 19.792L8.44932 18.9977C8.80821 19.1801 9.18077 19.3347 9.56272 19.4594L9.87461 20.8941C9.9303 21.1505 10.1573 21.3333 10.4196 21.3333H13.5804C13.8427 21.3333 14.0697 21.1505 14.1254 20.8941L14.4373 19.4594C14.8193 19.3347 15.1918 19.1801 15.5507 18.9977L16.7862 19.792C17.0069 19.9338 17.2967 19.9028 17.4822 19.7172L19.7172 17.4821C19.9027 17.2967 19.9338 17.0069 19.792 16.7861L18.9977 15.5507C19.1802 15.1918 19.3347 14.8192 19.4595 14.4373L20.8941 14.1254C21.1505 14.0696 21.3334 13.8427 21.3334 13.5804V10.4196C21.3334 10.1573 21.1505 9.93041 20.8941 9.87458V9.87458ZM15.3466 12C15.3466 13.8453 13.8453 15.3466 12 15.3466C10.1547 15.3466 8.6534 13.8453 8.6534 12C8.6534 10.1547 10.1547 8.65337 12 8.65337C13.8453 8.65337 15.3466 10.1547 15.3466 12V12Z" stroke="#35373A" />
        </svg>
    )

}

export default SettingsIcon