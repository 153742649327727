// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Oj2gN4xRGjHSo8Lh0ohE {\n  background: rgb(66, 158, 255);\n  background: linear-gradient(241deg, rgb(66, 158, 255) 0%, rgb(0, 124, 255) 100%);\n  padding: 50px 10px;\n  color: #fff;\n}\n.Oj2gN4xRGjHSo8Lh0ohE .NaOtG1TbvHtBUR5wg5jY {\n  font-size: 48px;\n  font-weight: 600;\n  text-align: center;\n  margin-bottom: 50px;\n  line-height: 50px;\n}\n\n.bwm_6i3gLZMWtqEWwKmE {\n  padding: 30px 10px 20px;\n  width: 100%;\n  max-width: 980px;\n  margin: 0 auto;\n}\n.bwm_6i3gLZMWtqEWwKmE .UdPAXpwHcJXwYym3Lxm7 {\n  font-size: 24px;\n  font-weight: 600;\n  margin-bottom: 20px;\n}\n.bwm_6i3gLZMWtqEWwKmE .lzinfgsEoltiVW1EJHrF {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-template-rows: 1fr;\n  grid-column-gap: 20px;\n  grid-row-gap: 20px;\n}", "",{"version":3,"sources":["webpack://./src/views/FAQ/ui/MainContent/MainContent.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,gFAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAGA;EACE,uBAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;AAAF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,qCAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;AADJ","sourcesContent":[".content {\n  background: rgb(66,158,255);\n  background: linear-gradient(241deg, rgba(66,158,255,1) 0%, rgba(0,124,255,1) 100%);\n  padding: 50px 10px;\n  color: #fff;\n\n  .title {\n    font-size: 48px;\n    font-weight: 600;\n    text-align: center;\n    margin-bottom: 50px;\n    line-height: 50px;\n  }\n}\n\n.interesting {\n  padding: 30px 10px 20px;\n  width: 100%;\n  max-width: 980px;\n  margin: 0 auto;\n\n  .smallTitle {\n    font-size: 24px;\n    font-weight: 600;\n    margin-bottom: 20px;\n  }\n\n  .cards {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-template-rows: 1fr;\n    grid-column-gap: 20px;\n    grid-row-gap: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Oj2gN4xRGjHSo8Lh0ohE",
	"title": "NaOtG1TbvHtBUR5wg5jY",
	"interesting": "bwm_6i3gLZMWtqEWwKmE",
	"smallTitle": "UdPAXpwHcJXwYym3Lxm7",
	"cards": "lzinfgsEoltiVW1EJHrF"
};
export default ___CSS_LOADER_EXPORT___;
