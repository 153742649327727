import React from "react";
import style from '../PlanFact.module.scss'
import cx from 'classnames'
import ColumnName from "./columnName.jsx";
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

const TableHead = ({ columnsVisibility, dateHeaders, scrollContainerRef }) => {

    const formatingDate = (day, month, year) => {
        let parsedMonth = format(new Date(`${year}-${month}-${day}`), 'MMMM', { locale: ru })
        return `${day} ${parsedMonth}`
    }

    return (
        <div className={style.header}>
            <div className={cx(style.tableRow, style.product)}>
                <div>

                    <div className={style.tableHead}>
                        <ColumnName name="Товар" />
                    </div>

                    <div className={style.tableRow}>
                        <div className={cx(style.tableColumn, style.head, style.product)}>

                            <ColumnName name="Наименование" filter="true" />

                        </div>

                        <div className={cx(style.tableColumn, style.head)}>
                            <ColumnName name="Бренд" filter="true" />
                        </div>
                        <div className={cx(style.tableColumn, style.head)}>
                            <ColumnName name="Артикул" filter="true" />
                        </div>

                        <div className={cx(style.tableColumn, style.action)}></div>

                    </div>

                </div>

            </div>

            {
                Object.values(columnsVisibility).every((value) => value === false) ?
                    <div className={cx(style.tableRow, style.xScroll, style.jcCenter)}>нет данных</div>
                    :
                    <div ref={scrollContainerRef} className={cx(style.tableRow, style.xScroll)}>
                        {dateHeaders.map(({ day, month, year }) => (
                            <div key={`${year}-${month}-${day}`} >

                                <div className={cx(style.tableHead, style.days)}>
                                    <ColumnName name={formatingDate(day, month, year)} />

                                </div>
                                <div className={style.tableRow}>

                                    {columnsVisibility.orders_count && (
                                        <div className={cx(style.tableColumn, style.head, style.bottom)}><ColumnName name="Заказы, шт." filter="true" /></div>
                                    )}

                                    {columnsVisibility.orders_sum && (
                                        <div className={cx(style.tableColumn, style.head, style.bottom)}><ColumnName name="Заказы, руб." filter="true" /></div>
                                    )}

                                    {columnsVisibility.sales_count && (
                                        <div className={cx(style.tableColumn, style.head, style.bottom)}><ColumnName name="Продажи, шт." filter="true" /></div>
                                    )}

                                    {columnsVisibility.sales_sum && (
                                        <div className={cx(style.tableColumn, style.head, style.bottom)}><ColumnName name="Продажи, руб." filter="true" /></div>
                                    )}

                                    {columnsVisibility.stock && (
                                        <div className={cx(style.tableColumn, style.head, style.bottom)}><ColumnName name="Склад" filter="true" /></div>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>
            }

            <div className={cx(style.tableRow, style.total)}>

                <div>

                    <div className={style.tableHead}>
                        <ColumnName name="Итого" />
                    </div>

                    <div className={style.tableRow}>
                        <div className={cx(style.tableColumn, style.head, style.stock)}>
                            <ColumnName name="Склад" filter="true" />
                        </div>

                        <div className={cx(style.tableColumn, style.head, style.execution)}>

                            <ColumnName name="% Выполнения" filter="true" />

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )

}

export default TableHead