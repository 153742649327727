import React from "react";
import { RowImage } from "../../../../CostPrice/RowImage.jsx";
import style from '../../PlanFact.module.scss'
import Tags from './tags.jsx'
import cx from 'classnames'
import SizeButton from "./sizeButton.jsx";


const Product = ({ data, action = false, isChild = false }) => {
    return (

        <div className={cx(style.tableRow, style.product)}>

            <div className={cx(style.tableColumn, style.product)}>

                <div className={style.productInfo}>

                    {

                        isChild ?
                            <span className={style.noImage}>

                            </span>
                            :
                            data.photo_url
                                ?
                                <div className={style.imgWrap}>
                                    <RowImage item={data} />
                                </div>
                                :
                                <span >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="m20.475 23.3l-2.3-2.3H4q-.825 0-1.413-.588T2 19V7q0-.825.588-1.413T4 5h1.025v2.85L.675 3.5L2.1 2.075l19.8 19.8l-1.425 1.425ZM22 19.125l-5.525-5.55q.125-1.05-.213-2.037T15.176 9.8q-.725-.725-1.713-1.063t-2.037-.212L7.5 4.625L9 3h6l1.85 2H20q.825 0 1.413.588T22 7v12.125ZM12 17.5q.575 0 1.113-.125t1.037-.4L8.025 10.85q-.275.5-.4 1.038T7.5 13q0 1.875 1.313 3.188T12 17.5Zm0-2q-1.05 0-1.775-.725T9.5 13q0-.5.188-.963t.537-.812l3.55 3.55q-.35.35-.812.538T12 15.5Z"
                                        />
                                    </svg>
                                </span>

                    }

                    <div className={style.info}>
                        <div className={cx(style.article, isChild && style.child)}>{data.article}</div>
                        {isChild ? null : <div className={style.tableRow}><div className={style.mp}>{data.mp}:</div>  {data.barcode}</div>}
                        {isChild ? null : <Tags data={data.tags} />}
                    </div>

                </div>

            </div>

            <div className={cx(style.tableColumn, style.startValue)}>{isChild ? data.size : data.brand}</div>
            <div className={cx(style.tableColumn, style.startValue)}>{data.nm}</div>
            <div className={cx(style.tableColumn, style.action)}><SizeButton action={action} show={isChild} /></div>

        </div>

    )
}

export default Product