import React from 'react';
import styles from './styles.module.scss'

function Switch({active, setActive}) {
    return ( 
        <div onClick={() => setActive(!active)} className={`${styles.switch} ${active ? styles.active : ''}`}>
            <span/>
        </div>
     );
}

export default Switch;