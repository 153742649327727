import Table from '../../components/custom/Table.jsx';
import style from './style.module.scss';
import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import {NavLink} from 'react-router-dom';
import {StaffStore} from '../../store/Staff/StaffStore';
import HeaderTable from '../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../components/custom/HeaderTableItem.jsx';
import BodyTable from '../../components/custom/BodyTable.jsx';
import StaffBody from './StaffBody.jsx';
import BodyTableLine from '../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../components/custom/BodyTableLineItem.jsx';
import {formatNumber2} from '../../static/js/commonFunc';

const Staff = observer(() => {
    const [state] = useState(() => new StaffStore());
    const [activeTab, setActiveTab] = React.useState('Все');

    const changeOrganization = (item) => {
        setActiveTab(item.name);
    };

    React.useEffect(() => {
        if (activeTab === 'Все') {
            state.employees = state.allEmployees;
            return;
        }

        state.employees = state.allEmployees.filter(item => activeTab.includes(item.organization));
    }, [activeTab, state]);

    return (
        <section className={style.section}>
            <h2 className={style.title}>Сотрудники</h2>
            <div className={style.content}>
                <div className={style.top}>
                    <div className={style.tabs}>
                        {state.organizations.map((item, index) => (
                            <button
                                key={index}
                                onClick={() => changeOrganization(item)}
                                className={`${style.tab} ${
                                    activeTab === item.name ? style.active : ''
                                }`}
                            >
                                {item.name}{' '}
                            </button>
                        ))}
                    </div>

                    <div className={style.itemButton}>
                        <NavLink to="/staff/card/">
                            + Добавить сотрудника
                        </NavLink>
                    </div>
                </div>
                {!state.employees || !state.employees?.length ? (
                    <div className={style.empty}>
                        Добавьте сотрудника
                    </div>
                ) : (
                    <Table>
                        <HeaderTable items='staff'>

                            <HeaderTableItem
                                name='Фио'
                                filter = 'true'
                            />
                            <HeaderTableItem
                                name='Подразделение'
                                filter = 'true'
                            />
                            <HeaderTableItem
                                name='Должность'
                                filter = 'true'
                            />
                            <HeaderTableItem
                                name='оклад'
                                filter = 'true'
                            />
                            <HeaderTableItem
                                name='касса'
                                filter = 'true'
                            />
                            <HeaderTableItem
                                name='p/c'
                                filter = 'true'
                            />
                            <HeaderTableItem
                                name='kpi'
                                filter = 'true'
                            />
                            <HeaderTableItem
                                name='Итого'
                                filter = 'true'
                            />

                        </HeaderTable>

                        <BodyTable>

                            <div>
                                <div className={style.staffValueBlock}>
                                    <StaffBody state={state} />
                                </div>
                                <BodyTableLine items='staff'>
                                    <BodyTableLineItem value={'Всего ФОТ'}/>
                                    <BodyTableLineItem />
                                    <BodyTableLineItem />
                                    <BodyTableLineItem value={state.employeesTotal.salary ? formatNumber2(state.employeesTotal.salary) : '-'} />
                                    <BodyTableLineItem value={state.employeesTotal.cash ? formatNumber2(state.employeesTotal.cash) : '-'} />
                                    <BodyTableLineItem value={state.employeesTotal.payment_account ? formatNumber2(state.employeesTotal.payment_account) : '-'} />
                                    <BodyTableLineItem value={state.employeesTotal.kpi ? formatNumber2(state.employeesTotal.kpi) : '-'} />
                                    <BodyTableLineItem value={state.employeesTotal.total_payment ? formatNumber2(state.employeesTotal.total_payment) : '-'} />
                                </BodyTableLine>
                            </div>
                        </BodyTable>
                    </Table>
                )}
            </div>
        </section>
    );
});

export default Staff;
