import React from 'react';
import style from '../../static/css/commonStyle.module.scss';
import cx from 'classnames';
import {NavLink} from 'react-router-dom';

export const BodyTableLine = (props) => {
    const {
        items,
        href,
        event, 
        additionalClass
    } = props;

    const addedClass = () => {
        let preparedClass = '';
        if (additionalClass) {
            preparedClass = additionalClass;
        }
        return preparedClass;
    };

    const identEl = () => {
        if (!href) {
            return (
                <div onClick={event} className={cx(style.BodyTableLine, style[`lineItems-${items}`], (style[addedClass()]) )}>
                    {props.children}
                </div>
            );
        }
        else {
            return (
                <NavLink to={href} className={cx(style.BodyTableLine, style[`lineItems-${items}`] )}>
                    {props.children}
                </NavLink>
            );
        }
    };

    return (
        identEl()
    );
};

export default BodyTableLine;