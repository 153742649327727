import React from 'react';

const NumberInput = ({ onChange, value, ...props }) => {
    const handleChange = (event) => {
        const inputValue = event.target.value;
        const onlyNumbers = inputValue.replace(/[^0-9]/g, '');

        if (inputValue === onlyNumbers) {
            onChange(event);
        }
    };

    return (
        <input
            type="text"
            value={value}
            onChange={handleChange}
            {...props}
        />
    );
}

export default NumberInput;
