import React, { useEffect, useRef, useState } from 'react';
import style from './FinancingReport.module.scss';
import { getData, postData, API_URL } from '../../static/js/request';
import { onMainScroll } from '../Advertisement/index.jsx';
import { useLocation } from 'react-router-dom';
import YearSelect from '../Balance/YearSelect/index.jsx';
import ReportTable from './Table.jsx';

const counterFormat = (counter) => {
  const nf = Intl.NumberFormat();
  return nf.format(counter);
};

const FinancingReport = () => {
  const [rerender, setRerender] = useState(false);

  const [listMonthValue, setListMonthValue] = useState([]);
  const [pnlValue, setPnlValue] = useState([]);
  const [pnlDate, setPnlDate] = useState([]);
  const [pnlDataValue, setPnlDataValue] = useState([]);

  const [year, changeYear] = useState(new Date().getFullYear());
  const [month, changeMonth] = useState(null);

  const [selectYear, setSelectYear] = useState([]);
  const [selectMonth, setSelectMonth] = useState([]);
  const [selectMP, setSelectMP] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [isActiveNested, setIsActiveNested] = useState(false);

  const [stickyRefs, setStickyRefs] = useState([]);

  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [allYears, setAllYears] = useState([]);

  const getPnlData = async () => {
    try {
      setLoading(true);
      const data = await getData(API_URL + '/api/v1/report/pnl/');
      setPnlValue(data);
      setPnlDate(data.date);
      setAllYears(data.years);
      setPnlDataValue(data.data);
      setStickyRefs(
        Array(Object.entries(data.data).length)
          .fill()
          .map(() => React.createRef())
      );
      setLoading(false);
    } catch (error) {}
  };

  React.useEffect(() => {
    getPnlData();
  }, [location]);

  const [dataForTable, setDataForTable] = useState({});
  const [dateForTable, setDateForTable] = useState({});
  const [selectMonthCustom, setSelectMonthCustom] = useState([]);

  const monthList = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  function filterByIndices(data, indices) {
    if (Array.isArray(data)) {
      return indices
        .filter((index) => index < data.length)
        .map((index) => data[index]);
    } else if (typeof data === 'object' && data !== null) {
      const result = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          result[key] = filterByIndices(data[key], indices);
        }
      }
      return result;
    } else {
      return data;
    }
  }

  function keepLastValue(data) {
    if (Array.isArray(data)) {
      return data.length > 0 ? [data[data.length - 1]] : [];
    } else if (typeof data === 'object' && data !== null) {
      const result = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          result[key] = keepLastValue(data[key]);
        }
      }
      return result;
    } else {
      return data;
    }
  }

  const [open, setOpen] = React.useState(false);
  const [bankData, setBankData] = React.useState(0);
  const [cashboxData, setCashboxData] = React.useState(0);
  useEffect(() => {
    const urlBank = `${API_URL}/api/v1/bank/incomplete/bank/operations/?organisation_id=1`;
    const urlBankData = getData(urlBank);

    const urlCashbox = `${API_URL}/api/v1/cashbox/incomplete/operations/`;
    const dataCashbox = getData(urlCashbox);

    (async () => {
      Promise.all([urlBankData, dataCashbox]).then((result) => {
        const [bank, cashbox] = result;
        setBankData(bank.count_incomplete_operation);
        setCashboxData(cashbox?.count_incomplete_operation);
        setOpen(true);
      });
    })();
  }, []);

  // function mergeObjectsRecursive(obj1, obj2) {
  //   const result = { ...obj1 };

  //   for (const key in obj2) {
  //     if (Object.hasOwn(obj2, key)) {
  //       if (
  //         typeof obj2[key] === 'object' &&
  //         obj2[key] !== null &&
  //         !Array.isArray(obj2[key])
  //       ) {
  //         if (result[key] === undefined) {
  //           result[key] = {};
  //         }
  //         result[key] = mergeObjectsRecursive(result[key], obj2[key]);
  //       } else if (Array.isArray(obj2[key])) {
  //         if (result[key] === undefined) {
  //           result[key] = [];
  //         }
  //         result[key] = result[key].concat(obj2[key]);
  //       } else {
  //         if (result[key] === undefined) {
  //           result[key] = obj2[key];
  //         } else if (
  //           typeof result[key] === 'number' &&
  //           typeof obj2[key] === 'number'
  //         ) {
  //           result[key] += obj2[key];
  //         } else {
  //           result[key] = obj2[key];
  //         }
  //       }
  //     }
  //   }

  //   return result;
  // }

  // function mergeObjectsArray(objectsArray) {
  //   if (!Array.isArray(objectsArray)) {
  //     throw new Error('Входные данные должны быть массивом объектов.');
  //   }

  //   let result = {};

  //   for (const obj of objectsArray) {
  //     if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
  //       throw new Error('Все элементы массива должны быть объектами.');
  //     }
  //     result = mergeObjectsRecursive(result, obj);
  //   }

  //   return result;
  // }
  // if (!dateForTable[newMonth]) {
  //   setSelectMonthCustom(newMonth);

  //   let indexList = [];

  //   const date = data.date.filter(([, item], index) => {
  //     if (item.startsWith('нед') || item === newMonth) {
  //       indexList.push(index);
  //       return true;
  //     }
  //   });

  //   setDateForTable((prev) => ({
  //     ...prev,
  //     [newMonth]: date,
  //   }));

  //   setDataForTable((prev) => ({
  //     ...prev,
  //     [newMonth]: filterByIndices(data.data, indexList),
  //     Итоги: keepLastValue(data.data),
  //   }));
  // } else {
  //   setDataForTable((prev) => {
  //     const old = { ...prev };
  //     delete old[newMonth];
  //     return old;
  //   });
  // }

  const getNewData = async (newMonth, newYear) => {
    try {
      setLoading(true);
      const url = `${API_URL}/api/v1/report/pnl/?year=${newYear || year}`;
      const data = await getData(newMonth ? `${url}&month=${newMonth}` : url);

      setPnlDate(data.date);
      setPnlDataValue(data.data);
      setStickyRefs(
        Array(Object.entries(data.data).length)
          .fill()
          .map(() => React.createRef())
      );
      changeMonth(newMonth);
      setLoading(false);
    } catch (error) {
      setRerender(null);
      setLoading(false);
    }
  };

  return (
    <>
      <main className={style.main}>
        <div className={style.details}>
          <div>Отчетный год</div>
          <YearSelect
            allValues={allYears}
            width={'120px'}
            onChange={(year) => {
              changeYear(year);
              getNewData(null, year);
            }}
            value={year}
          />
        </div>
        {pnlDataValue && Object.entries(pnlDataValue).length ? (
          <ReportTable
            dateList={pnlDate}
            dataList={pnlDataValue}
            loading={loading}
            currentMonth={month}
            action={(month, year) => getNewData(month, year)}
          />
        ) : (
          <p style={{ marginTop: '30px' }} className={style.empty}>
            Нет данных
          </p>
        )}
      </main>
    </>
  );
};

export default FinancingReport;
