// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cqFFPTFiwB5YmrkDnLEl {\n  display: flex;\n  align-items: flex-start;\n  position: absolute;\n  z-index: 120;\n  gap: 20px;\n  left: 20px;\n  top: 85px;\n  border-radius: 8px;\n  border: 0.5px solid var(--color-gray-light, #dce7f1);\n  background: var(--color-white, #fff);\n  padding: 10px;\n}\n.cqFFPTFiwB5YmrkDnLEl .C1vEyqaRiBic79bqn1SW {\n  height: 480px;\n  overflow-y: auto;\n}\n\n.iHviUAnltCXP7lt5eu5n {\n  width: 303px;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 10px;\n  margin-top: 30px;\n}\n.iHviUAnltCXP7lt5eu5n button {\n  padding: 13px;\n  width: 90px;\n  border: 0.5px solid var(--color-gray-light, #dce7f1);\n}\n.iHviUAnltCXP7lt5eu5n button.dLqgFWGkilBhVX3Rl1k_ {\n  background: #1386ff;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/shared/ui/components/MonthSelector/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;EACA,kBAAA;EACA,oDAAA;EACA,oCAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,gBAAA;AAEJ;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;EACA,oDAAA;AACJ;AACI;EACE,mBAAA;EACA,WAAA;AACN","sourcesContent":[".calendarBlock {\n  display: flex;\n  align-items: flex-start;\n  position: absolute;\n  z-index: 120;\n  gap: 20px;\n  left: 20px;\n  top: 85px;\n  border-radius: 8px;\n  border: 0.5px solid var(--color-gray-light, #dce7f1);\n  background: var(--color-white, #fff);\n  padding: 10px;\n  .calendarWrap {\n    height: 480px;\n    overflow-y: auto;\n  }\n}\n\n.months {\n  width: 303px;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 10px;\n  margin-top: 30px;\n\n  button {\n    padding: 13px;\n    width: 90px;\n    border: 0.5px solid var(--color-gray-light, #dce7f1);\n\n    &.active {\n      background: #1386ff;\n      color: #fff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarBlock": "cqFFPTFiwB5YmrkDnLEl",
	"calendarWrap": "C1vEyqaRiBic79bqn1SW",
	"months": "iHviUAnltCXP7lt5eu5n",
	"active": "dLqgFWGkilBhVX3Rl1k_"
};
export default ___CSS_LOADER_EXPORT___;
