import React, { useRef } from 'react';
import style from '../../Purchase.module.scss'
import Filter from './filter/index.jsx';
import ListTable from './table/index.jsx';

const List = ({ title, newItemLink, filters, columns, editColumns, data, setSortingBy }) => {

    const filterRef = useRef(null)

    return (
        <>
            <h1 className={style.title}>{title}</h1>
            <main className={style.main}>

                <Filter
                    filters={filters}
                    newItemLink={newItemLink}
                    columns={columns}
                    editColumns={editColumns}
                    filterRef={filterRef}
                />

                {data ?

                    Object.values(columns).every(column => column.isShow === false) ?

                        <div className={style.empty}><span>нет данных</span></div>

                        :

                        <ListTable
                            data={data}
                            columns={columns}
                            filterRef={filterRef}
                            setSortingBy={setSortingBy}
                        />

                    :

                    <div className={style.empty}><span>нет данных</span></div>

                }

            </main>
        </>
    )

}

export default List