import React, { useState, useEffect } from "react";
import Item from "../../view/item/index.jsx";
import { API_URL, postData, getData, putDataNew } from "../../../../static/js/request.js";
import { useNavigate, useParams } from 'react-router-dom';

const OrderItem = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [orderParams, setOrderParams] = useState({
        id: '',
        user: '',
        itemType:"purchase",
        formTitle: 'Заказ поставщику',
        dialogMessage: 'Сохранить заказ и перейти к созданию приёмки на основе текущего заказа',
        actionDate: '',
        status: '',
        columnWidth: '356px',
        columns: {
            warehouse: {
                label: 'Склад',
                alias: 'warehouse',
                placeholder: 'Выберите склад',
                value: '',
                options: [
                    { name: 'Склад 1', id: 3 },
                    { name: 'Склад 2', id: 70 }
                ]
            },
            organisation: {
                label: 'Организация',
                alias: 'organisation',
                placeholder: 'Выберите организацию',
                value: '',
                required: true,
                options: []
            },
            counterparty: {
                label: 'Конрагент',
                alias: 'counterparty',
                placeholder: 'Выберите контрагента',
                value: '',
                required: true,
                options: [
                    { name: 'Конрагент 1', id: 3 },
                    { name: 'Конрагент 2', id: 4 }
                ]
            }

        },
        comment: '',
        products: [],
        completed: false

    })

    const itemAction = async () => {

        const requestData = {
            id: orderParams.id || 0,
            user: orderParams.user || 0,
            organisation: orderParams.columns.organisation.value,
            status: orderParams.status,
            counterparty: orderParams.columns.counterparty.value,
            purchase_date: orderParams.actionDate,
            comment: orderParams.comment,
            products: orderParams.products
        }

        if(id){

            await putDataNew(API_URL + '/api/v1/purchase/detail/', requestData).then(resp => {
                console.log(resp)
            })

        }

        else{

            await postData(API_URL + '/api/v1/purchase/list/create/', requestData).then(resp => {
                console.log(resp)
            })

        }

        

    }

    useEffect(() => {

        if (id) {
            getData(API_URL + '/api/v1/purchase/detail/?id=' + id)
                .then((data) => {                   

                    setOrderParams(prev => ({
                        ...prev,
                        comment:data[0]['comment'],
                        id: data[0]['id'],
                        user: data[0]['user'],
                        status: data[0]['status'],
                        actionDate: data[0]['purchase_date'],
                        columns: {
                            ...prev.columns,
                            organisation:{
                                ...prev.columns.organisation,
                                value: data[0]['organisation']['id']
                            },
                            counterparty:{
                                ...prev.columns.counterparty,
                                value: data[0]['counterparty']['id']
                            }
                        },
                        //products: data[0]['products']
                        

                    }))
                });
        }

        else {
            getData(API_URL + '/api/v1/account/').then((data) => {
                setOrderParams(prev => ({
                    ...prev,
                    user: data['id']
                }))
            });
        }

    }, [])

    return (

        <Item
            title={id ? "Изменение заказа" : "Создание заказа"}
            itemParams={orderParams}
            setItemParams={(item) => setOrderParams(item)}
            itemAction={() => itemAction()}
        />

    )

}

export default OrderItem