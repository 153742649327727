import React, { useEffect, useRef, useState } from 'react';
import AccountMenu from '../AccountMenu.jsx';
import styles from './styles.module.scss';
import { NewCustomInput } from '../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import { API_URL, getData, postData, postDataFile, putData } from '../../../../static/js/request.js';
import { observer } from 'mobx-react-lite';
import { useUserStore } from '../../../../store/Login/useUserStore.js';
import YearSelect from '../../../Balance/YearSelect/index.jsx';
import { createNotification } from '../../../../static/js/commonFunc.js';
import { useQuery } from '@tanstack/react-query';


export const InputWrap = (props) => {
    const { title, select } = props;

    return (
        <div style={{ width: props.width }} className={styles.inputWrap}>
            <p className={styles.inputTitle}>{title}</p>
            {select ? <YearSelect {...props} /> : <NewCustomInput {...props} />}
        </div>
    );
};

const getPhotoRequest = async () => (await getData(API_URL + '/api/v1/account/photo/'));

// const setPhotoRequest = async () => (await postData(API_URL))

const Account = observer(() => {
    const { userInfo } = useUserStore();
    const inputPhoneRef = useRef();

    const [accountInfo, setAccountInfo] = useState({
        id: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone: '',
        tg_username: '',
    });

    const [defaultInfo, setDefaultInfo] = useState({});

    const [saveModal, setSaveModal] = useState(false);

    const changeInfo = (field, newValue) => {
        const info = accountInfo;

        info[field] = newValue;

        setAccountInfo({ ...info });

        if (JSON.stringify(accountInfo) !== JSON.stringify(defaultInfo)) {
            setSaveModal(true);
        } else {
            setSaveModal(false);
        }
    };

    useEffect(() => {
        if (userInfo && inputPhoneRef.current) {
            setAccountInfo({
                id: userInfo.id || '',
                first_name: userInfo.first_name || '',
                last_name: userInfo.last_name || '',
                middle_name: userInfo.middle_name || '',
                email: userInfo.email || '',
                phone: onPhoneLoad(userInfo.phone) || '',
                tg_username: userInfo.tg_username || '',
            });
            setDefaultInfo({
                id: userInfo.id || '',
                first_name: userInfo.first_name || '',
                last_name: userInfo.last_name || '',
                middle_name: userInfo.middle_name || '',
                email: userInfo.email || '',
                phone: userInfo.phone || '',
                tg_username: userInfo.tg_username || '',
            });
        }
    }, [userInfo, inputPhoneRef]);

    const resetChanges = () => {
        setAccountInfo(defaultInfo);
        setSaveModal(false);
    };

    const saveChanges = async () => {
        try {
            let phone = accountInfo.phone
                .replace(' ', '')
                .replace(' ', '')
                .replace('+', '')
                .replace('(', '')
                .replace(')', '')
                .replace('-', '')
                .replace('-', '');

            if (phone[0] === '8') {
                phone = '7' + phone.substring(1);
            }

            const formData = new FormData();

            Object.entries({
                ...accountInfo,
                tg_username: accountInfo.tg_username || null,
                phone: phone || null,
            }).forEach(([key, value]) => {
                if (value) {
                    formData.append(
                        key,
                        value
                    );
                }

            });


            const response = await putData(
                API_URL + '/api/v1/account/',
                formData,
                true
            );

            if (userPhoto) {
                const photoFormData = new FormData();
                photoFormData.append('photo', userPhoto);
                await postDataFile(API_URL + '/api/v1/account/photo/', photoFormData);
            }

            createNotification(response);

            setSaveModal(false);
            setDefaultInfo({ ...accountInfo, phone: onPhoneLoad(phone) });
            setAccountInfo({ ...accountInfo, phone: onPhoneLoad(phone) });
        } catch (error) {
            console.log(error);
        }
    };

    var getInputNumbersValue = function (input) {
        return input.value.replace(/\D/g, '');
    };

    const [userPhoto, setUserPhoto] = useState(null);

    useQuery({
        queryKey: ['photo'],
        queryFn: getPhotoRequest,
        onSuccess: (data) => {
            setUserPhoto(data.photo);
        },
        refetchOnWindowFocus: false,
    });


    const onPhoneLoad = function (value) {
        var x = `${value}`.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{5})/);
        if (x) {
            return (
                '+' +
                x[1][0] +
                ' (' +
                x[1].substring(1, 3) +
                x[2].substring(0, 1) +
                ') ' +
                x[2].substring(1) +
                x[3][0] +
                '-' +
                x[3][1] +
                x[3][2] +
                '-' +
                x[3][3] +
                x[3][4]
            );
        }

    };

    var onPhonePaste = function (e, value) {
        var input = e.target,
            inputNumbersValue = getInputNumbersValue(input);
        var pasted = e.clipboardData || window.clipboardData;
        if (pasted) {
            var pastedText = value || pasted.getData('Text');
            if (/\D/g.test(pastedText)) {
                changeInfo('phone', inputNumbersValue);
                return;
            }
        }
    };

    var onPhoneInput = function (e) {
        var input = e.target,
            inputNumbersValue = getInputNumbersValue(input),
            selectionStart = input.selectionStart,
            formattedInputValue = '';

        if (!inputNumbersValue) {
            return changeInfo('phone', '');
        }

        if (input.value.length != selectionStart) {
            if (e.data && /\D/g.test(e.data)) {
                changeInfo('phone', inputNumbersValue);
            }
            return;
        }

        if (['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] == '9')
                inputNumbersValue = '7' + inputNumbersValue;
            var firstSymbols = inputNumbersValue[0] == '8' ? '8' : '+7';
            formattedInputValue = input.value = firstSymbols + ' ';
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
            }
        } else {
            formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
        }
        changeInfo('phone', formattedInputValue);
    };
    var onPhoneKeyDown = function (e) {
        var inputValue = e.target.value.replace(/\D/g, '');
        if (e.keyCode == 8 && inputValue.length == 1) {
            changeInfo('phone', '');
        }
    };

    return (
        <div className={styles.wrap}>
            <AccountMenu />
            <div className={styles.content}>
                <h2 className={styles.title}>Учетная запись</h2>
                <div className={styles.editBlock}>
                    <div className={styles.image}>
                        {userPhoto ? (
                            <>
                                <img
                                    src={
                                        typeof userPhoto === 'string' ?
                                            userPhoto :
                                            URL.createObjectURL(userPhoto)
                                    }
                                    alt=""
                                    className={styles.img}
                                />
                                <input
                                    type="file"
                                    id="fileLoad"
                                    onChange={(e) => {
                                        setUserPhoto(e.target.files[0]);
                                        setSaveModal(true);
                                    }}
                                    style={{
                                        position: 'absolute',
                                        opacity: 0,
                                        visibility: 'hidden',
                                    }}
                                />
                                <label htmlFor="fileLoad" className={styles.loadImage}>
                                    Изменить фотографию
                                </label>
                            </>

                        ) : (
                            <>
                                <div className={styles.noImage}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="100"
                                        height="100"
                                        viewBox="0 0 100 100"
                                        fill="none"
                                    >
                                        <path
                                            d="M83.3337 87.5C83.3337 72.9875 68.3938 61.25 50.0003 61.25C31.6068 61.25 16.667 72.9875 16.667 87.5M50.0003 50C55.1462 50 60.0812 48.0246 63.7199 44.5083C67.3586 40.9919 69.4027 36.2228 69.4027 31.25C69.4027 26.2772 67.3586 21.5081 63.7199 17.9917C60.0812 14.4754 55.1462 12.5 50.0003 12.5C44.8545 12.5 39.9194 14.4754 36.2808 17.9917C32.6421 21.5081 30.5979 26.2772 30.5979 31.25C30.5979 36.2228 32.6421 40.9919 36.2808 44.5083C39.9194 48.0246 44.8545 50 50.0003 50Z"
                                            stroke="white"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <input
                                    type="file"
                                    id="fileLoad"
                                    onChange={(e) => {
                                        setUserPhoto(e.target.files[0]);
                                        setSaveModal(true);
                                        console.log(e.target.files[0]);
                                    }}
                                    style={{
                                        position: 'absolute',
                                        opacity: 0,
                                        visibility: 'hidden',
                                    }}
                                />
                                <label htmlFor="fileLoad" className={styles.loadImage}>
                                    Добавить фотографию
                                </label>
                            </>
                        )}
                    </div>
                    <div className={styles.info}>
                        <div className={styles.inputs}>
                            <InputWrap
                                title={
                                    <>
                                        Фамилия<span>*</span>
                                    </>
                                }
                                value={accountInfo.last_name ?? ''}
                                onChange={(value) => changeInfo('last_name', value)}
                                width="100%"
                                placeholder={'Введите фамилию'}
                            />
                            <InputWrap
                                title={'Электронная почта'}
                                value={accountInfo.email ?? ''}
                                onChange={(value) => changeInfo('email', value)}
                                width="100%"
                                placeholder={'Введите почту'}
                                type="email"
                            />
                        </div>
                        <div className={styles.inputs}>
                            <InputWrap
                                title={
                                    <>
                                        Имя<span>*</span>
                                    </>
                                }
                                value={accountInfo.first_name ?? ''}
                                onChange={(value) => changeInfo('first_name', value)}
                                placeholder={'Введите имя'}
                                width="100%"
                            />
                            <InputWrap
                                title={'Телефон'}
                                onChange={(e) => onPhoneInput(e)}
                                value={accountInfo.phone ?? ''}
                                width="100%"
                                placeholder={'Введите телефон'}
                                numeric
                                onPaste={onPhonePaste}
                                onKeyDown={onPhoneKeyDown}
                                forwardRef={inputPhoneRef}
                                returnEvent
                            />
                        </div>
                        <div className={styles.inputs}>
                            <InputWrap
                                title={'Отчество'}
                                placeholder={'Введите отчество'}
                                value={accountInfo.middle_name ?? ''}
                                onChange={(value) => changeInfo('middle_name', value)}
                                width="100%"
                            />
                            <InputWrap
                                title={'Telegram'}
                                placeholder={'@'}
                                value={((accountInfo.tg_username !== null && accountInfo.tg_username !== 'null') && accountInfo) ? accountInfo.tg_username : ''}
                                onChange={(value) => changeInfo('tg_username', value)}
                                width="100%"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {saveModal && (
                <div className={styles.saveModal}>
                    <p className={styles.modalTitle}>Сохранение изменений</p>
                    <div className={styles.buttons}>
                        <button onClick={resetChanges} className={styles.closeSaveBtn}>
                            Отмена
                        </button>
                        <button onClick={saveChanges} className={styles.saveBtn}>
                            Сохранить
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
});

export default Account;
