import React, { useState } from 'react';
import styles from './styles.module.scss';

function NoticeRow({ category }) {
  const [open, setOpen] = React.useState(false);

  const [selectNotice, setSelectNotice] = useState([]);

  const toggleNoticesToSelect = (notice) => {
    if (selectNotice.includes(notice)) {
      setSelectNotice(selectNotice.filter((item) => item !== notice));
    } else {
      setSelectNotice([...selectNotice, notice]);
    }
  };

  return (
    <div className={styles.category}>
      <button onClick={() => setOpen(!open)} className={styles.topRow}>
        <p className={styles.categoryName}>{category.name}</p>
        <span className={styles.devider} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.91615 10.4031L12.8099 6.19523C13.0634 5.92153 13.0634 5.47898 12.8099 5.20528C12.5567 4.93157 12.1471 4.93157 11.8939 5.20528L7.99994 9.41315L4.10614 5.20528C3.85274 4.93157 3.44333 4.93157 3.19005 5.20528C2.93665 5.47898 2.93665 5.92153 3.19005 6.19523L7.08385 10.4031C7.08385 10.4031 7.59251 11 7.99994 11C8.40737 11 8.91615 10.4031 8.91615 10.4031Z"
            fill="#35373A"
          />
        </svg>
      </button>
      {open && (
        <div className={styles.content}>
          {category.notices.map((notice) => (
            <div className={styles.notice} key={notice}>
              <button
                className={`${styles.checkbox} ${
                  selectNotice.includes(notice) ? styles.active : ''
                }`}
                onClick={() => toggleNoticesToSelect(notice)}
              >
                {selectNotice.includes(notice) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                  >
                    <path
                      d="M4.33268 9.58301L0.166016 5.44968L1.49102 4.16634L4.33268 6.95801L10.5077 0.833008L11.8327 2.14967L4.33268 9.58301Z"
                      fill="white"
                    ></path>
                  </svg>
                )}
              </button>
              <p className={styles.noticeName}>{notice}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default NoticeRow;
