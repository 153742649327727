import React, { useState, useEffect } from "react";
import Item from "../../view/item/index.jsx";
import { API_URL, postData, getData, putDataNew } from "../../../../static/js/request.js";
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

const ShipmentItem = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [shipmentParams, setShipmentParams] = useState({
        id: '',
        user: '',
        itemType:"shipment",
        formTitle: 'Отгрузка',
        dialogMessage: 'Сохранить отгрузку',
        actionDate: '',
        status: '',
        columnWidth: '380px',
        columns: {
            organisation: {
                label: 'Организация',
                alias: 'organisation',
                placeholder: 'Выберите организацию',
                value: '',
                required: true,
                options: []
            },
            counterparty: {
                label: 'Конрагент',
                alias: 'counterparty',
                placeholder: 'Выберите контрагента',
                value: '',
                required: true,
                options: [
                    { name: 'Конрагент 1', id: 3 },
                    { name: 'Конрагент 2', id: 4 }
                ]
            },
            transport_invoice_number: {
                label: '№ ТН',
                alias: 'transport_invoice_number',
                placeholder: '№',
                value: '',
                required: true,
                isInput: true
            },
            shipping_method: {
                label: 'Способ доставки',
                alias: 'shipping_method',
                placeholder: 'Доставки',
                value: '',
                required: false,
                options: [
                    { name: 'Самовывоз', id: 'Самовывоз' },
                    { name: 'Транспортная компания', id: 'Транспортная компания' },
                    { name: 'Почтовый голубь', id: 'Почтовый голубь' },
                    { name: 'Катапульта', id: 'Катапульта' }
                ]
            },
            sender_warehouse: {
                label: 'Склад отправ.',
                alias: 'sender_warehouse',
                placeholder: 'Отправление',
                value: '',
                required: true,
                options: [
                    { name: 'Склад 1', id: 3 },
                    { name: 'Склад 2', id: 4 }
                ]
            },
            destination_warehouse: {
                label: 'Склад получ.',
                alias: 'destination_warehouse',
                placeholder: 'Получение',
                value: '',
                options: [
                    { name: 'Склад 1', id: 5 },
                    { name: 'Склад 2', id: 6 }
                ]
            },
            destination: {
                label: 'Адрес доставки',
                alias: 'destination',
                placeholder: 'Адрес',
                value: '',
                required: false,
                isInput: true
            },


        },
        comment: '',
        products: [],
        completed: false

    })

    const itemAction = async () => {

        const requestData = {
            id: shipmentParams.id || 0,
            user: shipmentParams.user_id || 0,
            sender_warehouse: shipmentParams.columns.sender_warehouse.value,
            destination_warehouse: shipmentParams.columns.destination_warehouse.value,
            organisation: shipmentParams.columns.organisation.value,
            transport_invoice_number: shipmentParams.columns.transport_invoice_number.value,
            status: shipmentParams.status,
            shipping_method: shipmentParams.columns.shipping_method.value,
            counterparty: shipmentParams.columns.counterparty.value,
            comment: shipmentParams.comment,
            products: shipmentParams.products,
            delivery_date: dayjs(shipmentParams.actionDate).format('YYYY-MM-DD')
        }

        if (id) {

            await putDataNew(API_URL + '/api/v1/purchase/shipment/', requestData)

        }

        else {

            await postData(API_URL + '/api/v1/purchase/shipment/', requestData)

        }

    }

    useEffect(() => {

        if (id) {
            getData(API_URL + `/api/v1/purchase/shipment/${id}/`)
                .then((data) => {

                    setShipmentParams(prev => ({
                        ...prev,
                        comment: data[0]['comment'],
                        id: data[0]['id'],
                        user: data[0]['user'],
                        status: data[0]['status'],
                        actionDate: data[0]['delivery_date'],
                        columns: {
                            ...prev.columns,
                            organisation: {
                                ...prev.columns.organisation,
                                value: data[0]['organisation']['id']
                            },
                            counterparty: {
                                ...prev.columns.counterparty,
                                value: data[0]['counterparty']['id']
                            },
                            sender_warehouse: {
                                ...prev.columns.sender_warehouse,
                                value: data[0]['sender_warehouse']['id']
                            },
                            destination_warehouse: {
                                ...prev.columns.destination_warehouse,
                                value: data[0]['sender_warehouse']['id']
                            },
                            transport_invoice_number: {
                                ...prev.columns.transport_invoice_number,
                                value: data[0]['transport_invoice_number']

                            },
                            shipping_method: {
                                ...prev.columns.shipping_method,
                                value: data[0]['shipping_method']['id']

                            },
                            destination: {
                                ...prev.columns.destination,
                                value: data[0]['destination']

                            }

                        },
                        //products: data[0]['products']


                    }))
                });
        }

        else {
            getData(API_URL + '/api/v1/account/').then((data) => {
                setShipmentParams(prev => ({
                    ...prev,
                    user: data['id']
                }))
            });
        }

    }, [])

    return (

        <Item
            title={id ? "Изменение отгрузки" : "Создание отгрузки"}
            itemParams={shipmentParams}
            setItemParams={(item) => setShipmentParams(item)}
            itemAction={() => itemAction()}
        />

    )

}

export default ShipmentItem