import React, {useState} from 'react';
import mainStyle from '../../../static/css/mainStyle.module.scss';
import style from './Login.module.scss';
import AccountStyle from '../Account.module.scss';
import {postData, API_URL, postDataLogin} from '../../../static/js/request';
import {Store} from 'react-notifications-component';
import {observer} from 'mobx-react-lite';
import {UserStore} from '../../../store/Login/UserStore';


const Login = observer(() => {
    const [state] = useState(() => new UserStore());
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const token = localStorage.getItem('token');

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }
    function handleNameChange(event) {
        setName(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            username: name,
            password: password,
        };

        postDataLogin(API_URL + '/auth/token/login', data)
            .then(response => response.json())
            .then(data => {
                if (!data['auth_token']) {
                    Store.addNotification({
                        title: 'Авторизация',
                        message: data.message,
                        type: 'danger',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                        }
                    }); 
                    return;
                }

                localStorage.setItem('token', data['auth_token']);

                location.href = '/';
            });

    };

    const handleOut = () => {
        state.handleOut();
    };

    return (
        <main className={mainStyle.main}>
            {token ? (
                <div>
                    <form className={AccountStyle.mainForm} onSubmit={handleOut}>
                        <div className={AccountStyle.mainFormTitle}>
                            Вы авторизированы
                        </div>
                        <div className={AccountStyle.inputContainer}></div>
                        <button className={AccountStyle.AccountBtn}>Выйти</button>
                    </form>
                </div>
            ) : (
                <form className={AccountStyle.mainForm} onSubmit={handleSubmit}>
                    <div className={AccountStyle.mainFormTitle}>
                        Вход
                    </div>
                    <div className={AccountStyle.inputContainer}>
                        <input type="text" name='username'
                            onChange={handleNameChange}
                            className={AccountStyle.input} />

                        <input type="password" name='password'
                            onChange={handlePasswordChange}
                            className={AccountStyle.input}
                        />
                    </div>
                    <button className={AccountStyle.AccountBtn}>Вход</button>
                    <div className={style.loginLinks}>
                        <a href="/password-reset/">Я забыл(a) свой пароль</a>
                        <a href="/signup/">Регистрация</a>
                    </div>
                </form>
            )}

        </main>
    );
});

export default Login;