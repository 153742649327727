import React from 'react';
import {
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import s from './LineGraph.module.scss';
import { format, parseISO, isValid } from 'date-fns';
import { ru } from 'date-fns/locale';
import { formatNumber2 } from '../../../static/js/commonFunc';

const CustomTooltip = ({ active, payload, purchase_price }) => {
  if (active && payload && payload.length) {
    const currentData = payload[0].payload;
    const date = parseISO(currentData.date);
    const formattedDate = isValid(date)
      ? format(date, 'd MMMM yyyy', { locale: ru })
      : 'Недействительная дата';

    return (
      <div className={s.custom_tooltip}>
        <p className={s.label}>{`${formattedDate}`}</p>
        <div className={s.desc}>
          <div>
            <span>Цена:</span>
            <p>{formatNumber2(currentData.value)} ₽</p>
          </div>
          <div>
            <span>Изменения:</span>
            <p className={s.percent}>
              {currentData.percent[0] === '-' ? '' : '+'}
              {currentData.percent / 100}%
            </p>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const getMax = (arr) => {
  return Math.max.apply(null, arr);
};

const LineGraph = ({ data }) => {
  let tempArr = data.map((item) => {
    const date = parseISO(item.date);
    const isValidDate = isValid(date);
    const formattedDate = isValidDate
      ? format(date, 'yyyy-MM-dd', { locale: ru })
      : 'Недействительная дата';
    return { ...item, date: formattedDate };
  });

  if (tempArr.length === 1) {
    tempArr = [...tempArr, ...tempArr];
  }

  const formatData = [...tempArr];

  return (
    <section className={s.section}>
      <ResponsiveContainer width={'100%'} height={170}>
        <LineChart data={formatData}>
          <XAxis
            dataKey="date"
            stroke="#8496AF"
            tickFormatter={(tickItem) => {
              const date = parseISO(tickItem);
              return isValid(date)
                ? format(date, 'dd.MM', { locale: ru })
                : tickItem;
            }}
          />
          <YAxis
            hide={true}
            domain={[0, getMax(formatData.map((temp) => temp.value)) + 1000]}
            allowDataOverflow={true}
          />
          <Tooltip content={<CustomTooltip data={formatData} />} />
          <Line
            type="step"
            strokeWidth={3}
            dataKey="value"
            stroke="#429EFF"
            activeDot={{ r: 9 }}
            dot={{ fill: '#429EFF', stroke: '#ffffff', strokeWidth: 0.5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </section>
  );
};

export default LineGraph;
