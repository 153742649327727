// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H4aa3QPaZGGPq13a7JEz {\n  margin-top: 1px;\n  position: relative;\n  z-index: 999;\n}\n.k7OOzsK9lLxWgT37Atpb {\n  box-sizing: border-box;\n  background: #FFFFFF;\n  border: 1px solid #DCE7F1;\n  border-radius: 8px;\n  color: rgb(53, 55, 58);\n  padding: 14px 20px;\n}\n.k7OOzsK9lLxWgT37Atpb::placeholder {\n  color: rgb(132, 150, 175);\n}\n.k7OOzsK9lLxWgT37Atpb:focus {\n  outline: 1px solid rgb(66, 158, 255);\n}", "",{"version":3,"sources":["webpack://./src/fsd/shared/ui/temp_style/input.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,YAAA;AACJ;AACI;EACI,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;AACR;AACQ;EACI,yBAAA;AACZ;AAEQ;EACI,oCAAA;AAAZ","sourcesContent":[".input {\n    margin-top: 1px;\n    position: relative;\n    z-index: 999;\n\n    &--default {\n        box-sizing: border-box;\n        background: #FFFFFF;\n        border: 1px solid #DCE7F1;\n        border-radius: 8px;\n        color: rgba(53, 55, 58, 1);\n        padding: 14px 20px;\n\n        &::placeholder {\n            color: rgba(132, 150, 175, 1);\n        }\n\n        &:focus {\n            outline: 1px solid rgba(66, 158, 255, 1);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "H4aa3QPaZGGPq13a7JEz",
	"input--default": "k7OOzsK9lLxWgT37Atpb"
};
export default ___CSS_LOADER_EXPORT___;
