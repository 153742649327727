"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ui_1 = require("@/fsd/shared/ui");
function FloatInput({ value, className, onChange, ...props }) {
    const handleChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
            onChange?.(e);
        }
    };
    const formatValue = (e) => {
        const value = e.target.value;
        if (value && !isNaN(Number.parseFloat(value))) {
            const formattedValue = Number.parseFloat(value).toFixed(2);
            e.target.value = formattedValue;
            const syntheticEvent = {
                ...e,
                target: {
                    ...e.target,
                    value: formattedValue,
                },
            };
            onChange?.(syntheticEvent);
        }
    };
    return ((0, jsx_runtime_1.jsx)(ui_1.Input, { type: "text", inputMode: "decimal", value: value, onChange: handleChange, onBlur: formatValue, ...props }));
}
exports.FloatInput = FloatInput;
