import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss'
import { NewCustomInput } from '../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import YearSelect from '../../../Balance/YearSelect/index.jsx';
import { API_URL, postData } from '../../../../static/js/request';
import { createNotification } from '../../../../static/js/commonFunc';

function CreateAnswerModal({ onClose, getData }) {

    const [name, changeName] = useState();

    const [mp, setMp] = useState(null);

    const createAnswer = async () => {
        try {
            const response = await postData(API_URL + `/api/v1/answering_machine/constructors/`, {
                name,
                mp
            })
            createNotification(response);
            onClose();
            getData();
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(mp)
    }, [mp])

    return (
        <div className={styles.modal}>
            <span className={styles.closeBg} onClick={onClose} />
            <div className={styles.content}>
                <p className={styles.title}>Создать конструктор</p>
                <p className={styles.inputTitle}>Название</p>
                <NewCustomInput placeholder={'Введите название'} value={name} onChange={changeName} width={'100%'} />
                <p className={styles.inputTitle}>Маркетплейс</p>
                <YearSelect value={mp} onChange={setMp} allValues={['WB', 'OZON']} width={'100%'} placeholder={'Выберите маркетплейс'} textAlign={'left'}/>
                <div className={styles.buttons}>
                    <button onClick={onClose} className={styles.close}>Отмена</button>
                    <button onClick={createAnswer} disabled={!mp || !name.trim()} className={styles.save}>Создать</button>
                </div>
            </div>
        </div>
    );
}

export default CreateAnswerModal;