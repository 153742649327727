import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';

function YearSelect({ value, onChange, allValues, width, placeholder, disabledData, object, input, textAlign }) {

    const [active, setActive] = React.useState(false);

    const ousideClick = (e) => {

        if (e.target.getAttribute('id') !== 'noClose') {
            setActive(false);
        }
    };

    const [inputValue, setValue] = useState(input ? value || '' : '')

    useEffect(() => {
        if (inputValue === '' && input) {
            onChange(object ? {} : null);
        }
    }, [inputValue]);

    React.useEffect(() => {
        if (active) {
            document.body.addEventListener('click', ousideClick);
        } else {
            document.body.removeEventListener('click', ousideClick);
        }

    }, [active]);

    return (
        <div className={styles.content} style={{ minWidth: width ? width : '' }}>
            {
                input ?
                    <input autoComplete={'off'} placeholder={placeholder} id="noClose" value={inputValue} onChange={(e) => setValue(e.target.value)} onFocus={() => setActive(!active)} type="text" /> :
                    <button style={{ color: !value ? '#8496AF' : '' }} id="noClose" className={`${styles.button} ${active ? styles.active : ''}`} onClick={() => setActive(!active)}>
                        <p style={{ textAlign: textAlign ? textAlign : '' }} id="noClose">{!value ? placeholder : value}</p>
                        <svg id="noClose" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                            <path id="noClose" d="M1 1L5 5L9 1" stroke="#8496AF" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
            }
            <div style={{ width: width ? `calc(${width} - 2px)` : '' }} id="noClose" className={`${styles.selectContent} ${active ? styles.active : ''}`}>
                {
                    // eslint-disable-next-line react/prop-types
                    allValues?.length > 0 ?
                        allValues?.filter(item => object ? (item.article ?? item.name).toString().toLowerCase().includes(inputValue.toLowerCase()) : item.toString().toLowerCase().includes(inputValue.toLowerCase())).map((item, index) => (
                            <button style={{ textAlign: textAlign ? textAlign : '' }} key={index} disabled={disabledData?.includes(item)} id="noClose" className={styles.select} onClick={() => {
                                onChange(item);
                                setValue(input ? (object ? (item.article ?? item.name) : item) : '')
                                setActive(false);
                            }}>{object ? (item.article ?? item.name) : item}</button>
                        )) :
                        <p id="noClose" className={styles.empty}>Ничего не найдено</p>
                }
            </div>
        </div>
    );
}

export default YearSelect;