import {API_URL, getData, postData} from '../../static/js/request';
import { makeAutoObservable, runInAction } from 'mobx';

export class UserStore {
    isLogged = !!localStorage.getItem('token');
    userInfo = null;
    token = null;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
        if (this.isLogged) {
            this.token = localStorage.getItem('token');
            this.getUserInfo();
        }
    }

    async getUserInfo() {
        this.isLoading = true;
        try {
            let userInfo = await getData(API_URL + '/api/v1/account/');
            runInAction(() => {
                this.userInfo = userInfo;
                this.isLoading = false;
            });
            return userInfo;
        } catch (error) {
            this.handleOut();
            console.log(error);
        }
    }

    async handleOut() {
        try {
            await postData(API_URL + '/auth/token/logout', this.userInfo.user);
            localStorage.removeItem('token');
            runInAction(() => {
                this.isLogged = false;
                this.userInfo = null;
            });
        } catch (error) {
            console.log(error);
        }
    }
}
