import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ModeSwitch from './ModeSwitch/index.jsx';
import YearSelect from '../Balance/YearSelect/index.jsx';
import { onMainScroll } from '../Advertisement/index.jsx';
import { formatNumber2 } from '../../static/js/commonFunc';
import CheckboxList from '../Advertisement/CheckboxList/index.jsx';
import SettingsPreset from './SettingsPreset/index.jsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Comparison from './Сomparison/index.jsx';
import AddModal from './AddModal/index.jsx';
import { getData, API_URL } from '../../static/js/request';

function Economy() {
    const modes = [
        { name: 'Продажа', link: 'sell' },
        { name: 'Разработка', link: 'dev' },
        { name: 'Архив', link: 'archive' },
        { name: 'Сравнение', link: 'comparison' },
    ];
    const types = ['Неликвид', 'Ozon', 'WB'];

    const [activeMode, setMode] = useState('Продажа');
    const [activeType, setType] = useState(null);

    const [entity, setEntity] = useState(null);
    const [mp, setMp] = useState([]);

    const changeType = (type) => {
        setType(type === activeType ? null : type);
    };

    const [orgs, setOrgs] = useState([]);

    const [products, setProducts] = useState();

    const getOrgs = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/organisation/');
            setOrgs(response);
        } catch (error) {}
    };

    const getInfo = async () => {
        console.log(mp);
        try {
            const response = await getData(
                API_URL +
          `/api/v1/unit/?type=${
              activeMode === 'Продажа'
                  ? 'work'
                  : activeMode === 'Разработка'
                      ? 'develop'
                      : 'archive'
          }${
              entity
                  ? `&organisation=${
                      orgs.find((value) => value.name === entity).id
                  }`
                  : ''
          }${mp ? `&mp=${mp.join(',')}` : ''}`
            );
            setProducts(response.res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getOrgs();
    }, []);
    
    const [start, setStart] = useState(false);

    useEffect(() => {
        if(start) {
            getInfo();
        } else {
            setStart(true);
        }
    }, [activeMode, entity, mp]);

    const [filterActive, setFilter] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const { type } = params;

        if (type === 'dev') {
            setMode('Разработка');
        } else if (type === 'comparison') {
            setMode('Сравнение');
        } else if (type === 'archive') {
            setMode('Архив');
        } else {
            setMode('Продажа');
        }
    }, [location]);

    return (
        <section className={styles.economy}>
            {addModal && (
                <AddModal getInfo={getInfo} onClose={() => setAddModal(false)} />
            )}
            <div className={styles.top}>
                <h2 className={styles.title}>UNIT экономика</h2>
                <ModeSwitch
                    values={modes.map((temp) => temp.name)}
                    active={activeMode}
                    onChange={(name) =>
                        navigate(
                            `/economy/${modes.find((temp) => temp.name === name).link}`
                        )
                    }
                />
            </div>
            {activeMode === 'Сравнение' ? (
                <Comparison />
            ) : (
                <div className={styles.content}>
                    <div className={styles.contentTop}>
                        <div className={styles.selects}>
                            <YearSelect
                                allValues={orgs.map((value) => value.name)}
                                value={entity}
                                onChange={setEntity}
                                placeholder={'Выберите что-то'}
                                width={'150px'}
                                textAlign={'left'}
                            />
                            <CheckboxList
                                activeList={mp}
                                onChange={setMp}
                                id={'MP'}
                                values={['WB', 'OZON']}
                                title={mp.join(',') || 'Маркетплейсы'}
                            />
                        </div>
                        <div className={styles.contentSettings}>
                            {(activeMode === 'Разработка' || activeMode === 'Архив') && (
                                <button
                                    onClick={() => setAddModal(true)}
                                    className={styles.addBtn}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M3.28957 10.7896H9.21044V16.7104C9.21044 16.9198 9.29362 17.1207 9.44169 17.2687C9.58977 17.4168 9.7906 17.5 10 17.5C10.2094 17.5 10.4102 17.4168 10.5583 17.2687C10.7064 17.1207 10.7896 16.9198 10.7896 16.7104V10.7896H16.7104C16.9198 10.7896 17.1207 10.7064 17.2687 10.5583C17.4168 10.4102 17.5 10.2094 17.5 10C17.5 9.79059 17.4168 9.58976 17.2687 9.44169C17.1207 9.29362 16.9198 9.21043 16.7104 9.21043H10.7896V3.28956C10.7896 3.08016 10.7064 2.87933 10.5583 2.73126C10.4102 2.58319 10.2094 2.5 10 2.5C9.7906 2.5 9.58977 2.58319 9.44169 2.73126C9.29362 2.87933 9.21044 3.08016 9.21044 3.28956V9.21043H3.28957C3.08016 9.21043 2.87933 9.29362 2.73126 9.44169C2.58319 9.58976 2.5 9.79059 2.5 10C2.5 10.2094 2.58319 10.4102 2.73126 10.5583C2.87933 10.7064 3.08016 10.7896 3.28957 10.7896Z"
                                            fill="#429EFF"
                                        />
                                    </svg>
                                    Добавить товар
                                </button>
                            )}
                            <div className={styles.contentType}>
                                {types.map((item) => (
                                    <button
                                        className={`${styles.typeButton} ${
                                            activeType === item ? styles.active : ''
                                        }`}
                                        onClick={() => changeType(item)}
                                    >
                                        {item}
                                    </button>
                                ))}
                            </div>
                            <button
                                onClick={() => setFilter(true)}
                                className={styles.settingBtn}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M20.8954 9.87492L19.4607 9.56303C19.336 9.18107 19.1815 8.80851 18.999 8.44963L19.7933 7.21417C19.9351 6.99343 19.9039 6.70361 19.7185 6.51819L17.4834 4.28312C17.298 4.0977 17.0082 4.06651 16.7875 4.20835L15.552 5.0026C15.1931 4.82017 14.8206 4.66565 14.4386 4.54089L14.1267 3.1062C14.071 2.84985 13.844 2.66699 13.5817 2.66699H10.4209C10.1586 2.66699 9.93158 2.84985 9.8759 3.1062L9.56401 4.54089C9.18205 4.66565 8.80949 4.82017 8.4506 5.0026L7.21515 4.20835C6.99441 4.06651 6.70459 4.0977 6.51917 4.28312L4.2841 6.51819C4.09867 6.70361 4.06748 6.99343 4.20933 7.21417L5.00358 8.44963C4.82115 8.80851 4.66663 9.18107 4.54187 9.56303L3.10718 9.87492C2.85083 9.93075 2.66797 10.1576 2.66797 10.4199V13.5807C2.66797 13.843 2.85083 14.0699 3.10718 14.1257L4.54187 14.4376C4.66663 14.8196 4.82115 15.1921 5.00358 15.551L4.20933 16.7865C4.06748 17.0072 4.09867 17.297 4.2841 17.4825L6.51917 19.7175C6.70459 19.903 6.99441 19.9341 7.21515 19.7923L8.4506 18.998C8.80949 19.1805 9.18205 19.335 9.56401 19.4598L9.8759 20.8945C9.93158 21.1508 10.1586 21.3337 10.4209 21.3337H13.5817C13.844 21.3337 14.071 21.1508 14.1267 20.8945L14.4386 19.4598C14.8206 19.335 15.1931 19.1805 15.552 18.998L16.7875 19.7923C17.0082 19.9341 17.298 19.9031 17.4834 19.7175L19.7185 17.4825C19.9039 17.297 19.9351 17.0072 19.7933 16.7865L18.999 15.551C19.1815 15.1921 19.336 14.8196 19.4607 14.4376L20.8954 14.1257C21.1518 14.0699 21.3346 13.843 21.3346 13.5807V10.4199C21.3346 10.1576 21.1518 9.93075 20.8954 9.87492V9.87492ZM15.3479 12.0003C15.3479 13.8456 13.8466 15.3469 12.0013 15.3469C10.156 15.3469 8.65468 13.8456 8.65468 12.0003C8.65468 10.1551 10.156 8.65371 12.0013 8.65371C13.8466 8.65371 15.3479 10.1551 15.3479 12.0003V12.0003Z"
                                        stroke="#35373A"
                                    />
                                </svg>
                            </button>
                            {filterActive && (
                                <SettingsPreset onClose={() => setFilter(false)} />
                            )}
                        </div>
                    </div>
                    <div className={styles.tableMainSectionTitles} id="scrollableOfMain">
                        {products?.[0]?.addition.map((temp) => (
                            <div
                                className={styles.line}
                                style={{ minWidth: `${910}px`, color: temp.color }}
                            >
                                {temp.name}
                            </div>
                        ))}
                    </div>
                    {products?.length > 0 ? (
                        <div className={styles.table}>
                            <div className={styles.tableTop}>
                                <div className={styles.leftSide}>
                                    <div className={styles.photo}>Фото</div>
                                    <div className={styles.article}>
                                        Артикул
                                        <button className={styles.filterBtn}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                            >
                                                <path
                                                    d="M15.3741 2.01066H5.84164C5.57957 1.23228 4.84334 0.669922 3.97772 0.669922C3.11211 0.669922 2.37588 1.23228 2.11381 2.01066H0.625868C0.280222 2.01066 0 2.29088 0 2.63652C0 2.98217 0.280222 3.26239 0.625868 3.26239H2.11385C2.37592 4.04076 3.11215 4.60312 3.97776 4.60312C4.84338 4.60312 5.57961 4.04076 5.84168 3.26239H15.3741C15.7198 3.26239 16 2.98217 16 2.63652C16 2.29088 15.7197 2.01066 15.3741 2.01066ZM3.97772 3.35139C3.58355 3.35139 3.26286 3.03069 3.26286 2.63652C3.26286 2.24235 3.58355 1.92166 3.97772 1.92166C4.37189 1.92166 4.69259 2.24235 4.69259 2.63652C4.69259 3.03069 4.37189 3.35139 3.97772 3.35139Z"
                                                    fill="#8496AF"
                                                />
                                                <path
                                                    d="M15.3741 7.37394H13.8861C13.624 6.59557 12.8877 6.0332 12.0222 6.0332C11.1566 6.0332 10.4204 6.59557 10.1583 7.37394H0.625868C0.280222 7.37394 0 7.65416 0 7.99981C0 8.34545 0.280222 8.62567 0.625868 8.62567H10.1583C10.4204 9.40405 11.1566 9.96641 12.0222 9.96641C12.8878 9.96641 13.6241 9.40405 13.8861 8.62567H15.3741C15.7198 8.62567 16 8.34545 16 7.99981C16 7.65416 15.7197 7.37394 15.3741 7.37394ZM12.0222 8.71467C11.628 8.71467 11.3073 8.39398 11.3073 7.99981C11.3073 7.60563 11.628 7.28494 12.0222 7.28494C12.4164 7.28494 12.7371 7.60563 12.7371 7.99981C12.7371 8.39398 12.4164 8.71467 12.0222 8.71467Z"
                                                    fill="#8496AF"
                                                />
                                                <path
                                                    d="M15.3741 12.7362H8.52315C8.26108 11.9579 7.52485 11.3955 6.65923 11.3955C5.79361 11.3955 5.05739 11.9579 4.79531 12.7362H0.625868C0.280222 12.7362 0 13.0165 0 13.3621C0 13.7078 0.280222 13.988 0.625868 13.988H4.79531C5.05739 14.7663 5.79361 15.3287 6.65923 15.3287C7.52485 15.3287 8.26108 14.7663 8.52315 13.988H15.3741C15.7198 13.988 16 13.7078 16 13.3621C16 13.0165 15.7197 12.7362 15.3741 12.7362ZM6.65923 14.077C6.26506 14.077 5.94437 13.7563 5.94437 13.3622C5.94437 12.968 6.26506 12.6473 6.65923 12.6473C7.0534 12.6473 7.3741 12.9679 7.3741 13.3621C7.3741 13.7563 7.0534 14.077 6.65923 14.077Z"
                                                    fill="#8496AF"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className={styles.brand}>
                                        бренд
                                        <button className={styles.filterBtn}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                            >
                                                <path
                                                    d="M15.3741 2.01066H5.84164C5.57957 1.23228 4.84334 0.669922 3.97772 0.669922C3.11211 0.669922 2.37588 1.23228 2.11381 2.01066H0.625868C0.280222 2.01066 0 2.29088 0 2.63652C0 2.98217 0.280222 3.26239 0.625868 3.26239H2.11385C2.37592 4.04076 3.11215 4.60312 3.97776 4.60312C4.84338 4.60312 5.57961 4.04076 5.84168 3.26239H15.3741C15.7198 3.26239 16 2.98217 16 2.63652C16 2.29088 15.7197 2.01066 15.3741 2.01066ZM3.97772 3.35139C3.58355 3.35139 3.26286 3.03069 3.26286 2.63652C3.26286 2.24235 3.58355 1.92166 3.97772 1.92166C4.37189 1.92166 4.69259 2.24235 4.69259 2.63652C4.69259 3.03069 4.37189 3.35139 3.97772 3.35139Z"
                                                    fill="#8496AF"
                                                />
                                                <path
                                                    d="M15.3741 7.37394H13.8861C13.624 6.59557 12.8877 6.0332 12.0222 6.0332C11.1566 6.0332 10.4204 6.59557 10.1583 7.37394H0.625868C0.280222 7.37394 0 7.65416 0 7.99981C0 8.34545 0.280222 8.62567 0.625868 8.62567H10.1583C10.4204 9.40405 11.1566 9.96641 12.0222 9.96641C12.8878 9.96641 13.6241 9.40405 13.8861 8.62567H15.3741C15.7198 8.62567 16 8.34545 16 7.99981C16 7.65416 15.7197 7.37394 15.3741 7.37394ZM12.0222 8.71467C11.628 8.71467 11.3073 8.39398 11.3073 7.99981C11.3073 7.60563 11.628 7.28494 12.0222 7.28494C12.4164 7.28494 12.7371 7.60563 12.7371 7.99981C12.7371 8.39398 12.4164 8.71467 12.0222 8.71467Z"
                                                    fill="#8496AF"
                                                />
                                                <path
                                                    d="M15.3741 12.7362H8.52315C8.26108 11.9579 7.52485 11.3955 6.65923 11.3955C5.79361 11.3955 5.05739 11.9579 4.79531 12.7362H0.625868C0.280222 12.7362 0 13.0165 0 13.3621C0 13.7078 0.280222 13.988 0.625868 13.988H4.79531C5.05739 14.7663 5.79361 15.3287 6.65923 15.3287C7.52485 15.3287 8.26108 14.7663 8.52315 13.988H15.3741C15.7198 13.988 16 13.7078 16 13.3621C16 13.0165 15.7197 12.7362 15.3741 12.7362ZM6.65923 14.077C6.26506 14.077 5.94437 13.7563 5.94437 13.3622C5.94437 12.968 6.26506 12.6473 6.65923 12.6473C7.0534 12.6473 7.3741 12.9679 7.3741 13.3621C7.3741 13.7563 7.0534 14.077 6.65923 14.077Z"
                                                    fill="#8496AF"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={styles.mainSide}
                                    id="scrollableOfMain"
                                    onScroll={onMainScroll}
                                >
                                    {products?.[0]?.addition.map((temp) => (
                                        <div
                                            className={styles.specs}
                                            style={{ borderColor: temp.color }}
                                        >
                                            {temp.value.map((col) =>
                                                col.name === 'Наименование' ? (
                                                    <div className={styles.name}>
                                                        Наименование
                                                        <button className={styles.filterBtn}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M15.3741 2.01066H5.84164C5.57957 1.23228 4.84334 0.669922 3.97772 0.669922C3.11211 0.669922 2.37588 1.23228 2.11381 2.01066H0.625868C0.280222 2.01066 0 2.29088 0 2.63652C0 2.98217 0.280222 3.26239 0.625868 3.26239H2.11385C2.37592 4.04076 3.11215 4.60312 3.97776 4.60312C4.84338 4.60312 5.57961 4.04076 5.84168 3.26239H15.3741C15.7198 3.26239 16 2.98217 16 2.63652C16 2.29088 15.7197 2.01066 15.3741 2.01066ZM3.97772 3.35139C3.58355 3.35139 3.26286 3.03069 3.26286 2.63652C3.26286 2.24235 3.58355 1.92166 3.97772 1.92166C4.37189 1.92166 4.69259 2.24235 4.69259 2.63652C4.69259 3.03069 4.37189 3.35139 3.97772 3.35139Z"
                                                                    fill="#8496AF"
                                                                />
                                                                <path
                                                                    d="M15.3741 7.37394H13.8861C13.624 6.59557 12.8877 6.0332 12.0222 6.0332C11.1566 6.0332 10.4204 6.59557 10.1583 7.37394H0.625868C0.280222 7.37394 0 7.65416 0 7.99981C0 8.34545 0.280222 8.62567 0.625868 8.62567H10.1583C10.4204 9.40405 11.1566 9.96641 12.0222 9.96641C12.8878 9.96641 13.6241 9.40405 13.8861 8.62567H15.3741C15.7198 8.62567 16 8.34545 16 7.99981C16 7.65416 15.7197 7.37394 15.3741 7.37394ZM12.0222 8.71467C11.628 8.71467 11.3073 8.39398 11.3073 7.99981C11.3073 7.60563 11.628 7.28494 12.0222 7.28494C12.4164 7.28494 12.7371 7.60563 12.7371 7.99981C12.7371 8.39398 12.4164 8.71467 12.0222 8.71467Z"
                                                                    fill="#8496AF"
                                                                />
                                                                <path
                                                                    d="M15.3741 12.7362H8.52315C8.26108 11.9579 7.52485 11.3955 6.65923 11.3955C5.79361 11.3955 5.05739 11.9579 4.79531 12.7362H0.625868C0.280222 12.7362 0 13.0165 0 13.3621C0 13.7078 0.280222 13.988 0.625868 13.988H4.79531C5.05739 14.7663 5.79361 15.3287 6.65923 15.3287C7.52485 15.3287 8.26108 14.7663 8.52315 13.988H15.3741C15.7198 13.988 16 13.7078 16 13.3621C16 13.0165 15.7197 12.7362 15.3741 12.7362ZM6.65923 14.077C6.26506 14.077 5.94437 13.7563 5.94437 13.3622C5.94437 12.968 6.26506 12.6473 6.65923 12.6473C7.0534 12.6473 7.3741 12.9679 7.3741 13.3621C7.3741 13.7563 7.0534 14.077 6.65923 14.077Z"
                                                                    fill="#8496AF"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className={styles.col}>
                                                        {col.name}
                                                        <button className={styles.filterBtn}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M15.3741 2.01066H5.84164C5.57957 1.23228 4.84334 0.669922 3.97772 0.669922C3.11211 0.669922 2.37588 1.23228 2.11381 2.01066H0.625868C0.280222 2.01066 0 2.29088 0 2.63652C0 2.98217 0.280222 3.26239 0.625868 3.26239H2.11385C2.37592 4.04076 3.11215 4.60312 3.97776 4.60312C4.84338 4.60312 5.57961 4.04076 5.84168 3.26239H15.3741C15.7198 3.26239 16 2.98217 16 2.63652C16 2.29088 15.7197 2.01066 15.3741 2.01066ZM3.97772 3.35139C3.58355 3.35139 3.26286 3.03069 3.26286 2.63652C3.26286 2.24235 3.58355 1.92166 3.97772 1.92166C4.37189 1.92166 4.69259 2.24235 4.69259 2.63652C4.69259 3.03069 4.37189 3.35139 3.97772 3.35139Z"
                                                                    fill="#8496AF"
                                                                />
                                                                <path
                                                                    d="M15.3741 7.37394H13.8861C13.624 6.59557 12.8877 6.0332 12.0222 6.0332C11.1566 6.0332 10.4204 6.59557 10.1583 7.37394H0.625868C0.280222 7.37394 0 7.65416 0 7.99981C0 8.34545 0.280222 8.62567 0.625868 8.62567H10.1583C10.4204 9.40405 11.1566 9.96641 12.0222 9.96641C12.8878 9.96641 13.6241 9.40405 13.8861 8.62567H15.3741C15.7198 8.62567 16 8.34545 16 7.99981C16 7.65416 15.7197 7.37394 15.3741 7.37394ZM12.0222 8.71467C11.628 8.71467 11.3073 8.39398 11.3073 7.99981C11.3073 7.60563 11.628 7.28494 12.0222 7.28494C12.4164 7.28494 12.7371 7.60563 12.7371 7.99981C12.7371 8.39398 12.4164 8.71467 12.0222 8.71467Z"
                                                                    fill="#8496AF"
                                                                />
                                                                <path
                                                                    d="M15.3741 12.7362H8.52315C8.26108 11.9579 7.52485 11.3955 6.65923 11.3955C5.79361 11.3955 5.05739 11.9579 4.79531 12.7362H0.625868C0.280222 12.7362 0 13.0165 0 13.3621C0 13.7078 0.280222 13.988 0.625868 13.988H4.79531C5.05739 14.7663 5.79361 15.3287 6.65923 15.3287C7.52485 15.3287 8.26108 14.7663 8.52315 13.988H15.3741C15.7198 13.988 16 13.7078 16 13.3621C16 13.0165 15.7197 12.7362 15.3741 12.7362ZM6.65923 14.077C6.26506 14.077 5.94437 13.7563 5.94437 13.3622C5.94437 12.968 6.26506 12.6473 6.65923 12.6473C7.0534 12.6473 7.3741 12.9679 7.3741 13.3621C7.3741 13.7563 7.0534 14.077 6.65923 14.077Z"
                                                                    fill="#8496AF"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.rightSide}>
                                    <div className={styles.profit}>
                                        Чистая прибыль
                                        <button className={styles.filterBtn}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                            >
                                                <path
                                                    d="M15.3741 2.01066H5.84164C5.57957 1.23228 4.84334 0.669922 3.97772 0.669922C3.11211 0.669922 2.37588 1.23228 2.11381 2.01066H0.625868C0.280222 2.01066 0 2.29088 0 2.63652C0 2.98217 0.280222 3.26239 0.625868 3.26239H2.11385C2.37592 4.04076 3.11215 4.60312 3.97776 4.60312C4.84338 4.60312 5.57961 4.04076 5.84168 3.26239H15.3741C15.7198 3.26239 16 2.98217 16 2.63652C16 2.29088 15.7197 2.01066 15.3741 2.01066ZM3.97772 3.35139C3.58355 3.35139 3.26286 3.03069 3.26286 2.63652C3.26286 2.24235 3.58355 1.92166 3.97772 1.92166C4.37189 1.92166 4.69259 2.24235 4.69259 2.63652C4.69259 3.03069 4.37189 3.35139 3.97772 3.35139Z"
                                                    fill="#8496AF"
                                                />
                                                <path
                                                    d="M15.3741 7.37394H13.8861C13.624 6.59557 12.8877 6.0332 12.0222 6.0332C11.1566 6.0332 10.4204 6.59557 10.1583 7.37394H0.625868C0.280222 7.37394 0 7.65416 0 7.99981C0 8.34545 0.280222 8.62567 0.625868 8.62567H10.1583C10.4204 9.40405 11.1566 9.96641 12.0222 9.96641C12.8878 9.96641 13.6241 9.40405 13.8861 8.62567H15.3741C15.7198 8.62567 16 8.34545 16 7.99981C16 7.65416 15.7197 7.37394 15.3741 7.37394ZM12.0222 8.71467C11.628 8.71467 11.3073 8.39398 11.3073 7.99981C11.3073 7.60563 11.628 7.28494 12.0222 7.28494C12.4164 7.28494 12.7371 7.60563 12.7371 7.99981C12.7371 8.39398 12.4164 8.71467 12.0222 8.71467Z"
                                                    fill="#8496AF"
                                                />
                                                <path
                                                    d="M15.3741 12.7362H8.52315C8.26108 11.9579 7.52485 11.3955 6.65923 11.3955C5.79361 11.3955 5.05739 11.9579 4.79531 12.7362H0.625868C0.280222 12.7362 0 13.0165 0 13.3621C0 13.7078 0.280222 13.988 0.625868 13.988H4.79531C5.05739 14.7663 5.79361 15.3287 6.65923 15.3287C7.52485 15.3287 8.26108 14.7663 8.52315 13.988H15.3741C15.7198 13.988 16 13.7078 16 13.3621C16 13.0165 15.7197 12.7362 15.3741 12.7362ZM6.65923 14.077C6.26506 14.077 5.94437 13.7563 5.94437 13.3622C5.94437 12.968 6.26506 12.6473 6.65923 12.6473C7.0534 12.6473 7.3741 12.9679 7.3741 13.3621C7.3741 13.7563 7.0534 14.077 6.65923 14.077Z"
                                                    fill="#8496AF"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.tableContent}>
                                {products.map((item) => (
                                    <div className={styles.row}>
                                        <div key={item.id} className={styles.leftSide}>
                                            <div className={styles.photo}>
                                                <img
                                                    src={item.photo_url}
                                                    alt={item.name}
                                                    className={styles.img}
                                                    height={50}
                                                />
                                            </div>
                                            <div className={styles.article}>
                                                <p>{item.article}</p>
                                            </div>
                                            <div className={styles.brand}>
                                                <p>{item.brand}</p>
                                            </div>
                                        </div>
                                        <div
                                            className={styles.mainSide}
                                            id="scrollableOfMain"
                                            onScroll={onMainScroll}
                                        >
                                            {item.addition.map((temp) => (
                                                <div className={styles.specs}>
                                                    {temp.value.map((col, index) => (
                                                        <>
                                                            {col.name === 'Наименование' ? (
                                                                <div className={styles.name}>
                                                                    <p>{col.value}</p>
                                                                </div>
                                                            ) : (
                                                                <div className={styles.col}>
                                                                    <p>{col.value}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.rightSide}>
                                            <div className={styles.profit}>
                                                <p>{item.profit}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={styles.mainScroll}
                                style={{
                                    maxWidth: `${170 * products[0].bought?.length + 970}px`,
                                }}
                                onScroll={onMainScroll}
                                id="scrollableOfMain"
                            >
                                <span
                                    style={{
                                        width: `${170 * products[0].bought?.length + 970}px`,
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className={styles.empty}>Нет товаров в продаже</p>
                    )}
                </div>
            )}
        </section>
    );
}

export default Economy;
