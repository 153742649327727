import React, { createRef, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import BodyTableLine from '../../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../../components/custom/BodyTableLineItem.jsx';
import ArticleStore from '../../../store/Financing/ArticleStore';
import style from '../Financing.module.scss';
import {
    createNotification,
    formatNumber2,
} from '../../../static/js/commonFunc';
import {API_URL, delData, putData, putDataNew} from '../../../static/js/request';
import { confirmAlert } from 'react-confirm-alert';
import NumberInput from '../../../components/custom/NumberInput.jsx';
import AutoCompleteSearch from '../../../store/Financing/AutoComplete';
import { NewCustomInput } from '../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import { Calendar } from 'react-date-range';
import 'react-calendar/dist/Calendar.css';
import { ru } from 'date-fns/locale';
import dayjs from 'dayjs';
import YearSelect from '../../Balance/YearSelect/index.jsx';
import { CashboxStore } from '../../../store/Financing/CashboxStore.js';

const CashboxItemBody = observer(({ state, articles }) => {
    const myRefs = state.fields.map(() => createRef());
    const [searchResults, updateSearchResults] = useState([]);
    const [currentField, setCurrentField] = useState('');
    const [filteredResults, updateFilteredResults] = useState(
        Object.entries(ArticleStore.fields)
    );
    const searchRef = useRef(null);
    const selectRef = useRef(null);

    const [addedClick, setAddedClick] = useState(false);

    let [open, setOpen] = useState(false);

    useEffect(() => {
        const getSearchResults = () => {
            const searchResultsResponse = Object.entries(ArticleStore.fields);
            updateSearchResults(searchResultsResponse);
        };

        getSearchResults();

        const handleClickOutside = (evt) => {
            if (evt.target.getAttribute('id') !== 'noClose') {
                state.fields.some((x) => {
                    x.showBlock = false;
                });
            }
        };
        document.addEventListener('click', (evt) => {
            setAddedClick(true);
            handleClickOutside(evt);
        });

        return document.removeEventListener('click', (evt) => {
            handleClickOutside(evt);
        });
    }, [state.fields]);

    document.addEventListener('click', (evt) => {
        if (
            !evt.target.closest(`.${[style.dropdownItem]}`) &&
      !evt.target.closest('.js__open-menu')
        ) {
            myRefs.forEach((ref) => {
                if (ref.current) {
                    ref.current.classList.remove(style.active);
                }
            });
        }
    });

    const cashboxBodyItemOption = (index) => {
        if (myRefs[index].current.classList.contains(style.active)) {
            myRefs[index].current.classList.remove(style.active);
            return;
        }
        myRefs.forEach((ref) => {
            ref.current.classList.remove(style.active);
        });
        myRefs[index].current.classList.add(style.active);
    };

    const handleClick = (id) => {
        state.fields.some((x) => {
            x.showBlock = x.id === id;
        });
        setCurrentField(id);
    };

    const updateSearch = (value) => {
        updateFilteredResults(
            searchResults.filter((result) => {
                if (!result[1]) {
                    return;
                }
                return result[1].includes(value);
            })
        );
    };

    const handleSelectArticle = (event) => {
        const target = event.target;
        searchRef.current.querySelectorAll('div').forEach((el) => {
            el.classList.remove('active');
        });
        target.classList.add('active');
        selectRef.current.value = target.dataset.artText;
        selectRef.current.dataset.id = target.dataset.art;

        console.log(target.dataset.art);
        console.log(currentField);

        let data;
        state.fields.some((x) => {
            x.showBlock = false;
            if (x.id === currentField) {
                x.article = target.dataset.art;
                data = x;
            }
        });
        console.log(data);
        putDataNew(API_URL + '/api/v1/cashbox/operations/update/' + currentField + '/', {
            ...data,
            article: parseInt(data.article),
            amount: parseFloat(data.amount.replace(',', '.')),
            operation_type: data.operation_type === 'Приход' ? 'income' : 'expense',
        }).then((response) => {
            createNotification(response);
        });
    };

    const SearchResults = ({articles}) => {
        const Message = ({ text }) => (
            <div id={'noClose'} className="message">
                <div id={'noClose'}>{text}</div>
            </div>
        );

        const render = () => {
            return articles.map((article, index) => (
                <div
                    key={index}
                    data-art={article.id}
                    data-art-text={article.article ?? article.name}
                    onClick={() => {
                        handleSelectArticle(event);
                    }}
                >
                    {article.article ?? article.name}
                </div>
            ));
        };

        return render();
    };

    const formatDate = (inputDate) => {
        const datePart = inputDate.split('.');
        return `${datePart[2]}-${datePart[1]}-${datePart[0]}`;
    };

    const [date, changeDate] = useState();
    const [calendarDate, setCalendarDate] = useState();
    const [showCalendar, setShowCalendar] = useState(false);
    const [showChangeModal, setChangeModal] = useState(false);
    const [itemForChange, setItemForChange] = useState(null);

    const [createModal, setCreateModal] = useState(false);
    const [newItem, setNewItem] = useState({
        id: '',
        article: null,
        operation_type: null,
        amount: '',
        comment: '',
    });

    const changeItemClick = (item) => {
        setEditModalIndex();
        changeDate(item.executed);
        setItemForChange(item);
        setChangeModal(true);
    };

    const closeChangeModal = () => {
        setItemForChange(undefined);
        setChangeModal(false);
    };

    const handleInput = (str, oldValue, change) => {
        if (str.length === oldValue.length + 1) {
            if (str.length === 2 || str.length === 5) {
                str += '.';
            }
        }

        if (str.length <= 10) {
            change(str);
        }
    };
    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    const updateOperation = async () => {
        let data = {
            ...itemForChange,
            executed:
        dayjs(date).format('DD.MM.YYYY') === 'Invalid Date'
            ? date
            : dayjs(date).format('DD.MM.YYYY'),
            amount: parseFloat(itemForChange.amount.replace(',', '.')),
        };
        delete data.showBlock;
        delete data.id;
        console.log(data)
        try {
            const response = await putDataNew(
                API_URL + `/api/v1/cashbox/operations/update/${itemForChange.id}/`,
                {
                    ...data,
                }
            );
            createNotification(response);
            state.fillValue();
            closeChangeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const removeOperation = async (id) => {
        try {
            const response = await delData(
                API_URL + `/api/v1/cashbox/operations/delete/${id}/`
            );
            createNotification(response);
            state.fillValue();
            closeChangeModal();
        } catch (error) {}
    };

    const [activeEditModal, setEditModalIndex] = useState();

    const outsideClick = (e) => {
        if (e.target.getAttribute('id') !== 'noNavigate') {
            setEditModalIndex();
        }
    };

    useEffect(() => {
        if (activeEditModal) {
            document.addEventListener('click', outsideClick);
        } else {
            document.removeEventListener('click', outsideClick);
        }
    }, [activeEditModal]);

    const [searchInput, setSearchInput] = useState('');

    return (
        <>
            {showChangeModal && itemForChange && (
                <div className={style.changeModal}>
                    <span className={style.modalBg} onClick={closeChangeModal} />
                    <div className={style.content}>
                        <button onClick={closeChangeModal} className={style.modalClose}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                            >
                                <path
                                    d="M13.2826 12.5001L18.7339 7.04851C19.0887 6.6939 19.0887 6.12056 18.7339 5.76595C18.3793 5.41135 17.806 5.41135 17.4514 5.76595L11.9999 11.2176L6.5486 5.76595C6.19384 5.41135 5.62067 5.41135 5.26607 5.76595C4.91131 6.12056 4.91131 6.6939 5.26607 7.04851L10.7174 12.5001L5.26607 17.9517C4.91131 18.3063 4.91131 18.8797 5.26607 19.2343C5.44279 19.4111 5.67514 19.5 5.90734 19.5C6.13953 19.5 6.37172 19.4111 6.5486 19.2343L11.9999 13.7827L17.4514 19.2343C17.6283 19.4111 17.8605 19.5 18.0927 19.5C18.3249 19.5 18.557 19.4111 18.7339 19.2343C19.0887 18.8797 19.0887 18.3063 18.7339 17.9517L13.2826 12.5001Z"
                                    fill="#1E1E1E"
                                />
                            </svg>
                        </button>
                        <div className={style.modalTop}>
                            <h2 className={style.modalTitle}>Изменение операции</h2>
                            <button
                                className={style.removeItem}
                                onClick={() => removeOperation(itemForChange.id)}
                            >
                                Удалить операцию
                            </button>
                        </div>
                        <div className={style.sides}>
                            <div className={style.side}>
                                <div className={style.inputWrap}>
                                    <p className={style.inputTitle}>Дата</p>
                                    <input
                                        className={style.dateInput}
                                        onFocus={() => setShowCalendar(true)}
                                        type="text"
                                        placeholder={'Введите дату'}
                                        onChange={(e) =>
                                            handleInput(e.target.value, date, changeDate)
                                        }
                                        value={date}
                                    />
                                    {showCalendar && (
                                        <>
                                            <Calendar
                                                className={`${style.calendar} calendar`}
                                                rangeColors={['#3d91ff']}
                                                onChange={(ranges) => {
                                                    changeDate(
                                                        dayjs(new Date(ranges)).format('MM.DD.YYYY')
                                                    );
                                                    setCalendarDate(new Date(ranges));
                                                    setShowCalendar(false);
                                                }}
                                                value={calendarDate}
                                                months={1}
                                                fixedHeight={true}
                                                locale={customLocale}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                direction="horizontal"
                                                showDateDisplay={false}
                                                defaultValue={calendarDate}
                                            />
                                            <span
                                                onClick={() => setShowCalendar(false)}
                                                className={style.calendarCloseBg}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className={style.inputWrap}>
                                    <p className={style.inputTitle}>Сумма</p>
                                    <NewCustomInput
                                        width={'100%'}
                                        numeric
                                        value={itemForChange.amount}
                                        onChange={(text) =>
                                            setItemForChange({ ...itemForChange, amount: text })
                                        }
                                    />
                                </div>
                                <div className={style.inputWrap}>
                                    <p className={style.inputTitle}>Операция</p>
                                    <YearSelect
                                        textAlign={'left'}
                                        width={'100%'}
                                        value={
                                            itemForChange.operation_type === 'income'
                                                ? 'Приход'
                                                : 'Расход'
                                        }
                                        onChange={(item) =>
                                            setItemForChange({
                                                ...itemForChange,
                                                operation_type:
                          item === 'Приход' ? 'income' : 'expense',
                                            })
                                        }
                                        allValues={['Приход', 'Расход']}
                                    />
                                </div>
                            </div>
                            <div className={style.side}>
                                <div className={style.inputWrap}>
                                    <p className={style.inputTitle}>Статья</p>
                                    <YearSelect
                                        object
                                        textAlign={'left'}
                                        width={'100%'}
                                        value={
                                            articles.find(
                                                (article) => article.id === itemForChange.article
                                            )?.article
                                        }
                                        onChange={(item) =>
                                            setItemForChange({ ...itemForChange, article: item.id })
                                        }
                                        allValues={articles}
                                    />
                                </div>
                                <div className={style.inputWrap}>
                                    <p className={style.inputTitle}>Комментарии</p>
                                    <textarea
                                        value={itemForChange.comment}
                                        onChange={(e) =>
                                            setItemForChange({
                                                ...itemForChange,
                                                comment: e.target.value,
                                            })
                                        }
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={style.modalButtons}>
                            <button className={style.saveChanges} onClick={updateOperation}>
                                Сохранить
                            </button>
                            <button className={style.closeChanges} onClick={closeChangeModal}>
                                Отмена
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {state.fields.map((field, index) => (
                <div key={index} className={style.test}>
                    <div ref={myRefs[index]} className={style.CashboxLine}>
                        <BodyTableLine key={index} items="cashbox">
                            <BodyTableLineItem value={field.executed} />

                            <BodyTableLineItem
                                value={
                                    field.operation_type === 'income'
                                        ? formatNumber2(field.amount)
                                        : ''
                                }
                                additionalClass="alightAmount"
                                additionalClass2="cashBoxIncomeContent"
                            />

                            <BodyTableLineItem
                                value={
                                    field.operation_type === 'expense'
                                        ? formatNumber2(field.amount)
                                        : ''
                                }
                                additionalClass="cashBoxOutcome"
                                additionalClass2="alightAmountWidth"
                            />

                            {field.showBlock ? (
                                <BodyTableLineItem
                                    id={'noClose'}
                                    value={
                                        <div
                                            id={'noClose'}
                                            className={style.customAutocomplete}
                                            ref={searchRef}
                                        >
                                            <div
                                                id={'noClose'}
                                                className={style.customAutocompleteContainer}
                                            >
                                                <input
                                                    id={'noClose'}
                                                    className={style.customAutocompleteInput}
                                                    ref={selectRef}
                                                    type="text"
                                                    placeholder="Введите текст..."
                                                    defaultValue={''}
                                                    autoFocus={true}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                    value={searchInput}
                                                    onBlur={() => setSearchInput('')}
                                                />
                                                <div
                                                    id={'noClose'}
                                                    className={style.customAutocompleteResult}
                                                >
                                                    <SearchResults articles={articles.filter(item => (item.article ?? item.name).toLowerCase().includes(searchInput.toLowerCase()))} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    additionalClass={style.customAutocomplete}
                                />
                            ) : (
                                <BodyTableLineItem
                                    id={'noClose'}
                                    value={
                                        ArticleStore.fields[field.article] || 'Выберите статью!'
                                    }
                                    event={() => {
                                        handleClick(field.id);
                                    }}
                                    additionalClass={
                                        ArticleStore.fields[field.article] &&
                    ArticleStore.fields[field.article] !== 'Нет статьи'
                                            ? 'selectArt'
                                            : 'selectArtUnselected'
                                    }
                                />
                            )}

                            <BodyTableLineItem
                                additionalClass="commentFont"
                                value={field.comment}
                            />
                        </BodyTableLine>
                        <span
                            onClick={() => {
                                setEditModalIndex(field.id);
                            }}
                            className={`js__open-menu`}
                            id={'noNavigate'}
                        >
                            ...
                        </span>
                        {field.id === activeEditModal && (
                            <div className={style.moreModal} id={'noNavigate'}>
                                <button
                                    id={'noNavigate'}
                                    className={style.edit}
                                    onClick={() => changeItemClick(field)}
                                >
                                    <svg
                                        id={'noNavigate'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_2216_16800)">
                                            <path
                                                d="M4.5804 15.102L4.58033 15.102L0.781218 15.8056C0.617924 15.8359 0.449955 15.7839 0.332246 15.6662C0.214662 15.5486 0.162561 15.3807 0.19282 15.2172L0.241985 15.2263L0.19282 15.2172L0.896348 11.4178C0.89635 11.4178 0.896353 11.4178 0.896356 11.4178L0.896361 11.4177L0.945525 11.4268L4.5804 15.102ZM4.5804 15.102C4.67792 15.0838 4.77342 15.0346 4.84541 14.9625M4.5804 15.102L4.84541 14.9625M4.84541 14.9625C4.84542 14.9625 4.84542 14.9625 4.84542 14.9625L4.84541 14.9625ZM14.0494 4.32977L4.4884 13.8914L2.10685 11.5097L11.6678 1.94805L11.6325 1.9127L11.6678 1.94805L12.2186 1.39726C12.4872 1.12861 12.9243 1.12858 13.193 1.39726L14.6001 2.80446C14.8694 3.0738 14.8694 3.50961 14.6001 3.77899L14.6355 3.81434L14.6001 3.77899L14.0494 4.32977ZM3.45269 14.2838L1.31946 14.6789L1.7145 12.5455L3.45269 14.2838Z"
                                                fill="#1E1E1E"
                                                stroke="#1E1E1E"
                                                strokeWidth="0.1"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2216_16800">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Изменить
                                </button>
                                <button
                                    onClick={() => state.removeField(field.id, myRefs)}
                                    id={'noNavigate'}
                                    className={style.remove}
                                >
                                    <svg
                                        id={'noNavigate'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="15"
                                        viewBox="0 0 14 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z"
                                            fill="#FF5E5B"
                                        />
                                        <path
                                            d="M5.21702 12.5267C5.35526 12.5263 5.48772 12.4723 5.58547 12.3764C5.68323 12.2806 5.73832 12.1507 5.73873 12.0151V6.13124C5.73873 5.99555 5.68376 5.86542 5.58592 5.76948C5.48808 5.67353 5.35539 5.61963 5.21702 5.61963C5.07866 5.61963 4.94596 5.67353 4.84812 5.76948C4.75028 5.86542 4.69531 5.99555 4.69531 6.13124V12.0151C4.69572 12.1507 4.75082 12.2806 4.84857 12.3764C4.94632 12.4723 5.07878 12.5263 5.21702 12.5267Z"
                                            fill="#FF5E5B"
                                        />
                                        <path
                                            d="M8.52171 12.5267C8.65995 12.5263 8.79242 12.4723 8.89017 12.3764C8.98792 12.2806 9.04301 12.1507 9.04342 12.0151V6.13124C9.04342 5.99555 8.98845 5.86542 8.89061 5.76948C8.79277 5.67353 8.66008 5.61963 8.52171 5.61963C8.38335 5.61963 8.25065 5.67353 8.15281 5.76948C8.05497 5.86542 8 5.99555 8 6.13124V12.0151C8.00041 12.1507 8.05551 12.2806 8.15326 12.3764C8.25101 12.4723 8.38347 12.5263 8.52171 12.5267Z"
                                            fill="#FF5E5B"
                                        />
                                    </svg>
                                    Удалить
                                </button>
                                <button
                                    id={'noNavigate'}
                                    onClick={() => setEditModalIndex()}
                                    className={style.close}
                                >
                                    <svg
                                        id={'noNavigate'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M10.4031 8.91615L6.19523 12.8099C5.92153 13.0634 5.47898 13.0634 5.20528 12.8099C4.93157 12.5567 4.93157 12.1471 5.20528 11.8939L9.41315 7.99994L5.20528 4.10614C4.93157 3.85274 4.93157 3.44333 5.20528 3.19005C5.47898 2.93665 5.92153 2.93665 6.19523 3.19005L10.4031 7.08385C10.4031 7.08385 11 7.59251 11 7.99994C11 8.40737 10.4031 8.91615 10.4031 8.91615Z"
                                            fill="#1E1E1E"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
});

export default CashboxItemBody;
