import { InputWrap } from '../Account/Account.jsx';
import AccountMenu from '../AccountMenu.jsx';
import styles from './styles.module.scss';
import React, { useState } from 'react';
import NoticeRow from './NoticeRow/NoticeRow.jsx';

function Notices() {
    const [mainSettings, setMainSettings] = useState({
        email: false,
        telegram: false,
    });

    const [emailInput, setEmailInput] = useState('');
    const [telegramInput, setTelegramInput] = useState('');

    const checboxClick = (field) => {
        setMainSettings({ ...mainSettings, [field]: !mainSettings[field] });
    };

    const [selectNotices, setSelectNotices] = useState([]);

    const toggleNoticesToSelect = (notice) => {
        if (selectNotices.includes(notice)) {
            setSelectNotices(selectNotices.filter((item) => item !== notice));
        } else {
            setSelectNotices([...selectNotices, notice]);
        }
    };

    const categories = [
        {
            id: 0,
            name: 'Категория Уведомления',
            notices: [
                'Уведомление',
                'Уведомление1',
                'Уведомление2',
                'Уведомление3',
                'Уведомление4',
                'Уведомление5',
                'Уведомление6',
            ],
        },
        {
            id: 1,
            name: 'Категория Уведомления',
            notices: [
                'Уведомление',
                'Уведомление1',
                'Уведомление2',
                'Уведомление3',
                'Уведомление4',
                'Уведомление5',
                'Уведомление6',
            ],
        },
        {
            id: 2,
            name: 'Категория Уведомления',
            notices: [
                'Уведомление',
                'Уведомление1',
                'Уведомление2',
                'Уведомление3',
                'Уведомление4',
                'Уведомление5',
                'Уведомление6',
            ],
        },
        {
            id: 3,
            name: 'Категория Уведомления',
            notices: [
                'Уведомление',
                'Уведомление1',
                'Уведомление2',
                'Уведомление3',
                'Уведомление4',
                'Уведомление5',
                'Уведомление6',
            ],
        },
    ];

    return (
        <div className={styles.wrap}>
            <AccountMenu />
            <div className={styles.content}>
                <h2 className={styles.title}>Уведомления</h2>
                <div className={styles.inputs}>
                    <div className={styles.inputBlock}>
                        <div className={styles.checkInput}>
                            <button
                                onClick={() => checboxClick('email')}
                                className={`${styles.checkbox} ${
                                    mainSettings.email ? styles.active : ''
                                }`}
                            >
                                {mainSettings.email && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                    >
                                        <path
                                            d="M4.33268 9.58301L0.166016 5.44968L1.49102 4.16634L4.33268 6.95801L10.5077 0.833008L11.8327 2.14967L4.33268 9.58301Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                )}
                            </button>
                            Получать уведомление по электронной почте
                        </div>
                        <InputWrap
                            title={'Электронная почта'}
                            value={emailInput}
                            onChange={(value) => setEmailInput(value)}
                            width="100%"
                            type="text"
                            placeholder="Введите электронную почту"
                        />
                    </div>
                    <div className={styles.inputBlock}>
                        <div className={styles.checkInput}>
                            <button
                                onClick={() => checboxClick('telegram')}
                                className={`${styles.checkbox} ${
                                    mainSettings.telegram ? styles.active : ''
                                }`}
                            >
                                {mainSettings.telegram && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                    >
                                        <path
                                            d="M4.33268 9.58301L0.166016 5.44968L1.49102 4.16634L4.33268 6.95801L10.5077 0.833008L11.8327 2.14967L4.33268 9.58301Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                )}
                            </button>
                            Получать уведомление в Telegram
                        </div>
                        <InputWrap
                            title={'Telegram'}
                            value={telegramInput}
                            onChange={(value) => setTelegramInput(value)}
                            width="100%"
                            type="text"
                            placeholder="@"
                        />
                    </div>
                </div>
                {categories.map((category) => (
                    <NoticeRow key={category.id} category={category} />
                ))}
            </div>
        </div>
    );
}

export default Notices;
