import React from 'react'
import styles from './styles.module.scss';
import { RowImage } from '../../../../CostPrice/RowImage.jsx';

function ProductItem({ item, id }) {

    const [open, setOpen] = React.useState(false);

    return (
        <div className={`${styles.product}`}>
            <div className={styles.productTop} onClick={() => setOpen(!open)}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <RowImage item={item} />
                        <p title={item.product_name} className={styles.productTitle}>{item.product_name}</p>
                    </div>
                    <div className={styles.col}><p title={item.art}>{item.art}</p></div>
                    <div className={styles.col}>Все регионы</div>
                    <div className={styles.col}>{item.regions[0].stock_mp}</div>
                    <div className={styles.col}>{item.regions[0].stock_self}</div>
                    <div className={styles.col}>{item.regions[0].percentage_of_nonpurchase * 100}%</div>
                    <div className={styles.col}>{item.regions[0].to_delivery}</div>
                    <div className={styles.col}>{item.regions[0].to_order}</div>
                </div>
                <button className={`${styles.openBtn} ${open ? styles.active : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M6.71739 4.79586L1.26607 10.4064C0.91131 10.7713 0.91131 11.3614 1.26607 11.7263C1.62067 12.0912 2.194 12.0912 2.5486 11.7263L8.00008 6.11579L13.4514 11.7263C13.8062 12.0912 14.3793 12.0912 14.7339 11.7263C15.0887 11.3614 15.0887 10.7713 14.7339 10.4064L9.28261 4.79586C9.28261 4.79586 8.57048 4 8.00008 4C7.42969 4 6.71739 4.79586 6.71739 4.79586Z" fill="#1E1E1E" />
                    </svg>
                </button>
            </div>
            <div className={`${styles.wrap} ${open ? styles.open : ''}`}>
                {
                    item.regions.filter(region => region.region_name !== 'ИТОГО').map(region => (
                        <div className={styles.row}>
                            <div className={styles.col}></div>
                            <div className={styles.col}></div>
                            <div className={styles.col}>{region.region_name}</div>
                            <div className={styles.col}>{region.stock_mp}</div>
                            <div className={styles.col}>{region.stock_self}</div>
                            <div className={styles.col}>{region.percentage_of_nonpurchase * 100}%</div>
                            <div className={styles.col}>{region.to_delivery}</div>
                            <div className={styles.col}>{region.to_order}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ProductItem;