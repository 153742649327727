import React, { useState, useEffect } from "react";
import { API_URL, getData } from '../../../../../static/js/request.js'
import style from '../../../Purchase.module.scss'
import Add from "./add.jsx";
import Table from "./table/index.jsx";

const Products = ({ itemParams, setItemParams }) => {

    const [productList, setProductList] = useState(null)

    const getProducts = async () => {
        try {
            await getData(API_URL + `/api/v1/general/user/products/`)
                .then((item) => {
                    setProductList(item)
                });
        } catch (error) {
            console.log('Ошибка в получении списка продуктов: ', error);
        }
    }



    useEffect(() => {

        getProducts()

    }, [])
    
    return (

        <>

            <Add
                productList={productList}
                setProductList={(value => setProductList(value))}
                addedList = {itemParams.products}
                type={itemParams.itemType}
                setItemParams={setItemParams}
            />

            <Table
                itemParams={itemParams}
                setItemParams={setItemParams}
                products={productList}
                setProductList={(value => setProductList(value))}
            />

        </>

    )

}

export default Products