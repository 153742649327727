import React, { useEffect, useState, useContext, useRef } from 'react';
import styles from './styles.module.scss';
import {
  API_URL,
  delData,
  getData,
  postData,
  putData,
} from '../../../static/js/request';
import { createNotification } from '../../../static/js/commonFunc';

const Tag = ({
  tag,
  noBg,
  noHover,
  onDelete,
  renameTag,
  colors,
  changeColor,
}) => {
  const [editModal, setEditModal] = useState(false);

  const [rename, setRename] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);

  const tagRef = useRef();

  useEffect(() => {
    if (tagRef.current) {
      setInputWidth(tagRef.current.offsetWidth);
    }
  }, [tagRef]);

  useEffect(() => {
    if (rename) {
      setEditModal(false);
    }
  }, [rename]);

  const [editColor, setEditColor] = useState(false);

  const [renameValue, changeRenameValue] = useState('');

  return (
    <div className={styles.itemTag} ref={tagRef}>
      {editModal && (
        <>
          <span
            style={{ zIndex: 140 }}
            className={styles.closeModalBg}
            onClick={() => setEditModal(false)}
            id="moreBtn"
          />

          <div className={styles.editModal}>
            <button
              className={styles.modalButton}
              onClick={() => setRename(true)}
              id="moreBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                id="moreBtn"
              >
                <path
                  fill="none"
                  id="moreBtn"
                  stroke="currentColor"
                  strokeLnecap="round"
                  strokeLnejoin="round"
                  strokeWidth="2"
                  d="m5 16l-1 4l4-1L19.586 7.414a2 2 0 0 0 0-2.828l-.172-.172a2 2 0 0 0-2.828 0L5 16ZM15 6l3 3m-5 11h8"
                />
              </svg>
              Переименовать
            </button>
            <button
              className={styles.modalButton}
              onClick={() => setEditColor(true)}
              id="moreBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 2048 2048"
                id="moreBtn"
              >
                <path
                  fill="currentColor"
                  id="moreBtn"
                  d="M1024 0q141 0 272 36t245 103t207 160t160 208t103 245t37 272q0 141-36 272t-103 245t-160 207t-208 160t-245 103t-272 37q-53 0-99-20t-81-55t-55-81t-21-100q0-49 9-85t24-67t31-56t31-52t23-56t10-68q0-52-20-99t-55-81t-82-55t-99-21q-38 0-67 9t-56 24t-53 31t-56 31t-67 23t-85 10q-53 0-99-20t-81-55t-55-81t-21-100q0-141 36-272t103-245t160-207t208-160T751 37t273-37zm0 1920q123 0 237-32t214-90t182-141t140-181t91-214t32-238q0-123-32-237t-90-214t-141-182t-181-140t-214-91t-238-32q-123 0-237 32t-214 90t-182 141t-140 181t-91 214t-32 238q0 27 10 50t27 40t41 28t50 10q38 0 67-9t56-24t52-31t55-31t67-23t87-10q80 0 150 30t122 82t82 122t30 150q0 49-9 86t-24 67t-31 55t-31 52t-23 56t-10 68q0 27 10 50t27 40t41 28t50 10zM512 640q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10zm384-256q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10zm512 384q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10zm128 256q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10zm-256 384q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10z"
                />
              </svg>
              Изменить цвет
            </button>
            <button
              className={styles.modalButton}
              onClick={onDelete}
              id="moreBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                id="moreBtn"
                viewBox="0 0 16 16"
              >
                <path
                  fill="red"
                  id="moreBtn"
                  fillRule="evenodd"
                  d="M5.75 3V1.5h4.5V3h-4.5Zm-1.5 0V1a1 1 0 0 1 1-1h5.5a1 1 0 0 1 1 1v2h2.5a.75.75 0 0 1 0 1.5h-.365l-.743 9.653A2 2 0 0 1 11.148 16H4.852a2 2 0 0 1-1.994-1.847L2.115 4.5H1.75a.75.75 0 0 1 0-1.5h2.5Zm-.63 1.5h8.76l-.734 9.538a.5.5 0 0 1-.498.462H4.852a.5.5 0 0 1-.498-.462L3.62 4.5Z"
                  clipRule="evenodd"
                />
              </svg>
              Удалить
            </button>
            {editColor && (
              <div className={styles.editColor} id="moreBtn">
                <p className={styles.colorTitle} id="moreBtn">
                  Цвет
                </p>
                <button
                  className={styles.editColorClose}
                  id="moreBtn"
                  onClick={() => setEditColor(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    id="moreBtn"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      id="moreBtn"
                      d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                    />
                  </svg>
                </button>
                <div className={styles.colors} id="moreBtn">
                  {colors?.map((color, index) => (
                    <button
                      key={color.tag_bg + index}
                      id="moreBtn"
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                      }}
                      onClick={() => changeColor(color, tag)}
                      className={
                        tag.color_bg === color.tag_bg ? styles.active : ''
                      }
                    >
                      <span
                        id="moreBtn"
                        style={{
                          background: color.tag_bg,
                          borderRadius: '100%',
                        }}
                      />
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {rename ? (
        <input
          className={styles.renameInput}
          autoFocus
          style={{ width: `${inputWidth}px` }}
          value={renameValue}
          onChange={(e) => changeRenameValue(e.target.value)}
          onBlur={() => {
            setRename(false);
            renameTag(tag, renameValue);
          }}
        />
      ) : (
        <>
          <span
            className={styles.bgSpan}
            style={{ background: noBg ? '' : tag.color_bg }}
          />
          <p
            style={{
              color: tag.color_bg,
              opacity: noHover ? '1' : '',
              visibility: noHover ? 'visible' : '',
            }}
          >
            {tag.text}
          </p>
        </>
      )}
      {noHover && (
        <button
          onClick={() => setEditModal(true)}
          className={styles.noHoverMore}
          id="moreBtn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            id="moreBtn"
          >
            <path
              fill="currentColor"
              id="moreBtn"
              d="M12 3c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2m0 14c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2m0-7c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2"
            />
          </svg>
        </button>
      )}
      {!noHover && !rename && (
        <div className={styles.tagButtons}>
          <button onClick={() => setEditModal(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="4"
              viewBox="0 0 17 4"
              fill="none"
            >
              <circle cx="1.5" cy="2" r="1.5" fill={tag.color_bg} />
              <circle cx="8.5" cy="2" r="1.5" fill={tag.color_bg} />
              <circle cx="15.5" cy="2" r="1.5" fill={tag.color_bg} />
            </svg>
          </button>
          <button onClick={onDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M6.68711 6.00006L9.60746 3.07956C9.79751 2.88959 9.79751 2.58244 9.60746 2.39247C9.4175 2.20251 9.11036 2.20251 8.92039 2.39247L5.99996 5.31297L3.07961 2.39247C2.88955 2.20251 2.5825 2.20251 2.39254 2.39247C2.20249 2.58244 2.20249 2.88959 2.39254 3.07956L5.31289 6.00006L2.39254 8.92055C2.20249 9.11052 2.20249 9.41767 2.39254 9.60764C2.48721 9.7024 2.61168 9.75 2.73607 9.75C2.86046 9.75 2.98485 9.7024 3.07961 9.60764L5.99996 6.68714L8.92039 9.60764C9.01515 9.7024 9.13954 9.75 9.26393 9.75C9.38832 9.75 9.5127 9.7024 9.60746 9.60764C9.79751 9.41767 9.79751 9.11052 9.60746 8.92055L6.68711 6.00006Z"
                fill={tag.color_bg}
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

const CreateModal = ({
  tagsAlreadyAdded,
  setTags,
  allTags,
  setAllTags,
  colors,
  product_id,
}) => {
  const [searchValue, changeSearchValue] = useState('');

  const renameTag = async (currentTag, value) => {
    try {
      const response = await putData(API_URL + '/api/v1/tags/', {
        tag_id: currentTag.id,
        name: value,
        tag_bg: currentTag.color_bg,
      });
      setAllTags([
        ...allTags.map((item) => {
          return {
            ...item,
            text: item.id === currentTag.id ? value : item.text,
          };
        }),
      ]);
      setTags([
        ...tagsAlreadyAdded.map((item) => {
          return {
            ...item,
            text: item.id === currentTag.id ? value : item.text,
          };
        }),
      ]);
    } catch (error) {}
  };

  const [addLoading, setLoading] = useState(false);

  const addTag = async (tag) => {
    if (tag) {
      setTags([...tagsAlreadyAdded, tag]);
      changeSearchValue('');
      try {
        const response = await postData(API_URL + '/api/v1/product/tags/', {
          product_id: product_id,
          tag_id: tag.id,
          new: false,
        });
        console.log(response);
      } catch (error) {}
    } else if (searchValue.trim().length > 0) {
      if (
        !tagsAlreadyAdded
          .map((temp) => temp.text.toLowerCase())
          .includes(searchValue.toLowerCase().trim())
      ) {
        if (
          allTags
            .map((temp) => temp.text.toLowerCase())
            .includes(searchValue.toLowerCase().trim())
        ) {
          setTags([
            ...tagsAlreadyAdded,
            {
              ...allTags.find(
                (temp) =>
                  temp.text.toLowerCase().trim() ===
                  searchValue.toLowerCase().trim()
              ),
            },
          ]);
          changeSearchValue('');
          const currentTag = allTags.find(
            (temp) =>
              temp.text.toLowerCase() === searchValue.toLowerCase().trim()
          );
          try {
            const response = await postData(API_URL + '/api/v1/product/tags/', {
              product_id: product_id,
              tag_id: currentTag.id,
              new: false,
            });
            console.log(response);
          } catch (error) {}
        } else {
          try {
            const response = await postData(API_URL + '/api/v1/product/tags/', {
              product_id: product_id,
              new: true,
              tag_name: searchValue.trim(),
            });
            changeSearchValue('');
            console.log(response);
          } catch (error) {}
        }
      }
    }
  };

  const changeColor = async (color, currentTag) => {
    try {
      const response = await putData(API_URL + '/api/v1/tags/', {
        tag_id: currentTag.id,
        name: currentTag.text,
        tag_bg: color.tag_bg,
        tag_fg: color.tag_fg,
      });
      setTags([
        ...tagsAlreadyAdded.map((tag) => {
          return {
            ...tag,
            color_bg:
              tag.text === currentTag.text ? color.tag_bg : tag.color_bg,
            tag_fg: tag.text === currentTag.text ? color.tag_fg : tag.color_fg,
          };
        }),
      ]);
      setAllTags([
        ...allTags.map((tag) => {
          return {
            ...tag,
            color_bg:
              tag.text === currentTag.text ? color.tag_bg : tag.color_bg,
            tag_fg: tag.text === currentTag.text ? color.tag_fg : tag.color_fg,
          };
        }),
      ]);
    } catch (error) {}
  };

  const removeTag = async (tag) => {
    try {
      const response = await delData(API_URL + '/api/v1/product/tags/', {
        product_id,
        tag_id: tag.id,
      });
      setTags((state) => [...state.filter((temp) => temp.text !== tag.text)]);
    } catch (error) {}
  };

  const removeFromAllTags = async (tag) => {
    try {
      const response = await delData(API_URL + '/api/v1/tags/', {
        product_id,
        tag_id: tag.id,
      });
      setAllTags((state) => [
        ...state.filter((temp) => temp.text !== tag.text),
      ]);
    } catch (error) {}
  };

  return (
    <div className={styles.addModal}>
      {tagsAlreadyAdded.length > 0 && (
        <div className={styles.addedTags}>
          {tagsAlreadyAdded.map((tag) => (
            <Tag
              onDelete={() => removeTag(tag)}
              colors={colors}
              renameTag={renameTag}
              key={tag.id + tag.text}
              tag={tag}
              changeColor={changeColor}
            />
          ))}
        </div>
      )}
      <div className={styles.searchWrap}>
        <input
          type="text"
          value={searchValue}
          placeholder="Найти или создать тег"
          onChange={(e) => changeSearchValue(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              addTag();
            }
          }}
        />
        {searchValue.trim().length > 0 &&
        tagsAlreadyAdded
          .map((temp) => temp.text.toLowerCase())
          .filter((temp) => temp === searchValue.toLowerCase()).length === 0 ? (
          <button onClick={() => addTag()} className={styles.addTagButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                <path
                  fill="white"
                  d="M21.546 5.111a1.5 1.5 0 0 1 0 2.121L10.303 18.475a1.6 1.6 0 0 1-2.263 0L2.454 12.89a1.5 1.5 0 1 1 2.121-2.121l4.596 4.596L19.424 5.111a1.5 1.5 0 0 1 2.122 0Z"
                />
              </g>
            </svg>
          </button>
        ) : null}
      </div>
      <div className={styles.searchContent}>
        {!addLoading && (
          <>
            {allTags.filter(
              (tag) =>
                tag.text
                  .toLowerCase()
                  .includes(searchValue.toLowerCase().trim()) &&
                !tagsAlreadyAdded
                  .map((temp) => temp.text.toLowerCase().trim())
                  .includes(tag.text.toLowerCase().trim())
            ).length > 0 ? (
              <>
                {allTags.length > 0 ? (
                  <>
                    {allTags
                      ?.filter(
                        (tag) =>
                          tag.text
                            .toLowerCase()
                            .includes(searchValue.toLowerCase().trim()) &&
                          !tagsAlreadyAdded
                            .map((temp) => temp.text.toLowerCase())
                            .includes(tag.text.toLowerCase())
                      )
                      .map((tag) => (
                        <>
                          <button
                            onClick={(e) => {
                              if (e.target.getAttribute('id') !== 'moreBtn') {
                                addTag(tag);
                              }
                            }}
                            className={styles.tagButton}
                            key={tag.id + tag.text}
                          >
                            <Tag
                              renameTag={renameTag}
                              key={tag.id + tag.text}
                              noHover
                              tag={tag}
                              colors={colors}
                              changeColor={changeColor}
                              onDelete={() => removeFromAllTags(tag)}
                            />
                          </button>
                        </>
                      ))}
                  </>
                ) : (
                  <p className={styles.noResults}>Создайте тег</p>
                )}
              </>
            ) : tagsAlreadyAdded
                .map((temp) => temp.text.toLowerCase())
                .filter((temp) => temp === searchValue.toLowerCase()).length ===
              0 ? (
              <>
                {searchValue.trim().length > 0 && (
                  <p className={styles.noResults}>
                    Создать новый тег: {searchValue}
                  </p>
                )}
              </>
            ) : (
              <p className={styles.noResults}>Этот тег уже добален</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

function Tags({ itemTags, product_id }) {
  const [createTagModal, setCreateTagModal] = useState(false);

  const [tagsAlreadyAdded, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const changeColor = async (color, currentTag) => {
    try {
      const response = await putData(API_URL + '/api/v1/tags/', {
        tag_id: currentTag.id,
        name: currentTag.text,
        tag_bg: color.tag_bg,
        tag_fg: color.tag_fg,
      });
      setTags([
        ...tagsAlreadyAdded.map((tag) => {
          return {
            ...tag,
            color_bg:
              tag.text === currentTag.text ? color.tag_bg : tag.color_bg,
            tag_fg: tag.text === currentTag.text ? color.tag_fg : tag.color_fg,
          };
        }),
      ]);
      setAllTags([
        ...allTags.map((tag) => {
          return {
            ...tag,
            color_bg:
              tag.text === currentTag.text ? color.tag_bg : tag.color_bg,
            tag_fg: tag.text === currentTag.text ? color.tag_fg : tag.color_fg,
          };
        }),
      ]);
    } catch (error) {}
  };

  const [colors, setColors] = useState([]);

  useEffect(() => {
    setTags(itemTags);
  }, [itemTags]);

  const removeTag = async (tag) => {
    try {
      const response = await delData(API_URL + '/api/v1/product/tags/', {
        product_id,
        tag_id: tag.id,
      });
      setTags((state) => [...state.filter((temp) => temp.text !== tag.text)]);
    } catch (error) {}
  };

  return (
    <div className={styles.tags}>
      {createTagModal && (
        <>
          <span
            className={styles.closeModalBg}
            onClick={() => setCreateTagModal(false)}
          />
          <CreateModal
            allTags={allTags}
            setAllTags={setAllTags}
            colors={colors}
            setTags={setTags}
            tagsAlreadyAdded={tagsAlreadyAdded}
            product_id={product_id}
          />
        </>
      )}
      <div className={styles.itemTags}>
        {tagsAlreadyAdded
          ?.filter((tag, index) => index < 2)
          ?.map((tag) => (
            <Tag
              onDelete={() => removeTag(tag)}
              tag={tag}
              key={tag.id + tag.text}
              colors={colors}
              changeColor={changeColor}
            />
          ))}
      </div>
      {tagsAlreadyAdded.length > 2 ? (
        <button
          onClick={() => setCreateTagModal(true)}
          className={styles.tagsMoerCount}
        >
          +{tagsAlreadyAdded.length - 2}
        </button>
      ) : (
        <button
          style={{ marginLeft: tagsAlreadyAdded.length === 0 ? '0' : '' }}
          className={styles.tagsOpenModal}
          onClick={() => setCreateTagModal(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              fill="#8496AF"
              d="M1 7.775V2.75C1 1.784 1.784 1 2.75 1h5.025c.464 0 .91.184 1.238.513l6.25 6.25a1.75 1.75 0 0 1 0 2.474l-5.026 5.026a1.75 1.75 0 0 1-2.474 0l-6.25-6.25A1.752 1.752 0 0 1 1 7.775Zm1.5 0c0 .066.026.13.073.177l6.25 6.25a.25.25 0 0 0 .354 0l5.025-5.025a.25.25 0 0 0 0-.354l-6.25-6.25a.25.25 0 0 0-.177-.073H2.75a.25.25 0 0 0-.25.25ZM6 5a1 1 0 1 1 0 2a1 1 0 0 1 0-2Z"
            />
          </svg>
        </button>
      )}
    </div>
  );
}

export default Tags;
