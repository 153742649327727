"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const IconButton = ({ icon, ...props }) => ((0, jsx_runtime_1.jsx)("button", { ...props, style: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        pointerEvents: 'auto',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
    }, children: icon }));
exports.IconButton = IconButton;
