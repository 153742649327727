import React, { useEffect, useRef, useState } from 'react';
import style from '../../components/custom/CustomSelect/style.module.scss';
import cx from 'classnames';
import DownArrow from '../../shared/ui/icons/DownArrow.jsx';

const CustomSelectNew = ({ required, options, defaultOption, title, onOptionChange, width, fieldName, value, externalStyle }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const selectRef = useRef();


    const handleOutsideClick = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (value) {
            const option = options.find(opt => opt.name === value || opt.id === value);
            if (option) {
                setSelectedOption(option);
            }
        }
    }, [options, value]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onOptionChange) {
            onOptionChange(option, fieldName); // вызов функции обратного вызова с выбранным значением

        }
    };

    return (
        <div ref={selectRef} className={externalStyle ? externalStyle.select : style.customSelect} style={{ width: width ? width : '' }}>

            {
                title
                &&
                <div className={externalStyle ? externalStyle.title : style.title}>
                    {title}
                    {required && <span className={externalStyle ? externalStyle.required : style.required}>*</span>}
                </div>
            }

            <div className={cx(externalStyle ? externalStyle.selectContent : style.customSelectContent, isOpen ? (externalStyle ? externalStyle.active : style.active) : '')}>
                <div className={cx(externalStyle ? externalStyle.button : style.button, isOpen ? (externalStyle ? externalStyle.active : style.active) : '')} onClick={() => setIsOpen(!isOpen)}>
                    <span className={selectedOption.style ? (externalStyle ? externalStyle[selectedOption.style] : style[selectedOption.style]) : ''}>{selectedOption.name || defaultOption.name}</span>
                    <DownArrow />
                </div>
                {isOpen && (
                    <div className={cx(externalStyle ? externalStyle.optionList : style.optionList, isOpen ? (externalStyle ? externalStyle.active : style.active) : '')}>
                        {options.map((option, index) => (
                            <div

                                className={externalStyle ? externalStyle.option : style.option}
                                key={index}
                                onClick={() => handleOptionClick(option)}
                            >

                                {option.style ?
                                    <span className={externalStyle ? externalStyle[option.style] : style[option.style]}>{option.name}</span>
                                    :
                                    option.name
                                }

                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSelectNew;