import React, { useEffect, useRef, useState } from 'react';
import styles from './Articles.module.scss';
import { API_URL, delData, postData, putDataNew } from '@/static/js/request';
import { getArticle } from '@/api/getArticle';

// eslint-disable-next-line react/prop-types
const ArticleRow = ({
  item,
  firstLevel = false,
  refetch,
  level = 1,
  activeTab,
  parentName,
  grandpaName,
  greatGrandpaName,
  openArticles,
  toggleArticleOpen,
}) => {
  const [addRow, setAddRow] = useState(false);
  const [itemLocal, setItem] = useState(item);

  const isOpen = openArticles[itemLocal.info.id];

  const openRowLocal = (e) => {
    if (e.target.getAttribute('id') !== 'noEvents' && itemLocal.nestedEl) {
      toggleArticleOpen(itemLocal.info.id);
      setAddRow(false);
    }
  };

  useEffect(() => {
    setItem(item);
  }, [item]);

  const [changedTitleValue, setTitleValue] = useState(itemLocal?.title);
  const [editRow, setEditRow] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [positionValue, setPositionValue] = useState(
    `${item.info.position || ''}`
  );

  const addInnerRow = () => {
    setAddRow(!addRow);
    if (!isOpen) {
      toggleArticleOpen(itemLocal.info.id);
    }
  };
  

  const ref = useRef();
  const [paddingLeft, setPaddingLeft] = useState('');

  useEffect(() => {
    let padding = ref.current?.parentNode.parentNode.querySelector(
      `.${styles.top}`
    ).style.paddingLeft;

    if (firstLevel) {
      padding = '0';
    } else {
      padding = padding.substring(0, padding.length - 2);
    }

    if (ref.current) {
      ref.current.querySelector(`.${styles.top}`).style.paddingLeft = `${
        +padding + 25
      }px`;
      setPaddingLeft(`${+padding + 85}px`);
    }
  }, [ref]);

  const changePositionRequest = async (id, position) => {
    try {
      const response = await putDataNew(
        API_URL + '/api/v1/articles-management/',
        {
          id,
          position: +position,
        }
      );
      refetch();
    } catch (e) {}
  };

  const createArticle = async () => {
    try {
      const newPosition = itemLocal.nestedEl
        ? Math.max(
            0,
            ...Object.values(itemLocal.nestedEl).map(
              (i) => i.info?.position || 0
            )
          ) + 1
        : 1;

      const hierarchy = {
        article: newValue,
        reports: [
          {
            reportName:
              activeTab === 0 ? 'pnl' : activeTab === 1 ? 'dds' : 'balance',
            level: level + 1,
            position: newPosition,
            parent_article_id: itemLocal.info.id || null,
            grandpa_article_id: itemLocal.info.parent_id || null,
            great_grandpa_article_id: itemLocal.info.grandpa_id || null,
            great_great_grandpa_article_id:
              itemLocal.info.great_grandpa_id || null,
          },
        ],
      };

      const response = await postData(
        API_URL + '/api/v1/articles-management/',
        hierarchy
      );
      const newId = response?.data?.data?.article_id;

      if (newId) {
        const newArticleTitle = newValue;
        const newArticle = {
          info: {
            id: newId,
            is_default: false,
            position: newPosition,
            parent_id: itemLocal.info?.id,
            grandpa_id: itemLocal.info?.parent_id,
            great_grandpa_id: itemLocal.info?.grandpa_id,
          },
          nestedEl: {},
        };

        setItem((prev) => ({
          ...prev,
          nestedEl: {
            ...(prev.nestedEl || {}),
            [newArticleTitle]: newArticle,
          },
        }));
      }

      setNewValue('');
      setAddRow(false);

      await refetch();
    } catch (e) {
      console.error('Ошибка при создании статьи:', e);
    }
  };

  const changeArticle = async () => {
    try {
      await putDataNew(API_URL + '/api/v1/articles-management/', {
        id: itemLocal.info.id,
        article: changedTitleValue,
        level: level,
        parent_article: parentName,
        grandpa_article: grandpaName,
      });

      setItem((prev) => ({
        ...prev,
        title: changedTitleValue,
      }));
    } catch (e) {
      console.error('Ошибка при редактировании статьи:', e);
    }
  };

  const removeItem = async () => {
    try {
      await delData(API_URL + '/api/v1/articles-management/', {
        id: itemLocal.info.id,
      });

      await refetch();
    } catch (e) {
      console.error('Ошибка при удалении статьи:', e);
    }
  };

  return (
    <>
      {itemLocal ? (
        <div
          className={`${styles.row} ${isOpen ? styles.opened : ''}`}
          ref={ref}
        >
          <div
            className={styles.top}
            style={{ paddingLeft: '0' }}
            onClick={openRowLocal}
          >
            {!firstLevel && (
              <input
                id={'noEvents'}
                className={styles.positionInput}
                disabled={item.info.is_default}
                placeholder={'№'}
                type="text"
                value={positionValue}
                onChange={(e) => {
                  const val = e.target.value;
                  setPositionValue(val.replace(/[^0-9]/g, ''));
                  changePositionRequest(
                    itemLocal.info.id,
                    e.target.value.replace(/[^0-9]/g, '')
                  );
                }}
              />
            )}
            {itemLocal.nestedEl &&
              Object.keys(itemLocal.nestedEl).length > 0 && (
                <svg
                  className={styles.arrow}
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                >
                  <path
                    d="M5.40311 4.58385L1.19523 0.690051C0.921526 0.436649 0.478983 0.436649 0.205278 0.690051C-0.0684266 0.943335 -0.0684266 1.35286 0.205278 1.60614L4.41315 5.50006L0.205279 9.39386C-0.0684262 9.64726 -0.0684262 10.0567 0.205279 10.3099C0.478984 10.5634 0.921526 10.5634 1.19523 10.3099L5.40311 6.41615C5.40311 6.41615 6 5.90748 6 5.50006C6 5.09263 5.40311 4.58385 5.40311 4.58385Z"
                    fill="#35373A"
                  />
                </svg>
              )}
            {!editRow ? (
              itemLocal.title
            ) : (
              <div className={styles.editRow} id={'noEvents'}>
                <input
                  placeholder={'Введите название'}
                  value={changedTitleValue}
                  id={'noEvents'}
                  onChange={(e) => setTitleValue(e.target.value)}
                  type="text"
                />
                <div className={styles.buttons}>
                  <button
                    id={'noEvents'}
                    className={styles.close}
                    onClick={() => setEditRow(false)}
                  >
                    Отменить
                  </button>
                  <button
                    id={'noEvents'}
                    className={styles.save}
                    onClick={() => {
                      setEditRow(false);
                      changeArticle();
                    }}
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            )}
            {itemLocal.info.is_default && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="11"
                viewBox="0 0 8 11"
                fill="none"
              >
                <path
                  d="M7.33333 4.25H6.66667V3C6.66667 1.61937 5.47267 0.5 4 0.5C2.52733 0.5 1.33333 1.61937 1.33333 3V4.25H0.666667C0.298167 4.25 0 4.52953 0 4.875V9.875C0 10.2205 0.298167 10.5 0.666667 10.5H7.33333C7.70183 10.5 8 10.2205 8 9.875V4.875C8 4.52953 7.70183 4.25 7.33333 4.25ZM4 8C3.6315 8 3.33333 7.72047 3.33333 7.375C3.33333 7.02953 3.6315 6.75 4 6.75C4.3685 6.75 4.66667 7.02953 4.66667 7.375C4.66667 7.72047 4.3685 8 4 8ZM5.33333 4.25H2.66667V3C2.66667 2.30969 3.26367 1.75 4 1.75C4.73633 1.75 5.33333 2.30969 5.33333 3V4.25Z"
                  fill="#BACDDE"
                />
              </svg>
            )}
            {!editRow && (
              <div className={styles.editButtons}>
                {level < 5 && (
                  <button
                    id={'noEvents'}
                    className={styles.plus}
                    onClick={addInnerRow}
                  >
                    <svg
                      id={'noEvents'}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        id={'noEvents'}
                        d="M1.51981 7.64481H6.35519V12.4802C6.35519 12.6512 6.42312 12.8152 6.54405 12.9361C6.66498 13.0571 6.82899 13.125 7 13.125C7.17102 13.125 7.33503 13.0571 7.45595 12.9361C7.57688 12.8152 7.64481 12.6512 7.64481 12.4802V7.64481H12.4802C12.6512 7.64481 12.8152 7.57688 12.9361 7.45595C13.0571 7.33503 13.125 7.17101 13.125 7C13.125 6.82899 13.0571 6.66497 12.9361 6.54405C12.8152 6.42312 12.6512 6.35519 12.4802 6.35519H7.64481V1.51981C7.64481 1.3488 7.57688 1.18479 7.45595 1.06386C7.33503 0.942935 7.17102 0.875 7 0.875C6.82899 0.875 6.66498 0.942935 6.54405 1.06386C6.42312 1.18479 6.35519 1.3488 6.35519 1.51981V6.35519H1.51981C1.3488 6.35519 1.18479 6.42312 1.06386 6.54405C0.942937 6.66497 0.875 6.82899 0.875 7C0.875 7.17101 0.942937 7.33503 1.06386 7.45595C1.18479 7.57688 1.3488 7.64481 1.51981 7.64481Z"
                        fill="#35373A"
                      />
                    </svg>
                  </button>
                )}
                <button
                  disabled={item.info.is_default}
                  id={'noEvents'}
                  className={styles.edit}
                  onClick={() => setEditRow(true)}
                >
                  <svg
                    id={'noEvents'}
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      id={'noEvents'}
                      d="M0.666992 11.8329C0.534438 11.8327 0.407364 11.78 0.313634 11.6862C0.219904 11.5925 0.167169 11.4654 0.166992 11.3329L0.166992 8.54553C0.167472 8.41304 0.220172 8.28608 0.313659 8.19219L7.80766 0.699528C7.97606 0.531011 8.17601 0.397329 8.3961 0.306121C8.61619 0.214914 8.85209 0.167969 9.09033 0.167969C9.32856 0.167969 9.56446 0.214914 9.78455 0.306121C10.0046 0.397329 10.2046 0.531011 10.373 0.699528L11.3003 1.62686C11.4688 1.79526 11.6025 1.99522 11.6937 2.2153C11.7849 2.43539 11.8319 2.67129 11.8319 2.90953C11.8319 3.14776 11.7849 3.38367 11.6937 3.60375C11.6025 3.82384 11.4688 4.0238 11.3003 4.19219L3.80833 11.6862C3.76193 11.7328 3.70676 11.7698 3.646 11.7949C3.58524 11.8201 3.52009 11.833 3.45433 11.8329H0.666992ZM1.16699 8.7522V10.8329H3.24699L8.52033 5.55953L6.44033 3.47953L1.16699 8.7522ZM9.22766 4.85286L10.595 3.48553C10.7474 3.33269 10.8329 3.12568 10.8329 2.90986C10.8329 2.69404 10.7474 2.48703 10.595 2.3342L9.66633 1.40486C9.51358 1.25232 9.30653 1.16664 9.09066 1.16664C8.87479 1.16664 8.66774 1.25232 8.51499 1.40486L7.14699 2.77286L9.22766 4.85286Z"
                      fill="#8496AF"
                    />
                  </svg>
                </button>
                <button
                  disabled={item.info.is_default}
                  className={styles.remove}
                  onClick={removeItem}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                  >
                    <path
                      d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z"
                      fill="#8496AF"
                    />
                    <path
                      d="M5.21702 12.5262C5.35526 12.5258 5.48772 12.4718 5.58547 12.3759C5.68323 12.2801 5.73832 12.1502 5.73873 12.0146V6.13075C5.73873 5.99506 5.68376 5.86493 5.58592 5.76899C5.48808 5.67304 5.35539 5.61914 5.21702 5.61914C5.07866 5.61914 4.94596 5.67304 4.84812 5.76899C4.75028 5.86493 4.69531 5.99506 4.69531 6.13075V12.0146C4.69572 12.1502 4.75082 12.2801 4.84857 12.3759C4.94632 12.4718 5.07878 12.5258 5.21702 12.5262Z"
                      fill="#8496AF"
                    />
                    <path
                      d="M8.52171 12.5262C8.65995 12.5258 8.79242 12.4718 8.89017 12.3759C8.98792 12.2801 9.04301 12.1502 9.04342 12.0146V6.13075C9.04342 5.99506 8.98845 5.86493 8.89061 5.76899C8.79277 5.67304 8.66008 5.61914 8.52171 5.61914C8.38335 5.61914 8.25065 5.67304 8.15281 5.76899C8.05497 5.86493 8 5.99506 8 6.13075V12.0146C8.00041 12.1502 8.05551 12.2801 8.15326 12.3759C8.25101 12.4718 8.38347 12.5258 8.52171 12.5262Z"
                      fill="#8496AF"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
          {isOpen && (
            <div className={styles.innerContent}>
              {addRow && (
                <div
                  style={{ paddingLeft: paddingLeft }}
                  className={styles.addRow}
                >
                  <input
                    placeholder={'Введите название'}
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    type="text"
                  />
                  <div className={styles.buttons}>
                    <button
                      className={styles.close}
                      onClick={() => setAddRow(false)}
                    >
                      Отменить
                    </button>
                    <button
                      className={styles.save}
                      onClick={() => {
                        setAddRow(false);
                        createArticle();
                        setNewValue('');
                      }}
                    >
                      Сохранить
                    </button>
                  </div>
                </div>
              )}
              {itemLocal.nestedEl &&
                Object.entries(itemLocal.nestedEl)
                  .map(([key, value]) => ({
                    key,
                    ...value,
                  }))
                  .sort(
                    (a, b) => (a.info?.position || 0) - (b.info?.position || 0)
                  )
                  .map((child) => (
                    <ArticleRow
                      key={child.info.id}
                      item={{
                        title: child.title || child.key,
                        ...child,
                        info: {
                          ...child.info,
                          parent_id: itemLocal.info?.id,
                          grandpa_id: itemLocal.info?.parent_id,
                          great_grandpa_id: itemLocal.info?.grandpa_id,
                        },
                      }}
                      parentName={itemLocal.title}
                      grandpaName={parentName}
                      greatGrandpaName={grandpaName}
                      greatGreatGrandpaName={greatGrandpaName}
                      refetch={refetch}
                      level={level + 1}
                      activeTab={activeTab}
                      openArticles={openArticles}
                      toggleArticleOpen={toggleArticleOpen}
                    />
                  ))}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
export default ArticleRow;
