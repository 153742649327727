import {makeAutoObservable, toJS} from 'mobx';
import {
    API_URL,
    delCashbox,
    getData,
    postData,
    putData, putDataNew,
} from '../../static/js/request';
import { createNotification, formatNumber2 } from '../../static/js/commonFunc';

export class StaffStore {
    fields = {};
    organizations = [];
    organizations2 = [];
    employees = [];
    employeesTotal = {};
    allEmployees = [];
    positions = [];
    divisions = [];
    newField = {
        staff: {},
        wage: {},
        kpi: [],
    };
    isChange = false;
    calculationMethod = false;

    constructor() {
        makeAutoObservable(this);
        this.fillValue();
    }

    fillValue = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const id = queryParameters.get('id');
        if (id) {
            getData(`${API_URL}/api/v1/fot/${id ? id : ''}/`).then((res) => {
                this.newField = {
                    ...res,
                    wage: {
                        ...res.wage,
                        salary: formatNumber2(+res.wage.salary),
                        cash: formatNumber2(+res.wage.cash),
                        payment_account: formatNumber2(+res.wage.payment_account),
                    },
                };
            });
        }
        getData(API_URL + '/api/v1/organisation/').then((organizations) => {
            this.organizations = [
                { name: 'Все' },
                ...organizations.map((item) => {
                    return { id: item.id, name: item.name };
                }),
            ];
        });
        getData(API_URL + '/api/v1/organisation/').then((organizations) => {
            this.organizations2 = organizations.map((item) => {
                return { id: item.id, name: item.name };
            });
        });
        getData(API_URL + '/api/v1/fot/kpi-calculation-methods/').then((methods) => {
            this.calculationMethod = methods;
        });
        getData(`${API_URL}/api/v1/fot/`).then((res) => {
            this.employees = res;
            this.allEmployees = res;
            res.forEach((item) => {
                this.employeesTotal.salary =
          (this.employeesTotal.salary || 0) + item.salary;
                this.employeesTotal.cash = (this.employeesTotal.cash || 0) + item.cash;
                this.employeesTotal.payment_account =
          (this.employeesTotal.payment_account || 0) + item.payment_account;
                this.employeesTotal.kpi = (this.employeesTotal.kpi || 0) + item.kpi;
                this.employeesTotal.total_payment =
          (this.employeesTotal.total_payment || 0) + item.total_payment;
            });
        });
        getData(`${API_URL}/api/v1/fot/positions/`).then((res) => {
            this.positions = res;
        });
        getData(`${API_URL}/api/v1/fot/divisions/`).then((res) => {
            this.divisions = res;
        });
    };

    removeField = (index) => {
        const fieldId = this.employees[index].id;

        if (!fieldId) {
            return;
        }

        delCashbox(API_URL + `/api/v1/fot/${fieldId}/`).then((data) => {
            createNotification(data);
            if (data.ok) {
                this.employees.splice(index, 1);
            }
        });
    };

    createEmployees = (reset, organisation) => {
        // const queryParameters = new URLSearchParams(window.location.search);
        // const id = queryParameters.get('id');
        const id = this.newField.staff.id;

        this.newField.kpi = this.newField.kpi.map((item) => {
            const newArticles = item.articles.map((article) => article.id ? article.id : article);
            return { ...item, articles: newArticles };
        });

        console.log(this.newField)
        if (id) {
            putDataNew(API_URL + `/api/v1/fot/${id}/`, toJS({...this.newField, staff: {
                ...this.newField.staff,
                organisation: organisation[0],
                position: this.newField.staff.position.id ?
                    this.newField.staff.position.id :
                    this.positions.find(position => position.name === this.newField.staff.position).id
            }})).then(
                (response) => {
                    createNotification(response);
                    if (reset) {
                        this.newField = {
                            staff: {},
                            wage: {},
                            kpi: [],
                        };
                    }
                }
            );
        } else {
            postData(API_URL + '/api/v1/fot/create/', {...this.newField, staff: {
                ...this.newField.staff,
                organisation: organisation[0],
                position: this.newField.staff.position.id
            }}).then(
                (response) => {
                    createNotification(response);
                    if (reset) {
                        this.newField = {
                            staff: {},
                            wage: {},
                            kpi: [],
                        };
                    }
                }
            );
        }
    };
}
