import React, { useState } from 'react';
import {getData} from "../../../static/js/request";
import {applyPriceNoSymbolCurrency, formatNumber2} from "../../../static/js/commonFunc";

const CustomConfirmAlertLoans = ({ id, API_URL }) => {
    const [showAll, setShowAll] = useState(false);
    const [data, setData] = useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await getData(API_URL + `/api/v1/loan/${id}/payments/`);
            setData(response);
        };
        fetchData();
    }, [id, API_URL]);

    const getYearLabel = (years) => {
        if (!years || years % 100 === 11 || (years % 10 === 0)) {
            return `${years} лет`;
        } else if (years % 10 === 1) {
            return `${years} год`;
        } else if (years % 10 >= 2 && years % 10 <= 4) {
            return `${years} года`;
        } else {
            return `${years} лет`;
        }
    }

    const handleShowMore = () => setShowAll(true);

    if (!data) return ;

    const filteredPayments = showAll ?
        data.payments :
        data.payments.slice(0, 5).concat({showMoreButton: true}).concat(data.payments.slice(-2));

    return data ? (
        <div className="react-confirm-alert-body w-800">
            <div>
                <div className="loans-payment-schedule">
                    <div className="loans-payment-schedule__title">График платежей по кредиту</div>
                    <div className="loans-payment-schedule__info">
                        <div className="loans-payment-schedule__info_item">Сумма кредита: <span>{applyPriceNoSymbolCurrency(data['loan amount'])}</span></div>
                        <div className="loans-payment-schedule__info_item">Срок: <span>{getYearLabel(data.term.year)}  {data.term.month} мес. </span></div>
                        <div className="loans-payment-schedule__info_item">Ставка: <span>{data.rate * 100}%</span></div>
                    </div>
                    <div className="loans-payment-schedule__table">
                        <div className="loans-payment-schedule__table_header">
                            <div className="loans-payment-schedule__table_header_item">Дата</div>
                            <div className="loans-payment-schedule__table_header_item">Остаток, ₽</div>
                            <div className="loans-payment-schedule__table_header_item">Проценты, ₽</div>
                            <div className="loans-payment-schedule__table_header_item">Погашено, ₽</div>
                            <div className="loans-payment-schedule__table_header_item">Платеж, ₽</div>
                        </div>
                        <div className="loans-payment-schedule__table_body">

                            {filteredPayments.map((field, index) => {
                                if ('showMoreButton' in field && field.showMoreButton) {
                                    return (
                                       <div className="loans-payment-schedule__table_body_show-more" key={index} >
                                           <button onClick={handleShowMore}>
                                               Показать еще
                                           </button>
                                       </div>
                                    );
                                }
                                return (
                                    <div className="loans-payment-schedule__table_body_line" key={index}>
                                        <div className="loans-payment-schedule__table_body_line-item loans-date">
                                            <span>Платеж {field.payment_number}</span>
                                            <span>{field.payment_date}</span>
                                        </div>
                                        <div className="loans-payment-schedule__table_body_line-item">{formatNumber2(field.remaining_balance)}</div>
                                        <div className="loans-payment-schedule__table_body_line-item">{formatNumber2(field.interest_amount)}</div>
                                        <div className="loans-payment-schedule__table_body_line-item">{formatNumber2(field.principal_amount)}</div>
                                        <div className="loans-payment-schedule__table_body_line-item">{formatNumber2(field.payment_for_period)}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default CustomConfirmAlertLoans;