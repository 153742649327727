// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AWRXijMfkAS7wFhWaBTs .TfDOx1k8tgrCXddKe3S7 {\n  display: flex;\n  align-items: center;\n  padding: 10px 0;\n  border-bottom: 1px solid #dce7f1;\n  gap: 10px;\n  height: 40px;\n  box-sizing: border-box;\n}\n.AWRXijMfkAS7wFhWaBTs .TfDOx1k8tgrCXddKe3S7 svg {\n  transition: 0.3s;\n}\n\n.bIiQsjT4eXZAGmJ49B10 {\n  display: flex;\n  align-items: center;\n}\n.bIiQsjT4eXZAGmJ49B10 .HVJKbirkpAc2hsTM1Lwx {\n  min-width: 170px;\n  max-width: 170px;\n  display: flex;\n  justify-content: flex-end;\n  column-gap: 5px;\n  padding: 5px;\n  border-bottom: 1px solid #dce7f1;\n  box-sizing: border-box;\n  height: 40px;\n  transition: 0.3s;\n  align-items: center;\n  border-right: 1px solid #dce7f1;\n}", "",{"version":3,"sources":["webpack://./src/views/Financing/DDS/row.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;EACA,SAAA;EACA,YAAA;EACA,sBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;;AAKA;EACE,aAAA;EACA,mBAAA;AAFF;AAGE;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,YAAA;EACA,gCAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,+BAAA;AADJ","sourcesContent":[".row {\n  .top {\n    display: flex;\n    align-items: center;\n    padding: 10px 0;\n    border-bottom: 1px solid #dce7f1;\n    gap: 10px;\n    height: 40px;\n    box-sizing: border-box;\n\n    svg {\n      transition: .3s;\n    }\n  }\n}\n\n.valueRow {\n  display: flex;\n  align-items: center;\n  .value {\n    min-width: 170px;\n    max-width: 170px;\n    display: flex;\n    justify-content: flex-end;\n    column-gap: 5px;\n    padding: 5px;\n    border-bottom: 1px solid #dce7f1;\n    box-sizing: border-box;\n    height: 40px;\n    transition: 0.3s;\n    align-items: center;\n    border-right: 1px solid #dce7f1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "AWRXijMfkAS7wFhWaBTs",
	"top": "TfDOx1k8tgrCXddKe3S7",
	"valueRow": "bIiQsjT4eXZAGmJ49B10",
	"value": "HVJKbirkpAc2hsTM1Lwx"
};
export default ___CSS_LOADER_EXPORT___;
