import React, {useState} from "react";
import style from "../../views/Setting/Setting.module.scss";
import cx from "classnames";

export const customInput = (props) => {
	const {
		name,
		placeholder,
		value,
		event,
		status,
		comment,
		hideValue,
	} = props;

	const statusList = {
		true: style.statusTrue,
		false: style.statusFalse
	};

	const [isActive, setIsActive] = useState(false);

	const handleInputFocus = () => {
		setIsActive(true);
	}

	const handleInputBlur = () => {
		setIsActive(false);
	}
	const hiddenValue = () => {
		let hiddenValue = ''
		if (!value) {
			return hiddenValue
		}

		return hiddenValue = isActive ? value : '*******************'
	}

	return (
		<label className={cx(style.serviceCustomSelect, statusList[status])}>
			{
				hideValue ? (
					<input type="text" placeholder={placeholder} name={name}
						   className={style.serviceInput} value={hiddenValue()}
						   onChange={event} onFocus={handleInputFocus}
						   onBlur={handleInputBlur}/>
				): (
					<input type="text" placeholder={placeholder} name={name}
						   className={style.serviceInput} value={value}
						   onChange={event} />

				)
			}

			{/*<span className={style.errorStatus}>
				<svg width="3" height="8" viewBox="0 0 3 8" fill="none">
				<path d="M2.00034 1.93584C2.14302 1.93543 2.27973 1.87857 2.38061 1.77769C2.4815 1.6768 2.53836 1.54009 2.53877 1.39741V0.858984C2.53877 0.716185 2.48204 0.579234 2.38107 0.478259C2.28009 0.377284 2.14314 0.320557 2.00034 0.320557C1.85754 0.320557 1.72059 0.377284 1.61962 0.478259C1.51864 0.579234 1.46191 0.716185 1.46191 0.858984V1.39741C1.46232 1.54009 1.51918 1.6768 1.62007 1.77769C1.72095 1.87857 1.85767 1.93543 2.00034 1.93584V1.93584Z" fill="#FF7676"/>
				<path d="M0.923193 4.08955H1.46162V7.14079C1.46162 7.28359 1.51835 7.42054 1.61932 7.52152C1.7203 7.62249 1.85725 7.67921 2.00005 7.67921C2.14285 7.67921 2.2798 7.62249 2.38077 7.52152C2.48175 7.42054 2.53848 7.28359 2.53848 7.14079V3.55112C2.53807 3.40845 2.48121 3.27174 2.38032 3.17085C2.27943 3.06996 2.14272 3.0131 2.00005 3.0127H0.923193C0.780393 3.0127 0.643443 3.06942 0.542468 3.1704C0.441493 3.27137 0.384766 3.40832 0.384766 3.55112C0.384766 3.69392 0.441493 3.83088 0.542468 3.93185C0.643443 4.03283 0.780393 4.08955 0.923193 4.08955Z" fill="#FF7676"/>
				</svg>
			</span>*/}
			<span className={style.errorComment}>{comment}</span>
		</label>
	);
};

export default customInput;