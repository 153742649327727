import React, {useEffect, useState} from 'react';
import styles from './row.module.scss';
import {formatNumber2} from '../../../static/js/commonFunc';

const ValueRow = ({item, level = 1, openedRows}) => {

    const { value, nestedEl } = item[1];
    const title = item[0];

    console.log(
        item
    );

    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        setOpen(!!openedRows.find(val => val.level === level && val.title === title));
    }, [openedRows]);

    return (
        <>
            <div className={styles.valueRow}>
                {
                    value?.map(val => (
                        <div className={styles.value} key={val}>
                            {formatNumber2(val)}
                        </div>
                    ))
                }
            </div>
            {
                open && nestedEl &&
                Object.keys(nestedEl).map((key) => (
                    <ValueRow openedRows={openedRows} item={[key, nestedEl[key]]} level={level + 1} />
                ))
            }
        </>
    );
};
export default ValueRow;
