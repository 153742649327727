import React from 'react';
import style from '../../static/css/commonStyle.module.scss';
import cx from "classnames";
import { ListFilter } from 'lucide-react';

export const HeaderTableItem = (props) => {
    const {
        name,
        event,
        additionalClass,
        filter,
        filterEvent,
        filterValue,
    } = props;

    const addedClass = () => {
        let preparedClass = '';
        if (additionalClass) {
            preparedClass = additionalClass;
        }
        return preparedClass;
    };

    return (
        <>
            <div className={cx(style.headerTableItem, (style[addedClass()]))} onClick={event}>
                {name}
                {filter && (
                    <div onClick={filterEvent} className={style.headerTableItemIco}>
                        <ListFilter width={16} height={16} />
                    </div>
                )}
                {filterValue}
            </div>
        </>

    );
};

export default HeaderTableItem;