import React, {useEffect, useRef, useState} from 'react';
import styles from './ArticleContent.module.scss';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import {API_URL, getData} from '../../../../static/js/request';

const ArticleContent = () => {

    const {id} = useParams();
    
    const [item, setItem] = useState(null);

    const getArticles = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/knowbase/articles/' + id + '/');
            contentRef.current.innerHTML = response.content;
            setItem(response)
        } catch (err) {
            console.log(err);
        }
    };

    const contentRef = useRef(null);

    useEffect(() => {
        if(contentRef) {
            getArticles();
        }
    }, [contentRef]);


    return (
        <div className={styles.content}>
            <div className={styles.path}>
                <Link to={'/faq'}>Главная</Link>
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="9" viewBox="0 0 4 9" fill="none">
                    <path d="M3.60207 3.76708L0.796821 0.652041C0.614351 0.44932 0.319322 0.44932 0.136852 0.652041C-0.0456178 0.854668 -0.0456178 1.18229 0.136852 1.38491L2.9421 4.50005L0.136852 7.61509C-0.0456175 7.81781 -0.0456175 8.14533 0.136852 8.34796C0.319322 8.55068 0.614351 8.55068 0.796821 8.34796L3.60207 5.23292C3.60207 5.23292 4 4.82599 4 4.50005C4 4.17411 3.60207 3.76708 3.60207 3.76708Z" fill="#8496AF"/>
                </svg>
                <p>{item?.title}</p>
            </div>
            <div className={styles.article}>
                {
                    <>
                        <h2 className={styles.articleTitle}>{item?.title}</h2>
                        <div ref={contentRef}></div>
                    </> }
            </div>
        </div>
    );
};
export default ArticleContent;
