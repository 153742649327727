import React, { useRef } from "react";
import TableItem from './item.jsx';
import style from '../../../Purchase.module.scss'
import useSyncScroll from "../../../../../static/js/useSyncScroll.jsx";
import { Link } from "react-router-dom";

const TableRow = ({ data, columns, headRef }) => {

    const elementRef = useRef(null);

    useSyncScroll(headRef, elementRef, { horizontal: true });

    return (

        data.map((item, index) => {            
            return (
                <div className={style.row} key={index}>


                    <Link className={style.xScroll} ref={elementRef} style={{ cursor: 'pointer', color: 'inherit' }} to={`/purchase/orders/item/${item.id}`}>

                        {Object.values(columns).map((column, columnIndex) =>

                            column.isShow &&
                            <TableItem key={columnIndex} item={item} column={column} />

                        )}

                    </Link>


                </div>
            )
        }



        )

    )

}

export default TableRow
