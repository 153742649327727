import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { formatNumber2 } from '../../static/js/commonFunc';
import Tags from './Tags/index.jsx';
import sortBy from 'lodash/sortBy';

function ContentItem({ item, onScroll, allTags }) {
  const [hoverRow, setHoverRow] = useState();

  const [average, setAverage] = useState(
    Object.values(item.average).map((price) => {
      return price.values[0];
    })
  );

  return (
    <div className={styles.contentItem}>
      <div className={styles.product}>
        <div
          className={styles.productCard}
          style={{ minHeight: `${Object.values(item.metrics).length * 61}px` }}
        >
          <div className={styles.imageWrap}>
            {item.product.photo_url ? (
              <img src={item.product.photo_url} className={styles.image} />
            ) : (
              <div className={styles.noImage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="currentColor"
                    d="M30 3.414L28.586 2L2 28.586L3.414 30l2-2H26a2.003 2.003 0 0 0 2-2V5.414zM26 26H7.414l7.793-7.793l2.379 2.379a2 2 0 0 0 2.828 0L22 19l4 3.997zm0-5.832l-2.586-2.586a2 2 0 0 0-2.828 0L19 19.168l-2.377-2.377L26 7.414zM6 22v-3l5-4.997l1.373 1.374l1.416-1.416l-1.375-1.375a2 2 0 0 0-2.828 0L6 16.172V6h16V4H6a2.002 2.002 0 0 0-2 2v16z"
                  />
                </svg>
              </div>
            )}
          </div>
          <p className={styles.infoItem}>
            Арт:
            <span className={styles.articleText}>
              {item.product.vendorCode}
            </span>
            <span className={styles.hoverText}>{item.product.vendorCode}</span>
          </p>
          <p className={styles.infoItem}>
            Кат:
            <span>{item.product.category}</span>
          </p>
          {/* <Tags product_id={item.product.id} itemTags={item.product.tags} /> */}
          <p className={styles.advertCompany}>
            <span
              className={styles.status}
              style={{
                background:
                  item.product.advertCompany.status === 'open'
                    ? '#1AB889'
                    : '#FF5E5B',
              }}
            />
            РК:
            <span className={styles.id}>{item.product.advertCompany.id}</span>
          </p>
          <p className={styles.companyName}>
            {item.product.advertCompany.name}
          </p>
          <div className={styles.periodBalance}>
            <p className={styles.periodTitle}>бюджет на начало Периода</p>
            <p className={styles.periodValue}>
              {formatNumber2(item.product.start_budget)}
            </p>
          </div>
          <div className={styles.periodBalance}>
            <p className={styles.periodTitle}>бюджет на конец Периода</p>
            <p className={styles.periodValue}>
              {formatNumber2(item.product.end_budget)}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.metrics}>
        <div className={styles.metricCol}>
          {sortBy(Object.values(item.metrics), 'sequence_number').map(
            (name, number) => (
              <div
                key={name.name}
                className={`${styles.metricBlock}  ${number === hoverRow ? styles.hovered : ''
                  }`}
                onMouseEnter={() => setHoverRow(number)}
                onMouseLeave={() => setHoverRow()}
              >
                {name.name}
                <div className={styles.descriptionIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_4054_26967)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.9987 1.8335C4.59294 1.8335 1.83203 4.59441 1.83203 8.00016C1.83203 11.4059 4.59294 14.1668 7.9987 14.1668C11.4044 14.1668 14.1654 11.4059 14.1654 8.00016C14.1654 4.59441 11.4044 1.8335 7.9987 1.8335ZM0.832031 8.00016C0.832031 4.04212 4.04066 0.833496 7.9987 0.833496C11.9568 0.833496 15.1654 4.04212 15.1654 8.00016C15.1654 11.9582 11.9568 15.1668 7.9987 15.1668C4.04066 15.1668 0.832031 11.9582 0.832031 8.00016ZM7.9987 5.16683C7.5845 5.16683 7.2487 5.50262 7.2487 5.91683C7.2487 6.19297 7.02483 6.41683 6.7487 6.41683C6.47256 6.41683 6.2487 6.19297 6.2487 5.91683C6.2487 4.95033 7.03223 4.16683 7.9987 4.16683C8.96516 4.16683 9.7487 4.95033 9.7487 5.91683C9.7487 6.55906 9.40243 7.1199 8.8889 7.42376C8.7577 7.50136 8.64943 7.5861 8.5789 7.66923C8.51036 7.7499 8.4987 7.80256 8.4987 7.8335V8.66683C8.4987 8.94296 8.27483 9.16683 7.9987 9.16683C7.72256 9.16683 7.4987 8.94296 7.4987 8.66683V7.8335C7.4987 7.49623 7.64636 7.22256 7.81643 7.02216C7.98443 6.82416 8.19396 6.67296 8.3797 6.56309C8.60163 6.43176 8.7487 6.1912 8.7487 5.91683C8.7487 5.50262 8.4129 5.16683 7.9987 5.16683ZM7.9987 11.3335C8.3669 11.3335 8.66536 11.035 8.66536 10.6668C8.66536 10.2986 8.3669 10.0002 7.9987 10.0002C7.6305 10.0002 7.33203 10.2986 7.33203 10.6668C7.33203 11.035 7.6305 11.3335 7.9987 11.3335Z"
                        fill="#8496AF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4054_26967">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p className={styles.descriptionText}>{name.description}</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div
        id="scrollableOfMain"
        className={`${styles.xScrollWrap} ${styles.body}`}
        onScroll={onScroll}
      >
        {Object.keys(item.metrics)
          .filter((temp) => temp !== 'metrics_names')
          .map((index, number) => (
            <div
              key={index}
              className={`${styles.row} ${number === hoverRow ? styles.hovered : ''
                }`}
              onMouseEnter={() => setHoverRow(number)}
              onMouseLeave={() => setHoverRow()}
            >
              {item.metrics[index].values.map((value, num) => (
                <div key={num} className={styles.date}>
                  <div className={styles.valueWrap}>
                    <p className={styles.value}>
                      {item.metrics[index].type === 'rub'
                        ? `${formatNumber2(value.value)}`
                        : item.metrics[index].type === 'percent'
                          ? `${Math.floor(value.value * 100)}%`
                          : value.value}
                    </p>
                    <p
                      className={styles.percent}
                      style={{
                        color: value.changes > 0 ? '#1AB889' : '#FF5E5B',
                      }}
                    >
                      {value.changes > 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M4 12.5L12 4.5M12 4.5H6M12 4.5V10.5"
                            stroke="#1AB889"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M4 4.5L12 12.5M12 12.5H6M12 12.5V6.5"
                            stroke="#FF5E5B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {Math.floor(value.changes * 100)}%
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
      <div className={styles.col}>
        {average.map((price, index) => (
          <div
            key={index}
            className={`${styles.average}  ${index === hoverRow ? styles.hovered : ''
              }`}
            onMouseEnter={() => setHoverRow(index)}
            onMouseLeave={() => setHoverRow()}
          >
            {
              price ?
                price.type === 'rub'
                  ? formatNumber2(price.value)
                  : price.type === 'percent'
                    ? `${Math.floor(price.value * 100)}%`
                    : price.value
                :
                null
            }
          </div>
        ))}
      </div>
    </div>
  );
}

export default ContentItem;
