import React from "react";
import style from '../../PlanFact.module.scss'

const Tags = ({ data }) => {

    return (
        <div className={style.tableRow}>
            {

                data.map((item, index) => {
                    if (index < 3) {
                        return <div key={index} className={style.tag}>{item}</div>
                    }


                })

            }

            {
                data.length > 3 && <div className={style.tagCount}><i>+{data.length - 3}</i></div>
            }

        </div>
    )
}

export default Tags

