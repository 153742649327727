import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import IconCalendar from '../../shared/ui/icons/IconCalendar.jsx';
import MonthSelector from '../../shared/ui/components/MonthSelector/index.jsx';
import YearSelector from '../../shared/ui/components/YearSelector/index.jsx';
import dayjs from 'dayjs';
import { ru } from 'date-fns/locale';

const SingleDateSelection = ({
                                 calendarOpen,
                                 setOpenCalendar,
                                 selectedDate,
                                 setSelectedDate,
                                 savedDate,
                                 setSavedDate,
                             }) => {
    // todo: доработать этот компонент по стилям и добавить выбор времени

    const saveDate = useCallback(() => {
        setSavedDate(selectedDate);
    }, [selectedDate, setSavedDate]);

    const resetDate = useCallback(() => {
        setSavedDate(null);
        setSelectedDate(null);
    }, [setSavedDate, setSelectedDate]);

    const handleSelect = useCallback((date) => {
        setSelectedDate(date);
    }, [setSelectedDate]);

    return (
        <>
            {calendarOpen && (
                <>
                    <span className={styles.closeCalendarBg} onClick={() => setOpenCalendar(false)} />
                    <div className={styles.calendarBlock}>

                        <div>
                            <input
                                type="date"
                                onChange={(e) => handleSelect(new Date(e.target.value))}
                                value={selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : ''}
                            />
                        </div>

                        <div className={styles.infoSide}>
                            <div className={styles.bottomBtns}>
                                <button onClick={resetDate} className={styles.closeChanges}>Сбросить</button>
                                <button className={styles.saveChanges} onClick={() => {
                                    saveDate();
                                    setOpenCalendar(false);
                                }}>Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <button onClick={() => setOpenCalendar(true)} className={styles.calendarBtn}>
                <IconCalendar />
                <p>
                    {savedDate
                        ? `${dayjs(savedDate).format('DD.MM.YYYY')}`
                        : 'Выберите дату'}
                </p>
            </button>
        </>
    );
};

export default SingleDateSelection;