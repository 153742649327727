import React,{useEffect} from "react";
import style from '../../../Purchase.module.scss'
import cx from 'classnames';
import { API_URL, getData } from "../../../../../static/js/request.js";

const PaysList = ({id, name, show, toggle}) => {


    useEffect(() => {
        getData(API_URL + `/api/v1/purchase/payments/${name}/${id}/`)
                .then((data) => console.log(data))
    },[])

    return(
        show

        &&

        <div className={style.modalWrap}>
            <div className={cx(style.content, style.pays)}>

                <div className={style.message}>
                    <div className={style.head}>
                        Подтвердите действие
                    </div>

                    <div>Текст</div>
                </div>

                <div className={style.buttons}>
                    <button className={style.create} onClick={() => toggle(prev => !prev)}>Сохранить</button>
                    <button className={style.cancel} onClick={() => toggle(prev => !prev)}>Отменить</button>
                </div>

            </div>
        </div>
    )
}

export default PaysList