import React from "react";
import style from "../../static/css/commonStyle.module.scss";
import cx from "classnames";
import {NavLink} from "react-router-dom";
const link = window.location.pathname;

const FinancingNav = () => {
	return (
		<ul className={style['financing-nav']}>
			<li>
				 <NavLink to="/financing/banks/"
						  className={({ isActive }) => isActive ? style['active'] : ""}>
					 Банки
				 </NavLink>
			</li>
			<li>
				 <NavLink
						  to={!location.pathname.includes('/financing/cashbox/') ? '/financing/cashbox' : '/financing/cashbox'}
						  className={({ isActive }) => isActive ? style['active'] : ""}>
					 Кассы
				 </NavLink>
			</li>
			<li>
				 <NavLink to="/financing/rules/"
						  className={({ isActive }) => isActive ? style['active'] : ""}>
					 Правила
				 </NavLink>
			</li>
			<li>
				 <NavLink to="/financing/dds/"
						  className={({ isActive }) => isActive ? style['active'] : ""}>
					 ДДС
				 </NavLink>
			</li>
		</ul>
	);
};

export default FinancingNav;