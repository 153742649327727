import React, { useState, useEffect, useRef } from 'react';
import Item from '../../view/item/index.jsx';
import {
  API_URL,
  postData,
  getData,
  putDataNew,
} from '../../../../static/js/request.js';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { popupNotification } from '../../../../static/js/popupNotification.jsx';

const ShipmentItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [shipmentParams, setShipmentParams] = useState({
    id: '',
    user: '',
    itemType: 'shipment',
    formTitle: 'Отгрузка',
    dialogMessage: 'Сохранить отгрузку',
    actionDate: '',
    status: '',
    columnWidth: '380px',
    columns: {
      organisation: {
        label: 'Организация',
        alias: 'organisation',
        placeholder: 'Выберите организацию',
        value: '',
        required: true,
        options: [],
      },
      counterparty: {
        label: 'Контрагент',
        alias: 'counterparty',
        placeholder: 'Выберите контрагента',
        value: '',
        required: true,
        options: [],
        needSearch: true,
      },
      transport_invoice_number: {
        label: '№ ТН',
        alias: 'transport_invoice_number',
        placeholder: '№',
        value: '',
        required: true,
        isInput: true,
      },
      shipping_method: {
        label: 'Способ доставки',
        alias: 'shipping_method',
        placeholder: 'Доставки',
        value: '',
        required: false,
        options: [
          { name: 'Самовывоз', id: 'Самовывоз' },
          { name: 'Транспортная компания', id: 'Транспортная компания' },
          { name: 'Курьер', id: 'Курьер' },
          { name: 'Почта', id: 'Почта' },
        ],
      },
      sender_warehouse: {
        label: 'Склад отправ.',
        alias: 'sender_warehouse',
        placeholder: 'Отправление',
        value: '',
        required: true,
        options: [],
        needSearch: true,
      },
      destination_warehouse: {
        label: 'Склад получ.',
        alias: 'destination_warehouse',
        placeholder: 'Получение',
        value: '',
        options: [],
        needSearch: true,
      },
      destination: {
        label: 'Адрес доставки',
        alias: 'destination',
        placeholder: 'Адрес',
        value: '',
        required: false,
        isInput: true,
        disabled: true,
      },
    },
    comment: '',
    products: [],
    conducted: false,
  });

  const itemAction = async () => {
    const requestData = {
      sender_warehouse: shipmentParams.columns.sender_warehouse.value,
      destination_warehouse: shipmentParams.columns.destination_warehouse.value,
      organisation: shipmentParams.columns.organisation.value,
      transport_invoice_number:
        shipmentParams.columns.transport_invoice_number.value,
      status: shipmentParams.status || 'Не выполнен',
      shipping_method: shipmentParams.columns.shipping_method.value,
      counterparty: shipmentParams.columns.counterparty.value,
      comment: shipmentParams.comment,
      products: shipmentParams.products,
      conducted: shipmentParams.conducted,
      delivery_date: shipmentParams.actionDate || new Date().toISOString().split('T')[0],
    };
    
    if (id) {
      await putDataNew(
        API_URL + '/api/v1/purchase/shipment/',
        requestData
      ).then((response) => {
        popupNotification(response, 'update');
      });
    } else 
      
    requestData.shipping_method = String(requestData.shipping_method)
      
      await postData(API_URL + '/api/v1/purchase/shipment/', requestData)
        .then((response) => {
          popupNotification(response, 'create');
          if (response.ok) {
            return response.json();
          } 
        })
        .then((response) => {
          if (response?.id) {
            setShipmentParams((prev) => ({
              ...prev,
              id: response.id,
            }));
            navigate(`/purchase/shipment/item/${response.id}`);
          }
        });
    
  };

  const searchType = useRef('local');

const localLoadDate = async ()=>{
  await getData(
    API_URL + `/api/v1/purchase/shipment/${id}/?type=${searchType.current}`
  )
    .then((data) => {
      if (!data) return;

      setShipmentParams((prev) => ({
        ...prev,
        comment: data?.comment || '',
        id: data?.id || '',
        user: data?.user || '',
        status: data?.status || '',
        actionDate: data?.delivery_date || '',
        columns: {
          ...prev.columns,
          organisation: {
            ...prev.columns.organisation,
            value: data?.organisation?.id || '',
          },
          counterparty: {
            ...prev.columns.counterparty,
            value: data?.counterparty?.id || '',
          },
          sender_warehouse: {
            ...prev.columns.sender_warehouse,
            value: data?.sender_warehouse?.id || '',
          },
          destination_warehouse: {
            ...prev.columns.destination_warehouse,
            value: data?.destination_warehouse?.id || '',
          },
          transport_invoice_number: {
            ...prev.columns.transport_invoice_number,
            value: data?.transport_invoice_number || '',
          },
          shipping_method: {
            ...prev.columns.shipping_method,
            value: data?.shipping_method || '',
          },
          destination: {
            ...prev.columns.destination,
            value: data?.destination_warehouse?.address || '',
          },
        },
        products: data?.products || [],
        conducted: data?.conducted || false,
      }));
    })
    .catch((err) => console.error('Ошибка при загрузке данных:', err));

}

  useEffect(() => {
    if (!id) return;
    localLoadDate()
  }, [id]);

  useEffect(() => {
    if (!id) return;

    const { value } = shipmentParams.columns.counterparty;

    if (value === 33) {
      searchType.current = 'WB';
    }
    else if(value === 34){
      searchType.current = 'OZON';
    }

    localLoadDate()
  }, [shipmentParams]);

  return (
    <Item
      title={id ? 'Изменение отгрузки' : 'Создание отгрузки'}
      itemParams={shipmentParams}
      setItemParams={setShipmentParams}
      itemAction={() => itemAction()}
    />
  );
};

export default ShipmentItem;
