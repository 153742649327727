import BodyTable from '../../components/custom/BodyTable.jsx';
import BodyTableLine from '../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../components/custom/BodyTableLineItem.jsx';
import HeaderTable from '../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../components/custom/HeaderTableItem.jsx';
import Table from '../../components/custom/Table.jsx';
import Item from './Item/index.jsx';
import styles from './styles.module.scss';
import React, { useState } from 'react';
import costPriceStore from '../../store/Setting/costpriceStore';
import { observer } from 'mobx-react-lite';
import { API_URL, postData } from '../../static/js/request.js';
import { RowImage } from './RowImage.jsx';
import MarketplacesSwitch from '../Logistics/ui/MarketplacesSwitch/index.jsx';

const CostPrice = observer(() => {
    const [activeTab, setActiveTab] = React.useState('Все');

    const tabs = ['Все', 'Заполненная СС', 'Незаполненная СС'];

    const [items, setItems] = React.useState([]);

    const [activeItem, setActiveItem] = React.useState();

    const [currentItem, setCurrentItem] = React.useState();

    const [modalActive, setModalActive] = React.useState(false);

    const itemClick = (item, e) => {
        if (e.target.getAttribute('id') !== 'input') {
            setCurrentItem({ ...item });
            setModalActive(true);
        }
    };
    const [activeMp, setActiveMp] = useState(0);

    const { fetchData, productsWithoutCost, productsWithCost } = costPriceStore;

    React.useEffect(() => {
        fetchData(mp[activeMp]);
    }, [fetchData, activeMp]);

    React.useEffect(() => {
        if (activeTab === 'Все') {
            setItems(
                [...productsWithoutCost, ...productsWithCost]
                    .sort(dynamicSort(activeFilter))
                    .reverse()
            );
        } else if (activeTab === 'Заполненная СС') {
            setItems(productsWithCost.sort(dynamicSort(activeFilter)).reverse());
        } else {
            setItems(productsWithoutCost.sort(dynamicSort(activeFilter)).reverse());
        }
    }, [activeTab, productsWithoutCost, productsWithCost]);

    const changePrice = (item, value) => {
        if (value.length > 0 && value != item.purchase_price) {
            postData(`${API_URL}/api/v1/products/costs/`, {
                product: item.barcode_set[0].code,
                purchase_price: parseInt(value),
            }).then(() => {
                fetchData(mp[activeMp]);
            });
        }
    };

    const [activeFilter, setFilter] = React.useState('price_change');

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }

    const mp = ['None', 'WB', 'OZON'];

    React.useEffect(() => {
        setItems([...items.sort(dynamicSort(activeFilter))].reverse());
    }, [activeFilter]);

    return (
        <section className={styles.section}>
            <Item
                active={modalActive}
                setActive={setModalActive}
                item={currentItem}
            />
            <h2 className={styles.title}>Закупка</h2>
            <div className={styles.content}>
                <div className={styles.top}>
                    <div className={styles.tabs}>
                        {tabs.map((item) => (
                            <button
                                key={item}
                                onClick={() => setActiveTab(item)}
                                className={`${styles.tab} ${
                                    activeTab === item ? styles.active : ''
                                }`}
                            >
                                {item}{' '}
                                <span>
                                    {item === 'Все'
                                        ? productsWithoutCost?.length + productsWithCost?.length
                                        : item === 'Заполненная СС'
                                            ? productsWithCost?.length
                                            : productsWithoutCost?.length}
                                </span>
                            </button>
                        ))}
                    </div>
                    <MarketplacesSwitch active={activeMp} setActive={setActiveMp} />
                </div>
                {
                    items && items.length > 0 ?
                        <Table>
                            <HeaderTable items="7">
                                <HeaderTableItem additionalClass={'photo'} name="Фото" />
                                <HeaderTableItem
                                    filterActive={activeFilter === 'name'}
                                    filterEvent={() => setFilter('name')}
                                    additionalClass={'name'}
                                    name="Название товара"
                                    filter="true"
                                />
                                <HeaderTableItem
                                    filterActive={activeFilter === 'vendorCode'}
                                    filterEvent={() => setFilter('vendorCode')}
                                    additionalClass={'vandorCode'}
                                    name="Артикул"
                                    filter="true"
                                />
                                <HeaderTableItem
                                    filterActive={activeFilter === 'barcode'}
                                    filterEvent={() => setFilter('barcode')}
                                    name="Баркод"
                                    additionalClass={'costPrice-price'}
                                    filter="true"
                                />
                                <HeaderTableItem
                                    filterActive={activeFilter === 'size'}
                                    filterEvent={() => setFilter('size')}
                                    additionalClass={'costPrice-size'}
                                    name="Размер"
                                    filter="true"
                                />
                                <HeaderTableItem
                                    filterActive={activeFilter === 'purchase_price'}
                                    filterEvent={() => setFilter('purchase_price')}
                                    name="Себестоимость"
                                    additionalClass={'costPrice-price'}
                                    filter="true"
                                />
                                <HeaderTableItem
                                    filterActive={activeFilter === 'price_change'}
                                    filterEvent={() => setFilter('price_change')}
                                    additionalClass={'dinamic'}
                                    name="Динамика"
                                    filter="true"
                                />
                            </HeaderTable>
                            <div className={styles.tableContent}>
                                <BodyTable>
                                    {items.map((item) => {
                                        return (
                                            <BodyTableLine
                                                event={(e) => itemClick(item, e)}
                                                additionalClass={activeItem === item.id ? 'bordered' : ''}
                                                key={item.id}
                                                items="costPrice"
                                            >
                                                <BodyTableLineItem
                                                    additionalClass={'photo'}
                                                    value={
                                                        item.photo_url ? (
                                                            <RowImage item={item} />
                                                        ) : (
                                                            <span className={styles.noImage}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="m20.475 23.3l-2.3-2.3H4q-.825 0-1.413-.588T2 19V7q0-.825.588-1.413T4 5h1.025v2.85L.675 3.5L2.1 2.075l19.8 19.8l-1.425 1.425ZM22 19.125l-5.525-5.55q.125-1.05-.213-2.037T15.176 9.8q-.725-.725-1.713-1.063t-2.037-.212L7.5 4.625L9 3h6l1.85 2H20q.825 0 1.413.588T22 7v12.125ZM12 17.5q.575 0 1.113-.125t1.037-.4L8.025 10.85q-.275.5-.4 1.038T7.5 13q0 1.875 1.313 3.188T12 17.5Zm0-2q-1.05 0-1.775-.725T9.5 13q0-.5.188-.963t.537-.812l3.55 3.55q-.35.35-.812.538T12 15.5Z"
                                                            />
                                                        </svg>
                                                    </span>
                                                        )
                                                    }
                                                />
                                                <BodyTableLineItem
                                                    additionalClass={'name'}
                                                    value={
                                                        item.name ? (
                                                            item.name
                                                        ) : (
                                                            <p className={styles.emptyName}>Ошибка данных</p>
                                                        )
                                                    }
                                                />
                                                <BodyTableLineItem
                                                    additionalClass={'vandorCode'}
                                                    value={
                                                        <>
                                                            {item.article_OZON && (
                                                                <p style={{ margin: 0 }}>
                                                            <span
                                                                style={{ fontWeight: 500, color: '#8496AF' }}
                                                            >
                                                                OZON:
                                                            </span>
                                                                    {item.article_OZON}
                                                                </p>
                                                            )}
                                                            {item.vendorCode && (
                                                                <p style={{ margin: 0 }}>
                                                            <span
                                                                style={{ fontWeight: 500, color: '#8496AF' }}
                                                            >
                                                                WB:
                                                            </span>
                                                                    {item.vendorCode}
                                                                </p>
                                                            )}
                                                        </>
                                                    }
                                                />
                                                <BodyTableLineItem
                                                    additionalClass={'costPrice-price'}
                                                    value={
                                                        <p>
                                                            {item.barcode_set?.[0] ? item.barcode_set[0].code : ''}{' '}
                                                            <span style={{ fontWeight: 700, color: '#8496AF' }}>
                                                        {item.barcode_set?.length > 1
                                                            ? `+${item.barcode_set?.length - 1}`
                                                            : ''}
                                                    </span>
                                                        </p>
                                                    }
                                                />
                                                <BodyTableLineItem
                                                    additionalClass={'costPrice-size'}
                                                    value={item.size}
                                                />
                                                <BodyTableLineItem
                                                    additionalClass={'costPrice-price'}
                                                    value={
                                                        <div className={styles.inputWrap}>
                                                            {/*TODO: изменить тип поля, чтобы при клике открывался всплывающее окно с вводом закуп.цены и кол-ва*/}
                                                            <input
                                                                placeholder="Стоимость"
                                                                id={'input'}
                                                                onFocus={() => setActiveItem(item.id)}
                                                                onBlur={(e) => {
                                                                    setActiveItem(null);
                                                                    if (e.target.value) {
                                                                        changePrice(item, e.target.value);
                                                                    }
                                                                }}
                                                                className={styles.priceInput}
                                                                defaultValue={item.purchase_price}
                                                            />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M12.1401 2.89182L12.1401 2.89183L13.1067 3.85849C13.608 4.3598 13.608 5.17132 13.1067 5.67255L5.91533 12.8645C5.84904 12.9308 5.76218 12.9755 5.67268 12.9922L5.6725 12.9922L3.06274 13.4756L3.06273 13.4756L3.03541 13.3281C2.93435 13.3468 2.83039 13.3146 2.75753 13.2418C2.68476 13.169 2.65251 13.0651 2.67124 12.9639L12.1401 2.89182ZM12.1401 2.89182C11.6399 2.3916 10.8264 2.39163 10.3262 2.89182L10.3262 2.89183L3.13476 10.0838L12.1401 2.89182ZM12.0744 5.39684L5.58828 11.8834L4.11585 10.4108L10.6019 3.92429L10.6019 3.92429L10.9802 3.54593C11.1196 3.40656 11.3467 3.40653 11.4861 3.54593L12.4527 4.51259L12.5587 4.40653L12.4527 4.5126C12.5925 4.65245 12.5925 4.87861 12.4527 5.01849L12.5587 5.12455L12.4526 5.01849L12.0744 5.39684ZM4.63958 12.2428L3.55575 12.4435L3.75646 11.3596L4.63958 12.2428Z"
                                                                    fill="#DCE7F1"
                                                                    stroke="#DCE7F1"
                                                                    strokeWidth="0.3"
                                                                />
                                                            </svg>
                                                        </div>
                                                    }
                                                />
                                                <BodyTableLineItem
                                                    value={
                                                        !item.price_change ? (
                                                            '0%'
                                                        ) : (
                                                            <p>
                                                                {item.price_change.toFixed(0)}%
                                                                {item.price_change < 0 ? (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                    >
                                                                        <g clipPath="url(#clip0_825_7798)">
                                                                            <path
                                                                                d="M18.5463 10.8998L21.7238 17.7539M21.7238 17.7539L14.0463 18.694M21.7238 17.7539L10.1688 11.0826L13.1669 5.86347L5.32074 1.36861"
                                                                                stroke="#1AB889"
                                                                                strokeWidth="2"
                                                                                strokeMiterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_825_7798">
                                                                                <rect width="24" height="24" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                ) : (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                    >
                                                                        <g clipPath="url(#clip0_816_7786)">
                                                                            <path
                                                                                d="M14.3897 5.40032L21.798 3.9193M21.798 3.9193L20.9109 11.6031M21.798 3.9193L12.6022 13.5869L8.23203 9.44818L2.02202 16.0209"
                                                                                stroke="#FF5E5B"
                                                                                strokeWidth="2"
                                                                                strokeMiterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_816_7786">
                                                                                <rect width="24" height="24" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                )}
                                                            </p>
                                                        )
                                                    }
                                                    additionalClass={'dinamic'}
                                                />
                                            </BodyTableLine>
                                        );
                                    })}
                                </BodyTable>
                            </div>
                        </Table> :
                        <p style={{marginTop: '30px'}} className={styles.empty}>Нет данных</p>
                }
            </div>
        </section>
    );
});

export default CostPrice;
