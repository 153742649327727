import React, { useState, useEffect } from "react";
import Item from "../../view/item/index.jsx";
import { API_URL, postData, getData, putDataNew } from "../../../../static/js/request.js";
import { useNavigate, useParams } from 'react-router-dom';


const AcceptanceItem = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [acceptanceParams, setAcceptanceParams] = useState({
        id: '',
        user:'',
        itemType:"acceptance",
        formTitle: 'Приемка',
        dialogMessage: 'Сохранить приемку и перейти к созданию отгрузки',
        actionDate: '',
        status: '',
        columnWidth: '356px',
        columns: {
            warehouse: {
                label: 'Склад',
                alias: 'warehouse',
                placeholder: 'Выберите склад',
                value: '',
                options: [
                    { name: 'Склад 1', id: 3 },
                    { name: 'Склад 2', id: 70 }
                ]
            },
            organisation: {
                label: 'Организация',
                alias: 'organisation',
                placeholder: 'Выберите организацию',
                value: '',
                required: true,
                options: []
            },
            counterparty: {
                label: 'Конрагент',
                alias: 'counterparty',
                placeholder: 'Выберите контрагента',
                value: '',
                required: true,
                options: [
                    { name: 'Конрагент 1', id: 3 },
                    { name: 'Конрагент 2', id: 3 }
                ]
            }

        },
        comment: '',
        products: [],
        completed: false

    })

    const itemAction = async () => {

        const requestData = {
            id: acceptanceParams.id || 0,
            user: acceptanceParams.user || 0,
            warehouse: acceptanceParams.columns.warehouse.value,
            organisation: acceptanceParams.columns.organisation.value,
            status: acceptanceParams.status,
            counterparty: acceptanceParams.columns.counterparty.value,
            acceptance_datetime: acceptanceParams.actionDate,
            comment: acceptanceParams.comment,
            products: acceptanceParams.products
        }

        if (id) {
            
            await putDataNew(API_URL + '/api/v1/purchase/acceptance/', requestData)
        }

        else {
            await postData(API_URL + '/api/v1/purchase/acceptance/', requestData)
        }



    }

    useEffect(() => {

        if (id) {
            getData(API_URL + `/api/v1/purchase/acceptance/${id}/`)
                .then((data) => {                   

                    setAcceptanceParams(prev => ({
                        ...prev,
                        comment:data[0]['comment'],
                        id: data[0]['id'],
                        user: data[0]['user'],
                        status: data[0]['status'],
                        actionDate: data[0]['purchase_date'],
                        columns: {
                            ...prev.columns,
                            organisation:{
                                ...prev.columns.organisation,
                                value: data[0]['organisation']['id']
                            },
                            counterparty:{
                                ...prev.columns.counterparty,
                                value: data[0]['counterparty']['id']
                            },
                            warehouse:{
                                ...prev.columns.warehouse,
                                value: data[0]['warehouse']['id']
                            }

                        },
                        //products: data[0]['products']
                        

                    }))
                });
        }

        else {
            getData(API_URL + '/api/v1/account/').then((data) => {
                setAcceptanceParams(prev => ({
                    ...prev,
                    user: data['id']
                }))
            });
        }

    }, [])

    return (

        <Item            
            title={id ? "Изменение приемки" : "Создание приемки"}
            itemParams={acceptanceParams}
            setItemParams={(item) => setAcceptanceParams(item)}
            itemAction={() => itemAction()}
        />

    )

}

export default AcceptanceItem