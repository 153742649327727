import React, { useEffect, useRef, useState } from 'react';
import style from './DDS.module.scss';
import cx from 'classnames';
import { getData, API_URL } from '../../../static/js/request';
import YearSelect from '../../Balance/YearSelect/index.jsx';
import { formatNumber2 } from '../../../static/js/commonFunc';
import { onMainScroll } from '../../Advertisement/index.jsx';
import Row from './Row.jsx';
import ValueRow from './ValueRow.jsx';

const counterFormat = (counter) => {
    const nf = Intl.NumberFormat();
    return nf.format(counter);
};

const pnlData = (year) => getData(`${API_URL}/api/v1/report/dds/?year=${year}`);

const DDS = () => {
    const [rerender, setRerender] = useState(false);
    const [pnlDate, setPnlDate] = useState([]);
    const [pnlDataValue, setPnlDataValue] = useState([]);
    const [pnlDataBalance, setPnlDataBalance] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [isActiveNested, setIsActiveNested] = useState(false);
    const mainTableFinanceLeft = useRef(null);
    const mainTableFinanceRight = useRef(null);

    const handleClick = (evt, index) => {
        mainTableFinanceRight.current
            .querySelectorAll(`.${style.financingRowParent}`)
            .forEach((el, indexEl) => {
                if (index === indexEl) {
                    el.classList.toggle(style.active);
                }
            });
        evt.target
            .closest(`.${style.financingRowParent}`)
            .classList.toggle(style.active);
        setIsActive(!isActive);
    };

    const handleClickNestedEl = (evt, index, mainIndex) => {
        mainTableFinanceRight.current
            .querySelectorAll(`.${style.financingRowParent}`)
            .forEach((el, index1) => {
                if (mainIndex === index1) {
                    el.querySelectorAll(`.${style.financingTableNestedBlock}`).forEach(
                        (el, indexEl) => {
                            if (index === indexEl) {
                                el.classList.toggle(style.active);
                            }
                        }
                    );
                }
            });
        evt.target
            .closest(`.${style.financingTableNestedBlock}`)
            .classList.toggle(style.active);
        setIsActiveNested(!isActiveNested);
    };

    const [allYears, setAllYears] = useState([]);

    const [year, changeYear] = useState(new Date().getFullYear().toString());

    React.useEffect(() => {
        pnlData(year).then((data) => {
            console.log(data)
            // setPnlDate(data.date);
            // setPnlDataValue(data.data);
            // setPnlDataBalance(data.balances);
            setAllYears(data.months_names);
        });
    }, [rerender, year]);

    const [hoveredRow, setHoveredRow] = useState('');
    const [hoveredCol, setHoveredCol] = useState('');

    const onHoverRow = (item) => {
        setHoveredRow(item);
    };

    const [openedRows, setOpenedRows] = useState([]);

    useEffect(() => {
        console.log(openedRows);
    }, [openedRows]);

    return (
        <>
            <h1 className={style.title}>Движение денежных средств</h1>
            <main className={style.main}>
                <div className={style.details}>
                    <div>Отчетный год</div>
                    <YearSelect
                        allValues={allYears}
                        width={'120px'}
                        onChange={changeYear}
                        value={year}
                    />
                </div>

                <section
                    className={cx(
                        style['fd-c'],
                        style['flex'],
                        style['row-gap-10'],
                        style['overflow-auto']
                    )}
                >
                    <div className={style.topRow}>
                        <div
                            className={style.datesWrap}
                            id="scrollableOfMain"
                            onScroll={onMainScroll}
                        >
                            {pnlDate.map((item, index) => (
                                <div
                                    onMouseEnter={() => setHoveredCol(index)}
                                    onMouseLeave={() => setHoveredCol('')}
                                    key={index}
                                    className={`${cx(
                                        style.financingTableColumn,
                                        style.financingTableColumnHeader
                                    )} ${hoveredCol === index ? style.hovered : ''}`}
                                    data-year={item[0]}
                                >
                                    {item[1]}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={style.wrap}>
                        {
                            Object.entries(pnlDataValue).length > 0 && pnlDataValue ?
                                <div className={style.mainTableFinance}>
                                    <div
                                        className={style.mainTableFinanceLeft}
                                        ref={mainTableFinanceLeft}
                                    >
                                        <div
                                            onMouseEnter={() =>
                                                onHoverRow('Остаток средств на начало месяца')
                                            }
                                            onMouseLeave={() => setHoveredRow('')}
                                            className={cx(
                                                style['gap-10'],
                                                style['flex'],
                                                style['ai-c'],
                                                style['lineHeight']
                                            )}
                                        >
                                            <div
                                                className={`${style.financingTable} ${
                                                    hoveredRow === 'Остаток средств на начало месяца'
                                                        ? style.hovered
                                                        : ''
                                                }`}
                                            >
                                                <span>Остаток средств на начало месяца</span>
                                            </div>
                                        </div>

                                        {Object.entries(pnlDataValue).map((item) => (
                                            <Row setOpenedRows={setOpenedRows} item={item} openedRows={openedRows} />
                                        ))}

                                        <div
                                            onMouseEnter={() =>
                                                onHoverRow('Остаток средств на конец месяца')
                                            }
                                            onMouseLeave={() => setHoveredRow('')}
                                            className={cx(
                                                style['gap-10'],
                                                style['flex'],
                                                style['ai-c'],
                                                style['lineHeight']
                                            )}
                                        >
                                            <div
                                                className={`${style.financingTable} ${
                                                    hoveredRow === 'Остаток средств на конец месяца'
                                                        ? style.hovered
                                                        : ''
                                                }`}
                                            >
                                                <span>Остаток средств на конец месяца</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={style.mainTableFinanceRight}
                                        ref={mainTableFinanceRight}
                                        id="scrollableOfMain"
                                        onScroll={onMainScroll}
                                    >
                                        {/*--*/}
                                        <div
                                            className={`${style.mainLine} ${cx(
                                                style['gap-10'],
                                                style['flex'],
                                                style['ai-c'],
                                                style['lineHeight']
                                            )} ${
                                                hoveredRow === 'Остаток средств на начало месяца'
                                                    ? style.hovered
                                                    : ''
                                            }`}
                                        >
                                            {Object.entries(pnlDataBalance).map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={`${style.financingTableColumn} ${
                                                        hoveredCol === index ? style.hovered : ''
                                                    }`}
                                                >
                                                    <span>{formatNumber2(item[1]['начальный остаток'])}</span>
                                                </div>
                                            ))}
                                        </div>
                                        {/*--*/}

                                        {Object.entries(pnlDataValue).map((item) => (
                                            <ValueRow setHoveredCol={setHoveredCol} item={item} openedRows={openedRows} />
                                        ))}

                                        {/*--*/}
                                        <div
                                            className={`${style.mainLine} ${cx(
                                                style['gap-10'],
                                                style['flex'],
                                                style['ai-c'],
                                                style['lineHeight']
                                            )} ${
                                                hoveredRow === 'Остаток средств на конец месяца'
                                                    ? style.hovered
                                                    : ''
                                            }`}
                                        >
                                            {Object.entries(pnlDataBalance).map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={`${style.financingTableColumn} ${
                                                        hoveredCol === index ? style.hovered : ''
                                                    }`}
                                                >
                                                    <span>{formatNumber2(item[1]['конечный остаток'])}</span>
                                                </div>
                                            ))}
                                        </div>
                                        {/*--*/}
                                    </div>
                                </div>
                                :  <p style={{marginTop: '30px'}} className={style.empty}>Нет данных</p>
                        }
                    </div>
                    <div
                        className={style.mainScroll}
                        style={{ maxWidth: `${170 * pnlDate.length + 120}px` }}
                        onScroll={onMainScroll}
                        id="scrollableOfMain"
                    >
                        <span style={{ width: `${170 * pnlDate.length + 120}px` }} />
                    </div>
                </section>
            </main>
        </>
    );
};

export default DDS;
