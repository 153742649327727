"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaymentHistory = void 0;
const react_1 = require("react");
const payment_history_api_1 = require("../api/payment-history-api");
const usePaymentHistory = (staffId) => {
    const [data, setData] = (0, react_1.useState)(null);
    const [details, setDetails] = (0, react_1.useState)(null);
    const [loading, setLoading] = (0, react_1.useState)(true);
    const [error, setError] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const loadData = async () => {
            try {
                const response = await (0, payment_history_api_1.fetchPaymentHistoryByMonth)(staffId);
                setData(response);
            }
            catch (err) {
                setError(err instanceof Error ? err.message : 'Unknown error');
            }
            finally {
                setLoading(false);
            }
        };
        loadData();
    }, [staffId]);
    const loadDetails = async (month, year) => {
        try {
            const response = await (0, payment_history_api_1.fetchPaymentDetailsByMonth)(staffId, month, year);
            setDetails(response);
        }
        catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
        }
    };
    return { data, details, loading, error, loadDetails };
};
exports.usePaymentHistory = usePaymentHistory;
