import React, { useState, useEffect, useRef } from 'react';
import style from '../Financing.module.scss';
import Table from '../../../components/custom/Table.jsx';
import HeaderTable from '../../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../../components/custom/HeaderTableItem.jsx';
import BodyTable from '../../../components/custom/BodyTable.jsx';
import CashboxItemBody from './CashboxItemBody.jsx';
import { CashboxStore } from '../../../store/Financing/CashboxStore';
import { observer } from 'mobx-react-lite';
import commonStyle from '../../../static/css/commonStyle.module.scss';
import {
    applyPriceNoSymbolCurrency,
    formatNumber2,
} from '../../../static/js/commonFunc';
import ArticleStore from '../../../store/Financing/ArticleStore';
import cx from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import useOnClickOutside from '../../../core/Hooks/useOnClickOutside';
import NumberInput from '../../../components/custom/NumberInput.jsx';
import { API_URL, getData, postData } from '../../../static/js/request';
import YearSelect from '../../Balance/YearSelect/index.jsx';
import { NewCustomInput } from '../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import dayjs from 'dayjs';
import { Calendar } from 'react-date-range';
import 'react-calendar/dist/Calendar.css';
import { ru } from 'date-fns/locale';
import ChangeTitle from '../../../components/custom/ChangeTitle/index.jsx';

const CashboxItem = observer(() => {
    const [state] = useState(() => new CashboxStore());

    const [isSelectDataVisible, setIsSelectDataVisible] = useState(false);
    const [isSelectExpenseVisible, setIsSelectExpenseVisible] = useState(false);
    const [isSelectComingVisible, setIsSelectComingVisible] = useState(false);

    const selectDataContainerRef = useRef(null);
    const selectExpenseContainerRef = useRef(null);
    const selectComingContainerRef = useRef(null);

    const navigate = useNavigate();

    const [visibleAddedOperation, setVisibleAddedOperation] = useState(false);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const dataInputs = useRef(null);
    const addedOperation = useRef(null);
    const [input1Value, setInput1Value] = useState('');
    const [input2Value, setInput2Value] = useState('');
    const queryParameters = new URLSearchParams(window.location.search);
    const [selectYear, setSelectYear] = useState(2023);
    const id = queryParameters.get('id');

    const [articles, setArticles] = useState([]);

    const [selectAddArticle, setAddArticle] = useState({});

    const handleInput1Change = (input, name) => {
        setInput1Value(input);
        state.newArticle['amount'] = input;
        state.newArticle['operation_type'] = name;
    };

    const handleInput2Change = (input, name) => {
        setInput2Value(input);
        state.newArticle['amount'] = input;
        state.newArticle['operation_type'] = name;
    };

    useEffect(() => {
    }, []);

    const showSelectData = (ident) => {
        if (ident === 'date') {
            setIsSelectDataVisible(!isSelectDataVisible);
        } else if (ident === 'coming') {
            setIsSelectExpenseVisible(!isSelectExpenseVisible);
        } else if (ident === 'expense') {
            setIsSelectComingVisible(!isSelectComingVisible);
        }
    };

    useOnClickOutside(selectDataContainerRef, () =>
        setIsSelectDataVisible(false)
    );
    useOnClickOutside(selectExpenseContainerRef, () =>
        setIsSelectExpenseVisible(false)
    );
    useOnClickOutside(selectComingContainerRef, () =>
        setIsSelectComingVisible(false)
    );

    const showAddedOperation = () => {
        setVisibleAddedOperation(!visibleAddedOperation);
    };

    const handleMonthSelection = (month) => {
        if (selectedMonths.includes(month + 1)) {
            setSelectedMonths(selectedMonths.filter((m) => m !== month + 1));
        } else {
            setSelectedMonths([...selectedMonths, month + 1]);
        }
    };

    const handleResetSelection = () => {
        setSelectedMonths([]);
    };

    const handleAllCheckSelection = () => {
        setSelectedMonths([...Array(12).keys()].map((m) => m + 1));
    };

    const handleApplySelection = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const id = queryParameters.get('id');
        const convertedString = selectedMonths.map((month) => month).join(',');
        setIsSelectDataVisible(false);
        state.overwriteValue(id, convertedString);
    };

    const [searchResults, updateSearchResults] = useState([]);

    const [startDate, setStartDate] = useState(
        dayjs(new Date()).format('DD.MM.YYYY')
    );

    const getSearchResults = () => {
        const searchResultsResponse = Object.entries(ArticleStore.fields);
        updateSearchResults(searchResultsResponse);
    };

    const [inputValue, setInputValue] = useState(state.cashboxName);

    const handleInputChange = (text) => {
        setInputValue(text);
    };

    const handleInputBlur = () => {
        const data = {
            id: id,
            name: inputValue,
        };

        postData(API_URL + '/api/v1/cashbox/update/', data).then((response) => {
        });
    };

    useEffect(() => {
        setInputValue(state.cashboxName);
        getData(API_URL + '/api/v1/articles-management/all/?chapter=cashbox').then((response) => {
            setArticles(response);
        });
    }, [state.cashboxName]);

    const inputRef = useRef();

    useEffect(() => {
    }, [articles])

    const handleInput = (str, oldValue, change) => {
        if (str.length === oldValue.length + 1) {
            if (str.length === 2 || str.length === 5) {
                str += '.';
            }
        }

        if (str.length <= 10) {
            change(str);
        }
    };

    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    const [startDateCalendar, setStartDateCalendar] = useState(new Date());
    const [showStartCalendar, setShowStartCalendar] = useState(false);

    return (
        <main>
            {showStartCalendar && (
                <span
                    onClick={() => {
                        setShowStartCalendar(false);
                        inputRef.current.blur();
                    }}
                    className={style.calendarCloseBg}
                />
            )}
            <div className={style.CashboxHeader}>
                <ChangeTitle
                    arrowFunc={() => {
                        if (!location.pathname.includes('/financing/cashbox/')) {
                            navigate('/financing/cashbox');
                        } else {
                            navigate('/financing/cashbox');
                        }
                    }}
                    title={inputValue}
                    onChange={(text) => {
                        handleInputChange(text);
                    }}
                    onBlur={handleInputBlur}
                />
                <div className={style.CashboxHeaderUser}>
                    <span>Ответственный</span>
                    <span>{state.cashboxResponsible}</span>
                </div>
            </div>

            <section className={style.CashboxItemSection}>
                <div className={style.CashboxItemSectionHeader}>
                    <div className={style.CashboxItemBalance}>
                        <div className={style.CashboxItemBalanceCount}>
                            <div>Входящий остаток</div>
                            <div>{formatNumber2(state.cashboxIncoming_balance) || 0}</div>
                        </div>
                        <div className={style.CashboxItemBalanceCount}>
                            <div>Исходящий остаток</div>
                            <div>{formatNumber2(state.cashboxOutgoing_balance) || 0}</div>
                        </div>
                    </div>
                    <div className={style.CashboxItemButton} ref={addedOperation}>
                        <button
                            onClick={() => {
                                showAddedOperation();
                            }}
                        >
                            + Добавить операцию
                        </button>
                    </div>
                </div>

                {visibleAddedOperation && (
                    <div className={style.addedArticle}>
                        <div className={style.addedArticleFields}>
                            <label className={style.addedArticleLabel}>
                                <span>Дата</span>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        handleInput(e.target.value, startDate, setStartDate)
                                    }
                                    value={startDate}
                                    onClick={() => {
                                        setShowStartCalendar(true);
                                    }}
                                    ref={inputRef}
                                />
                                {showStartCalendar && (
                                    <>
                                        <Calendar
                                            className={`${style.calendar} calendar`}
                                            rangeColors={['#3d91ff']}
                                            onChange={(ranges) => {
                                                setStartDate(
                                                    dayjs(new Date(ranges)).format('DD.MM.YYYY')
                                                );
                                                setStartDateCalendar(new Date(ranges));
                                                setShowStartCalendar(false);
                                            }}
                                            value={startDateCalendar}
                                            months={1}
                                            fixedHeight={true}
                                            locale={customLocale}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            direction="horizontal"
                                            showDateDisplay={false}
                                            defaultValue={startDateCalendar}
                                        />
                                    </>
                                )}
                            </label>
                            <label className={style.addedArticleLabel}>
                                <span>Приход</span>
                                <NewCustomInput
                                    value={input1Value}
                                    onChange={(text) => handleInput1Change(text, 'income')}
                                    disabled={input2Value !== ''}
                                    placeholder=""
                                    numeric
                                    name="income"
                                />
                            </label>
                            <label className={style.addedArticleLabel}>
                                <span>Расход</span>
                                <NewCustomInput
                                    value={input2Value}
                                    onChange={(text) => handleInput2Change(text, 'expense')}
                                    disabled={input1Value !== ''}
                                    placeholder=""
                                    numeric
                                    name="expense"
                                />
                            </label>
                            {/*<label className={style.addedArticleLabel}>
                                <span>Операция</span>
                                <select onChange={state.handleChangeBanks} defaultValue={"default"} name='operation_type'>
                                    <option value={"default"} disabled>
                                        Выберите операцию
                                    </option>
                                    <option value="income">Приход</option>
                                    <option value="expense">Расход</option>
                                </select>
                            </label>*/}

                            <div
                                className={style.addedArticleLabel}
                                style={{ width: '280px' }}
                            >
                                <span>Статья</span>
                                <YearSelect
                                    width={'250px'}
                                    input
                                    object
                                    placeholder={'Выберите статью'}
                                    onChange={setAddArticle}
                                    allValues={articles}
                                    value={selectAddArticle.article}
                                />
                            </div>

                            <label className={`${style.addedArticleLabel} ${style.comment}`}>
                                <span>Комментарий</span>
                                <input
                                    type="text"
                                    placeholder="Введите комментарий"
                                    name="comment"
                                    onChange={state.handleChangeBanks}
                                />
                            </label>
                        </div>

                        <div className={style.addedArticleButtons}>
                            <button
                                onClick={() => {
                                    state.handleSubmit(selectAddArticle, startDateCalendar);
                                    setVisibleAddedOperation(!visibleAddedOperation);
                                    setInput1Value('');
                                    setInput2Value('');
                                    setAddArticle('');
                                }}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                )}

                <div className={style.wrap} style={{ height: 'calc(100vh - 255px)' }}>
                    <Table>
                        <HeaderTable items="cashbox">
                            <HeaderTableItem
                                name="Дата"
                                filterEvent={() => {
                                    showSelectData('date');
                                }}
                                additionalClass="selectData"
                                filter="true"
                                filterValue={
                                    isSelectDataVisible && (
                                        <div
                                            className={style.selectData}
                                            ref={selectDataContainerRef}
                                        >
                                            <div
                                                className={style.sortingTitle}
                                                onClick={() => {
                                                    state.sortByAscendingDate();
                                                    setIsSelectDataVisible(false);
                                                }}
                                            >
                                                Сортировка по возрастанию
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M8.5 13.5C8.5 13.7761 8.27614 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5L8.5 13.5ZM7.64645 3.14645C7.84171 2.95118 8.15829 2.95118 8.35355 3.14645L11.5355 6.32843C11.7308 6.52369 11.7308 6.84027 11.5355 7.03553C11.3403 7.2308 11.0237 7.2308 10.8284 7.03553L8 4.20711L5.17157 7.03553C4.97631 7.2308 4.65973 7.2308 4.46447 7.03553C4.2692 6.84027 4.2692 6.52369 4.46447 6.32843L7.64645 3.14645ZM7.5 13.5L7.5 3.5L8.5 3.5L8.5 13.5L7.5 13.5Z"
                                                        fill="#35373A"
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                className={style.sortingTitle}
                                                onClick={() => {
                                                    state.sortByDescendingDate();
                                                    setIsSelectDataVisible(false);
                                                }}
                                            >
                                                Сортировка по убыванию
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M8.5 3.5C8.5 3.22386 8.27614 3 8 3C7.72386 3 7.5 3.22386 7.5 3.5L8.5 3.5ZM7.64645 13.8536C7.84171 14.0488 8.15829 14.0488 8.35355 13.8536L11.5355 10.6716C11.7308 10.4763 11.7308 10.1597 11.5355 9.96447C11.3403 9.7692 11.0237 9.7692 10.8284 9.96447L8 12.7929L5.17157 9.96447C4.97631 9.7692 4.65973 9.7692 4.46447 9.96447C4.2692 10.1597 4.2692 10.4763 4.46447 10.6716L7.64645 13.8536ZM7.5 3.5L7.5 13.5L8.5 13.5L8.5 3.5L7.5 3.5Z"
                                                        fill="#35373A"
                                                    />
                                                </svg>
                                            </div>
                                            <div className={style.selectDataTitle}>
                                                Фильтровать по дате
                                            </div>
                                            <div className={style.selectDataInputsButton}>
                                                <button onClick={handleAllCheckSelection}>
                                                    Выбрать все
                                                </button>
                                                <button onClick={handleResetSelection}>Сбросить</button>
                                            </div>
                                            <YearSelect
                                                value={selectYear}
                                                onChange={setSelectYear}
                                                width={'100%'}
                                                allValues={
                                                    state.filterYears.length > 0
                                                        ? state.filterYears
                                                        : [new Date().getFullYear()]
                                                }
                                            />
                                            <div className={style.months} ref={dataInputs}>
                                                {[
                                                    'Январь',
                                                    'Февраль',
                                                    'Март',
                                                    'Апрель',
                                                    'Май',
                                                    'Июнь',
                                                    'Июль',
                                                    'Август',
                                                    'Сентябрь',
                                                    'Октябрь',
                                                    'Ноябрь',
                                                    'Декабрь',
                                                ].map((month, index) => (
                                                    // <label key={index}>
                                                    //   <input
                                                    //     type="checkbox"
                                                    //     checked={selectedMonths.includes(index + 1)}
                                                    //     onChange={() => handleMonthSelection(index)}
                                                    //   />
                                                    //   {month}
                                                    // </label>
                                                    <button
                                                        key={index}
                                                        className={
                                                            selectedMonths.includes(index + 1)
                                                                ? style.active
                                                                : ''
                                                        }
                                                        onClick={() => handleMonthSelection(index)}
                                                    >
                                                        {month}
                                                    </button>
                                                ))}
                                            </div>
                                            <div className={style.selectDataButtonContainer}>
                                                <button onClick={handleApplySelection}>Выбрать</button>
                                                <button onClick={() => setIsSelectDataVisible(false)}>
                                                    Отменить
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                            />
                            <HeaderTableItem
                                name="Приход"
                                additionalClass="cashBoxIncome"
                                filter="true"
                            /* filterEvent = {() => {showSelectData('coming')}}
                        filterValue = {isSelectExpenseVisible && (
                        <div className={style.selectData} ref={selectExpenseContainerRef}>
                            <div className={style.selectDataTitle}>Сортировка по возрастанию</div>
                            <div className={style.selectDataTitle}>Сортировка по убыванию</div>
                        </div>
                        )}*/
                            />
                            <HeaderTableItem
                                name="Расход"
                                additionalClass="alightAmountWidthHeader"
                                filter="true"
                            /*  filterEvent = {() => {showSelectData('expense')}}
                        filterValue = {isSelectComingVisible && (
                        <div className={style.selectData} ref={selectComingContainerRef}>
                            <div className={style.selectDataTitle}>Сортировка по возрастанию</div>
                            <div className={style.selectDataTitle}>Сортировка по убыванию</div>
                        </div>
                        )}*/
                            />
                            <HeaderTableItem name="Статья" filter="true" />
                            <HeaderTableItem name="Комментарии" filter="true" />
                        </HeaderTable>
                        <div className={style.scrollWrap}>
                            <BodyTable>
                                <CashboxItemBody articles={articles} state={state} />
                            </BodyTable>
                        </div>
                    </Table>
                </div>
            </section>
        </main>
    );
});

export default CashboxItem;
