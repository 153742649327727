import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { formatNumber2 } from '../../../../static/js/commonFunc';
import { onMainScroll } from '../../../Advertisement/index.jsx';

function RightSide({ tabs, openTabs, setHoveredRow, hovered }) {
  const onEnter = (item) => {
    setHoveredRow(item);
  };

  const onLeave = (item) => {
    setHoveredRow('');
  };

  if (!tabs) return <></>;

  return (
    <div
      className={styles.content}
      id="scrollableOfMain"
      onScroll={onMainScroll}
    >
      <div className={styles.item}>
        {Object.entries(tabs).map((firstLevel) => {
          if (firstLevel[0] !== 'Итого Активы') {
            return (
              <div className={styles.rowContentFirstLevel}>
                <div
                  className={`${styles.row} ${
                    firstLevel[0] === 'Итого Активы' ? styles.total : ''
                  } ${hovered === firstLevel[0] ? styles.hovered : ''}  ${
                    firstLevel[0] === 'Проверка' ? styles.check : ''
                  }`}
                  onMouseEnter={() => onEnter(firstLevel[0])}
                  onMouseLeave={() => onLeave(firstLevel[0])}
                >
                  {firstLevel[1].value.length > 0 ? (
                    firstLevel[1].value.map((price) => (
                      <div
                        className={`${styles.col}`}
                        style={{
                          color:
                            firstLevel[0] === 'Проверка'
                              ? price === 0
                                ? '#1AB889'
                                : '#FF5E5B'
                              : '',
                        }}
                      >
                        {firstLevel.length > 0 ? formatNumber2(price) : '?'}
                      </div>
                    ))
                  ) : (
                    <div
                      className={`${styles.col}`}
                      style={{
                        color:
                          firstLevel[0] === 'Проверка'
                            ? price === 0
                              ? '#1AB889'
                              : '#FF5E5B'
                            : '',
                      }}
                    >
                      {'?'}
                    </div>
                  )}
                </div>
                {firstLevel[1].nestedEl &&
                  Object.entries(firstLevel[1].nestedEl).map((secondLevel) => (
                    <div
                      className={styles.rowContentFirstLevel}
                      style={{
                        height: openTabs.includes(firstLevel[0]) ? 'auto' : '0',
                      }}
                    >
                      <div
                        className={`${styles.row} ${
                          hovered === secondLevel[0] ? styles.hovered : ''
                        }`}
                        onMouseEnter={() => onEnter(secondLevel[0])}
                        onMouseLeave={() => onLeave(secondLevel[0])}
                      >
                        {secondLevel[1].value.map((price) => (
                          <div className={`${styles.col}`}>
                            {formatNumber2(price)}
                          </div>
                        ))}
                      </div>
                      {secondLevel[1].nestedEl &&
                        Object.entries(secondLevel[1].nestedEl).map(
                          (thirdLevel) => (
                            <div
                              className={styles.rowContentFirstLevel}
                              style={{
                                height: openTabs.includes(secondLevel[0])
                                  ? 'auto'
                                  : '0',
                              }}
                            >
                              <div
                                className={`${styles.row} ${
                                  hovered === thirdLevel[0]
                                    ? styles.hovered
                                    : ''
                                }`}
                                onMouseEnter={() => onEnter(thirdLevel[0])}
                                onMouseLeave={() => onLeave(thirdLevel[0])}
                              >
                                {thirdLevel[1].value.map((price) => (
                                  <div className={`${styles.col}`}>
                                    {formatNumber2(price)}
                                  </div>
                                ))}
                              </div>
                              {thirdLevel[1].nestedEl &&
                                Object.entries(thirdLevel[1].nestedEl).map(
                                  (fourthLevel) => (
                                    <div
                                      className={styles.rowContentFirstLevel}
                                      style={{
                                        height: openTabs.includes(thirdLevel[0])
                                          ? 'auto'
                                          : '0',
                                      }}
                                    >
                                      <div
                                        className={`${styles.row} ${
                                          hovered === fourthLevel[0]
                                            ? styles.hovered
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          onEnter(fourthLevel[0])
                                        }
                                        onMouseLeave={() =>
                                          onLeave(fourthLevel[0])
                                        }
                                      >
                                        {fourthLevel[1].value.map((price) => (
                                          <div className={`${styles.col}`}>
                                            {formatNumber2(price)}
                                          </div>
                                        ))}
                                      </div>
                                      {fourthLevel[1].nestedEl &&
                                        Object.entries(
                                          fourthLevel[1].nestedEl
                                        ).map((fifthLevel) => (
                                          <div
                                            className={
                                              styles.rowContentFirstLevel
                                            }
                                            style={{
                                              height: openTabs.includes(
                                                fourthLevel[0]
                                              )
                                                ? 'auto'
                                                : '0',
                                            }}
                                          >
                                            <div
                                              className={`${styles.row} ${
                                                hovered === fifthLevel[0]
                                                  ? styles.hovered
                                                  : ''
                                              }`}
                                              onMouseEnter={() =>
                                                onEnter(fifthLevel[0])
                                              }
                                              onMouseLeave={() =>
                                                onLeave(fifthLevel[0])
                                              }
                                            >
                                              {fifthLevel[1].value.map(
                                                (price) => (
                                                  <div
                                                    className={`${styles.col}`}
                                                  >
                                                    {formatNumber2(price)}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                            {fifthLevel[1].nestedEl &&
                                              Object.entries(
                                                fifthLevel[1].nestedEl
                                              ).map((sixthLevel) => (
                                                <div
                                                  className={
                                                    styles.rowContentFirstLevel
                                                  }
                                                  style={{
                                                    height: openTabs.includes(
                                                      fifthLevel[0]
                                                    )
                                                      ? 'auto'
                                                      : '0',
                                                  }}
                                                >
                                                  <div
                                                    className={`${styles.row} ${
                                                      hovered === sixthLevel[0]
                                                        ? styles.hovered
                                                        : ''
                                                    }`}
                                                    onMouseEnter={() =>
                                                      onEnter(sixthLevel[0])
                                                    }
                                                    onMouseLeave={() =>
                                                      onLeave(sixthLevel[0])
                                                    }
                                                  >
                                                    {sixthLevel[1].value.map(
                                                      (price) => (
                                                        <div
                                                          className={`${styles.col}`}
                                                        >
                                                          {formatNumber2(price)}
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        ))}
                                    </div>
                                  )
                                )}
                            </div>
                          )
                        )}
                    </div>
                  ))}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default RightSide;
