import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    Chart as ChartJSX,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './styles.module.scss'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatNumber2 } from '../../../../../static/js/commonFunc.js';
import { ProfitTooltip } from './ProfitTooltip.jsx';


ChartJSX.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);




export function Profit({ allData, point, allProfit }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipData, setTooltipData] = useState(null);
    const [tooltipPos, setTooltipPos] = useState(null);
    const chartRef = useRef(null);

    const [bars, setBars] = useState([])

    useEffect(() => {
        const arr = allData.map(item => item.total)
        const arr2 = arr.map((item, index) => {
            return {
                o: index === 0 ? 0 : arr[index - 1],
                h: item,
                l: index === 0 ? 0 : arr[index - 1],
                c: item,
                s: [index === 0 ? 0 : arr[index - 1], item]
            }
        })
        setBars(arr2)
    }, [allData])

    const data = {
        labels: [...allData?.map(item => `${(item.date.month && item.date.month?.length > 4) ? item.date.month.substring(0, 3) : item.date.month}`), 2023],
        datasets: [
            {
                type: 'line',
                label: 'Dataset 2',
                data: [...allData?.map(item => item), 2023].map(item => point),
                backgroundColor: '#c0cfe4',
                borderColor: '#c0cfe4',
                pointRadius: 0,
                pointHoverRadius: 0,
                borderDash: [2, 2],
                datalabels: {
                    formatter: (value, context) => {
                        const { dataIndex } = context;
                        if (dataIndex === 0) {
                            return 'Точка окупаемости'
                        }
                        return ''
                    },
                    align: 'end',
                    anchor: 'end',
                    color: '#8496AF',
                    textAlign: 'center'
                },

            },
            {
                type: 'bar',
                label: 'Dataset 1',
                data: [
                    ...bars.map(item => {
                        return {
                            x: new Date('2023-07-05').setHours(0, 2, 0, 0),
                            ...item
                        }
                    }),
                    {
                        x: new Date('2023-07-05').setHours(0, 2, 0, 0),
                        o: 0,
                        h: allProfit,
                        l: 0,
                        c: allProfit,
                        s: [0, allProfit]
                    },
                ],
                backgroundColor: (ctx) => {
                    console.log(ctx)
                    if (ctx.dataIndex === allData.length) {
                        return '#429EFF'
                    } else if (ctx.raw.o < ctx.raw.c) {
                        return '#59D0A5'
                    } else {
                        return '#FF6A7C'
                    }
                },
            },

        ],
    };

    const customTooltip = (context) => {
        const { o, c } = context.tooltip.dataPoints[1].raw;
        if (context.tooltip.opacity === 0 || o === c || context.tooltip.dataPoints[1].parsed.x === allData.length) {
            setTooltipVisible(false);
            return
        }

        const chart = chartRef.current;
        const canvas = chart.canvas;
        if (canvas) {
            if (!tooltipVisible) {
                setTooltipVisible(true);
            }

            const left = context.tooltip.caretX;
            const top = context.tooltip.y;

            const tooltipPosLocal = tooltipPos?.left
            if (tooltipPosLocal != left + 40) {
                setTooltipPos({ top: top, left: left + 40 });
                setTooltipData({ ...context.tooltip });
            }
        }
    };

    const options = {
        animation: true,
        plugins: {
            title: {
                display: false,
                text: '',
            },
            legend: {
                display: false
            },

            tooltip: {
                enabled: false,
                external: customTooltip
            },
            datalabels: {
                formatter: function (value, context) {
                    const { o, c } = value
                    const { dataIndex } = context;
                    if (dataIndex !== allData.length) {
                        if (o !== c) {
                            if (o > c) {
                                return '- ' + formatNumber2(o - c)
                            }
                            return '+ ' + formatNumber2(c - o);
                        }
                        return ''
                    }
                    return `Прибыль на\nконец года\n${formatNumber2(c)}`

                },
                textAlign: 'center',
                anchor: (ctx) => {
                    const { dataIndex } = ctx;
                    const currentData = ctx.dataset.data[dataIndex];
                    const { o, c } = currentData;
                    if (o > c) {
                        return 'start'
                    }
                    return 'end'
                },
                align: (ctx) => {
                    const { dataIndex } = ctx;
                    const currentData = ctx.dataset.data[dataIndex];
                    const { o, c } = currentData;
                    if (o > c) {
                        return 'start'
                    }
                    return 'end'
                },
                color: (ctx) => {
                    const { dataIndex } = ctx;
                    const currentData = ctx.dataset.data[dataIndex];
                    const { o, c } = currentData;
                    if (dataIndex !== allData.length) {
                        if (o > c) {
                            return '#FF6A7C'
                        }
                        return '#59D0A5'
                    }
                    return '#429EFF'
                },
                font: {
                    weight: 600,
                    size: 12
                },
                offset: 0
            },

        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        parsing: {
            xAxisKey: 'x',
            yAxisKey: 's',
        },
        scales: {
            y: {
                position: 'right',
                max: (point > allProfit ? point : allProfit) + 20000
            },
        },
        borderRadius: 6,
        borderSkipped: false
    };

    return (

        <>
            <div className={styles.graphWrap}>
                <Chart height={400} type='bar' options={options} data={data} ref={chartRef} />
                <ProfitTooltip
                    allData={allData.map(item => {return [...item.value]})}
                    labels={[...allData?.map(item => item.date.month)]}
                    data={tooltipData}
                    position={tooltipPos}
                    visibility={tooltipVisible}
                    total={allData.map(item => item.total)}
                />
            </div>
        </>
    );
}