import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { API_URL, getData } from "../../../../static/js/request";
import { ProjectTable } from "./ProjectTable/ProjectTable.jsx";
import { ProjectGraph } from "./ProjectGraph/ProjectGraph.jsx";
import ProjectSettings from './ProjectSettings/index.jsx';
import { formatNumber2 } from '../../../../static/js/commonFunc';

export const ProjectItem = () => {

    const [itemInfo, setInfo] = useState();

    const params = useParams();

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('Показатели');
    const location = useLocation()

    const [change, setChange] = useState(false);

    const getInfo = async () => {
        const response = await getData(API_URL + `/api/v1/projects/${params.id}/settings/`);
        setInfo({
            ...response.data, 
            products: response.data.products.map(item => {
                return {
                    ...item,
                    planned_sales_amount: formatNumber2(item.planned_sales_amount),
                    planned_sales_qty: formatNumber2(item.planned_sales_qty)
                }
            })
        });
    };

    useEffect(() => {
        getInfo();
    }, [change]);

    useEffect(() => {
        if(location.search.substring(location.search.length - 4) === 'edit') {
            setActiveTab('Настройки');
        } else if (location.search.substring(location.search.length - 5) === 'table') {
            setActiveTab('Таблица')
        } else {
            setActiveTab('Показатели')
        }
    }, [location])


    return (
        <>
            <h2 className={styles.title}>
                <svg onClick={() => window.history.back()} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M20 13.25C20.4142 13.25 20.75 12.9142 20.75 12.5C20.75 12.0858 20.4142 11.75 20 11.75L20 13.25ZM4.46967 11.9697C4.17678 12.2626 4.17678 12.7374 4.46967 13.0303L9.24264 17.8033C9.53553 18.0962 10.0104 18.0962 10.3033 17.8033C10.5962 17.5104 10.5962 17.0355 10.3033 16.7426L6.06066 12.5L10.3033 8.25736C10.5962 7.96447 10.5962 7.48959 10.3033 7.1967C10.0104 6.90381 9.53553 6.90381 9.24264 7.1967L4.46967 11.9697ZM20 11.75L5 11.75L5 13.25L20 13.25L20 11.75Z" fill="#429EFF" />
                </svg>
                {itemInfo?.name}
            </h2>
            <div className={styles.content}>
                <div className={styles.top}>
                    <button onClick={() => navigate(`/analytics/projects/item/${params.id}?from=analytic`)} className={`${styles.tab} ${activeTab === 'Показатели' ? styles.active : ''}`}>Показатели</button>
                    <button onClick={() => navigate(`/analytics/projects/item/${params.id}?from=table`)} className={`${styles.tab} ${activeTab === 'Таблица' ? styles.active : ''}`}>Таблица</button>
                    <button onClick={() => navigate(`/analytics/projects/item/${params.id}?from=edit`)} className={`${styles.tab} ${activeTab === 'Настройки' ? styles.active : ''}`}>Настройки</button>
                </div>
                {
                    activeTab === 'Таблица' ?
                        <ProjectTable data={itemInfo} /> :
                        activeTab === 'Показатели' ?
                            <ProjectGraph /> : activeTab === 'Настройки' ?
                                <ProjectSettings getInfo={getInfo} setChange={setChange} info={itemInfo} /> : null
                }
            </div>
        </>
    );
};
