import React from 'react';

const BackArrowIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
            >
                <path
                    d="M16 7.25C16.4142 7.25 16.75 6.91421 16.75 6.5C16.75 6.08579 16.4142 5.75 16 5.75L16 7.25ZM0.46967 5.96967C0.176777 6.26256 0.176777 6.73744 0.46967 7.03033L5.24264 11.8033C5.53553 12.0962 6.01041 12.0962 6.3033 11.8033C6.59619 11.5104 6.59619 11.0355 6.3033 10.7426L2.06066 6.5L6.3033 2.25736C6.59619 1.96447 6.59619 1.48959 6.3033 1.1967C6.01041 0.903806 5.53553 0.903806 5.24264 1.1967L0.46967 5.96967ZM16 5.75L1 5.75L1 7.25L16 7.25L16 5.75Z"
                    fill="#429EFF"
                />
            </svg>
        </>
    );
};

export default BackArrowIcon;