import React, { useState, useEffect, useRef } from 'react';
import style from '../Financing.module.scss';
import Table from '../../../components/custom/Table.jsx';
import HeaderTable from '../../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../../components/custom/HeaderTableItem.jsx';
import BodyTable from '../../../components/custom/BodyTable.jsx';
import BanksBody from './BanksBody.jsx';
import useOnClickOutside from '../../../core/Hooks/useOnClickOutside';
import MyDateRangePicker from '../../../components/custom/DateRange.jsx';
import { format } from 'date-fns';
import { BanksStore } from '../../../store/Financing/BanksStore';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import YearSelect from '../../Balance/YearSelect/index.jsx';
import { getFinancing } from '../../../api/getFinancing';

const Banks = observer(() => {
    const [state] = useState(() => new BanksStore());
    const tabs = ['Все', 'Разнесенные', 'Не разнесенные'];
    const [filterGroup, setFilterGroup] = useState('Все');
    const [isSelectDataVisible, setIsSelectDataVisible] = useState(false);
    const selectDataContainerRef = useRef(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    const showSelectData = () => {
        setIsSelectDataVisible(!isSelectDataVisible);
    };

    useOnClickOutside(selectDataContainerRef, () =>
        setIsSelectDataVisible(false)
    );

    const navigation = useNavigate();

    const [filterPeriod, setPeriodFilter] = useState('Все время');

    const changingFilter = () => {
        state.fillValue({ period: filterPeriod, group: filterGroup });
    };

    useEffect(() => {
        changingFilter();
    }, [filterPeriod, filterGroup]);

    return (
        <main>
            <div className={style.top}>
                <div className={style.title}>Банки</div>
                <button
                    onClick={() => navigation('/financing/rules/')}
                    className={style.rulesBtn}
                >
                    Правила
                </button>
            </div>
            <section className={style.section}>
                <div className={style.section__filter}>
                    <div className={style.filter}>
                        Период:
                        <YearSelect
                            value={filterPeriod}
                            onChange={setPeriodFilter}
                            allValues={['Все время', 'Месяц', 'Квартал', 'Год']}
                            width={'150px'}
                        />
                    </div>
                    <div className={style.tabs}>
                    {tabs.map((item) => (
                        <button
                            key={item}
                            onClick={() => setFilterGroup(item)}
                            className={`${style.tab} ${
                                filterGroup === item ? style.active : ''
                            }`}
                        >
                            {item}{' '}
                            {/*TODO: сделать расчет кол-ва операций в каждом фильтре*/}
                            {/*<span>*/}
                            {/*        {item === 'Все'*/}
                            {/*            ? productsWithoutCost?.length + productsWithCost?.length*/}
                            {/*            : item === 'Заполненная СС'*/}
                            {/*                ? productsWithCost?.length*/}
                            {/*                : productsWithoutCost?.length}*/}
                            {/*    </span>*/}
                        </button>
                    ))}
                </div>
                </div>
                {
                    state.fields.data && state.fields.data.length > 0 ?
                        <Table>
                            <HeaderTable items="banks">
                                <HeaderTableItem
                                    name="Дата"
                                    filter="true"
                                    filterEvent={() => {
                                        showSelectData();
                                    }}
                                    filterValue={
                                        isSelectDataVisible && (
                                            <div
                                                className={style.selectData}
                                                ref={selectDataContainerRef}
                                            >
                                                <div
                                                    className={style.sortingTitle}
                                                    onClick={() => {
                                                        state.sortByAscendingDate();
                                                        setIsSelectDataVisible(false);
                                                    }}
                                                >
                                                    Сортировка по возрастанию
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M8.5 3C8.5 2.72386 8.27614 2.5 8 2.5C7.72386 2.5 7.5 2.72386 7.5 3L8.5 3ZM7.64645 13.3536C7.84171 13.5488 8.15829 13.5488 8.35355 13.3536L11.5355 10.1716C11.7308 9.97631 11.7308 9.65973 11.5355 9.46447C11.3403 9.2692 11.0237 9.2692 10.8284 9.46447L8 12.2929L5.17157 9.46447C4.97631 9.2692 4.65973 9.2692 4.46447 9.46447C4.2692 9.65973 4.2692 9.97631 4.46447 10.1716L7.64645 13.3536ZM7.5 3L7.5 13L8.5 13L8.5 3L7.5 3Z"
                                                            fill="#1D1D1D"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    className={style.sortingTitle}
                                                    onClick={() => {
                                                        state.sortByDescendingDate();
                                                        setIsSelectDataVisible(false);
                                                    }}
                                                >
                                                    Сортировка по убыванию
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M8.5 13C8.5 13.2761 8.27614 13.5 8 13.5C7.72386 13.5 7.5 13.2761 7.5 13L8.5 13ZM7.64645 2.64645C7.84171 2.45118 8.15829 2.45118 8.35355 2.64645L11.5355 5.82843C11.7308 6.02369 11.7308 6.34027 11.5355 6.53553C11.3403 6.7308 11.0237 6.7308 10.8284 6.53553L8 3.70711L5.17157 6.53553C4.97631 6.7308 4.65973 6.7308 4.46447 6.53553C4.2692 6.34027 4.2692 6.02369 4.46447 5.82843L7.64645 2.64645ZM7.5 13L7.5 3L8.5 3L8.5 13L7.5 13Z"
                                                            fill="#1D1D1D"
                                                        />
                                                    </svg>
                                                </div>
                                                {/*<div className={style.selectDataTitle}>Фильтровать по дате</div>*/}
                                                {/*<MyDateRangePicker*/}
                                                {/*    onDateRangeSelected={(startDate, endDate) => {*/}
                                                {/*        setDateFrom(format(startDate, 'dd.MM.yyyy'));*/}
                                                {/*        setDateTo(format(endDate, 'dd.MM.yyyy'));*/}
                                                {/*    }}*/}
                                                {/*/>*/}

                                                {/*<div className={style.selectDataButtonContainer}>*/}
                                                {/*    <button onClick={handleApplySelection}>Выбрать</button>*/}
                                                {/*    <button onClick={() => setIsSelectDataVisible(false)}>Отменить</button>*/}
                                                {/*</div>*/}
                                            </div>
                                        )
                                    }
                                />
                                <HeaderTableItem name="Приход" filter="true" />
                                <HeaderTableItem name="Расход" filter="true" />
                                <HeaderTableItem name="Банк" filter="true" />
                                <HeaderTableItem name="Контрагент" filter="true" />
                                <HeaderTableItem name="Статьи" filter="true" />
                                <HeaderTableItem name="Описание" filter="true" />
                            </HeaderTable>

                            <BodyTable>
                                <BanksBody state={state} />
                            </BodyTable>
                        </Table> :
                        <p style={{ marginTop: '30px' }} className={style.emptyData}>Нет данных</p>
                }

            </section>
        </main>
    );
});

export default Banks;
