import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import style from '../Financing.module.scss';
import Table from '../../../components/custom/Table.jsx';
import HeaderTable from '../../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../../components/custom/HeaderTableItem.jsx';
import BodyTable from '../../../components/custom/BodyTable.jsx';
import CashboxesBody from './CashboxesBody.jsx';
import { confirmAlert } from 'react-confirm-alert';
import CustomInput from '../../../components/custom/customInput.jsx';
import { API_URL, postData } from '../../../static/js/request';
import { createNotification } from '../../../static/js/commonFunc';
import { CashboxesStore } from '../../../store/Financing/CashboxesStore';
import { useUserStore } from '../../../store/Login/useUserStore';

const Cashboxes = () => {
    const [id, setId] = useState(null);
    // TODO: После создания кассы нужно отображать сразу ее в списке касс
    const [addCashboxField, setAddCashboxField] = useState({
        name: '1',
        responsible: id,
        incoming_balance: '1',
        outgoing_balance: '1',
    });

    const userStore = useUserStore();

    useLayoutEffect(() => {
        userStore.getUserInfo().then(result => {
            setId(result.id);
        });
    }, []);

    const handleChange = (evt) => {
        const target = evt.target;
        const name = target.name;
        addCashboxField[name] = target.value;
    };

    const handleSubmit = async (onClose) => {
        try {
            const data = await postData(
                API_URL + '/api/v1/cashbox/create/',
                { ...addCashboxField, responsible: id },
            )
            onClose();
            // createNotification(data);
            // new CashboxesStore().fillValue();
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {

    }, [handleSubmit]);
    const addCashbox = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Добавление кассы</h1>
                        <div className="addCashbox">
                            <CustomInput
                                name="name"
                                placeholder="Название кассы"
                                event={(event) => handleChange(event)}
                            />

                            <CustomInput
                                name="incoming_balance"
                                placeholder="Входящий остаток"
                                event={(event) => handleChange(event)}
                            />
                        </div>

                        <div className="react-confirm-alert-button-group">
                            <button className="cancel" onClick={onClose}>
                                Отменить
                            </button>
                            <button
                                className="complete"
                                onClick={() => {
                                    handleSubmit(onClose);
                                }}
                            >
                                Подтвердить
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    return (
        <main>
            <div className={style.title}>Кассы</div>

            <section className={style.section}>
                <div className={style.addCashboxContainer}>
                    <button className={style.addCashbox} onClick={() => addCashbox()}>
                        Добавить кассу
                    </button>
                </div>
                <Table>
                    <HeaderTable items="9">
                        <HeaderTableItem
                            additionalClass="cashBoxName"
                            name="Название"
                            filter="true"
                        />
                        <HeaderTableItem
                            additionalClass="responsible"
                            name="Ответственный"
                            filter="true"
                        />
                        <HeaderTableItem
                            additionalClass={'flexEnd'}
                            name="Входящий остаток"
                            filter="true"
                        />
                        <HeaderTableItem
                            additionalClass={'flexEnd'}
                            name="исходящий остаток"
                            filter="true"
                        />
                        <HeaderTableItem
                            additionalClass={'date'}
                            name="Дата изменения"
                            filter="true"
                        />
                    </HeaderTable>

                    <BodyTable>
                        <div className={style.cashboxWrap}>
                            <CashboxesBody />
                        </div>
                    </BodyTable>
                </Table>
            </section>
        </main>
    );
};

export default Cashboxes;
