import React from 'react';
import styles from './styles.module.scss';
import YearSelect from '../../../../views/Balance/YearSelect/index.jsx';

const MonthSelector = ({ selectYear, setSelectYear, selectMonth, setSelectMonth, years, months }) => {
    return (
        <div className={styles.calendarWrap}>
            <YearSelect
                value={selectYear}
                onChange={setSelectYear}
                allValues={years}
                width={'100%'}
            />
            <div className={styles.months}>
                {months.map((item) => (
                    <button
                        key={item}
                        className={selectMonth === item ? styles.active : ''}
                        onClick={() => setSelectMonth(item)}
                    >
                        {item}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default MonthSelector;