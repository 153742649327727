import React, { useEffect } from 'react';
import TableRowItem from './RowItem.jsx';

const TableRow = ({ data, loading = false, headRef = false, level = 0}) => {
    
    return (
        Object.entries(data).map((item, index) => (
        <TableRowItem
                key={index}
                data={item}
                loading={loading}
                headRef={headRef}                  
                level={level}
            />
        ))
    );
};

export default TableRow;