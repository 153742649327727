import React from 'react';

const RightArrow = ({isLeft=false}) => {

    return (
        <svg
            style={{ transform: `rotate(${isLeft ? '0deg' : '180deg'})`, width: '12px' }}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M12.727 3.687a1 1 0 1 0-1.454-1.374l-8.5 9a1 1 0 0 0 0 1.374l8.5 9.001a1 1 0 1 0 1.454-1.373L4.875 12z"
            />
        </svg>
    );

};

export default RightArrow;
