import React from 'react';
import styles from './styles.module.scss';

function ModeSwitch({ values, onChange, active }) {
  const handleClick = (newValue) => {
    onChange(newValue);
  };

  return (
    <div className={styles.switch}>
      {values.map((item) => (
        <button
          onClick={() => handleClick(item)}
          className={`${styles.switchBtn} ${
            active === item ? styles.active : ''
          }`}
          key={item}
        >
          {item}
        </button>
      ))}
    </div>
  );
}

export default ModeSwitch;
