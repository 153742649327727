import React, { useEffect, useState } from 'react';
import styles from './Articles.module.scss';
import AccountMenu from '../AccountMenu.jsx';
import ArticleRow from './ArticleRow.jsx';
import { API_URL, getData } from '@/static/js/request';
import Switcher from '@/components/custom/Switchers/index.jsx';

const Articles = () => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const REPORT_NAMES = ['pnl', 'dds', 'balance'];
  const [openArticles, setOpenArticles] = useState({});
  const toggleArticleOpen = (id) => {
    setOpenArticles((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const getArticles = async () => {
    const reportName = REPORT_NAMES[activeTab];

    try {
      const response = await getData(
        `${API_URL}/api/v1/articles-management/?report=${reportName}`
      );
      setData(response.data);
    } catch (e) {
      console.error('Ошибка при получении статей:', e);
    }
  };

  useEffect(() => {
    setData([]);
    getArticles();
  }, [activeTab]);

  const switcherValue = [
    { id: 0, name: 'PnL' },
    { id: 1, name: 'ДДС' },
    { id: 2, name: 'Баланс' },
  ];

  return (
    <div className={styles.wrap}>
      <AccountMenu />
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>Статьи</h2>
          <Switcher
            setActive={setActiveTab}
            active={activeTab}
            values={switcherValue}
          />
        </div>
        <div className={styles.rows}>
          {Object.entries(data).map(([title, info]) => (
            <ArticleRow
              key={info.info.id}
              item={{ title, ...info }}
              firstLevel={true}
              level={1}
              activeTab={activeTab}
              refetch={getArticles}
              openArticles={openArticles}
              toggleArticleOpen={toggleArticleOpen}
              parentName={null}
              grandpaName={null}
              greatGrandpaName={null}
              greatGreatGrandpaName={null}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Articles;
