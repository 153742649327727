import React, { useEffect, useState } from 'react';
import styles from './Articles.module.scss';
import AccountMenu from '../AccountMenu.jsx';
import ArticleRow from './ArticleRow.jsx';
import { API_URL, getData } from '../../../../static/js/request';
import Switcher from '../../../../components/custom/Switchers/index.jsx';
import { useParams } from 'react-router-dom';

const Articles = () => {
    const [data, setData] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    // TODO: стоит рассмотреть вариант с использованием useParams для переключения между отчетами
    // const params = useParams();

    const getArticles = async () => {
        let name_report;
        if (activeTab === 0) {
            name_report = 'pnl'
        } else if (activeTab === 1) {
            name_report = 'dds'
        } else {
            name_report = 'balance'
        }

        try {
            const response = await getData(
                API_URL + `/api/v1/articles-management/?report=${name_report}`,
            );

            setData(response.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        setData([]);
        getArticles();
    }, [activeTab]);


    const switcherValue = [
        { id: 0, name: 'PnL' },
        { id: 1, name: 'ДДС' },
        { id: 2, name: 'Баланс' },
    ];

    return (
        <div className={styles.wrap}>
            <AccountMenu />
            <div className={styles.content}>
                <div className={styles.header}>
                    <h2 className={styles.title}>Статьи</h2>
                    <Switcher setActive={setActiveTab} active={activeTab} values={switcherValue} />
                </div>
                <div className={styles.rows}>
                    {Object.keys(data).map((item, index) => (
                        <ArticleRow
                            refetch={getArticles}
                            nextEl={true}
                            grandpaId={item}
                            item={{ title: item, ...data[item] }}
                            key={index}
                            firstLevel={true}
                            activeTab={activeTab}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Articles;
