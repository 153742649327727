import React, { useEffect, useState } from 'react';
import List from '../../view/list/index.jsx';
import DataArray from './data.json'
import { API_URL, postData } from '../../../../static/js/request.js';

const ShipmentList = () => {

    const [columns, editColumns] = useState({
        id: { label: '№', isShow: true, value: 'id', type: 'id', noSort: true, width: "80px" },
        created_at: { label: 'Дата', isShow: true, value: 'created_at', type: 'date', width: "150px" },
        organisation: { label: 'Организация', isShow: true, value: 'organisation', type: 'object', width: "240px" },
        counterparty: { label: 'Контрагент', isShow: true, value: 'counterparty', type: 'object', width: "300px" },      
        transport_invoice_number: { label: '№ поставки', isShow: true, value: 'transport_invoice_number', type: 'string', width: "200px" }, 
        shipping_method: { label: 'Способ доставки', isShow: true, value: 'shipping_method', type: 'string', width: "200px" },  
        count: { label: 'кол-во', isShow: true, value: 'count', type: 'id', width: "100px" },
        sending: { label: 'Отправка план', isShow: true, value: 'sending', type: 'sending', width: "200px" },
        arrival: { label: 'Приемка план', isShow: true, value: 'arrival', type: 'arrival', width: "200px" },  
        delivery_date: { label: 'Приемка факт', isShow: true, value: 'delivery_date', type: 'delivery_date', width: "200px" },  
        status: { label: 'Статус', isShow: true, value: 'status', type: 'status', width: "160px" },
        sum: { label: 'Сумма', isShow: true, value: 'sum', type: 'pay', width: "140px" },
        //paid: { label: 'Оплачено', isShow: true, value: 'paid', type: 'pay', width: "140px" },
        //accepted: { label: 'Принято', isShow: true, value: 'accepted', type: 'pay', width: "140px" },
        
        comment: { label: 'Комментарий', isShow: true, value: 'comment', type: 'string', width: "270px" }
    })

    const [filters, setFilters] = useState(['Фильтр первый', 'Фильтр второй', 'Фильтр третий'])

    const [sortingBy, setSortingBy] = useState({
        key: '',
        value: ''
    })

    const [data, setData] = useState(null)

    const getData = async () => {

        try {

            const requestData = {
                sorted: sortingBy.key ? [{ key: sortingBy.key, value: sortingBy.value }] : []
            };

            await postData(API_URL + `/api/v1/purchase/shipment/list/`, requestData)
                .then((response) => response.json())
                .then((response) => {

                    setData(response.data.length>0 ? response.data : DataArray)

                });

        } catch (error) {
            console.log("Не удалось получить данные", error)
        }
    }

    useEffect(() => {
        getData()
    }, [sortingBy])

    return (
        <List
            title="Отгрузки"
            newItemLink={{ name: "Создать отгрузку", value: "/purchase/shipment/item/" }}
            filters={filters}
            columns={columns}
            editColumns={(value) => editColumns(value)}
            data={data}
            setSortingBy={(item) => setSortingBy(item)}
        />
    )

}

export default ShipmentList