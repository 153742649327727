import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import YearSelect from '../../Balance/YearSelect/index.jsx';
import { NewCustomInput } from '../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import { API_URL, getData } from '../../../static/js/request';
import { RowImage } from '../../CostPrice/RowImage.jsx';
import Switch from '../../Answering/AnsweringEdit/ui/Notices/Switch/index.jsx';
import { formatNumber2 } from '../../../static/js/commonFunc';

function Comparison() {
  const [mp, setMp] = useState(null);
  const [searchValue, changeSearchValue] = useState('');

  const [products, setProducts] = useState([]);

  const [selectProduct, setSelectProduct] = useState();

  const getProducts = async () => {
    try {
      const response = await getData(
        API_URL + '/api/v1/unit/compare/?product=173'
      );
      // setProducts(response);
    } catch (error) {}
  };

  useEffect(() => {
    getProducts();
  }, []);

  const selectProductClick = (item) => {
    setSelectProduct(item);
  };

  const [activeSize, setActiveSize] = useState('S');

  const [switchValue, setSwitch] = useState(false);

  const [openRow, setOpenRow] = useState(false);

  return (
    <div className={styles.content}>
      <div className={styles.leftSide}>
        <div className={styles.top}>
          <h2 className={styles.title}>Выберите товар для сравнения</h2>
          <div className={styles.searchBlock}>
            <YearSelect
              value={mp}
              onChange={setMp}
              allValues={['WB', 'OZON']}
              width={'160px'}
              placeholder={'Маркетплейс'}
              textAlign={'left'}
            />
            <NewCustomInput
              value={searchValue}
              onChange={changeSearchValue}
              width={'330px'}
              placeholder={'Найти товары'}
            />
          </div>
        </div>
        <div className={styles.searchContent}>
          {products.filter((temp) =>
            temp.name
              ?.toLowerCase()
              .trim()
              .includes(searchValue.toLowerCase().trim())
          ).length > 0 ? (
            <div className={styles.searchWrap}>
              {products
                .filter((temp) =>
                  temp.name
                    ?.toLowerCase()
                    .trim()
                    .includes(searchValue.toLowerCase().trim())
                )
                .map((item) => (
                  <div className={styles.productBlock} key={item.id}>
                    <div className={styles.imgWrap}>
                      <RowImage item={item} />
                    </div>
                    <p className={styles.name} title={item.name}>
                      {item.name}
                    </p>
                    <button
                      onClick={() => selectProductClick(item)}
                      className={styles.addProduct}
                    >
                      Сравнить
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M12.4031 9.08385L8.19523 5.19005C7.92153 4.93665 7.47898 4.93665 7.20528 5.19005C6.93157 5.44333 6.93157 5.85286 7.20528 6.10614L11.4132 10.0001L7.20528 13.8939C6.93157 14.1473 6.93157 14.5567 7.20528 14.8099C7.47898 15.0634 7.92153 15.0634 8.19523 14.8099L12.4031 10.9161C12.4031 10.9161 13 10.4075 13 10.0001C13 9.59263 12.4031 9.08385 12.4031 9.08385Z"
                          fill="#429EFF"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
            </div>
          ) : (
            <p className={styles.empty}>Нет подходящих товаров</p>
          )}
        </div>
      </div>
      <div className={styles.rightSide}>
        {selectProduct ? (
          <div className={styles.comparisonContent}>
            <div className={styles.sizes}>
              <button
                onClick={() => setActiveSize('S')}
                className={`${styles.size} ${
                  activeSize === 'S' ? styles.active : ''
                }`}
              >
                S
              </button>
              <button
                onClick={() => setActiveSize('M')}
                className={`${styles.size} ${
                  activeSize === 'M' ? styles.active : ''
                }`}
              >
                M
              </button>
            </div>
            <div className={styles.cards}>
              <div className={styles.block}>
                <div className={`${styles.card} ${styles.ozon}`}>
                  <h2 className={styles.cardTitle}>OZON</h2>
                  <img
                    src={selectProduct.photo_url}
                    alt=""
                    className={styles.cardImg}
                  />
                  <p className={styles.name} title={selectProduct.name}>
                    {selectProduct.name}
                  </p>
                  <div className={styles.info}>
                    <p className={styles.infoItem}>
                      <span>Арт:</span>
                      {selectProduct.vendorCode || '-'}
                    </p>
                    <p className={styles.infoItem}>
                      <span>Кат:</span>
                      Одежда
                    </p>
                    <p className={styles.infoItem}>
                      <span>ID:</span>
                      13377331
                    </p>
                    <p className={styles.infoItem}>
                      <span>ШК:</span>
                      13377331
                    </p>
                  </div>
                </div>
                <div className={styles.reviews}>
                  <div className={styles.stars}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#DCE7F1"
                      />
                    </svg>
                  </div>
                  <p className={styles.count}>29 отзывов</p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={`${styles.card} ${styles.wb}`}>
                  <h2 className={styles.cardTitle}>Wildberries</h2>
                  <img
                    src={selectProduct.photo_url}
                    alt=""
                    className={styles.cardImg}
                  />
                  <p className={styles.name} title={selectProduct.name}>
                    {selectProduct.name}
                  </p>
                  <div className={styles.info}>
                    <p className={styles.infoItem}>
                      <span>Арт:</span>
                      {selectProduct.vendorCode || '-'}
                    </p>
                    <p className={styles.infoItem}>
                      <span>Кат:</span>
                      Одежда
                    </p>
                    <p className={styles.infoItem}>
                      <span>ID:</span>
                      13377331
                    </p>
                    <p className={styles.infoItem}>
                      <span>ШК:</span>
                      13377331
                    </p>
                  </div>
                </div>
                <div className={styles.reviews}>
                  <div className={styles.stars}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#FDB600"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                        fill="#DCE7F1"
                      />
                    </svg>
                  </div>
                  <p className={styles.count}>29 отзывов</p>
                </div>
              </div>
            </div>
            <div className={styles.comparisonInfo} style={{ width: '520px' }}>
              <div className={styles.infoTop}>
                <p className={styles.infoTitle}>Сравнение</p>
                <div className={styles.switch}>
                  <p>Только различия</p>
                  <Switch active={switchValue} setActive={setSwitch} />
                </div>
              </div>
              <div className={styles.infoRows}>
                <div className={styles.row}>
                  <p className={styles.rowTitle}>Цена продажи</p>
                  <div className={styles.rowValues}>
                    <div className={styles.value}>
                      {formatNumber2(150000)} ₽
                    </div>
                    <div className={styles.value}>
                      {formatNumber2(150000)} ₽
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.row} ${styles.opened} ${
                    openRow ? styles.active : ''
                  }`}
                >
                  <div>
                    <p
                      className={styles.rowTitle}
                      onClick={() => setOpenRow(!openRow)}
                    >
                      Стоимость пользования МП
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                      >
                        <path
                          d="M1 9L5 5L1 1"
                          stroke="#8496AF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </p>
                    <div className={styles.rowValues}>
                      <div className={styles.value}>
                        {formatNumber2(150000)} ₽
                      </div>
                      <div className={styles.value}>
                        {formatNumber2(150000)} ₽
                      </div>
                    </div>
                  </div>

                  {openRow && (
                    <div className={styles.innerValues}>
                      <div className={styles.row}>
                        <p className={styles.rowTitle}>Комиссия</p>
                        <div className={styles.rowValues}>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                        </div>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.rowTitle}>Логистика</p>
                        <div className={styles.rowValues}>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                        </div>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.rowTitle}>Хранение</p>
                        <div className={styles.rowValues}>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                        </div>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.rowTitle}>Прочие</p>
                        <div className={styles.rowValues}>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                          <div className={styles.value}>
                            {formatNumber2(150000)} ₽
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.row}>
                  <p className={styles.rowTitle}>Чистая прибыль</p>
                  <div className={styles.rowValues}>
                    <div className={styles.value}>
                      {formatNumber2(150000)} ₽
                    </div>
                    <div className={styles.value}>
                      {formatNumber2(150000)} ₽
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.empty}>
            <p>Товар для сравнения не выбран</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="347"
              height="347"
              viewBox="0 0 347 347"
              fill="none"
            >
              <path
                d="M161.319 37.3424L315.509 101.231L208.063 189.014L180.1 178.198L161.319 37.3424Z"
                fill="#F8FBFF"
              />
              <path
                d="M181.952 2.11848C182.945 0.422774 185.023 -0.300237 186.854 0.412659L331.831 56.849C333.996 57.6914 334.99 60.1938 333.994 62.2918L315.509 101.231L161.319 37.3424L181.952 2.11848Z"
                fill="#F5F9FF"
              />
              <path
                d="M161.319 37.3424L30.6383 139.214L156.712 195.502L180.1 178.198L161.319 37.3424Z"
                fill="#EDF4FF"
              />
              <path
                d="M132.179 8.75481C130.776 7.37858 128.581 7.22526 127.001 8.39307L1.96026 100.78C0.0612954 102.183 -0.215595 104.917 1.3635 106.672L30.6383 139.214L161.319 37.3424L132.179 8.75481Z"
                fill="#F5F9FF"
              />
              <path
                d="M184.782 208.034L223.963 248.553L323.438 160.694L333.925 239.351C334.11 240.742 333.554 242.128 332.458 243.004L203.22 346.321L184.782 208.034Z"
                fill="#EDF4FF"
              />
              <path
                d="M184.782 208.034L158.277 257.311L39.2581 198.585L49.7462 277.247C49.9313 278.635 50.8283 279.825 52.1114 280.384L203.22 346.321L184.782 208.034Z"
                fill="#F8FBFF"
              />
              <path
                d="M184.782 208.034L30.6383 139.214L10.844 180.031C9.88514 182.008 10.701 184.389 12.6712 185.362L158.277 257.311L184.782 208.034Z"
                fill="#F5F9FF"
              />
              <path
                d="M184.782 208.034L315.509 101.231L345.932 135.34C347.407 136.994 347.256 139.531 345.596 140.999L223.963 248.553L184.782 208.034Z"
                fill="#F5F9FF"
              />
              <path
                d="M180.1 178.198L208.063 189.014L184.782 208.034L156.712 195.502L180.1 178.198Z"
                fill="#E3EFFF"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default Comparison;
