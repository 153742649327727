import React, { useState, useEffect, useRef } from 'react';
import style from './loansStyle.module.scss';
import { deleteConfirmAlert } from '../../../static/js/commonFunc.js';
import RigthArrow from '../../../shared/ui/icons/RightArrow.jsx';
import RecycleIcon from '../../../shared/ui/icons/Recycle.jsx';
import EditIcon from '../../../shared/ui/icons/Edit.jsx';

const LoanAction = ({ showModal, params, setData, remove }) => {
  const elementref = useRef(null);

  const [show, toggleShow] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (elementref.current && !elementref.current.contains(e.target)) {
        toggleShow(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      {show && (
        <div ref={elementref} className={style.moreModal}>
          <button
            onClick={() => {
              setData(params);
              showModal();
            }}
            className={style.edit}
          >
            <EditIcon /> Изменить
          </button>

          <button
            onClick={() => deleteConfirmAlert(() => remove(params.loan_id))}
            className={style.remove}
          >
            <RecycleIcon /> Удалить
          </button>
          <button onClick={() => toggleShow(false)} className={style.close}>
            <RigthArrow />
          </button>
        </div>
      )}
      <div onClick={() => toggleShow((prev) => !prev)} className={style.button}>
        <span>...</span>
      </div>
    </>
  );
};

export default LoanAction;
