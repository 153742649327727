import React, { useState } from 'react';
import style from '../../../Purchase.module.scss';
import { NewCustomInput } from '../../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import cx from 'classnames';
import PlusIcon from '../../../../../shared/ui/icons/Plus.jsx';
import Search from './search.jsx';

const Add = ({
  productList,
  setProductList,
  setItemParams,
  type,
  addedList,
}) => {
  const [count, setCount] = useState('');
  const [price, setPrice] = useState('');

  const getCountLabel = () => {
    switch (type) {
      case 'shipment':
        return 'Отгрузили, шт';
        break;

      case 'acceptance':
        return 'Приняли, шт';
        break;

      default:
        return 'Заказали, шт';
        break;
    }
  };

  const addProduct = () => {
    setItemParams((prev) => {
      const selectedProducts = productList
        .filter((item) => item.checked)
        .map((item) => ({
          product: item.id,
          price: price,
          ...(type === 'acceptance'
            ? { count_fact: count, count_plan: 0 }
            : { count: count }),
        }));

      return {
        ...prev,
        products: [...prev.products, ...selectedProducts],
      };
    });

    setProductList((prev) =>
      prev.map((item) => ({
        ...item,
        checked: false,
      }))
    );

    setCount('');
    setPrice('');
  };

  return (
    <div className={style.filter}>
      <div className={cx(style.topRow, style.product)}>
        <div className={style.left}>
          <div className={style.column}>
            <div className={style.title}>Добавление товара</div>
          </div>

          <Search
            productList={productList}
            setProductList={setProductList}
            addedList={addedList}
          />

          <div className={style.column}>
            <NewCustomInput
              type={'text'}
              value={count}
              onChange={(value) => setCount(parseInt(value))}
              width={'160px'}
              placeholder={getCountLabel()}
              numeric
            />
          </div>
          <div className={style.column}>
            <NewCustomInput
              type={'text'}
              value={price}
              onChange={(value) => setPrice(parseFloat(value))}
              width={'160px'}
              placeholder="Цена, руб"
              numeric
            />
          </div>
        </div>

        <div className={style.rigth}>
          <button className={style.chain} onClick={() => addProduct()}>
            <PlusIcon />
            <span>Добавить товар</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add;
