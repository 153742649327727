import React from 'react'

const PlusIcon = _ => {

    return (
        <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M3.28957 10.7896H9.21044V16.7104C9.21044 16.9198 9.29362 17.1207 9.44169 17.2687C9.58977 17.4168 9.7906 17.5 10 17.5C10.2094 17.5 10.4102 17.4168 10.5583 17.2687C10.7064 17.1207 10.7896 16.9198 10.7896 16.7104V10.7896H16.7104C16.9198 10.7896 17.1207 10.7064 17.2687 10.5583C17.4168 10.4102 17.5 10.2094 17.5 10C17.5 9.79059 17.4168 9.58976 17.2687 9.44169C17.1207 9.29362 16.9198 9.21044 16.7104 9.21044H10.7896V3.28956C10.7896 3.08016 10.7064 2.87933 10.5583 2.73126C10.4102 2.58319 10.2094 2.5 10 2.5C9.7906 2.5 9.58977 2.58319 9.44169 2.73126C9.29362 2.87933 9.21044 3.08016 9.21044 3.28956V9.21044H3.28957C3.08016 9.21044 2.87933 9.29362 2.73126 9.44169C2.58319 9.58976 2.5 9.79059 2.5 10C2.5 10.2094 2.58319 10.4102 2.73126 10.5583C2.87933 10.7064 3.08016 10.7896 3.28957 10.7896V10.7896Z" fill="white" />
        </svg>
    )

}

export default PlusIcon


