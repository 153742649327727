import Table from './Table/index.jsx';
import YearSelect from './YearSelect/index.jsx';
import styles from './styles.module.scss';
import React from 'react';

function Balance() {
    const [selectYear, setSelect] = React.useState(new Date().getFullYear());

    const [years, setYears] = React.useState([]);

    return (
        <section className={styles.section}>
            <h2 className={styles.title}>Баланс</h2>
            <div className={styles.content}>
                <div className={styles.top}>
                    <p className={styles.topYear}>Отчетный год:</p>
                    <YearSelect
                        width={'120px'}
                        allValues={years}
                        value={selectYear}
                        onChange={setSelect}
                    />
                </div>
                <Table year={selectYear} setYears={setYears} />
            </div>
        </section>
    );
}

export default Balance;
