import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import {
  API_URL,
  delData,
  getData,
  postData,
  putData,
} from '../../../../../static/js/request';
import { useParams } from 'react-router-dom';
import { createNotification } from '../../../../../static/js/commonFunc';

function AnswersConstructor() {
  const [answers, setAnswers] = useState([]);

  const [openEditModalIndex, setEditModalIndex] = useState();

  const [edit, setEdit] = useState();
  const [createIndex, setCreateIndex] = useState();
  const [create, setCreate] = useState(false);

  const [createValue, setCreateValue] = useState('');

  const createRef = useRef();
  const [closeCode, setCloseCode] = useState(false);

  const [oldValue, setOldValue] = useState('');

  useEffect(() => {
    const outsideClick = (e) => {
      const id = e.target.getAttribute('id');
      if (openEditModalIndex || edit || create) {
        if (
          id !== 'createNoClose' &&
          create &&
          e.target !== createRef.current
        ) {
          if (createRef.current.value?.trim().length > 0) {
            createAnswer(parseInt(createRef.current.getAttribute('id')));
          }
          setCreate(false);
          setCreateIndex(null);
        }
        if (
          !id?.includes('noClose') &&
          !id?.includes(edit?.replace(' ', '-')) &&
          closeCode
        ) {
          setEditModalIndex();
          setCloseCode(false);
          setEdit();
          setNewText();
          changeText();
        }
      }
    };

    if (openEditModalIndex || edit || create) {
      document.body.addEventListener('click', outsideClick, true);
    } else {
      document.body.removeEventListener('click', outsideClick, true);
    }

    return () => {
      document.body.removeEventListener('click', outsideClick, true);
    };
  }, [openEditModalIndex, edit, create]);

  const [editValue, setEditValue] = useState('');

  const areaRef = useRef();

  const getInfo = async () => {
    try {
      const response = await getData(
        API_URL + `/api/v1/answering_machine/constructors/${params.id}/`
      );

      const arr = response.blocks.map((item) => ({
        id: item.id,
        title: item.name,
        priority: item.sequence_number,
        items: item.texts
          .map((text) => ({
            id: text.id,
            value: text.text,
          }))
          .reverse(),
      }));

      setAnswers(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const createAnswer = async (id) => {
    console.log(id);
    try {
      const response = await postData(
        API_URL + `/api/v1/answering_machine/constructors/${params.id}/add_text/`,
        {
          block_id: id,
          text: createRef.current.value,
        }
      );
      createNotification(response);
      getInfo();
      createRef.current.value = '';
      setEditValue('');
    } catch (error) {}
  };

  const removeBlock = async (id) => {
    try {
      const response = await delData(
        API_URL + `/api/v1/answering_machine/constructors/${params.id}/`,
        {
          block_id: id,
        }
      );
      createNotification(response);
      getInfo();
    } catch (error) {}
  };

  const changeText = async () => {
    const text = areaRef.current?.innerHTML || areaRef.current.value;
    if (oldValue.trim().toLowerCase() !== text.trim().toLowerCase()) {
      try {
        const id = parseInt(
          areaRef.current
            .getAttribute('id')
            .replace('noClose ', '')
            .substring(
              2,
              areaRef.current.getAttribute('id').replace('noClose ', '').length
            )
        );
        const response = await putData(
          API_URL + `/api/v1/answering_machine/constructors/${params.id}/`,
          {
            block_id: id,
            text: text,
          }
        );
        getInfo();
      } catch (error) {}
    }
  };

  const setNewText = async () => {
    const text = areaRef.current.innerHTML || areaRef.current.value;
    const col = parseInt(
      areaRef.current
        .getAttribute('id')
        .replace('noClose ', '')
        .substring(
          2,
          areaRef.current.getAttribute('id').replace('noClose ', '').length
        )
    );
    const item = parseInt(
      areaRef.current.getAttribute('id').replace('noClose ', '').substring(0, 2)
    );
    setEditValue(text);
    if (editValue.trim().length > 0 && oldValue !== text) {
      setAnswers([
        ...answers.map((value) => {
          return {
            ...value,
            items:
              col === value.id
                ? [
                    ...value.items.map((temp) => {
                      return {
                        ...temp,
                        value: temp.id === item ? text : temp.value,
                      };
                    }),
                  ]
                : [...value.items],
          };
        }),
      ]);
    }
  };

  function insertAtCursor(myField, myValue, col, item, create = false) {
    const startPos = myField.selectionStart;
    const endPos = myField.selectionEnd;
    if (document.selection) {
      myField.focus();
      sel = document.selection.createRange();
      sel.text = myValue;
      if (create) {
        setCreateValue(sel.text);
      } else {
        setNewText(col, item, sel.text);
      }
    } else if (myField.selectionStart || myField.selectionStart == '0') {
      myField.value =
        myField.value.substring(0, startPos) +
        myValue +
        myField.value.substring(endPos, myField.value.length);
      if (create) {
        setCreateValue(myField.value);
      } else {
        setNewText(col, item, myField.value);
      }
    } else {
      myField.value += myValue;
      if (create) {
        setCreateValue(myField.value);
      } else {
        setNewText(col, item, myField.value);
      }
    }
    if (create) {
      createRef.current.selectionStart = startPos + myValue.length;
      createRef.current.selectionEnd = endPos + myValue.length;
      createRef.current.focus();
    } else {
      areaRef.current.selectionStart = startPos + myValue.length;
      areaRef.current.selectionEnd = endPos + myValue.length;
      areaRef.current.focus();
    }
  }

  const [variables, setVariables] = useState([]);

  const params = useParams();

  const getVariables = async () => {
    try {
      const response = await getData(API_URL + `/api/v1/answering_machine/variables/`);
      setVariables(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVariables();
    getInfo();
  }, []);

  const addAnswerClick = (id) => {
    setCreateIndex(id);
    setCreate(true);
  };

  useEffect(() => {
    console.log(edit);
  }, [edit]);

  return (
    <div className={styles.content}>
      <div className={styles.top}>
        {answers.map((item) => (
          <div key={item.id} className={styles.topItem}>
            <p className={styles.itemTitle}>
              {item.title} ({item.items.length})
            </p>
            <button
              id="createNoClose"
              onClick={() => addAnswerClick(item.id)}
              className={styles.add}
            >
              <svg
                id="createNoClose"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  id="createNoClose"
                  d="M3 8H13M8 13V3"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
      <div className={styles.values}>
        {answers.map((col) => (
          <div key={col.id} className={styles.col}>
            {create && createIndex === col.id && (
              <div id="createNoClose" className={styles.createBlock}>
                <textarea ref={createRef} id={col.id} autoFocus></textarea>
                <div id={`createNoClose`} className={styles.addCode}>
                  {variables.map((temp) => (
                    <button
                      key={temp.name}
                      id={`createNoClose`}
                      onClick={() => {
                        insertAtCursor(
                          createRef.current,
                          temp.name,
                          col.id,
                          null,
                          true
                        );
                      }}
                    >
                      {temp.name}{' '}
                      <span id={`createNoClose`}>{temp.description}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
            {col.items.map((item) => (
              <div
                key={item.id}
                className={`${styles.value} ${edit === `${col.id} ${item.id}`}`}
              >
                {openEditModalIndex === `${col.id} ${item.id}` && (
                  <div
                    id={`noClose ${col.id + '-' + item.id}`}
                    className={styles.editModal}
                  >
                    <button
                      onClick={() => removeBlock(item.id)}
                      id={`noClose ${col.id + '-' + item.id}`}
                      className={styles.remove}
                    >
                      <svg
                        id={`noClose ${col.id + '-' + item.id}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                      >
                        <path
                          id={`noClose ${col.id + '-' + item.id}`}
                          d="M0.521708 4.51099H1.56512V14.4884C1.56553 14.624 1.62063 14.7539 1.71838 14.8497C1.81613 14.9456 1.94859 14.9996 2.08683 15H11.6522C11.7904 14.9996 11.9229 14.9456 12.0207 14.8497C12.1184 14.7539 12.1735 14.624 12.1739 14.4884V4.51099H13.4783C13.6167 4.51099 13.7494 4.45709 13.8472 4.36114C13.945 4.2652 14 4.13507 14 3.99938C14 3.86369 13.945 3.73356 13.8472 3.63762C13.7494 3.54167 13.6167 3.48777 13.4783 3.48777H10.9913C10.9728 2.61733 10.6368 1.78221 10.0444 1.1338C9.32181 0.383763 8.26958 0 6.90442 0C5.53926 0 4.5128 0.383763 3.86094 1.14265C3.32764 1.80761 3.06709 2.64407 3.13047 3.48777H0.521708C0.453196 3.48777 0.385355 3.501 0.322059 3.52671C0.258762 3.55242 0.20125 3.59011 0.152805 3.63762C0.10436 3.68512 0.0659311 3.74152 0.0397128 3.8036C0.0134946 3.86567 0 3.93219 0 3.99938C0 4.06657 0.0134946 4.13309 0.0397128 4.19517C0.0659311 4.25724 0.10436 4.31364 0.152805 4.36114C0.20125 4.40865 0.258762 4.44634 0.322059 4.47205C0.385355 4.49776 0.453196 4.51099 0.521708 4.51099ZM4.64362 1.79939C5.08715 1.27914 5.84361 1.02344 6.89583 1.02344C7.95665 1.02344 8.75672 1.29641 9.26984 1.82509C9.68254 2.28669 9.91915 2.87441 9.93931 3.48799H4.17388C4.10978 2.88733 4.27736 2.28491 4.64362 1.79939ZM11.1305 4.51099V13.9766H2.60788V4.51099H11.1305Z"
                          fill="#FF5E5B"
                        />
                        <path
                          id={`noClose ${col.id + '-' + item.id}`}
                          d="M5.21702 12.5267C5.35526 12.5263 5.48772 12.4723 5.58547 12.3764C5.68323 12.2806 5.73832 12.1507 5.73873 12.0151V6.13124C5.73873 5.99555 5.68376 5.86542 5.58592 5.76948C5.48808 5.67353 5.35539 5.61963 5.21702 5.61963C5.07866 5.61963 4.94596 5.67353 4.84812 5.76948C4.75028 5.86542 4.69531 5.99555 4.69531 6.13124V12.0151C4.69572 12.1507 4.75082 12.2806 4.84857 12.3764C4.94632 12.4723 5.07878 12.5263 5.21702 12.5267Z"
                          fill="#FF5E5B"
                        />
                        <path
                          id={`noClose ${col.id + '-' + item.id}`}
                          d="M8.52171 12.5267C8.65995 12.5263 8.79242 12.4723 8.89017 12.3764C8.98792 12.2806 9.04301 12.1507 9.04342 12.0151V6.13124C9.04342 5.99555 8.98845 5.86542 8.89061 5.76948C8.79277 5.67353 8.66008 5.61963 8.52171 5.61963C8.38335 5.61963 8.25065 5.67353 8.15281 5.76948C8.05497 5.86542 8 5.99555 8 6.13124V12.0151C8.00041 12.1507 8.05551 12.2806 8.15326 12.3764C8.25101 12.4723 8.38347 12.5263 8.52171 12.5267Z"
                          fill="#FF5E5B"
                        />
                      </svg>
                    </button>
                    <button
                      id={`noClose ${col.id + '-' + item.id}`}
                      onClick={() => setEditModalIndex()}
                      className={styles.closeModal}
                    >
                      <svg
                        id={`noClose ${col.id + '-' + item.id}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          id={`noClose ${col.id + '-' + item.id}`}
                          d="M10.4031 7.08385L6.19523 3.19005C5.92153 2.93665 5.47898 2.93665 5.20528 3.19005C4.93157 3.44333 4.93157 3.85286 5.20528 4.10614L9.41315 8.00006L5.20528 11.8939C4.93157 12.1473 4.93157 12.5567 5.20528 12.8099C5.47898 13.0634 5.92153 13.0634 6.19523 12.8099L10.4031 8.91615C10.4031 8.91615 11 8.40749 11 8.00006C11 7.59263 10.4031 7.08385 10.4031 7.08385Z"
                          fill="#1E1E1E"
                        />
                      </svg>
                    </button>
                  </div>
                )}
                <button
                  id={`noClose ${col.id + '-' + item.id}`}
                  className={styles.edit}
                  onClick={() => {
                    setEditModalIndex(`${col.id} ${item.id}`);
                    setCloseCode(true);
                  }}
                >
                  <svg
                    id={`noClose ${col.id + '-' + item.id}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="12"
                    viewBox="0 0 4 12"
                    fill="none"
                  >
                    <circle
                      id={`noClose ${col.id + '-' + item.id}`}
                      cx="2.2513"
                      cy="1.33333"
                      r="1.33333"
                      fill="#1E1E1E"
                    />
                    <circle
                      id={`noClose ${col.id + '-' + item.id}`}
                      cx="2.2513"
                      cy="5.99996"
                      r="1.33333"
                      fill="#1E1E1E"
                    />
                    <circle
                      id={`noClose ${col.id + '-' + item.id}`}
                      cx="2.2513"
                      cy="10.6667"
                      r="1.33333"
                      fill="#1E1E1E"
                    />
                  </svg>
                </button>
                {edit === `${col.id} ${item.id}` && closeCode ? (
                  <textarea
                    id={`noClose ${col.id + '-' + item.id}`}
                    ref={areaRef}
                    autoFocus
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  />
                ) : (
                  <p
                    id={`noClose ${col.id + '-' + item.id}`}
                    onClick={() => {
                      setEdit(`${col.id} ${item.id}`);
                      setEditValue(item.value);
                      setCloseCode(true);
                      setOldValue(item.value);
                    }}
                    className={styles.valueText}
                  >
                    {item.value}
                  </p>
                )}
                {edit === `${col.id} ${item.id}` && closeCode && (
                  <div
                    id={`noClose ${col.id + '-' + item.id}`}
                    className={styles.addCode}
                  >
                    {variables.map((temp) => (
                      <button
                        key={temp.name}
                        id={`noClose ${col.id + '-' + item.id}`}
                        onClick={() => {
                          insertAtCursor(
                            areaRef.current,
                            temp.name,
                            col.id,
                            item.id
                          );
                        }}
                      >
                        {temp.name}{' '}
                        <span id={`noClose ${col.id + '-' + item.id}`}>
                          {temp.description}
                        </span>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AnswersConstructor;
