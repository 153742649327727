import React, { useEffect, useState } from 'react';
import RightArrow from '../../shared/ui/icons/RightArrow.jsx';
import style from './FinancingReport.module.scss';
import cx from 'classnames';
import { Tooltip } from '@mui/material';
import { getData, API_URL } from '../../static/js/request.js';

const TableHead = ({ dateList, action, headRef, currentMonth }) => {
  const [bankData, setBankData] = useState(0);
  const [cashboxData, setCashboxData] = useState(0);

  useEffect(() => {
    const urlBank = `${API_URL}/api/v1/bank/incomplete/bank/operations/?organisation_id=1`;
    const urlBankData = getData(urlBank);

    const urlCashbox = `${API_URL}/api/v1/cashbox/incomplete/cash-box/operations/`;
    const dataCashbox = getData(urlCashbox);

    (async () => {
      Promise.all([urlBankData, dataCashbox]).then((result) => {
        const [bank, cashbox] = result;
        setBankData(bank.count_incomplete_operation);
        setCashboxData(cashbox.count_incomplete_operation);
      });
    })();
  }, []);


  const TooltipWrapper = ({ hasTooltip, component }) => {
    if (hasTooltip) {
      return (
        <Tooltip
          disableInteractive
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
                {},
              ],
            },
            tooltip: {
              style: {
                backgroundColor: '#454F5C',
                opacity: 1,
              },
            },
          }}
          title={
            <div
              style={{
                color: '#FFFFFF',
                width: '263px',
                fontSize: '16px',
              }}
            >
              <p style={{ marginBottom: '10px', textAlign: 'center' }}>
                Незаполненные данные
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '14px',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <span
                    style={{
                      background: '#FFAA5B',
                      width: '3px',
                      height: '19px',
                      borderRadius: '3px',
                    }}
                  ></span>
                  Точка
                </span>{' '}
                <span>{bankData}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '5px',
                  fontSize: '14px',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <span
                    style={{
                      background: '#1AB889',
                      width: '3px',
                      height: '19px',
                      borderRadius: '3px',
                    }}
                  ></span>
                  Касса ИП Маск И.И.:
                </span>{' '}
                <span>{cashboxData}</span>
              </div>
            </div>
          }
        >
          {component}
        </Tooltip>
      );
    }
    return component;
  };

  return (
    <div className={style.row}>
      <div className={style.title} />
      <div className={style.xScroll} ref={headRef}>
        {dateList.map((item, index) => {
          const isWeek = !item[1].includes('нед');
          const isActive = currentMonth === item[1];

          return (
            <TooltipWrapper
              key={index}
              hasTooltip={isWeek}
              component={
                <div
                  className={cx(
                    style.column,
                    isWeek ? style.dates : style.week,
                    isActive ? style.active : null
                  )}
                  data-year={item[0]}
                  onClick={() => {
                    if (
                      item[1] !== 'Итог' &&
                      !item[1].includes('нед') && action
                    ) {
                      action(
                        currentMonth === item[1] ? null : item[1],
                        item[0]
                      );
                    }
                  }}
                >
                  {item[1]}
                  {item[1] !== 'Итог' && !item[1].includes('нед') && (
                    <RightArrow />
                  )}
                </div>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default TableHead;
