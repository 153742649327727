import {API_URL, getData} from '../static/js/request';

export const getRules = () => {
    return getData(API_URL + '/api/v1/bank/rules/');
};

export const getContrAgents = () => {
    return getData(API_URL + '/api/v1/bank/unique-contragent-names/');
};


export const getArticles = () => {
    return getData(API_URL + '/api/v1/articles-management/all/');
};
