import React, { useState, useRef } from 'react';
import loansStyle from './loansStyle.module.scss';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import {
    formatNumber2,
} from '../../../static/js/commonFunc';
import { LoansStore } from '../../../store/Financing/LoansStore';

const LoansTable = observer(() => {
    const [state] = useState(() => new LoansStore());

    const [rerender, setRerender] = useState(false);

    const [isActive, setIsActive] = useState(false);

    const mainTableFinanceLeft = useRef(null);
    const mainTableFinanceRight = useRef(null);

    React.useEffect(() => {}, [rerender]);

    const handleClick = (evt, index) => {
        mainTableFinanceRight.current
            .querySelectorAll(`.${loansStyle.financingRowParent}`)
            .forEach((el, indexEl) => {
                if (index === indexEl) {
                    el.classList.toggle(loansStyle.active);
                }
            });
        evt.target
            .closest(`.${loansStyle.financingRowParent}`)
            .classList.toggle(loansStyle.active);
        setIsActive(!isActive);
    };


    return (
        <div className={loansStyle.mainTableFinance}>
            <div
                className={loansStyle.mainTableFinanceLeft}
                ref={mainTableFinanceLeft}
            >
                {state.tableView?.data &&
          Object.entries(state.tableView?.data)?.map((item, mainIndex) => (
              <div key={mainIndex} className={loansStyle.financingRowParent}>
                  <div
                      className={cx(
                          loansStyle['gap-10'],
                          loansStyle['flex'],
                          loansStyle['ai-c'],
                          loansStyle['lineHeight'],
                          loansStyle.financingRowParentTitle
                      )}
                  >
                      <div className={loansStyle.financingTable}>
                          {Object.entries(item[1]['nestedEl']).length ? (
                              <span
                                  className={loansStyle.showMoreRowLoans}
                                  onClick={(evt) => {
                                      handleClick(evt, mainIndex);
                                  }}
                              >
                                  <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                          d="M8.91615 5.59689L12.8099 9.80477C13.0634 10.0785 13.0634 10.521 12.8099 10.7947C12.5567 11.0684 12.1471 11.0684 11.8939 10.7947L7.99994 6.58685L4.10614 10.7947C3.85274 11.0684 3.44333 11.0684 3.19005 10.7947C2.93665 10.521 2.93665 10.0785 3.19005 9.80477L7.08385 5.59689C7.08385 5.59689 7.59251 5 7.99994 5C8.40737 5 8.91615 5.59689 8.91615 5.59689Z"
                                          fill="#1E1E1E"
                                      />
                                  </svg>
                              </span>
                          ) : (
                              <></>
                          )}
                          <span>{item[0]}</span>
                      </div>
                  </div>

                  {Object.entries(item[1]['nestedEl']).length ? (
                      <div className={cx(loansStyle.financingTableHideColumn)}>
                          {Object.entries(item[1]['nestedEl']).map(
                              (nestedItem, index) => (
                                  <div
                                      key={index}
                                      className={cx(
                                          loansStyle.financingTableNestedBlock,
                                          loansStyle.pl40,
                                          loansStyle.boldText
                                      )}
                                  >
                                      <div
                                          className={cx(
                                              loansStyle['gap-10'],
                                              loansStyle['flex'],
                                              loansStyle['ai-c'],
                                              loansStyle['lineHeight']
                                          )}
                                      >
                                          <div
                                              className={cx(
                                                  loansStyle['financingTable'],
                                                  loansStyle['pl-20']
                                              )}
                                          >
                                              <span>{nestedItem[0]}</span>
                                          </div>
                                      </div>
                                  </div>
                              )
                          )}
                      </div>
                  ) : (
                      <></>
                  )}
              </div>
          ))}
            </div>
            <div
                className={loansStyle.mainTableFinanceRight}
                ref={mainTableFinanceRight}
            >
                <div
                    className={cx(
                        loansStyle.financingRowParentDate,
                        loansStyle['gap-10'],
                        loansStyle['flex'],
                        loansStyle['ai-c'],
                        loansStyle['lineHeight']
                    )}
                >
                    {state.tableView?.date.map((item, index) => (
                        <div
                            key={index}
                            className={cx(
                                loansStyle.financingTableColumn,
                                loansStyle.financingTableColumnHeader
                            )}
                            data-year={item[0]}
                        >
                            {!item[1] ? item[0] : item[1]}
                        </div>
                    ))}
                </div>
                {state.tableView?.data &&
          Object.entries(state.tableView?.data).map((item, index) => (
              <div key={index} className={loansStyle.financingRowParent}>
                  <div
                      className={cx(
                          loansStyle['gap-10'],
                          loansStyle['flex'],
                          loansStyle['ai-c'],
                          loansStyle['lineHeight'],
                          loansStyle.financingRowParentValue
                      )}
                  >
                      {item[1].value.map((nestedItem, i) => (
                          <div key={i} className={loansStyle.financingTableColumn}>
                              {nestedItem ? (
                                  <span>
                                      {nestedItem >= 0 ? (
                                          <span className={loansStyle.green}>
                                              {formatNumber2(nestedItem)}
                                          </span>
                                      ) : (
                                          <span className={loansStyle.red}>
                                              {formatNumber2(nestedItem)}
                                          </span>
                                      )}
                                  </span>
                              ) : (
                                  <></>
                              )}
                          </div>
                      ))}
                  </div>

                  {Object.entries(item[1]['nestedEl']).length ? (
                      <div className={loansStyle.financingTableHideColumn}>
                          {Object.entries(item[1]['nestedEl']).map(
                              (nestedItem, index) => (
                                  <div
                                      key={index}
                                      className={loansStyle.financingTableNestedBlock}
                                  >
                                      <div
                                          className={cx(
                                              loansStyle['gap-10'],
                                              loansStyle['flex'],
                                              loansStyle['ai-c'],
                                              loansStyle['lineHeight']
                                          )}
                                      >
                                          {nestedItem[1].value.map((nestedItemValue, index) => (
                                              <div
                                                  key={index}
                                                  className={loansStyle.financingTableColumn}
                                              >
                                                  {nestedItemValue[0] ? (
                                                      <span>
                                                          {nestedItemValue[0] >= 0 ? (
                                                              <span className={loansStyle.green}>
                                                                  {formatNumber2(nestedItemValue[0])}
                                                              </span>
                                                          ) : (
                                                              <span className={loansStyle.red}>
                                                                  {formatNumber2(nestedItemValue[0])}
                                                              </span>
                                                          )}
                                                      </span>
                                                  ) : (
                                                      <></>
                                                  )}
                                              </div>
                                          ))}
                                      </div>
                                  </div>
                              )
                          )}
                      </div>
                  ) : (
                      <></>
                  )}
              </div>
          ))}
            </div>
        </div>
    );
});

export default LoansTable;
