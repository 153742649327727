import React, { useState, useRef } from 'react';
import style from '../../../Purchase.module.scss'
import SettingsIcon from '../../../../../shared/ui/icons/Settings.jsx';
import TableSettings from './settings.jsx';
import { useNavigate } from 'react-router-dom';
import PlusIcon from '../../../../../shared/ui/icons/Plus.jsx';
import FilterIcon from '../../../../../shared/ui/icons/Filter.jsx';

const Filter = ({ filters, newItemLink, columns, editColumns, filterRef }) => {

    const [showSettings, toggleSettings] = useState(false)
    const showSettingsButtonRef = useRef(null)

    const navigate = useNavigate();

    return (

        <div className={style.filter} ref={filterRef}>

            <div className={style.topRow}>

                <div className={style.left}>

                    <button onClick={() => console.log('show filters')} className={style.filterButton}>
                        <span>Фильтр</span>
                        <FilterIcon />
                    </button>

                </div>

                <div className={style.rigth}>

                    <button onClick={() => navigate(newItemLink.value)} className={style.create}>
                        <PlusIcon />
                        <span>{newItemLink.name}</span>
                    </button>

                    <button ref={showSettingsButtonRef} onClick={() => toggleSettings(prev => !prev)} className={style.settings}>
                        <SettingsIcon />
                    </button>

                </div>

            </div>

            <div className={style.bottomRow}>

                {
                    filters.map((item, index) =>
                        <div className={style.filterValue} key={index}>
                            <span>
                                {item}
                            </span>
                        </div>
                    )
                }

            </div>

            <TableSettings
                columns={columns}
                editColumns={editColumns}
                show={showSettings}
                showSettings={(value) => toggleSettings(value)}
                showSettingsButtonRef={showSettingsButtonRef}
            />

        </div>
    )
}

export default Filter