import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import './static/css/confirmAllert.scss';
import './static/css/notification.scss';
import {QueryClient, QueryClientProvider} from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log(
    '%c BUSYBOARD ',
    'background: #fff; color: #429EFF; font-size: 32px; border-radius: 30px'
);

const queryClient = new QueryClient();

// console.log = () => {};

root.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <ReactNotifications />
            <App />
        </BrowserRouter>
    </QueryClientProvider>
);
