import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { formatNumber2 } from '../../../../../static/js/commonFunc';

export const ProfitTooltip = ({ data, position, visibility, allData, labels, total }) => {

    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        if (allData) {
            setCurrentData(allData.map(item => {
                return item.map(arr => {
                    return {
                        ...arr,
                        value: arr.category === 'income' ? arr.value : -Math.abs(arr.value)
                    }
                })
            }))
        }
    }, [allData])

    return (
        <div
            className={`${styles.tooltip} ${visibility ? styles.visible : ''}`}
            style={{
                top: position?.top > 200 ? position?.top - 100 : position?.top + 100,
                left: position?.left,
                position: 'absolute'
            }}
        >
            {data && (
                <>
                    <h5 className={styles.tooltipTitle}>
                        {data ? labels[data?.dataPoints[0].dataIndex] : ''}
                    </h5>

                    <div className="divide-y divide-gray-100/60">
                        {
                            currentData[data?.dataPoints[0].dataIndex].map((item, index) => (
                                <div
                                    key={index}
                                    className={styles.label}
                                >
                                    <p className={styles.labelTitle}>
                                        {item.name}:
                                    </p>
                                    <p className={styles.labelValue} style={{ color: item.value > 0 ? '#1AB889' : '#FF5E5B' }}>
                                        {`${item.value > 0 ? '+' : '-'} ${formatNumber2(Math.abs(item.value))}`}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </>
            )}
        </div>
    );
};