import React, { useRef, useState, useEffect } from 'react';
import TableHead from './head.jsx';
import TableRow from './row.jsx';
import style from '../../../Purchase.module.scss'
import ScrollBar from '../../../../../components/custom/ScrollBar/index.jsx';

const ListTable = ({ data, columns, filterRef, setSortingBy }) => {

    const headRef = useRef(null);
    const bodyRef = useRef(null);

    const [yScrollHeigth, setYScrollHeigth] = useState(null)  
    const [scrollOffset, setScrollOffset] = useState(null)  
    const [scrollHeigth, setScrollHeigth] = useState(null)

    useEffect(() => {
        if (filterRef.current) {
            const height = filterRef.current.offsetHeight;
            setYScrollHeigth( (725 - height) + 'px' )
            setScrollOffset(140 + height)
            setScrollHeigth((805 - height) + 'px')
        }
    }, [columns]);

    return (

        <section className={style.section}>

            <TableHead columns={columns} headRef={headRef} setSortingBy={setSortingBy} />

            <div className={style.body} >

                <div style={{maxHeight: yScrollHeigth}} ref={bodyRef} className={style.yScroll} >

                    <TableRow data={data} columns={columns} headRef={headRef} />

                </div>

            </div>

            <ScrollBar targetRef={headRef} horizontal={true} offset={0} />

            <ScrollBar targetRef={bodyRef} height={scrollHeigth} offset={scrollOffset} />

        </section>


    )

}

export default ListTable