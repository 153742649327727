import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import MarketplacesSwitch from '../Logistics/ui/MarketplacesSwitch/index.jsx';
import YearSelect from '../Balance/YearSelect/index.jsx';
import {DateRangePicker} from 'react-date-range';
import dayjs from 'dayjs';
import {ru} from 'date-fns/locale';
import CheckboxList from "../Advertisement/CheckboxList/index.jsx";
import {API_URL, getData} from "../../static/js/request";
import Table from "./ui/Table/index.jsx";

const Index = () => {

    const [activeMp, setActiveMp] = React.useState(1);

    const [tagsSelect, setSelectTags] = useState([]);
    const [brandsSelect, setSelectBrands] = useState([]);
    const [categoriesSelect, setSelectCategories] = useState([]);
    const [articlesSelect, setSelectArticles] = useState([]);
    const [companiesSelect, setSelectCompanies] = useState([]);
    const [savedDates, setSavedDates] = useState({
        startDate: null,
        endDate: null,
    });

    const [calendarOpen, setOpenCalendar] = useState(false);

    const [disabledMetric, setDisabledMetric] = useState([]);
    const [metricFilter, setMetricFIlter] = useState(false);

    const [data, setData] = useState({});

    const [metricsSaved, setSavedMetrics] = useState([]);

    const removeAllFilters = () => {
        setSelectTags([]);
        setSelectBrands([]);
        setSelectCategories([]);
        setSelectArticles([]);
        setSelectCompanies([]);
        resetDates();
    };

    useEffect(() => {
        if (metricFilter && metricsSaved.length > 0) {
            setDisabledMetric(
                data?.values[0].metrics.metrics_names.filter(
                    (temp) => !metricsSaved.includes(temp)
                )
            );
        }
    }, [metricFilter]);

    const [dataChanged, setChengedData] = useState(false);

    useEffect(() => {
        if (tags && data.values && !dataChanged) {
            setChengedData(true);
            setData({
                ...data,
                values: data.values.map((item) => {
                    return {
                        ...item,
                        product: {
                            ...item.product,
                            tags: tags.filter((tag) =>
                                item.product.tags.map((temp) => temp.id).includes(tag.id)
                            ),
                        },
                    };
                }),
            });
        }
    }, [tags, data]);

    const [tags, setTags] = useState([]);

    const [products, setProducts] = useState([]);

    const getAllTags = async () => {
        if (tags.length === 0) {
            try {
                const response = await getData(API_URL + '/api/v1/tags/');
                setTags(response.tags);
            } catch (error) {}
        }
    };

    const [companies, setCompanies] = useState([]);

    const getAllCompanies = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/ad-campaigns/');
            setCompanies(response);
        } catch (error) {}
    };

    const [articles, setArticles] = useState([]);

    const getProducts = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/general/user/products/');
            setArticles(response.map((item) => item.vendorCode));
            setProducts(response);
        } catch (error) {}
    };

    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/user/categories/');
            setCategories(response);
        } catch (error) {}
    };

    const [brands, setBrands] = useState([]);

    const getBrands = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/user/brands/');
            setBrands(response.filter((item) => item));
        } catch (error) {}
    };

    useEffect(() => {
        getAllTags();
        getAllCompanies();
        getProducts();
        getCategories();
        getBrands();
    }, []);

    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1);
        return new Date(d.setDate(diff));
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    const handleSelect = (ranges) => {
        if (calendarMode === 'Неделя') {
            const monday = getMonday(ranges.selection.endDate);
            const sunday = monday.addDays(6);

            setStartDate(monday);
            setEndDate(sunday);
        } else {
            setStartDate(ranges.selection.startDate);
            setEndDate(ranges.selection.endDate);
        }
    };

    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

    function getLastDayOfMonth(year, month) {
        return new Date(year, month + 1, 0);
    }

    const [calendarMode, setCalendarMode] = useState(null);

    const changeCalndarMode = (mode) => {
        setCalendarMode((state) => (state === mode ? null : mode));
    };

    const months = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
    ];

    const years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];

    const [selectYear, setSelectYear] = useState(2023);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectMonth, setSelectMonth] = useState('');

    useEffect(() => {
        if (calendarMode === 'Месяц') {
            setStartDate(getFirstDayOfMonth(selectYear, months.indexOf(selectMonth)));
            setEndDate(getLastDayOfMonth(selectYear, months.indexOf(selectMonth)));
        } else {
            const newStartDate = new Date();
            newStartDate.setFullYear(selectYear);
            newStartDate.setMonth(0);
            newStartDate.setDate(1);

            const newEndDate = new Date();
            newEndDate.setFullYear(selectYear);
            newEndDate.setMonth(11);
            newEndDate.setDate(31);

            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
    }, [selectYear, selectMonth]);

    useEffect(() => {
        setStartDate(null);
        setEndDate(null);
    }, [calendarMode]);

    const saveDate = () => {
        setSavedDates({
            startDate,
            endDate,
        });
    };

    const resetDates = () => {
        setSavedDates({
            startDate: null,
            endDate: null,
        });
        setStartDate(null);
        setEndDate(null);
        setSelectMonth(null);
        setSelectYear(2023);
    };

    return (
        <div className={styles.page}>
            <div className={styles.top}>
                <h2 className="">Продажи</h2>
                <MarketplacesSwitch active={activeMp} setActive={setActiveMp} all={false} />
            </div>
            <div className={styles.content}>
                <div className={styles.contentTop}>
                    {calendarOpen && (
                        <>
                            <span
                                className={styles.closeCalendarBg}
                                onClick={() => setOpenCalendar(false)}
                            />
                            <div className={styles.calendarBlock}>
                                {calendarMode === 'Месяц' ? (
                                    <div className={styles.calendarWrap}>
                                        <YearSelect
                                            value={selectYear}
                                            onChange={setSelectYear}
                                            allValues={years}
                                            width={'100%'}
                                        />
                                        <div className={styles.months}>
                                            {months.map((item) => (
                                                <button
                                                    className={selectMonth === item ? styles.active : ''}
                                                    onClick={() => setSelectMonth(item)}
                                                >
                                                    {item}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ) : calendarMode === 'Год' ? (
                                    <div className={styles.calendarWrap}>
                                        <div className={styles.months}>
                                            {years.map((item) => (
                                                <button
                                                    className={selectYear === item ? styles.active : ''}
                                                    onClick={() => setSelectYear(item)}
                                                >
                                                    {item}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.calendarWrap}>
                                        <DateRangePicker
                                            ranges={[
                                                {
                                                    startDate: startDate ? startDate : new Date(),
                                                    endDate: endDate ? endDate : new Date(),
                                                    key: 'selection',
                                                },
                                            ]}
                                            onChange={handleSelect}
                                            rangeColors={['#3d91ff']}
                                            showSelectionPreview={false}
                                            // disabledDay={(date) => }
                                            months={12}
                                            direction="vertical"
                                            staticRanges={[]}
                                            inputRanges={[]}
                                            fixedHeight={true}
                                            showPreview={false}
                                            showDateDisplay={false}
                                            dateDisplayFormat="dd.MM.yyyy"
                                            locale={customLocale}
                                        />
                                    </div>
                                )}

                                <div className={styles.infoSide}>
                                    <button
                                        className={`${styles.sideBtn} ${
                                            calendarMode === 'Неделя' ? styles.active : ''
                                        }`}
                                        onClick={() => changeCalndarMode('Неделя')}
                                    >
                                        Неделя
                                    </button>
                                    <button
                                        className={`${styles.sideBtn} ${
                                            calendarMode === 'Месяц' ? styles.active : ''
                                        }`}
                                        onClick={() => changeCalndarMode('Месяц')}
                                    >
                                        Месяц
                                    </button>
                                    <button
                                        className={`${styles.sideBtn} ${
                                            calendarMode === 'Год' ? styles.active : ''
                                        }`}
                                        onClick={() => changeCalndarMode('Год')}
                                    >
                                        Год
                                    </button>
                                    <div className={styles.sideInput}>
                                        <p className={styles.inputTitle}>Начало периода</p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                dayjs(startDate).format('DD.MM.YYYY') === 'Invalid Date'
                                                    ? 'Дата начала'
                                                    : dayjs(startDate).format('DD.MM.YYYY')
                                            }
                                        />
                                    </div>
                                    <div className={styles.sideInput}>
                                        <p className={styles.inputTitle}>Конец периода</p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                dayjs(endDate).format('DD.MM.YYYY') === 'Invalid Date'
                                                    ? 'Дата конца'
                                                    : dayjs(endDate).format('DD.MM.YYYY')
                                            }
                                        />
                                    </div>
                                    <div className={styles.bottomBtns}>
                                        <button
                                            onClick={resetDates}
                                            className={styles.closeChanges}
                                        >
                                            Сбросить
                                        </button>
                                        <button
                                            className={styles.saveChanges}
                                            onClick={() => {
                                                saveDate();
                                                setOpenCalendar(false);
                                            }}
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <button
                        onClick={() => setOpenCalendar(true)}
                        className={styles.calendarBtn}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                        >
                            <path
                                d="M14.4995 1.24544H13.692V0.625465C13.692 0.277529 13.41 -0.00451172 13.0621 -0.00451172C12.7141 -0.00451172 12.4321 0.277529 12.4321 0.625465V1.24544H9.59843V0.625465C9.59843 0.277529 9.31639 -0.00451172 8.96846 -0.00451172C8.62052 -0.00451172 8.33848 0.277529 8.33848 0.625465V1.24544H5.53608V0.625465C5.53608 0.277529 5.25404 -0.00451172 4.90611 -0.00451172C4.55817 -0.00451172 4.27613 0.277529 4.27613 0.625465V1.24544H3.49991C2.1187 1.24544 0.995 2.36914 0.995 3.75035V13.5C0.995 14.8812 2.1187 16.0049 3.49991 16.0049H14.4995C15.8807 16.0049 17.0044 14.8812 17.0044 13.5V3.75035C17.0044 2.36914 15.8807 1.24544 14.4995 1.24544ZM3.49991 2.5054H4.27613V3.12537C4.27613 3.47331 4.55817 3.75535 4.90611 3.75535C5.25404 3.75535 5.53608 3.47331 5.53608 3.12537V2.5054H8.33848V3.12537C8.33848 3.47331 8.62052 3.75535 8.96846 3.75535C9.31639 3.75535 9.59843 3.47331 9.59843 3.12537V2.5054H12.4321V3.12537C12.4321 3.47331 12.7141 3.75535 13.0621 3.75535C13.41 3.75535 13.692 3.47331 13.692 3.12537V2.5054H14.4995C15.186 2.5054 15.7445 3.06389 15.7445 3.75035V13.5C15.7445 14.1865 15.186 14.7449 14.4995 14.7449H3.49991C2.81344 14.7449 2.25495 14.1865 2.25495 13.5V3.75035C2.25495 3.06389 2.81344 2.5054 3.49991 2.5054Z"
                                fill="#429EFF"
                                stroke="#429EFF"
                                strokeWidth="0.01"
                            />
                            <path
                                d="M13.0625 7.18745C13.4076 7.18745 13.6875 6.90764 13.6875 6.56248C13.6875 6.21731 13.4076 5.9375 13.0625 5.9375C12.7173 5.9375 12.4375 6.21731 12.4375 6.56248C12.4375 6.90764 12.7173 7.18745 13.0625 7.18745Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M10.3437 7.18745C10.6889 7.18745 10.9687 6.90764 10.9687 6.56248C10.9687 6.21731 10.6889 5.9375 10.3437 5.9375C9.99856 5.9375 9.71875 6.21731 9.71875 6.56248C9.71875 6.90764 9.99856 7.18745 10.3437 7.18745Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M7.62498 9.9062C7.97014 9.9062 8.24995 9.62639 8.24995 9.28123C8.24995 8.93606 7.97014 8.65625 7.62498 8.65625C7.27981 8.65625 7 8.93606 7 9.28123C7 9.62639 7.27981 9.9062 7.62498 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M10.3437 9.9062C10.6889 9.9062 10.9687 9.62639 10.9687 9.28123C10.9687 8.93606 10.6889 8.65625 10.3437 8.65625C9.99856 8.65625 9.71875 8.93606 9.71875 9.28123C9.71875 9.62639 9.99856 9.9062 10.3437 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M10.3437 12.6245C10.6889 12.6245 10.9687 12.3447 10.9687 11.9995C10.9687 11.6543 10.6889 11.3745 10.3437 11.3745C9.99856 11.3745 9.71875 11.6543 9.71875 11.9995C9.71875 12.3447 9.99856 12.6245 10.3437 12.6245Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M13.0625 9.9062C13.4076 9.9062 13.6875 9.62639 13.6875 9.28123C13.6875 8.93606 13.4076 8.65625 13.0625 8.65625C12.7173 8.65625 12.4375 8.93606 12.4375 9.28123C12.4375 9.62639 12.7173 9.9062 13.0625 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M13.0625 12.6245C13.4076 12.6245 13.6875 12.3447 13.6875 11.9995C13.6875 11.6543 13.4076 11.3745 13.0625 11.3745C12.7173 11.3745 12.4375 11.6543 12.4375 11.9995C12.4375 12.3447 12.7173 12.6245 13.0625 12.6245Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M4.90623 7.18745C5.25139 7.18745 5.5312 6.90764 5.5312 6.56248C5.5312 6.21731 5.25139 5.9375 4.90623 5.9375C4.56106 5.9375 4.28125 6.21731 4.28125 6.56248C4.28125 6.90764 4.56106 7.18745 4.90623 7.18745Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M4.90623 9.9062C5.25139 9.9062 5.5312 9.62639 5.5312 9.28123C5.5312 8.93606 5.25139 8.65625 4.90623 8.65625C4.56106 8.65625 4.28125 8.93606 4.28125 9.28123C4.28125 9.62639 4.56106 9.9062 4.90623 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M4.90623 12.625C5.25139 12.625 5.5312 12.3451 5.5312 12C5.5312 11.6548 5.25139 11.375 4.90623 11.375C4.56106 11.375 4.28125 11.6548 4.28125 12C4.28125 12.3451 4.56106 12.625 4.90623 12.625Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M7.62498 12.6245C7.97014 12.6245 8.24995 12.3447 8.24995 11.9995C8.24995 11.6543 7.97014 11.3745 7.62498 11.3745C7.27981 11.3745 7 11.6543 7 11.9995C7 12.3447 7.27981 12.6245 7.62498 12.6245Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M7.62498 7.18745C7.97014 7.18745 8.24995 6.90764 8.24995 6.56248C8.24995 6.21731 7.97014 5.9375 7.62498 5.9375C7.27981 5.9375 7 6.21731 7 6.56248C7 6.90764 7.27981 7.18745 7.62498 7.18745Z"
                                fill="#429EFF"
                            />
                        </svg>
                        <p>
                            {savedDates.startDate || savedDates.endDate
                                ? `${savedDates.startDate.getDate()} ${months[
                                    savedDates.startDate.getMonth()
                                ].substring(0, 3)} ${savedDates.startDate.getFullYear()}` +
                                ' - ' +
                                `${savedDates.endDate.getDate()} ${months[
                                    savedDates.endDate.getMonth()
                                ].substring(0, 3)} ${savedDates.endDate.getFullYear()}`
                                : 'Выберите дату'}
                        </p>
                    </button>
                    <CheckboxList
                        activeList={companiesSelect}
                        onChange={setSelectCompanies}
                        values={companies.map((company) => company.name)}
                        id={'company'}
                        title={
                            companiesSelect.length > 0
                                ? `Компании: ${companiesSelect.length}`
                                : 'Все компании'
                        }
                    />
                    <CheckboxList
                        activeList={categoriesSelect}
                        onChange={setSelectCategories}
                        values={categories}
                        id={'category'}
                        title={
                            categoriesSelect.length > 0
                                ? `Категории: ${categoriesSelect.length}`
                                : 'Все категории'
                        }
                    />
                    <CheckboxList
                        activeList={tagsSelect}
                        onChange={setSelectTags}
                        values={tags.map((tag) => tag.name)}
                        id={'tags'}
                        title={
                            tagsSelect.length > 0 ? `Теги: ${tagsSelect.length}` : 'Все теги'
                        }
                    />
                    <button className={styles.resetFilters} onClick={removeAllFilters}>
                        Сбросить все
                    </button>
                </div>
                <div className={styles.contentMain}>
                    <Table />
                </div>
            </div>
        </div>
    );
};
export default Index;
