import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import { InputWrap } from '../../Account/Account.jsx';
import {API_URL, getData, postData} from '../../../../../static/js/request';
import CheckboxList from "../CheckboxList/index.jsx";


function AddModalStaff({ onClose, allWorks, setSended, orgId, getStaff }) {
    const [telegram, changeTelegram] = useState('');
    const [work, setWork] = useState(null);
    const [email, changeEmail] = useState('');
    const [name, changeName] = useState('');
    const [surname, changeSurname] = useState('');
    const [last_name, changeLastName] = useState('');
    const [organisations, setOrganisations] = useState([]);
    const [allOrgs, setAllOrgs] = useState([]);

    const sendRequest = async () => {
        try {
            const response = await postData(API_URL + '/api/v1/access/employee/', {
                first_name: name,
                middle_name: last_name,
                last_name: surname,
                organisation: allOrgs.find(item => item.name === organisations[0]).id,
                access: allWorks?.find(item => item.name === work).id,
                tg_username: telegram,
                email: email
            });
            onClose();
            setSended(true);
            getStaff();
        } catch (e) {
            console.log(e);
        }
    };

    const getOrganisations = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/organisation/');
            console.log(response);
            setAllOrgs(response);
        } catch (e) {
            console.log(e);
        }
    };


    useEffect(() => {
        getOrganisations();
    }, []);



    return (
        <div className={styles.modal}>
            <span onClick={onClose} className={styles.bgClose} />
            <div className={styles.content}>
                <button onClick={onClose} className={styles.closeBtn}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M13.2826 12.0001L18.7339 6.54851C19.0887 6.1939 19.0887 5.62056 18.7339 5.26595C18.3793 4.91135 17.806 4.91135 17.4514 5.26595L11.9999 10.7176L6.5486 5.26595C6.19384 4.91135 5.62067 4.91135 5.26607 5.26595C4.91131 5.62056 4.91131 6.1939 5.26607 6.54851L10.7174 12.0001L5.26607 17.4517C4.91131 17.8063 4.91131 18.3797 5.26607 18.7343C5.44279 18.9111 5.67514 19 5.90734 19C6.13953 19 6.37172 18.9111 6.5486 18.7343L11.9999 13.2827L17.4514 18.7343C17.6283 18.9111 17.8605 19 18.0927 19C18.3249 19 18.557 18.9111 18.7339 18.7343C19.0887 18.3797 19.0887 17.8063 18.7339 17.4517L13.2826 12.0001Z"
                            fill="#35373A"
                        />
                    </svg>
                </button>
                <h2 className={styles.title}>Пригласить сотрудника</h2>
                <p className={styles.subtitle}>
                    Сотрудник получит приглашение в Telegram для подтверждения аккаунта
                </p>
                <div className={styles.inputsCol}>
                    <InputWrap
                        title={
                            <>
                                Фамилия <span>*</span>
                            </>
                        }
                        value={surname}
                        onChange={changeSurname}
                        placeholder="Введите фамилию"
                        width="100%"
                    />
                    <InputWrap
                        title={
                            <>
                                Имя <span>*</span>
                            </>
                        }
                        value={name}
                        onChange={changeName}
                        placeholder="Введите имя"
                        width="100%"
                    />
                    <InputWrap
                        title={
                            <>
                                Отчество <span>*</span>
                            </>
                        }
                        value={last_name}
                        onChange={changeLastName}
                        placeholder="Введите отчество"
                        width="100%"
                    />
                    <InputWrap
                        title={
                            <>
                                Telegram <span>*</span>
                            </>
                        }
                        value={telegram}
                        onChange={changeTelegram}
                        placeholder="@"
                        width="100%"
                    />
                    <div className={styles.orgsChecks}>
                        <p className={styles.orgsTitle}>
                            Организации <span>*</span>
                        </p>
                        <CheckboxList activeList={organisations} onChange={(names) => {
                            setOrganisations(names);
                        }} noActiveStyles values={allOrgs.map(item => item.name)} title={
                            organisations?.length > 0
                                ? organisations[0]
                                : 'Выберите организацию'
                        }/>
                    </div>
                    <InputWrap
                        title={
                            <>
                                Роль <span>*</span>
                            </>
                        }
                        value={work}
                        onChange={setWork}
                        placeholder="Выберите роль"
                        allValues={allWorks.map(item => item.name)}
                        width="100%"
                        select
                        textAlign="left"
                    />
                    <InputWrap
                        title={<>Электронная почта</>}
                        value={email}
                        onChange={changeEmail}
                        placeholder="Введите почту"
                        width="100%"
                    />
                </div>
                <p className={styles.bottomText}>
                    Предоставляя персональные данные третьего лица для добавления
                    сотрудника, я заверяю о наличии согласия такого лица на передачу вам
                    персональных данных, а так же последующую обработку вами персональных
                    данных
                </p>
                <button onClick={sendRequest} disabled={!telegram || !work || !name || !surname || !last_name || organisations.length === 0} className={styles.send}>
                    Отправить приглашение
                </button>
            </div>
        </div>
    );
}

export default AddModalStaff;
