import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import MainSettings from "./MainSettings/index.jsx";
import ProductSettings from "./ProductsSettings/index.jsx";
import { API_URL, putData } from "../../../../../static/js/request";
import { useLocation, useParams } from "react-router-dom";
import { createNotification } from "../../../../../static/js/commonFunc";

function ProjectSettings({ info, setChange, getInfo }) {

    const [changed, setChanged] = useState(false);

    const [defaultInfo, setDefaultInfo] = useState()

    const [newInfo, changeInfo] = useState(info)

    const params = useParams()


    useEffect(() => {
        if (info && !defaultInfo) {
            setDefaultInfo(info)
        }
    }, [info])

    const toDefault = () => {
        setChange(info)
    }

    useEffect(() => {
        console.log(defaultInfo)
        console.log(newInfo)
        if(JSON.stringify(defaultInfo) == JSON.stringify(newInfo)) {
            setChanged(false)
        } else {
            setChanged(true)
        }
    }, [newInfo])

    const saveChanges = async () => {
        const response = await putData(API_URL + `/api/v1/projects/${params.id}/settings/`, {
            ...newInfo,
            products: [...newInfo.products.map(item => {
                return {
                    id: item.id,
                    planned_sales_amount: parseFloat(item.planned_sales_amount.toString().replace(',', '.').replace(' ', '')),
                    planned_sales_qty: parseFloat(item.planned_sales_qty.toString().replace(',', '.').replace(' ', '')),
                }
            })]
        })
        createNotification(response)
        setChanged(false)
        getInfo()
    }


    return (
        <div className={styles.content}>
            <MainSettings changeInfo={changeInfo} setChanged={setChanged} info={info} />
            <ProductSettings newInfo={newInfo} changeInfo={changeInfo} setChanged={setChanged} info={info} />
            {
                changed &&
                <div className={styles.saveBlock}>
                    <p>Созранение изменений</p>
                    <div className={styles.saveButtons}>
                        <button onClick={toDefault} className={styles.reload}>Отмена</button>
                        <button onClick={saveChanges} className={styles.save}>Сохранить</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default ProjectSettings;