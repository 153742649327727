import React, { useState, useEffect, useRef } from "react";
import EditIcon from "../../../../../../shared/ui/icons/Edit.jsx";
import PlusIcon from "../../../../../../shared/ui/icons/Plus.jsx";
import { NewCustomInput } from "../../../../../../components/custom/NewCustomInput/NewCustomInput.jsx";
import style from '../../../../Purchase.module.scss'
import { Input } from "@/fsd/shared/ui";
import { formatStringToFloat } from "@/fsd/shared/utils/formatters";
import { FloatInput } from "@/fsd/entities/order-price-input/ui/order-price-input";


const EditValue = ({ value, column, convertFunction = false, action, id, isInteger }) => {

    const [isEdit, toggleEdit] = useState(false)
    const [currentValue, setValue] = useState(value)

    const elementRef = useRef(null)

    const handler = () => {
        toggleEdit(false)
        action(currentValue, id, column)
    }

    useEffect(() => {
        const element = elementRef.current;

        const handleOutsideClick = (e) => {
            if (element && !element.contains(e.target)) {
                toggleEdit(false);

                if (currentValue !== value) {
                    action(currentValue, id, column);
                }
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [currentValue]);

    return (
        <div ref={elementRef} style={{ display: 'flex', alignItems: 'center' }}>
            {
                isEdit ?
                    <>
                        <FloatInput value={currentValue} style={{ width: '100%' }} onChange={(e) => setValue(isInteger ? parseInt(e.target.value) : e.target.value)} />
                        <span className={style.plus} onClick={() => handler()}>
                            <PlusIcon />
                        </span>
                    </>
                    :
                    <>
                        {currentValue || 0}
                        <span onClick={() => toggleEdit(true)}>
                            <EditIcon />
                        </span>
                    </>
            }
        </div>
    )
}

export default EditValue