import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { PercentCard } from './PercentCard.jsx';
import { Payback } from './Payback.jsx';
import MarketplacesSwitch from '../../../../Logistics/ui/MarketplacesSwitch/index.jsx';
import { Profit } from './Profit.jsx';
import { API_URL, getData } from '../../../../../static/js/request';
import { useParams } from 'react-router-dom';

export const ProjectGraph = () => {

    const [activeTab, setActiveTab] = useState(0);

    const [data, setData] = useState();

    const params = useParams();

    const getProject = async () => {
        const response = await getData(API_URL + `/api/v1/projects/${params.id}/charts/`);
        setData(response.data);
    };

    useEffect(() => {
        getProject();
    }, []);

    return (
        <div className={styles.content}>
            <div className={styles.percents}>
                {
                    data?.widgets.map(item => <PercentCard {...item} />)
                }
            </div>
            <div className={styles.graph}>
                <div className={styles.graphTop}>
                    <p className={styles.topTitle}>График</p>
                    <MarketplacesSwitch setActive={setActiveTab} active={activeTab}
                                        values={[{ id: 0, name: 'Окупаемость' }, { id: 1, name: 'Прибыль' }]} />
                </div>
                {
                    activeTab === 0 ?
                        <Payback data={data?.charts.find(item => item.name === 'Окупаемость').data} key={1} />
                        : <Profit allProfit={data?.charts.find(item => item.name === 'Прибыль')['total profit']}
                                  point={data?.charts.find(item => item.name === 'Прибыль')['payback point']}
                                  allData={data?.charts.find(item => item.name === 'Прибыль').data} key={2} />
                }
            </div>
        </div>
    );
};
