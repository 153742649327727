// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MvPzsymDEmWXWCQd2TPf {\n  display: flex;\n  gap: 5px;\n  border-radius: 8px;\n  border: 1px solid var(--Color-gray-light, #dce7f1);\n  background: var(--Color-gray-light, #dce7f1);\n  width: fit-content;\n}\n.MvPzsymDEmWXWCQd2TPf .mTi1ZfeFhG1PwYf4GeWq {\n  color: var(--Color-black, #35373a);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.28px;\n  padding: 13px 20px;\n  border: none;\n  background: transparent;\n  transition: 0.15s;\n  border-radius: 8px;\n}\n.MvPzsymDEmWXWCQd2TPf .mTi1ZfeFhG1PwYf4GeWq.abZJIrfGS9twVR8HYtHE {\n  background: #fff;\n}", "",{"version":3,"sources":["webpack://./src/views/Economy/ModeSwitch/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,kBAAA;EACA,kDAAA;EACA,4CAAA;EACA,kBAAA;AACF;AACE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACI;EACE,gBAAA;AACN","sourcesContent":[".switch {\n  display: flex;\n  gap: 5px;\n  border-radius: 8px;\n  border: 1px solid var(--Color-gray-light, #dce7f1);\n  background: var(--Color-gray-light, #dce7f1);\n  width: fit-content;\n\n  .switchBtn {\n    color: var(--Color-black, #35373a);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    letter-spacing: 0.28px;\n    padding: 13px 20px;\n    border: none;\n    background: transparent;\n    transition: 0.15s;\n    border-radius: 8px;\n\n    &.active {\n      background: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "MvPzsymDEmWXWCQd2TPf",
	"switchBtn": "mTi1ZfeFhG1PwYf4GeWq",
	"active": "abZJIrfGS9twVR8HYtHE"
};
export default ___CSS_LOADER_EXPORT___;
