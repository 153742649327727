import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { formatNumber2 } from '../../../../../static/js/commonFunc';

export const GraphTooltip = ({ data, position, visibility, labels, allData }) => {

    const [currentArr, setArr] = useState([])
    const [payback, setPayback] = useState()

    useEffect(() => {
        if (data) {
            const index = data?.dataPoints[0].parsed.x;
            console.log(allData[index].value.map(item => (
                {
                    ...item,
                    value: item.category === 'expense' ? -Math.abs(item.value) : item.value
                }
            )))
            setArr(allData[index].value.map(item => (
                {
                    ...item,
                    value: item.category === 'expense' ? -Math.abs(item.value) : item.value
                }
            )))
            setPayback(allData[index].payback)
        }
    }, [allData, data])

    return (
        <div
            className={`${styles.tooltip} ${visibility ? styles.visible : ''}`}
            style={{
                top: position?.top > 200 ? position?.top - 100 : position?.top + 100,
                left: position?.left,
                position: 'absolute'
            }}
        >
            {data && (
                <>
                    <h5 className={styles.tooltipTitle}>
                        {data ? labels[data?.dataPoints[0].parsed.x] : ''}
                    </h5>

                    <div className="divide-y divide-gray-100/60">
                        {/* {data.dataPoints.map((val, index) => {
                            return (
                                <div
                                    key={index}
                                    className={styles.label}
                                >
                                    <p className={styles.labelTitle}>
                                        {`${currentArr[index]}`}{':'}
                                    </p>
                                    <p className={styles.labelValue} style={{ color: val?.raw > 0 ? '#1AB889' : '#FF5E5B' }}>
                                        {`${val?.raw > 0 ? '+' : '-'} ${formatNumber2(Math.abs(val?.raw))}`}
                                    </p>
                                </div>
                            );
                        })} */}
                        {
                            currentArr.map((item, index) => (
                                <div
                                    key={index}
                                    className={styles.label}
                                >
                                    <p className={styles.labelTitle}>
                                        {`${item.name}`}{':'}
                                    </p>
                                    <p className={styles.labelValue} style={{ color: item.value > 0 ? '#1AB889' : '#FF5E5B' }}>
                                        {`${item.value > 0 ? '+' : '-'} ${formatNumber2(Math.abs(item.value))}`}
                                    </p>
                                </div>
                            ))
                        }
                        <div
                            className={styles.label}
                        >
                            <p className={`${styles.labelTitle} ${styles.bold}`}>
                                {`Итого`}{':'}
                            </p>
                            <p className={`${styles.labelValue}`} style={{ color: payback > 0 ? '#1AB889' : '#FF5E5B' }}>
                                {`${payback > 0 ? '+' : '-'} ${formatNumber2(Math.abs(payback))}`}
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};