import React from 'react';
import styles from './styles.module.scss';

export const NewCustomInput = ({
                                   onChange,
                                   value,
                                   type,
                                   numeric,
                                   placeholder,
                                   disabled,
                                   name,
                                   width,
                                   onBlur,
                                   onFocus,
                                   returnEvent,
                                   forwardRef,
                                   required,
                               }) => {
    function validate(s) {
        return s.replace(/[^\d,.]/g, '');
    }

    return (
        <input
            onBlur={onBlur || null}
            onFocus={onFocus || null}
            style={{ width: width ? width : '' }}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            className={styles.input}
            type={type}
            value={value}
            ref={forwardRef}
            required={required}
            onChange={(e) =>
                returnEvent
                    ? onChange(e)
                    : onChange(numeric ? validate(e.target.value) : e.target.value)
            }
        />
    );
};
