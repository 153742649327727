import React, { useState } from 'react';
import AccountStyle from '../Account.module.scss';
import { NavLink } from 'react-router-dom';

const AccountMenu = () => {
    return (
        <div className={AccountStyle['menu']}>
            <div className={AccountStyle['menu__title']}>Настройки</div>
            <div className={AccountStyle['menu__page-list']}>
                <div className={AccountStyle['menu__page-list_subtitle']}>Аккаунт</div>
                <div className={AccountStyle['menu__page-list_item']}>
                    <NavLink
                        to="/settings/account/"
                        className={({ isActive }) =>
                            isActive ? AccountStyle['menu__page-list_item--active'] : ''
                        }
                    >
                        Учетная запись
                    </NavLink>
                </div>
                {/*<div className={AccountStyle['menu__page-list_item']}>*/}
                {/*    <NavLink*/}
                {/*        to="/settings/notices/"*/}
                {/*        className={({ isActive }) =>*/}
                {/*            isActive ? AccountStyle['menu__page-list_item--active'] : ''*/}
                {/*        }*/}
                {/*    >*/}
                {/*        Уведомления*/}
                {/*    </NavLink>*/}
                {/*</div>*/}

                <div className={AccountStyle['menu__page-list_subtitle']}>
                    Seller API
                </div>
                <div className={AccountStyle['menu__page-list_item']}>
                    <NavLink
                        to="/settings/account/api/"
                        className={({ isActive }) =>
                            isActive ? AccountStyle['menu__page-list_item--active'] : ''
                        }
                    >
                        Организации
                    </NavLink>
                </div>

                <div className={AccountStyle['menu__page-list_subtitle']}>
                    Управление
                </div>
                <div className={AccountStyle['menu__page-list_item']}>
                    <NavLink
                        to="/settings/staff/"
                        className={({ isActive }) =>
                            isActive ? AccountStyle['menu__page-list_item--active'] : ''
                        }
                    >
                        Доступы
                    </NavLink>
                </div>
                <div className={AccountStyle['menu__page-list_item']}>
                    <NavLink
                        to="/settings/articles/"
                        className={({ isActive }) =>
                            isActive ? AccountStyle['menu__page-list_item--active'] : ''
                        }
                    >
                        Статьи
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default AccountMenu;
