import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss'
import { RowImage } from '../../../../../CostPrice/RowImage.jsx';
import { NewCustomInput } from '../../../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import { API_URL, delData, postData } from '../../../../../../static/js/request';
import { useParams } from 'react-router-dom';
import { createNotification } from '../../../../../../static/js/commonFunc';

function KeyWordProduct({ item }) {

    const [value, changeValue] = useState('');

    const [words, setWords] = useState(item.keywords);

    const [wordsActive, setWordsActive] = useState(false);

    const wrapRef = useRef();
    const outerWrapRef = useRef();

    const params = useParams();

    const addWord = async () => {
        try {
            const response = await postData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/keywords/`, {
                product_id: item.id,
                keyword: value
            })
            createNotification(response)
            setWords([...words, value]);
            changeValue('');
        } catch (error) {
            console.log(error)
        }
    }

    const removeWord = async (word) => {
        try {
            const response = await delData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/keywords/`, {
                product_id: item.id,
                keyword: word
            });
            setWords([...words.filter(item => item !== word)]);
            createNotification(response);
        } catch (error) {
            console.log(error)
        }
    }

    const [longList, setLongList] = useState(false);

    useEffect(() => {
        setLongList((outerWrapRef.current.offsetWidth - 40) < wrapRef.current.offsetWidth);
        if (wrapRef.current.offsetHeight > 27) {
            console.log(words.length - 2)
        }
    }, [words])

    return (
        <div className={`${styles.product} ${(wordsActive && longList) ? styles.open : ''}`}>
            <div className={styles.imgWrap}>
                <RowImage item={item} noHover />
            </div>
            <p className={styles.article}>{item.article_WB ?? item.article_OZON}</p>
            <NewCustomInput placeholder={'Ключевое слово'} value={value} onChange={changeValue} width={'10%'} />
            <button onClick={addWord} disabled={value.trim().length === 0 || words.includes(value.trim())} className={styles.add}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M2.63165 9.13165H7.36835V13.8683C7.36835 14.0359 7.4349 14.1965 7.55336 14.315C7.67181 14.4335 7.83248 14.5 8 14.5C8.16753 14.5 8.32819 14.4335 8.44665 14.315C8.5651 14.1965 8.63165 14.0359 8.63165 13.8683V9.13165H13.3683C13.5359 9.13165 13.6965 9.0651 13.815 8.94665C13.9335 8.82819 14 8.66752 14 8.5C14 8.33248 13.9335 8.17181 13.815 8.05335C13.6965 7.9349 13.5359 7.86835 13.3683 7.86835H8.63165V3.13165C8.63165 2.96413 8.5651 2.80346 8.44665 2.68501C8.32819 2.56655 8.16753 2.5 8 2.5C7.83248 2.5 7.67181 2.56655 7.55336 2.68501C7.4349 2.80346 7.36835 2.96413 7.36835 3.13165V7.86835H2.63165C2.46413 7.86835 2.30347 7.9349 2.18501 8.05335C2.06655 8.17181 2 8.33248 2 8.5C2 8.66752 2.06655 8.82819 2.18501 8.94665C2.30347 9.0651 2.46413 9.13165 2.63165 9.13165Z" fill="white" />
                </svg>
                Добавить
            </button>
            <div className={styles.outerWrap} ref={outerWrapRef}>
                <div className={styles.wordsWrap} ref={wrapRef}>
                    {
                        words.length > 0 ?
                            words.map(item => (
                                <button onClick={() => removeWord(item)} key={item} className={styles.word}>
                                    {item}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M8.91615 8.50007L12.8099 4.60608C13.0633 4.35279 13.0633 3.94325 12.8099 3.68997C12.5567 3.43668 12.1471 3.43668 11.8939 3.68997L7.99994 7.58396L4.10614 3.68997C3.85274 3.43668 3.44333 3.43668 3.19005 3.68997C2.93665 3.94325 2.93665 4.35279 3.19005 4.60608L7.08385 8.50007L3.19005 12.3941C2.93665 12.6474 2.93665 13.0569 3.19005 13.3102C3.31628 13.4365 3.48225 13.5 3.6481 13.5C3.81395 13.5 3.9798 13.4365 4.10614 13.3102L7.99994 9.41618L11.8939 13.3102C12.0202 13.4365 12.1861 13.5 12.3519 13.5C12.5178 13.5 12.6836 13.4365 12.8099 13.3102C13.0633 13.0569 13.0633 12.6474 12.8099 12.3941L8.91615 8.50007Z" fill="#FF5E5B" />
                                    </svg>
                                </button>
                            )) : <p className={styles.empty}>ключевые слова не добавлены для артикула</p>
                    }
                </div>
            </div>
            {/* {
                (longList && !wordsActive) &&
                <div className={styles.word}>...</div>
            } */}
            {
                longList &&
                <button onClick={() => setWordsActive(!wordsActive)} className={`${styles.open} ${wordsActive ? styles.rotated : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M6.71739 11.2041L1.26607 5.59364C0.91131 5.2287 0.91131 4.63864 1.26607 4.2737C1.62067 3.90876 2.194 3.90876 2.5486 4.2737L8.00008 9.88421L13.4514 4.2737C13.8062 3.90877 14.3793 3.90877 14.7339 4.2737C15.0887 4.63864 15.0887 5.2287 14.7339 5.59364L9.28261 11.2041C9.28261 11.2041 8.57048 12 8.00008 12C7.42969 12 6.71739 11.2041 6.71739 11.2041Z" fill="#1E1E1E" />
                    </svg>
                </button>
            }
        </div>
    );
}

export default KeyWordProduct;