"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatStringToFloat = void 0;
function formatStringToFloat(s, decimalPlaces = 2) {
    // Удаляем пробелы в начале и конце строки
    s = s.trim();
    // Заменяем все запятые на точки
    s = s.replace(/,/g, '.');
    // Удаляем все символы, кроме цифр, точек и знака минус
    s = s.replace(/[^\d.-]/g, '');
    // Разделяем строку на части по точкам
    const parts = s.split('.');
    // Если есть хотя бы одна точка, обрабатываем
    if (parts.length > 1) {
        const integerPart = parts[0]; // Целая часть
        const fractionalPart = parts[1]; // Дробная часть (после первой точки)
        // Если дробная часть существует и содержит цифры, ограничиваем её длину
        if (fractionalPart && /\d/.test(fractionalPart)) {
            const truncatedFractionalPart = fractionalPart.slice(0, decimalPlaces);
            s = `${integerPart}.${truncatedFractionalPart}`;
        }
        else {
            // Если дробная часть отсутствует или не содержит цифр, игнорируем её
            s = integerPart;
        }
    }
    // Проверяем, что строка соответствует формату числа
    if (/^-?\d+(\.\d+)?$/.test(s)) {
        return parseFloat(s); // Преобразуем строку в число с плавающей точкой
    }
    else {
        return null; // Возвращаем null, если строка не является числом
    }
}
exports.formatStringToFloat = formatStringToFloat;
