import { useState } from 'react';
import { postData, API_URL } from '../../static/js/request.js';
import dayjs from 'dayjs';
import { Store } from 'react-notifications-component';

export default function useDatePickerStore(
    selectedProduct,
    updatePriceHistory
) {
    const [startDateInput, setStartDateInput] = useState('');
    const [endDateInput, setEndDateInput] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [price, setPrice] = useState('');

    const handleSelect = (ranges) => {
        setStartDate(ranges);
        setStartDateInput(ranges.toLocaleDateString());
    };

    const handleInput = (str, setterInput, setterDate, oldValue) => {
        if(str.length === oldValue.length + 1 ) {
            if (str.length === 2 || str.length === 5) {
                str += '.';
            }
        }

        if (str.length === 10) {
            const [day, month, year] = str.split('.');
            setterDate(new Date(year, month - 1, day));
        }

        if(str.length <= 10) {
            setterInput(str);
        }
    };

    const handleReset = () => {
        setStartDateInput('');
        setEndDateInput('');
        setStartDate(null);
        setEndDate(null);
        setPrice('');
    };

    const handleSubmit = async () => {

        const purchase_price = parseFloat(price.replace(',', '.'));
        console.log(endDate);


        const payload = {
            product: selectedProduct.barcode,
            purchase_price: purchase_price,
            start_date: dayjs(startDate).format('YYYY-MM-DD'),
        };

        try {
            await postData(
                `${API_URL}/api/v1/products/costs/history/`,
                {...payload, barcode: selectedProduct.barcode_set[0].code}
            );
            Store.addNotification({
                title: 'Сохранено',
                message: 'Себестоимость добавлена',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                }
            });
            handleReset();
            updatePriceHistory();
        } catch (error) {
            Store.addNotification({
                title: 'Ошибка',
                message: 'Что-то пошло не так',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                }
            });
        }
    };

    return {
        startDateInput,
        setStartDateInput,
        endDateInput,
        setEndDateInput,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        price,
        setPrice,
        handleSelect,
        handleInput,
        handleReset,
        handleSubmit,
    };
}
