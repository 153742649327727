// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZwkYTPKuC1jxSgkBT15X {\n  text-align: center;\n  padding-top: 110px;\n}\n.ZwkYTPKuC1jxSgkBT15X svg {\n  margin-bottom: 15px;\n}\n.ZwkYTPKuC1jxSgkBT15X h1 {\n  color: #1E304C;\n  font-size: 260px;\n}\n.ZwkYTPKuC1jxSgkBT15X h3 {\n  color: #429EFF;\n  margin: 50px 0 20px;\n}\n.ZwkYTPKuC1jxSgkBT15X p {\n  color: #8496AF;\n  font-size: 18px;\n  margin-bottom: 40px;\n}\n.ZwkYTPKuC1jxSgkBT15X a {\n  background: #429EFF;\n  color: #fff;\n  padding: 15px 20px;\n  border-radius: 8px;\n}", "",{"version":3,"sources":["webpack://./src/views/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,cAAA;EACA,gBAAA;AAAJ;AAGE;EACE,cAAA;EACA,mBAAA;AADJ;AAIE;EACE,cAAA;EACA,eAAA;EACA,mBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".page {\n  text-align: center;\n  padding-top: 110px;\n\n  svg {\n    margin-bottom: 15px;\n  }\n\n  h1 {\n    color: #1E304C;\n    font-size: 260px;\n  }\n\n  h3 {\n    color: #429EFF;\n    margin: 50px 0 20px;\n  }\n\n  p {\n    color: #8496AF;\n    font-size: 18px;\n    margin-bottom: 40px;\n  }\n\n  a {\n    background: #429EFF;\n    color: #fff;\n    padding: 15px 20px;\n    border-radius: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "ZwkYTPKuC1jxSgkBT15X"
};
export default ___CSS_LOADER_EXPORT___;
