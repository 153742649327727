import React from "react"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import style from '../PlanFact.module.scss'
import './dateperiod-style.scss'
import CalendarIcon from "../icons/calendarIcon.jsx";

const DatePeriod = ({ changeDates, values }) => {    

    return (
        <div className={style.datePeriod}>

            <CalendarIcon />

            <DatePicker
                selectsRange
                startDate={values[0]}
                endDate={values[1]}
                onChange={changeDates}
                dateFormat="dd MMM yyyy"
                locale={ru}
                placeholderText="Период"
            />

        </div>

    )

}

export default DatePeriod