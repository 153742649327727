import React from "react"

const EditIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6266_865)">
                <path d="M3.33331 17.2921C3.16762 17.2918 3.00878 17.2259 2.89161 17.1088C2.77445 16.9916 2.70853 16.8327 2.70831 16.6671V13.1829C2.70891 13.0173 2.77479 12.8586 2.89165 12.7412L12.2591 3.37539C12.4696 3.16474 12.7196 2.99764 12.9947 2.88363C13.2698 2.76962 13.5647 2.71094 13.8625 2.71094C14.1603 2.71094 14.4552 2.76962 14.7303 2.88363C15.0054 2.99764 15.2553 3.16474 15.4658 3.37539L16.625 4.53455C16.8356 4.74505 17.0027 4.995 17.1167 5.27011C17.2307 5.54521 17.2894 5.84009 17.2894 6.13789C17.2894 6.43568 17.2307 6.73056 17.1167 7.00567C17.0027 7.28077 16.8356 7.53072 16.625 7.74122L7.25998 17.1087C7.20198 17.167 7.13302 17.2132 7.05707 17.2446C6.98112 17.2761 6.89969 17.2922 6.81748 17.2921H3.33331ZM3.95831 13.4412V16.0421H6.55831L13.15 9.45039L10.55 6.85039L3.95831 13.4412ZM14.0341 8.56705L15.7433 6.85789C15.9338 6.66684 16.0407 6.40808 16.0407 6.1383C16.0407 5.86853 15.9338 5.60976 15.7433 5.41872L14.5825 4.25705C14.3915 4.06638 14.1327 3.95928 13.8629 3.95928C13.5931 3.95928 13.3342 4.06638 13.1433 4.25705L11.4333 5.96705L14.0341 8.56705Z" fill="#DCE7F1" />
            </g>
            <defs>
                <clipPath id="clip0_6266_865">
                    <rect width="20" height="20" rx="3" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EditIcon


