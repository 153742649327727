import React, { useState } from "react";
import EditIcon from "../../../../../../shared/ui/icons/Edit.jsx";
import PlusIcon from "../../../../../../shared/ui/icons/Plus.jsx";
import { NewCustomInput } from "../../../../../../components/custom/NewCustomInput/NewCustomInput.jsx";
import style from '../../../../Purchase.module.scss'


const EditValue = ({ value, column, convertFunction = false, action, id }) => {

    const [isEdit, toggleEdit] = useState(false)
    const [currentValue, setValue] = useState(value)

    const handler = () => {        
        toggleEdit(false)
        action(currentValue, id, column)
    }

    return (

        isEdit ?
            <>
                <NewCustomInput
                    value={currentValue}
                    onChange={(item) => setValue(item)}
                />
                <span className={style.plus} onClick={() => handler()}>
                    <PlusIcon />
                </span>
            </>
            :
            <>
                {convertFunction ? convertFunction(currentValue) : currentValue}
                <span onClick={() => toggleEdit(true)}>
                    <EditIcon />
                </span>
            </>



    )
}

export default EditValue