import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.scss';
import {
  API_URL,
  delData,
  getData,
  postData,
} from '../../../../../static/js/request';
import { ProductRow } from '../../../../Analytics/Projects/AddProjectModal/ProductRow/index.jsx';
import dayjs from 'dayjs';
import { createNotification } from '../../../../../static/js/commonFunc.js';
import 'react-calendar/dist/Calendar.css';
import { ru } from 'date-fns/locale';
import { useParams } from 'react-router-dom';

function Settings() {
  const params = useParams();

  const [allProducts, setAllProducts] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedAddedProducts, setSelectedAddedProducts] = useState([]);

  const [selectResponsible, setSelectResponsible] = useState('');
  const [name, setName] = useState('');
  const [season, setSeason] = useState(null);

  const checkboxValues = ['Wildberries', 'Ozon'];

  const [marketPlace, setMarketPlace] = useState([]);

  const [startDate, changeStartDate] = useState('');
  const [endDate, changeEndDate] = useState('');

  const [searchValue, changeSearchValue] = useState('');

  const [showAllProducts, setShowAllProducts] = useState(false);

  const searchRef = useRef();

  const [organisation, setOrganisation] = useState(null);

  const [addedItems, setAddedItems] = useState([]);

  const getProducts = async () => {
    const response = await getData(API_URL + '/api/v1/general/user/products/');
    setAllProducts([...response]);
  };

  const getOrganistations = async () => {
    const response = await getData(API_URL + '/api/v1/organisation/');
    setAllOrg([...response]);
  };

  const getAddedProducts = async () => {
    try {
      const response = await getData(
        API_URL + `/api/v1/answering_machine/constructors/${params.id}/products/`
      );
      setAddedProducts(response.products);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts();
    getAddedProducts();
    getOrganistations();
  }, []);

  const selectAll = (list, func) => {
    func([...list]);
  };

  const addAll = async () => {
    if (selectedProducts.length > 0) {
      try {
        const response = await postData(
          API_URL + `/api/v1/answering_machine/constructors/${params.id}/products/`,
          {
            product_id: [...selectedProducts],
          }
        );
        setAllProducts([
          ...allProducts.filter((item) => !selectedProducts.includes(item.id)),
        ]);
        setAddedProducts([
          ...addedProducts,
          ...allProducts.filter((item) => selectedProducts.includes(item.id)),
        ]);
        setSelectedProducts([]);
        createNotification(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeAll = () => {
    if (selectedAddedProducts.length > 0) {
      setAllProducts([
        ...allProducts,
        ...addedProducts.filter((item) =>
          selectedAddedProducts.includes(item.id)
        ),
      ]);
      setAddedProducts([
        ...addedProducts.filter(
          (item) => !selectedAddedProducts.includes(item.id)
        ),
      ]);
      setSelectedAddedProducts([]);
    }
  };

  let show = true;

  const outsideClick = (e) => {
    if (show) {
      if (e.target.getAttribute('id') !== 'noCloseModal') {
        setShowAllProducts(false);
        show = false;
      } else {
        searchRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (showAllProducts) {
      document.addEventListener('click', outsideClick);
    } else {
      document.removeEventListener('click', outsideClick);
    }
  }, [showAllProducts]);

  const addProject = async (item) => {
    try {
      const response = await postData(
        API_URL + `/api/v1/answering_machine/constructors/${params.id}/products/`,
        {
          product_id: [item.id],
        }
      );
      createNotification(response);
      setAddedProducts([...addedProducts, item]);
    } catch (error) {
      console.log(error);
    }
  };

  const remove = async (temp) => {
    try {
      const response = await delData(
        API_URL + `/api/v1/answering_machine/constructors/${params.id}/products/`,
        {
          product_id: selectedAddedProducts.includes(temp.id)
            ? [...selectedAddedProducts]
            : [...[...selectedAddedProducts, temp.id].filter((item) => item)],
        }
      );
      setAllProducts([
        ...allProducts,
        ...addedProducts.filter((item) =>
          selectedAddedProducts.includes(item.id)
        ),
        temp,
      ]);
      setAddedProducts([
        ...addedProducts.filter(
          (item) =>
            !selectedAddedProducts.includes(item.id) && item.id !== temp.id
        ),
      ]);
      setSelectedAddedProducts([]);
      createNotification(response);
    } catch (error) {}
  };

  const [pr, setPr] = useState(false);

  const wrapRef = useRef();

  useEffect(() => {
    if (wrapRef.current) {
      if (
        wrapRef.current.scrollHeight > wrapRef.current.offsetHeight &&
        !showAllProducts
      ) {
        setPr(false);
      } else {
        setPr(true);
      }
    }
  }, [addedProducts, showAllProducts]);

  return (
    <div className={styles.content}>
      <div className={styles.top}>
        <p className={styles.title}>Настройки</p>
      </div>
      <div className={styles.products}>
        <p className={styles.productTitle}>
          Добавлено: <span>{addedProducts.length}</span> товаров
        </p>
        <div className={styles.inputWrap}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.9595 16.2565L14.9604 16.2565C15.0525 16.2567 15.1436 16.238 15.2282 16.2013C15.3124 16.1648 15.3883 16.1114 15.4512 16.0444C15.5783 15.9119 15.6491 15.7349 15.6491 15.551C15.6491 15.3667 15.578 15.1895 15.4505 15.0569L15.4505 15.0569L15.4491 15.0555L11.1147 10.6657C12.1118 9.50981 12.6473 8.01811 12.6135 6.48214C12.5786 4.89865 11.9413 3.38935 10.8335 2.26807C9.72568 1.14675 8.23258 0.499759 6.66447 0.462093C5.09637 0.424426 3.5745 0.998998 2.41517 2.06591C1.25589 3.13278 0.548326 4.60982 0.439056 6.18986C0.329786 7.76988 0.827171 9.33192 1.82821 10.5513C2.82929 11.7706 4.25714 12.5536 5.81516 12.7367C7.32956 12.9148 8.85316 12.5128 10.0866 11.6133L14.4719 16.0458C14.5351 16.1115 14.6106 16.1639 14.694 16.2001L14.7537 16.0625L14.694 16.2001C14.7779 16.2365 14.8681 16.2556 14.9595 16.2565ZM1.71228 6.53989C1.7123 5.58328 1.993 4.64828 2.51869 3.85313C3.04439 3.05796 3.79143 2.43845 4.66515 2.07268C5.53885 1.70692 6.50015 1.61125 7.42756 1.79769C8.35498 1.98413 9.20709 2.44438 9.87604 3.12047C10.545 3.79658 11.0008 4.65819 11.1854 5.59643C11.3701 6.53468 11.2753 7.50718 10.9131 8.39089C10.5509 9.27459 9.93773 10.0296 9.15126 10.5607C8.36485 11.0918 7.44046 11.3752 6.49497 11.3752C5.22739 11.374 4.01173 10.8646 3.11492 9.95826C2.21806 9.05183 1.71342 7.82245 1.71228 6.53989Z"
              fill="#8496AF"
              stroke="#8496AF"
              strokeWidth="0.3"
            />
          </svg>
          <input
            id={'noCloseModal'}
            ref={searchRef}
            style={{ paddingLeft: '47px' }}
            onClick={() => {
              setShowAllProducts(true);
            }}
            type="text"
            autoComplete={'off'}
            placeholder={'Поиск товаров'}
            value={searchValue}
            onChange={(e) => changeSearchValue(e.target.value)}
          />
        </div>
        <div
          className={styles.productsContent}
          id={'noCloseModal'}
          ref={wrapRef}
        >
          {showAllProducts ? (
            <>
              <div id={'noCloseModal'} className={styles.searchBlock}>
                <div id={'noCloseModal'} className={styles.contentTop}>
                  <button
                    id={'noCloseModal'}
                    onClick={() => {
                      if (
                        allProducts.filter(
                          (temp) =>
                            !addedProducts
                              .map((item) => item.id)
                              .includes(temp.id)
                        ).length === selectedProducts.length
                      ) {
                        setSelectedProducts([]);
                      } else {
                        selectAll(
                          allProducts
                            .filter(
                              (item) =>
                                (item.name
                                  ?.toLowerCase()
                                  .includes(searchValue?.toLowerCase()) ||
                                  item.vendorCode
                                    ?.toLowerCase()
                                    .includes(searchValue?.toLowerCase())) &&
                                !addedProducts
                                  .map((temp) => temp.id)
                                  .includes(item.id)
                            )
                            .map((item) => item.id),
                          setSelectedProducts
                        );
                      }
                    }}
                    className={`${styles.selectAllBtn} ${
                      allProducts.filter(
                        (temp) =>
                          !addedProducts
                            .map((item) => item.id)
                            .includes(temp.id)
                      ).length === selectedProducts.length &&
                      selectedProducts.length > 0
                        ? styles.check
                        : ''
                    }`}
                  >
                    {allProducts.filter(
                      (temp) =>
                        !addedProducts.map((item) => item.id).includes(temp.id)
                    ).length === selectedProducts.length &&
                      selectedProducts.length > 0 && (
                        <svg
                          id="noCloseModal"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="11"
                          viewBox="0 0 14 11"
                          fill="none"
                        >
                          <path
                            id="noCloseModal"
                            d="M5 10.5L0 5.54L1.59 4L5 7.35L12.41 0L14 1.58L5 10.5Z"
                            fill="#429EFF"
                          ></path>
                        </svg>
                      )}
                  </button>
                  <p className={styles.topPhoto}>Фото</p>
                  <p className={styles.topName}>Название</p>
                  <p className={styles.topArticle}>Артикул</p>
                  <p className={styles.topSize}>Размер</p>
                  <p className={styles.topBtn} style={{ width: '75px' }}></p>
                  {selectedProducts.length > 0 && (
                    <button
                      id={'noCloseModal'}
                      className={styles.deleteSelected}
                      onClick={addAll}
                    >
                      Добавить выбранные
                    </button>
                  )}
                </div>
                {allProducts
                  .filter(
                    (item) =>
                      (item.name
                        ?.toLowerCase()
                        .includes(searchValue?.toLowerCase()) ||
                        item.vendorCode
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase())) &&
                      !addedProducts.map((temp) => temp.id).includes(item.id)
                  )
                  ?.map((item) => (
                    <ProductRow
                      clickEvent={() => addProject(item)}
                      setSelect={() =>
                        setSelectedProducts([...selectedProducts, item.id])
                      }
                      setUnselected={() =>
                        setSelectedProducts([
                          ...selectedProducts.filter((id) => id !== item.id),
                        ])
                      }
                      select={selectedProducts.includes(item.id)}
                      key={item.id}
                      item={item}
                    />
                  ))}
              </div>
            </>
          ) : (
            <>
              <div
                className={styles.pl}
                style={{ paddingRight: pr ? '6px' : 0 }}
              >
                <div
                  id={'noCloseModal'}
                  className={`${styles.contentTop} ${styles.addedTop}`}
                >
                  <button
                    onClick={() => {
                      if (
                        selectedAddedProducts.length === addedProducts.length
                      ) {
                        setSelectedAddedProducts([]);
                      } else {
                        setSelectedAddedProducts([
                          ...addedProducts.map((temp) => temp.id),
                        ]);
                      }
                    }}
                    className={`${styles.selectAllBtn} ${
                      selectedAddedProducts.length > 0 &&
                      selectedAddedProducts.length === addedProducts.length
                        ? styles.check
                        : ''
                    }`}
                  >
                    {selectedAddedProducts.length > 0 &&
                      selectedAddedProducts.length === addedProducts.length && (
                        <svg
                          id="noCloseModal"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="11"
                          viewBox="0 0 14 11"
                          fill="none"
                        >
                          <path
                            id="noCloseModal"
                            d="M5 10.5L0 5.54L1.59 4L5 7.35L12.41 0L14 1.58L5 10.5Z"
                            fill="#429EFF"
                          ></path>
                        </svg>
                      )}
                  </button>
                  <p className={styles.topPhoto}>Фото</p>
                  <p className={styles.topName}>Название</p>
                  <p className={styles.topArticle}>Артикул</p>
                  <p className={styles.topSize}>Размер</p>
                  <p className={styles.topBtn}></p>
                  {selectedAddedProducts.length > 0 && (
                    <button
                      id={'noCloseModal'}
                      disabled={selectedAddedProducts.length === 0}
                      className={styles.deleteSelected}
                      onClick={() => remove(false)}
                    >
                      Удалить выбранные
                    </button>
                  )}
                </div>
              </div>

              <div
                className={styles.pl}
                style={{ paddingRight: pr ? '6px' : 0 }}
              >
                {addedProducts.length > 0 ? (
                  addedProducts.map((item) => (
                    <ProductRow
                      checkboxes
                      short
                      select={selectedAddedProducts.includes(item.id)}
                      setSelect={() =>
                        setSelectedAddedProducts([
                          ...selectedAddedProducts,
                          item.id,
                        ])
                      }
                      setUnselected={() =>
                        setSelectedAddedProducts([
                          ...selectedAddedProducts.filter(
                            (id) => id !== item.id
                          ),
                        ])
                      }
                      clickEvent={() => remove(item)}
                      added
                      key={item.id}
                      item={item}
                    />
                  ))
                ) : (
                  <p className={styles.empty}>Добавьте товары</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
