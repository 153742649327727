import { action, makeAutoObservable, observable } from 'mobx';
import { getFinancing } from '../../api/getFinancing';
import { confirmAlert } from 'react-confirm-alert';
import React from 'react';
import { API_URL, getData, postData } from '../../static/js/request';
import AutoCompleteSearch from './AutoComplete';
import { Store } from 'react-notifications-component';
import { createNotification } from '../../static/js/commonFunc';

export class BanksStore {
    fields = {
        meta: {},
        data: [],
    };
    article = {};

    constructor() {
        makeAutoObservable(this, {
            fields: observable,
            sortByAscendingDate: action,
            sortByDescendingDate: action,
        });
    }

    setRef = (ref) => {
        this.ref = ref;
    };

    fillValue = ({ period, group }) => {
        getFinancing(period, group).then((response) => {
            if (!response) {
                return;
            }
            response.json().then(data => {
                // this.fields = data;
                this.fields.meta = data['meta'];
                this.fields.data = data['data'];

            });
        });
    };

    sortByAscendingDate = () => {
        this.fields.sort((a, b) => {
            let dateA = a.executed.split('.').reverse().join(',');
            let dateB = b.executed.split('.').reverse().join(',');
            return new Date(dateA) - new Date(dateB);
        });
    };

    sortByDescendingDate = () => {
        this.fields.sort((a, b) => {
            let dateA = a.executed.split('.').reverse().join(',');
            let dateB = b.executed.split('.').reverse().join(',');
            return new Date(dateB) - new Date(dateA);
        });
    };

    recoverField = (dateFrom, dateTo) => {
        let data = {
            "period": "ALL",
            "group": "all"
        };

        postData(API_URL + `/api/v1/bank/`, data).then((response) => {
            if (!response) {
                return;
            }
            this.fields = response
            })

    };

    handleSubmitArt = (id, onClose) => {
        const activeArt = this.ref.querySelector('div .active').dataset.art;
        let data;
        this.fields.forEach((el) => {
            if (el.id === id) {
                el.article = activeArt;
                data = el;
            }
        });

        postData(API_URL + '/api/v1/bank/operations/add-article/', data).then((response) => {
            onClose();
            createNotification(response);
        });
    };

    changeArticle = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body" ref={this.setRef}>
                        <h1>Изменение статьи</h1>
                        <AutoCompleteSearch />

                        <div className="react-confirm-alert-button-group">
                            <button className="cancel" onClick={onClose}>
                                Отменить
                            </button>
                            <button
                                className="complete"
                                onClick={() => {
                                    this.handleSubmitArt(id, onClose);
                                }}
                            >
                                Подтвердить
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };
}
