import React, {useEffect, useState} from 'react';
import styles from './CategoriesContent.module.scss';
import {Link, useLocation, useParams} from 'react-router-dom';
import {API_URL, getData} from '../../../../static/js/request';

const CategoriesContent = () => {

    const {id} = useParams();

    const [data, setData] = useState([]);

    const [title, setTitle] = useState('');

    const params = new URLSearchParams(window.location.search);

    const getInfo = async () => {
        try {
            const response = await getData(API_URL + `/api/v1/knowbase/sections/${id}/articles/`);
            setData(response);
        }catch(err) {
            console.log(err);
        }
    };

    const getTitle = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/knowbase/sections/');
            response.map(item => {
                if (item.id === +id) {
                    setTitle(item.title);
                }
            });
        } catch (e) {
            setTitle('');
        }
    };

    const [searchResults, setSearchResults] = useState(null);

    const getSearch = async (value) => {
        try {
            const response = await getData(API_URL + `/api/v1/knowbase/search/?q=${value}`);
            setSearchResults([...response.content_matching_articles, ...response.title_matching_articles]);
        } catch (e) {
            console.log(e);
        }
    };

    const location = useLocation();

    useEffect(() => {
        if(params.get('search')) {
            getSearch(params.get('search'));
        } else {
            getInfo();
            getTitle();
        }
    }, [location]);

    return (
        <div className={styles.content}>
            {
                id !== 'null' ?
                    <>
                        <h2 className={styles.title}>{title}</h2>
                        <p className={styles.path}>
                            <Link to={'/faq/'}><span>Главная</span></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="9" viewBox="0 0 4 9" fill="none">
                                <path
                                    d="M3.60207 3.76708L0.796821 0.652041C0.614351 0.44932 0.319322 0.44932 0.136852 0.652041C-0.0456178 0.854668 -0.0456178 1.18229 0.136852 1.38491L2.9421 4.50005L0.136852 7.61509C-0.0456175 7.81781 -0.0456175 8.14533 0.136852 8.34796C0.319322 8.55068 0.614351 8.55068 0.796821 8.34796L3.60207 5.23292C3.60207 5.23292 4 4.82599 4 4.50005C4 4.17411 3.60207 3.76708 3.60207 3.76708Z"
                                    fill="#8496AF"/>
                            </svg>
                            {title}
                        </p>
                        <div className={styles.articles}>
                            {
                                data.map(item => (
                                    <Link to={'/faq/article/' + item.id} className={styles.categorieCard}>
                                        <p className={styles.cardTitle}>{item.title}</p>
                                        <p className={styles.cardText}>{item.short_description}</p>
                                    </Link>
                                ))
                            }
                        </div>
                    </> :
                    <>
                        <p className={styles.path}>
                            Результаты поиска: <span>{params.get('search')}</span>
                        </p>
                        <div className={styles.articles}>
                            {
                                searchResults && searchResults.map(item => (
                                    <Link to={'/faq/article/' + item.id} className={styles.categorieCard}>
                                        <p className={styles.cardTitle}>{item.title}</p>
                                        <p className={styles.cardText}>{item.short_description}</p>
                                    </Link>
                                ))
                            }
                        </div>
                    </>
            }
        </div>
    );
};
export default CategoriesContent;

