import React, { useState, useRef, useEffect } from 'react';
import style from './FinancingReport.module.scss';
import cx from 'classnames';
import TableRow from './Row.jsx';
import { formatNumber2 } from '../../static/js/commonFunc';
import ShowMore from '../../shared/ui/icons/ShowMore.jsx';
import useSyncScroll from '../../static/js/useSyncScroll.jsx';

function strToBase64(str) {
    return new Promise((resolve) => {
        const blob = new Blob([str], { type: 'text/plain' });
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(blob);
    });
}

const TableRowItem = ({ data, loading = false, headRef = false, level }) => {
    const [showChild, toggleChild] = useState(false)
    const elementRef = useRef(null);

    if (!Array.isArray(data) || data.length < 2) return null;

    useEffect(() => {
        strToBase64(data[0]).then(hash => {
            toggleChild(sessionStorage.getItem(hash) === 'true');
        });
    }, [data]);


    const handleAction = (canDo) => {
        strToBase64(data[0]).then(hash => sessionStorage.setItem(hash, canDo ? 'true' : 'false'));
        if (canDo) toggleChild(prev => !prev);
    };

    const hasChild = !!data[1]?.nestedEl && Object.keys(data[1].nestedEl).length > 0;
    const childStyle = { paddingLeft: `${10 * level}px` };
    const backGround = `rgb(230, 230, 230, ${level > 0 ? (level * 2) / 10 : 0})`;
    const fontSize = level > 3 ? '12px' : '14px';

    useSyncScroll(headRef, elementRef, { horizontal: true });

    return (
        <>
            <div className={style.row} >
                <div
                    className={cx(style.title, style.label)}
                    style={{ cursor: hasChild ? 'pointer' : 'default', background: backGround, borderBottom: '1px solid #dce7f1', fontSize }}
                    onClick={() => handleAction(hasChild)}
                >
                    <span style={childStyle}>{data[0]}</span>
                    {hasChild && <span className={cx(style.showMoreRow, showChild ? style.active : null)}><ShowMore /></span>}
                </div>
                <div
                    className={cx(style.flex, style['fd-r'], style.xScroll, style.scrolled)}
                    ref={elementRef}
                    style={{ overflowX: loading ? 'hidden' : '', alignItems: 'center', borderBottom: '1px solid #dce7f1', background: backGround }}
                >
                    {Array.isArray(data[1]?.value)
                        ? data[1].value.map((item, index) => (
                            <div key={index} className={style.column}>
                                <span>{formatNumber2(item)}</span>
                            </div>
                        ))
                        : <div className={style.column}><span>Нет данных</span></div>
                    }
                </div>
            </div>

            {hasChild && showChild && <TableRow data={data[1].nestedEl} level={level + 1} headRef={headRef} />}

        </>
    );
};

export default TableRowItem;
