import React from "react";
import style from '../../../Purchase.module.scss'
import TextArea from "antd/es/input/TextArea";
import cx from 'classnames';

const Comment = ({ itemParams, setItemParams, action }) => {
    return (

        <div className={style.filter}>
            <div className={cx(style.topRow, style.comment)}>

                <div className={style.left}>

                    <div className={style.column}>
                        <div className={style.title}>Комментарий</div>
                        <TextArea value={itemParams.comment} onChange={(e) => setItemParams(prev => ({
                            ...prev,
                            comment: e.target.value
                        }))} />
                    </div>

                </div>

                <div className={style.rigth}>

                    <button onClick={() => console.log('/purchase/orders/item/')} className={style.cancel}>
                        <span>Отмена</span>
                    </button>

                    <button onClick={() => action(true)} className={style.create}>
                        <span>Сохранить</span>
                    </button>
                </div>

            </div>
        </div>

    )
}

export default Comment