import React from "react";

const VectorIcon = () => {
    return (
        <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4L24.9186 0.660254L10 20.8205L0 4Z" fill="#454F5C" />
        </svg>
    )
}

export default VectorIcon