import React, { useState } from "react";
import style from '../../PlanFact.module.scss'
import cx from 'classnames'
import VectorIcon from "../../icons/vector.jsx";
import DoneIcon from "../../icons/done.jsx";
import CupIcon from "../../icons/cup.jsx";


const Result = ({ data }) => {

    const [isHovered, setIsHovered] = useState(false);

    const parseStatus = (status) => {
        switch (status) {

            case ('ok'):
                return <DoneIcon />
            case ('fail'):
                return <span>плохо</span>
            case ('lag'):
                return <span>отклонение</span>
            case ('perfect'):
                return <CupIcon />
            default:
                return <DoneIcon />

        }

    }

    return (
        <div className={cx(style.tableRow,style.total)}>
            <div className={cx(style.tableColumn, style.value, style.stock)}>{data.stock}</div>
            <div className={cx(style.tableColumn, style.value, style.execution)}>
                <div>
                    {data.execution.fact}
                    <span className={style.planValue}>/</span>
                    <span className={style.planValue}>{data.execution.plan}</span>
                </div>
                <div><b>{(data.execution.fact / data.execution.plan * 100).toFixed(2)}%</b></div>
                <div
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {parseStatus(data.execution.status)}
                </div>
            </div>
            {isHovered &&
                <div className={style.executionDescription}>
                    <VectorIcon />
                    <div className={style.row}>
                        <div>Продано:</div>
                        <div>{data.execution.fact}</div>
                    </div>
                    <div className={style.row}>
                        <div>План:</div>
                        <div>{data.execution.plan}</div>
                    </div>

                    <div className={cx(style.row, style.bottom)}>
                        <div>Выполнение плана:</div>
                        <div>{(data.execution.fact / data.execution.plan * 100).toFixed(2)}%</div>
                    </div>
                </div>
            }
        </div>
    )

}

export default Result