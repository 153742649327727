import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { getData, API_URL, delData } from '../../../../../../static/js/request';
import { ProductRow } from '../../../AddProjectModal/ProductRow/index.jsx';
import { useParams } from 'react-router-dom';
import { createNotification } from '../../../../../../static/js/commonFunc';

function ProductSettings({ info, changeInfo, newInfo }) {
    const [allProducts, setAllProducts] = useState([]);
    const [addedProducts, setAddedProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const params = useParams();

    const [searchValue, changeSearchValue] = useState('');

    const [showAllProducts, setShowAllProducts] = useState(false);

    const searchRef = useRef();

    const [addedSelectProducts, setAddedSelectProducts] = useState([]);

    const getProducts = async () => {
        const response = await getData(API_URL + '/api/v1/general/user/products/');
        setAllProducts([...response]);
    };

    useEffect(() => {
        if (info) {
            getProducts();
            setAddedProducts(info.products.map(item => {
                return {
                    ...item,
                }
            }))
        }
    }, [info]);

    const selectAll = (list, func) => {
        func([...list]);
    };

    const addAll = () => {
        if (selectedProducts.length > 0) {
            setAllProducts([...allProducts.filter(item => !selectedProducts.includes(item.id))]);
            setAddedProducts([...addedProducts, ...allProducts.filter(item => selectedProducts.includes(item.id)).map(item => {
                return {
                    ...item,
                    planned_sales_qty: 0,
                    planned_sales_amount: 0
                }
            })]);
            setSelectedProducts([]);
        }
    };

    let show = true;

    const outsideClick = (e) => {
        if (show) {
            if (e.target.getAttribute('id') !== 'noCloseModal') {
                setShowAllProducts(false);
                show = false;
            } else {
                searchRef.current.focus();
            }
        }
    };

    const change_plan_amount = (text, item) => {
        setAddedProducts(addedProducts.map(temp => {
            if (item.id === temp.id) {
                return {
                    ...item,
                    planned_sales_amount: text
                }
            }
            return { ...temp }
        }))
    }

    const change_plan_qty = (text, item) => {
        setAddedProducts(addedProducts.map(temp => {
            if (item.id === temp.id) {
                return {
                    ...item,
                    planned_sales_qty: text
                }
            }
            return { ...temp }
        }))
    }

    useEffect(() => {
        if (showAllProducts) {
            document.addEventListener('click', outsideClick);
        } else {
            document.removeEventListener('click', outsideClick);
        }
        setSelectedProducts([])
    }, [showAllProducts]);

    useEffect(() => {
        changeInfo(state => {
            return {
            ...state,
            products: [...addedProducts]
        }})
    }, [addedProducts])

    const selectOrRemoveAll = () => {
        if (selectedProducts.length === addedProducts.length) {
            setSelectedProducts([])
        } else {
            setSelectedProducts([...addedProducts.map(item => item.id)])
        }
    }

    const removeItems = async (id) => {
        try {
            const response = await delData(API_URL + `/api/v1/projects/${params.id}/settings/delete-product`, {
                product_ids: selectedProducts.includes(id) ? [...selectedProducts] : [...selectedProducts, id]
            })
            createNotification(response)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={styles.content}>
            <h2 className={styles.title}>
                Настройки товара
                <span>{addedProducts.length}</span>
            </h2>
            <div className={styles.inputWrap}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M14.9595 16.2565L14.9604 16.2565C15.0525 16.2567 15.1436 16.238 15.2282 16.2013C15.3124 16.1648 15.3883 16.1114 15.4512 16.0444C15.5783 15.9119 15.6491 15.7349 15.6491 15.551C15.6491 15.3667 15.578 15.1895 15.4505 15.0569L15.4505 15.0569L15.4491 15.0555L11.1147 10.6657C12.1118 9.50981 12.6473 8.01811 12.6135 6.48214C12.5786 4.89865 11.9413 3.38935 10.8335 2.26807C9.72568 1.14675 8.23258 0.499759 6.66447 0.462093C5.09637 0.424426 3.5745 0.998998 2.41517 2.06591C1.25589 3.13278 0.548326 4.60982 0.439056 6.18986C0.329786 7.76988 0.827171 9.33192 1.82821 10.5513C2.82929 11.7706 4.25714 12.5536 5.81516 12.7367C7.32956 12.9148 8.85316 12.5128 10.0866 11.6133L14.4719 16.0458C14.5351 16.1115 14.6106 16.1639 14.694 16.2001L14.7537 16.0625L14.694 16.2001C14.7779 16.2365 14.8681 16.2556 14.9595 16.2565ZM1.71228 6.53989C1.7123 5.58328 1.993 4.64828 2.51869 3.85313C3.04439 3.05796 3.79143 2.43845 4.66515 2.07268C5.53885 1.70692 6.50015 1.61125 7.42756 1.79769C8.35498 1.98413 9.20709 2.44438 9.87604 3.12047C10.545 3.79658 11.0008 4.65819 11.1854 5.59643C11.3701 6.53468 11.2753 7.50718 10.9131 8.39089C10.5509 9.27459 9.93773 10.0296 9.15126 10.5607C8.36485 11.0918 7.44046 11.3752 6.49497 11.3752C5.22739 11.374 4.01173 10.8646 3.11492 9.95826C2.21806 9.05183 1.71342 7.82245 1.71228 6.53989Z" fill="#8496AF" stroke="#8496AF" strokeWidth="0.3" />
                </svg>
                <input id={'noCloseModal'} ref={searchRef} style={{ paddingLeft: '47px' }} onClick={() => {
                    setShowAllProducts(true);
                }} type="text" placeholder={'Поиск товаров'} value={searchValue} onChange={(e) => {
                    changeSearchValue(e.target.value)
                }} />
            </div>
            {
                showAllProducts ?
                    <div id={'noCloseModal'} className={styles.contentTop}>
                        <p id={'noCloseModal'} className={styles.topTitle}>Поиск</p>
                        <button id={'noCloseModal'} className={styles.selectAll} onClick={() => selectAll(allProducts.filter(item => ((item.name?.toLowerCase().includes(searchValue.toLowerCase()) || item.vendorCode?.toLowerCase().includes(searchValue.toLowerCase())) && !addedProducts.map(temp => temp.id).includes(item.id))).map(item => item.id), setSelectedProducts)}>Выбрать все</button>
                        <button id={'noCloseModal'} className={styles.deleteSelected} onClick={addAll}>Добавить выбранные</button>
                    </div> :
                    addedProducts.length > 0 &&
                    <div className={styles.tableTop}>
                        <span onClick={selectOrRemoveAll} className={`${styles.check} ${selectedProducts.length === addedProducts.length ? styles.active : ''}`}>
                            {
                                selectedProducts.length === addedProducts.length &&
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                                    <path d="M5 10.5L0 5.54L1.59 4L5 7.35L12.41 0L14 1.58L5 10.5Z" fill="#429EFF" />
                                </svg>
                            }
                        </span>
                        <p className={styles.photo}>ФОТО</p>
                        <p className={styles.article}>Артикул</p>
                        <p className={styles.colTitle}>Название</p>
                        <p className={styles.vendorCode}></p>
                        <p className={styles.size}>вес товара</p>
                        <p className={styles.plan}>План, руб</p>
                        <p className={styles.plan}>План, шт</p>
                        <p className={styles.delete}></p>
                    </div>
            }

            <div className={styles.productsContent} id={'noCloseModal'}>
                {
                    showAllProducts ?
                        <>
                            <div id={'noCloseModal'} className={styles.searchBlock}>

                                <div className={styles.tableTop}>
                                    <span className={styles.checkboxAll}>

                                    </span>
                                </div>
                                {
                                    allProducts.filter(item => ((item.name?.toLowerCase().includes(searchValue.toLowerCase()) || item.vendorCode?.toLowerCase().includes(searchValue.toLowerCase())) && !addedProducts.map(temp => temp.id).includes(item.id))).map(item => <ProductRow longTitle clickEvent={() => setAddedProducts([...addedProducts, { ...item, planned_sales_qty: 0, planned_sales_amount: 0 }])} setSelect={() => {
                                        setSelectedProducts([...selectedProducts, item.id])
                                    }} setUnselected={() => setSelectedProducts([...selectedProducts.filter(id => id !== item.id)])} select={selectedProducts.includes(item.id)} key={item.id} item={item} />)
                                }
                            </div>
                        </> :
                        <>
                            {
                                addedProducts.length > 0 ?
                                    addedProducts.map(item => (
                                        <ProductRow longTitle line plans_change_qty={(text) => change_plan_qty(text, item)} plans_change_amount={(text) => change_plan_amount(text, item)} plans miniRemove article clickEvent={() => {
                                            setAddedProducts(selectedProducts.length > 0 ? [...addedProducts.filter(temp => !selectedProducts.includes(temp.id))] : [...addedProducts.filter(temp => temp.id !== item.id)])
                                            removeItems(item.id)
                                        }} setSelect={() => setSelectedProducts([...selectedProducts, item.id])} added key={item.id} item={item} setUnselected={() => setSelectedProducts([...selectedProducts.filter(id => id !== item.id)])} select={selectedProducts.includes(item.id)} />
                                    )) :
                                    <p className={styles.empty}>Добавьте товары</p>
                            }
                        </>
                }
            </div>

        </div>
    );
}

export default ProductSettings;