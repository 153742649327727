import {makeAutoObservable} from 'mobx';
import React from 'react';
import {getArticle} from '../../api/getArticle';

class ArticleStor {
    fields = [];

    constructor() {
        makeAutoObservable(this);
        this.fillValue();
    }

    fillValue = () => {
        getArticle().then((item) => {            
            item?.forEach((el) => {
                /* Костыль. Нужно наблюдать проверять на всех страницах, где используется хранилище */ 
                this.fields[el.id] = el.article ?? el.name;
            });
        });
    };
}

const ArticleStore = new ArticleStor();
export default ArticleStore;
