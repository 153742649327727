import React, { useRef } from 'react';

const ImportExportButton = ({ buttonClassName, label, onSubmitFile, name }) => {
    const fileInput = useRef(null);

    const handleClick = () => {
        fileInput.current.click();
    };

    return (
        <>
            <button className={buttonClassName} onClick={handleClick}>
                {label}
            </button>
            <input
                type="file"
                name={name}
                onChange={onSubmitFile}
                ref={fileInput}
                style={{ display: 'none' }}
            />
        </>
    );
};

export default ImportExportButton;
