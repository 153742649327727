import React from "react"

const CalendarIcon = () =>

    <>

        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1563 3.66735H14.3996V3.08644C14.3996 2.76007 14.135 2.49552 13.8087 2.49552C13.4823 2.49552 13.2178 2.76007 13.2178 3.08644V3.66735H10.5618V3.08644C10.5618 2.76007 10.2973 2.49552 9.97093 2.49552C9.64457 2.49552 9.38001 2.76007 9.38001 3.08644V3.66735H6.75339V3.08644C6.75339 2.76007 6.48883 2.49552 6.16247 2.49552C5.83611 2.49552 5.57155 2.76007 5.57155 3.08644V3.66735H4.84416C3.5491 3.66735 2.49549 4.72096 2.49549 6.01602V15.1563C2.49549 16.4514 3.5491 17.505 4.84416 17.505H15.1563C16.4514 17.505 17.505 16.4514 17.505 15.1563V6.01602C17.505 4.72096 16.4514 3.66735 15.1563 3.66735ZM4.84416 4.84919H5.57155V5.4301C5.57155 5.75647 5.83611 6.02102 6.16247 6.02102C6.48883 6.02102 6.75339 5.75647 6.75339 5.4301V4.84919H9.38001V5.4301C9.38001 5.75647 9.64457 6.02102 9.97093 6.02102C10.2973 6.02102 10.5618 5.75647 10.5618 5.4301V4.84919H13.2178V5.4301C13.2178 5.75647 13.4823 6.02102 13.8087 6.02102C14.135 6.02102 14.3996 5.75647 14.3996 5.4301V4.84919H15.1563C15.7997 4.84919 16.3231 5.37263 16.3231 6.01602V15.1563C16.3231 15.7997 15.7997 16.3232 15.1563 16.3232H4.84416C4.20077 16.3232 3.67732 15.7997 3.67732 15.1563V6.01602C3.67732 5.37263 4.20077 4.84919 4.84416 4.84919Z" fill="#429EFF" stroke="white" stroke-width="0.01" />
            <path d="M13.8083 9.23839C14.1319 9.23839 14.3942 8.97607 14.3942 8.65248C14.3942 8.32888 14.1319 8.06656 13.8083 8.06656C13.4847 8.06656 13.2224 8.32888 13.2224 8.65248C13.2224 8.97607 13.4847 9.23839 13.8083 9.23839Z" fill="#429EFF" />
            <path d="M11.2597 9.23839C11.5833 9.23839 11.8457 8.97607 11.8457 8.65248C11.8457 8.32888 11.5833 8.06656 11.2597 8.06656C10.9362 8.06656 10.6738 8.32888 10.6738 8.65248C10.6738 8.97607 10.9362 9.23839 11.2597 9.23839Z" fill="#429EFF" />
            <path d="M8.71104 11.787C9.03463 11.787 9.29696 11.5247 9.29696 11.2011C9.29696 10.8775 9.03463 10.6152 8.71104 10.6152C8.38745 10.6152 8.12512 10.8775 8.12512 11.2011C8.12512 11.5247 8.38745 11.787 8.71104 11.787Z" fill="#429EFF" />
            <path d="M11.2597 11.787C11.5833 11.787 11.8457 11.5247 11.8457 11.2011C11.8457 10.8775 11.5833 10.6152 11.2597 10.6152C10.9362 10.6152 10.6738 10.8775 10.6738 11.2011C10.6738 11.5247 10.9362 11.787 11.2597 11.787Z" fill="#429EFF" />
            <path d="M11.2597 14.3357C11.5833 14.3357 11.8457 14.0734 11.8457 13.7498C11.8457 13.4262 11.5833 13.1639 11.2597 13.1639C10.9362 13.1639 10.6738 13.4262 10.6738 13.7498C10.6738 14.0734 10.9362 14.3357 11.2597 14.3357Z" fill="#429EFF" />
            <path d="M13.8083 11.787C14.1319 11.787 14.3942 11.5247 14.3942 11.2011C14.3942 10.8775 14.1319 10.6152 13.8083 10.6152C13.4847 10.6152 13.2224 10.8775 13.2224 11.2011C13.2224 11.5247 13.4847 11.787 13.8083 11.787Z" fill="#429EFF" />
            <path d="M13.8083 14.3357C14.1319 14.3357 14.3942 14.0734 14.3942 13.7498C14.3942 13.4262 14.1319 13.1639 13.8083 13.1639C13.4847 13.1639 13.2224 13.4262 13.2224 13.7498C13.2224 14.0734 13.4847 14.3357 13.8083 14.3357Z" fill="#429EFF" />
            <path d="M6.16221 9.23839C6.4858 9.23839 6.74813 8.97607 6.74813 8.65248C6.74813 8.32888 6.4858 8.06656 6.16221 8.06656C5.83862 8.06656 5.57629 8.32888 5.57629 8.65248C5.57629 8.97607 5.83862 9.23839 6.16221 9.23839Z" fill="#429EFF" />
            <path d="M6.16221 11.7871C6.4858 11.7871 6.74813 11.5248 6.74813 11.2012C6.74813 10.8776 6.4858 10.6153 6.16221 10.6153C5.83862 10.6153 5.57629 10.8776 5.57629 11.2012C5.57629 11.5248 5.83862 11.7871 6.16221 11.7871Z" fill="#429EFF" />
            <path d="M6.16221 14.3358C6.4858 14.3358 6.74813 14.0735 6.74813 13.7499C6.74813 13.4263 6.4858 13.1639 6.16221 13.1639C5.83862 13.1639 5.57629 13.4263 5.57629 13.7499C5.57629 14.0735 5.83862 14.3358 6.16221 14.3358Z" fill="#429EFF" />
            <path d="M8.71104 14.3357C9.03463 14.3357 9.29696 14.0734 9.29696 13.7498C9.29696 13.4262 9.03463 13.1639 8.71104 13.1639C8.38745 13.1639 8.12512 13.4262 8.12512 13.7498C8.12512 14.0734 8.38745 14.3357 8.71104 14.3357Z" fill="#429EFF" />
            <path d="M8.71104 9.23839C9.03463 9.23839 9.29696 8.97607 9.29696 8.65248C9.29696 8.32888 9.03463 8.06656 8.71104 8.06656C8.38745 8.06656 8.12512 8.32888 8.12512 8.65248C8.12512 8.97607 8.38745 9.23839 8.71104 9.23839Z" fill="#429EFF" />
        </svg>

    </>

export default CalendarIcon