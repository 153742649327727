import styles from './styles.module.scss'
import React from 'react'
import MarketplacesSwitch from './ui/MarketplacesSwitch/index.jsx';
import { useState } from 'react'
import DaysSelect from './ui/DaysSelect/index.jsx';
import DropDownOrg from './ui/DropDownOrg/index.jsx';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import { API_URL, getData } from '../../static/js/request';
import { Store } from 'react-notifications-component';

function Logistics() {

    const [activeTab, setActiveTab] = useState(0)

    const navigate = useNavigate()

    const days = [7, 14, 30]

    const [selectDay, setSelectDay] = useState(7)
    const [selectDay2, setSelectDay2] = useState(30)

    const [organisations, setOrganisations] = useState([])

    const mp = ['ALL', 'WB', 'OZON']

    const getOrganisations = async () => {
        try {
            const response = await getData(`${API_URL}/api/v1/deliverypalner/?mp=${mp[activeTab]}`)
            setOrganisations(response.organisations)
        } catch (error) {

        }
    }

    useEffect(() => {
        getOrganisations()
    }, [activeTab])

    const [downloadLoading, setDownloadLoading] = React.useState(false)

    const download = () => {
        if(!downloadLoading) {
            setDownloadLoading(true)
            setTimeout(() => {
                setDownloadLoading(false)
                Store.addNotification({
                    title: "Сохранено",
                    message: "Файл готов к загрузке",
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                    }
                });
            }, 2000)
        }
    }

    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <h2 className={styles.title}>Планировщик поставок</h2>
                <MarketplacesSwitch active={activeTab} setActive={setActiveTab} all={true} />
            </div>
            <div className={styles.block}>
                <div className={styles.blockTop}>
                    <div className={styles.selects}>
                        <DaysSelect id={'select1'} select={selectDay} onChange={setSelectDay} days={days} title="Кол-во дней для анализа продаж и заказов" />
                        <DaysSelect id={'select2'} select={selectDay2} onChange={setSelectDay2} days={days} title="На какой срок закупка" />
                    </div>
                    <div className={styles.buttons}>
                        <button className={styles.download} onClick={download}>
                            {
                                downloadLoading ?
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="200px" height="200px" viewBox="0 0 100 100" >
                                        <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                                        </circle>
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M1 9.25806V15.2796C1 15.7359 1.1873 16.1735 1.5207 16.4961C1.8541 16.8187 2.30628 17 2.77778 17H15.2222C15.6937 17 16.1459 16.8187 16.4793 16.4961C16.8127 16.1735 17 15.7359 17 15.2796V9.25806M5.35556 9.25806L9 12.6989M9 12.6989L12.6444 9.25806M9 12.6989V1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                            }
                            Скачать zip
                        </button>
                        <button className={styles.settings} onClick={() => navigate('/scheduler/logistics/settings')}>Настройки</button>
                    </div>
                </div>
                <div className={styles.table}>
                    <div className={`${styles.row} ${styles.titleRow}`}>
                        <div className={styles.col}>Товар</div>
                        <div className={styles.col}>артикул</div>
                        <div className={styles.col}>Регион</div>
                        <div className={styles.col}>остаток<br />на маркетплейсе</div>
                        <div className={styles.col}>остатки<br />на личном складе</div>
                        <div className={styles.col}>% невыкупа</div>
                        <div className={styles.col}>К поставке</div>
                        <div className={styles.col}>Заказать у поставщика</div>
                    </div>
                    <div className={styles.scrollable}>
                        {
                            organisations.map(item => <DropDownOrg analysis={selectDay} purchase={selectDay2} mp={mp[activeTab]} key={item.id} item={item} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Logistics;