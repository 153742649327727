import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import YearSelect from '../../../Balance/YearSelect/index.jsx';
import { NewCustomInput } from '../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import {
    API_URL,
    asyncGetData,
    getData,
    postData,
    postDataFile,
} from '../../../../static/js/request';
import dayjs from 'dayjs';
import { Calendar } from 'react-date-range';
import 'react-calendar/dist/Calendar.css';
import { ru } from 'date-fns/locale';
import ModeSwitch from '../../../Economy/ModeSwitch/index.jsx';

function CreateModal({ data, onClose }) {
    const [id, setID] = useState(data.loan_id ?? null);
    const [given, setGiven] = useState(data.lender_type ?? null);
    const [percentages, setPercentages] = useState(data.percentages ?? '');
    const [loanAmount, setLoanAmount] = useState(data.loan_amount ?? '');
    const [lenderName, setLenderName] = useState(data.lender_name ?? '');
    const [paymentRefundDate, setPaymentRefundDate] = useState('');
    // const [payment_refund_date, setPaymentRefundDate] = useState('');
    const [typeLoan, setTypeLoan] = useState('Без графика');
    // todo: сделать запрос на получение списка по url /api/v1/loan/lenders/
    const [typeCreditors, setTypeCreditors] = useState(['Частное лицо', 'Юридическое лицо', 'Сбербанк', 'Т-Банк', 'СимплФинанс']);
    const [name, changeName] = useState(data.name ?? '');
    const [selectOrg, setSelectOrg] = useState(data.organisation ?? null);

    const [startDateCalendar, setStartDateCalendar] = useState(new Date());
    const [showStartCalendar, setShowStartCalendar] = useState(false);
    const [refundDateCalendar, setRefundDateCalendar] = useState(new Date());
    const [showRefundCalendar, setShowRefundCalendar] = useState(false);
    const [startDate, changeStartDate] = useState('');

    const [organisation, setOrganistations] = useState([]);

    const [file, setFile] = useState(null);

    const getOrganisations = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/organisation/');
            setOrganistations(response);
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getOrganisations();
    }, []);

    const handleInput = (str, oldValue, change) => {
        if (str.length === oldValue.length + 1) {
            if (str.length === 2 || str.length === 5) {
                str += '.';
            }
        }

        if (str.length <= 10) {
            change(str);
        }
    };
    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    const createLoan = async () => {
        try {
            const formData = new FormData();

            formData.append(
                'organisation_id',
                organisation.find((temp) => temp.name === selectOrg).id,
            );
            formData.append('name', name);
            formData.append('loan_currency', 'RUB');
            formData.append('date_provision', startDate);
            formData.append('lender_type', given);
            formData.append('lender_name', lenderName);
            // todo: условие, либо одно, либо другое
            if (typeLoan === 'График платежей') {
                formData.append('file', file, file.name);
            } else {
                formData.append('percentages', percentages);
                formData.append('loan_amount', loanAmount);
                formData.append('payment_refund_date', paymentRefundDate);
            }

            const response = await postDataFile(
                API_URL + '/api/v1/loan/create/',
                formData,
            );
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    const downloadPaymentScheduleTemplate = async () => {
        try {
            const url = `${API_URL}/api/v1/loan/download-payment-schedule-template/`;
            const response = await asyncGetData(url);

            const blob = await response.blob();

            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'payment_schedule_template.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Освобождаем URL
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Ошибка при загрузке файла:', error);
        }
    };

    return (
        <div className={styles.modal}>
            <span className={styles.closeBg} onClick={onClose} />
            <div className={styles.content}>
                <button className={styles.closeBtn} onClick={onClose}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M11.1452 10.0001L16.0124 5.13259C16.3292 4.81598 16.3292 4.30407 16.0124 3.98746C15.6958 3.67085 15.1839 3.67085 14.8673 3.98746L9.99993 8.85496L5.13268 3.98746C4.81592 3.67085 4.30417 3.67085 3.98756 3.98746C3.67081 4.30407 3.67081 4.81598 3.98756 5.13259L8.85481 10.0001L3.98756 14.8676C3.67081 15.1842 3.67081 15.6961 3.98756 16.0127C4.14535 16.1707 4.35281 16.25 4.56012 16.25C4.76743 16.25 4.97475 16.1707 5.13268 16.0127L9.99993 11.1452L14.8673 16.0127C15.0253 16.1707 15.2326 16.25 15.4399 16.25C15.6472 16.25 15.8545 16.1707 16.0124 16.0127C16.3292 15.6961 16.3292 15.1842 16.0124 14.8676L11.1452 10.0001Z"
                            fill="#35373A"
                        />
                    </svg>
                </button>
                <h2 className={styles.title}>Добавление займа/кредита</h2>
                <div className={styles.inputs}>
                    <ModeSwitch
                        values={['Без графика', 'График платежей']}
                        active={typeLoan}
                        onChange={setTypeLoan}
                    />
                </div>
                <div className={styles.inputs}>
                    <div className={styles.inputWrap}>
                        <p className={styles.inputTitle}>Кто выдал займ</p>
                        <YearSelect
                            value={given}
                            onChange={setGiven}
                            placeholder={'Выберите из списка'}
                            width={'100%'}
                            allValues={typeCreditors}
                            textAlign={'left'}
                            required={true}
                        />
                    </div>
                    {
                        (data.lender_type || given === 'Частное лицо' || given === 'Юридическое лицо') ? (
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Имя кредитора</p>
                                <NewCustomInput
                                    placeholder={'Введите имя кредитора'}
                                    width={'100%'}
                                    value={lenderName}
                                    onChange={setLenderName}
                                    required={true}
                                />
                            </div>
                        ) : null
                    }


                    <div className={styles.inputWrap}>
                        <p className={styles.inputTitle}>Название займа</p>
                        <NewCustomInput
                            placeholder={'Введите название'}
                            width={'100%'}
                            value={name}
                            onChange={changeName}
                            required={true}
                        />
                    </div>
                </div>
                <div className={styles.inputs}>
                    <div className={styles.inputWrap}>
                        <p className={styles.inputTitle}>Выбор организации</p>
                        <YearSelect
                            value={selectOrg}
                            onChange={setSelectOrg}
                            placeholder={'Выберите из списка'}
                            width={'100%'}
                            allValues={organisation.map((temp) => temp.name)}
                            textAlign={'left'}
                        />
                    </div>
                    <div className={styles.inputWrap}>
                        <p className={styles.inputTitle}>Дата выдачи займа</p>
                        <input
                            className={styles.inputDate}
                            onFocus={() => setShowStartCalendar(true)}
                            type="text"
                            placeholder={'Введите дату'}
                            onChange={(e) =>
                                handleInput(e.target.value, startDate, changeStartDate)
                            }
                            value={startDate}
                            required={true}
                        />
                        {showStartCalendar && (
                            <>
                                <Calendar
                                    className={`${styles.calendar} calendar`}
                                    rangeColors={['#3d91ff']}
                                    onChange={(ranges) => {
                                        changeStartDate(
                                            dayjs(new Date(ranges)).format('DD.MM.YYYY'),
                                        );
                                        setStartDateCalendar(new Date(ranges));
                                        setShowStartCalendar(false);
                                    }}
                                    value={startDateCalendar}
                                    months={1}
                                    fixedHeight={true}
                                    locale={customLocale}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    direction="horizontal"
                                    showDateDisplay={false}
                                    defaultValue={startDateCalendar}
                                />
                                <span
                                    onClick={() => setShowStartCalendar(false)}
                                    className={styles.calendarCloseBg}
                                />
                            </>
                        )}
                    </div>
                </div>

                {
                    typeLoan === 'График платежей' ?
                        <div className={styles.inputs}>
                            <button
                                className={styles.loadFile}
                                onClick={downloadPaymentScheduleTemplate}
                            >
                                Скачать шаблон
                            </button>
                            <input
                                type="file"
                                accept=".xlsx, .xls"
                                style={{ display: 'none' }}
                                id="file"
                                onChange={(e) => setFile(e.target.files[0])}
                                required={true}
                            />
                            {file && <p className={styles.fileName}>{file.name}</p>}
                            <label className={styles.loadFile} htmlFor="file">
                                <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.0238 3.26834C12.8813 3.15084 12.6925 3.10209 12.51 3.13584L2.51 5.01084C2.21375 5.06584 2 5.32334 2 5.62459V19.3746C2 19.6746 2.21375 19.9334 2.51 19.9884L12.51 21.8634C12.5475 21.8709 12.5875 21.8746 12.625 21.8746C12.7688 21.8746 12.9113 21.8246 13.0238 21.7309C13.1675 21.6121 13.25 21.4346 13.25 21.2496V3.74959C13.25 3.56334 13.1675 3.38709 13.0238 3.26834ZM12 20.4959L3.25 18.8559V6.14334L12 4.50334V20.4959Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M21.375 5.62463H12.625C12.28 5.62463 12 5.90463 12 6.24963C12 6.59463 12.28 6.87463 12.625 6.87463H20.75V18.1246H12.625C12.28 18.1246 12 18.4046 12 18.7496C12 19.0946 12.28 19.3746 12.625 19.3746H21.375C21.72 19.3746 22 19.0946 22 18.7496V6.24963C22 5.90463 21.72 5.62463 21.375 5.62463Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M15.125 8.12463H12.625C12.28 8.12463 12 8.40463 12 8.74963C12 9.09463 12.28 9.37463 12.625 9.37463H15.125C15.47 9.37463 15.75 9.09463 15.75 8.74963C15.75 8.40463 15.47 8.12463 15.125 8.12463Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M15.125 10.6246H12.625C12.28 10.6246 12 10.9046 12 11.2496C12 11.5946 12.28 11.8746 12.625 11.8746H15.125C15.47 11.8746 15.75 11.5946 15.75 11.2496C15.75 10.9046 15.47 10.6246 15.125 10.6246Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M15.125 13.1246H12.625C12.28 13.1246 12 13.4046 12 13.7496C12 14.0946 12.28 14.3746 12.625 14.3746H15.125C15.47 14.3746 15.75 14.0946 15.75 13.7496C15.75 13.4046 15.47 13.1246 15.125 13.1246Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M15.125 15.6246H12.625C12.28 15.6246 12 15.9046 12 16.2496C12 16.5946 12.28 16.8746 12.625 16.8746H15.125C15.47 16.8746 15.75 16.5946 15.75 16.2496C15.75 15.9046 15.47 15.6246 15.125 15.6246Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M18.875 8.12463H17.625C17.28 8.12463 17 8.40463 17 8.74963C17 9.09463 17.28 9.37463 17.625 9.37463H18.875C19.22 9.37463 19.5 9.09463 19.5 8.74963C19.5 8.40463 19.22 8.12463 18.875 8.12463Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M18.875 10.6246H17.625C17.28 10.6246 17 10.9046 17 11.2496C17 11.5946 17.28 11.8746 17.625 11.8746H18.875C19.22 11.8746 19.5 11.5946 19.5 11.2496C19.5 10.9046 19.22 10.6246 18.875 10.6246Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M18.875 13.1246H17.625C17.28 13.1246 17 13.4046 17 13.7496C17 14.0946 17.28 14.3746 17.625 14.3746H18.875C19.22 14.3746 19.5 14.0946 19.5 13.7496C19.5 13.4046 19.22 13.1246 18.875 13.1246Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M18.875 15.6246H17.625C17.28 15.6246 17 15.9046 17 16.2496C17 16.5946 17.28 16.8746 17.625 16.8746H18.875C19.22 16.8746 19.5 16.5946 19.5 16.2496C19.5 15.9046 19.22 15.6246 18.875 15.6246Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M10.5961 14.5883L6.22108 9.58827C5.99108 9.32702 5.59733 9.30202 5.33858 9.52952C5.07858 9.75702 5.05233 10.152 5.27983 10.4108L9.65483 15.4108C9.77858 15.552 9.95108 15.6245 10.1248 15.6245C10.2711 15.6245 10.4173 15.5733 10.5373 15.4695C10.7973 15.242 10.8236 14.8483 10.5961 14.5883Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M10.5088 8.88206C10.2363 8.66831 9.84377 8.71956 9.63127 8.99081L5.25627 14.6158C5.04502 14.8883 5.09377 15.2821 5.36627 15.4933C5.48127 15.5821 5.61627 15.6246 5.75002 15.6246C5.93502 15.6246 6.12002 15.5421 6.24252 15.3846L10.6175 9.75956C10.83 9.48581 10.7813 9.09331 10.5088 8.88206Z"
                                        fill="white"
                                    ></path>
                                </svg>
                                {file ? 'Загрузить другой график' : 'Загрузить график платежей'}
                            </label>
                        </div> :
                        <div className={styles.inputs}>
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Дата возврата займа</p>
                                <input
                                    className={styles.inputDate}
                                    onFocus={() => setShowRefundCalendar(true)}
                                    type="text"
                                    width={'100%'}
                                    placeholder={'Введите дату'}
                                    onChange={(e) =>
                                        handleInput(e.target.value, paymentRefundDate, setPaymentRefundDate)
                                    }
                                    value={paymentRefundDate}
                                />
                                {showRefundCalendar && (
                                    <>
                                        <Calendar
                                            className={`${styles.calendar} calendar`}
                                            rangeColors={['#3d91ff']}
                                            onChange={(ranges) => {
                                                setPaymentRefundDate(
                                                    dayjs(new Date(ranges)).format('DD.MM.YYYY'),
                                                );
                                                setRefundDateCalendar(new Date(ranges));
                                                setShowRefundCalendar(false);
                                            }}
                                            value={refundDateCalendar}
                                            months={1}
                                            fixedHeight={true}
                                            locale={customLocale}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            direction="horizontal"
                                            showDateDisplay={false}
                                            defaultValue={refundDateCalendar}
                                        />
                                        <span
                                            onClick={() => setShowRefundCalendar(false)}
                                            className={styles.calendarCloseBg}
                                        />
                                    </>
                                )}
                            </div>
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Сумма займа, ₽</p>
                                <NewCustomInput
                                    numeric
                                    placeholder={'Введите сумму'}
                                    width={'100%'}
                                    value={loanAmount}
                                    onChange={setLoanAmount}
                                    required={true}
                                />
                            </div>
                            <div className={styles.inputWrap}>
                                <p className={styles.inputTitle}>Процентная ставка, %</p>
                                <NewCustomInput
                                    numeric
                                    placeholder={'Введите %'}
                                    width={'100%'}
                                    value={percentages}
                                    onChange={setPercentages}
                                />
                            </div>
                        </div>
                }

                <div className={styles.buttons}>
                    <button
                        className={styles.saveButton}
                        disabled={!given || ((given === 'Частное лицо' || given === 'Юридическое лицо') ? (!name || !lenderName) : !name) || !selectOrg || !startDate || (typeLoan === 'График платежей' ? !file : (!loanAmount))}
                        onClick={createLoan}
                    >
                        Сохранить
                    </button>
                    <button className={styles.close} onClick={onClose}>
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateModal;
