import { makeAutoObservable } from 'mobx';
import { getCashbox, getCashboxMonth } from '../../api/getCashboxes';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import AutoCompleteSearch from './AutoComplete';
import {
    API_URL,
    delData,
    getData,
    postData,
    putData,
} from '../../static/js/request';
import { Store } from 'react-notifications-component';
import style from '../../views/Financing/Financing.module.scss';
import { createNotification, formatNumber2 } from '../../static/js/commonFunc';
import dayjs from 'dayjs';
import { AiFillWallet } from 'react-icons/ai';

export class CashboxStore {
    fields = [];
    newArticle = {
        executed: '',
        amount: '',
        operation_type: '',
        article: '',
        comment: '',
        cashbox: '',
    };
    activeArticle = 'Выберите статью';
    cashboxIncoming_balance = '';
    cashboxOutgoing_balance = '';
    cashboxName = '';
    cashboxResponsible = '';
    filterYears = [];

    constructor() {
        makeAutoObservable(this);
        this.fillValue();
    }

    setRef = (ref) => {
        this.ref = ref;
    };

    setRefArt = (ref) => {
        this.refArt = ref;
    };

    fillValue = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        this.newArticle.cashbox = queryParameters.get('id');
        // TODO: сделать пагинацию, сортировку и фильтрацию
        let data = {
            'cashbox_id': this.newArticle.cashbox,
            'paginator': {
                'page_current': 1,
                'page_size': 100,
            },
            'sorted': [
                // {"key": "article", "value": "desc"}
            ],
            'filters': [
                // {"key": "article", "value": "300"}
            ],
        };

        postData(API_URL + `/api/v1/cashbox/operations/`, data).then(response => {
            if (!response) return;

            response.json().then(data => {
                if (!data.operations) {
                    this.fields = [];
                    return;
                }

                // Извлечение операций
                const operations = data.operations;
                this.fields = operations;

                // Получение уникальных годов
                this.filterYears = [...new Set(operations.map(op => +op.executed.substring(6)))];

                // Установка значений баланса и информации о кассе
                this.cashboxIncoming_balance = data.incoming_balance ?? 0;
                this.cashboxOutgoing_balance = data.outgoing_balance ?? 0;
                this.cashboxName = data.name;
                this.cashboxResponsible = data.responsible;
            });
        });
    };

    sortByAscendingDate = () => {
        this.fields.sort((a, b) => {
            // Преобразование строки 'дд.мм.гггг' в объект одного из видов: new Date('гггг, мм, дд')
            let dateA = a.executed.split('.').reverse().join(',');
            let dateB = b.executed.split('.').reverse().join(',');
            return new Date(dateA) - new Date(dateB);
        });
    };

    sortByDescendingDate = () => {
        this.fields.sort((a, b) => {
            let dateA = a.executed.split('.').reverse().join(',');
            let dateB = b.executed.split('.').reverse().join(',');
            return new Date(dateB) - new Date(dateA);
        });
    };

    overwriteValue = (id, month) => {
        const queryParameters = new URLSearchParams(window.location.search);
        this.newArticle.cashbox = queryParameters.get('id');

        getCashboxMonth(id, month).then((item) => {
            this.cashboxIncoming_balance =
                item.incoming_balance === null ? 0 : item.incoming_balance;
            this.cashboxOutgoing_balance =
                item.outgoing_balance === null ? 0 : item.outgoing_balance;
            this.cashboxName = item.name;
            this.cashboxResponsible = item.responsible;
            if (!item.operations) {
                return (this.fields = []);
            }
            this.fields = item.operations;
        });
    };

    handleChangeBanks = (event) => {
        const target = event.target;
        if (target.name === 'executed') {
            const name = target.name;
            const value = dayjs(new Date(target.value)).format('DD.MM.YYYY');
            this.newArticle[name] = value;
        } else {
            const name = target.name;
            this.newArticle[name] = target.value;
        }
    };

    handleChangeField = (event, index) => {
        const target = event.target;
        const name = target.name;
        this.fields[index][name] = target.value;
    };

    handleChangeOperationType = (event, index) => {
        const target = event.target;
        const name = target.name;
        this.fields[index][name] = target.value;
    };

    handleSelectArt = (onClose) => {
        this.newArticle.article = this.ref.querySelector('input').dataset.id;
        this.activeArticle = this.ref.querySelector('input').value;
        onClose();
    };

    removeField = (index, myRefs) => {
        const field = this.fields.find((el) => el.id === index);
        const fieldIndex = this.fields.indexOf(field);
        delData(API_URL + `/api/v1/cashbox/operations/delete/${index}/`).then(
            (response) => {
                this.fields.splice(fieldIndex, 1);
                myRefs[fieldIndex].current.classList.remove(style.active);
            },
        );
    };

    formatDate(dateString) {
        const regex = /^\d{2}.\d{2}.\d{4}$/;

        if (regex.test(dateString)) {
            return dateString;
        }

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${day}.${month}.${year}`;
    }

    saveChangeSubmit = (fieldIndex, index, onClose) => {
        if (this.refArt.querySelector('input').dataset.id !== undefined) {
            this.fields[fieldIndex].article =
                this.refArt.querySelector('input').dataset.id;
        }

        const obj = {
            executed: this.formatDate(this.fields[fieldIndex].executed),
            cashbox: this.fields[fieldIndex].cashbox,
            article: this.fields[fieldIndex].article,
            operation_type: this.fields[fieldIndex].operation_type,
            amount: this.fields[fieldIndex].amount.replace(/,/g, '.'),
            comment: this.fields[fieldIndex].comment,
        };

        putData(API_URL + `/api/v1/operations/update/${index}/`, obj).then(
            (response) => {
                createNotification(response);
            },
        );

        onClose();
    };

    handleSubmit = (article, date) => {
        const queryParameters = new URLSearchParams(window.location.search);
        this.newArticle.cashbox = queryParameters.get('id');
        postData(API_URL + '/api/v1/cashbox/operations/create/', {
            ...this.newArticle,
            amount: parseFloat(this.newArticle.amount.replace(',', '.')),
            article: article.id,
            executed: dayjs(date).format('DD.MM.YYYY'),
        }).then((response) => {
            createNotification(response);
            this.newArticle = {
                executed: '',
                amount: '',
                operation_type: '',
                article: '',
                comment: '',
                cashbox: '',
            };
            this.fillValue();
        });
    };

    selectArticle = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body" ref={this.setRef}>
                        <h1>Выбор статьи</h1>
                        <AutoCompleteSearch />

                        <div className="react-confirm-alert-button-group">
                            <button className="cancel" onClick={onClose}>
                                Отменить
                            </button>
                            <button
                                className="complete"
                                onClick={() => {
                                    this.handleSelectArt(onClose);
                                }}
                            >
                                Подтвердить
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };
}
