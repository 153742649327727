import React from 'react'
import style from '../../../../Purchase.module.scss'
import FilterIcon from '../../../../../../shared/ui/icons/Filter.jsx'
import cx from 'classnames';

const Head = ({ headRef }) => {

    const handleSort = (value) => {
        return true
    }

    return (

        <div className={style.row}>
            <div className={style.xScroll} ref={headRef}>

                <div style={{ width: '80px' }} className={cx(style.column, style.header, style.center)}>
                    <span>№</span>
                </div>

                <div style={{ width: '60px' }} className={cx(style.column, style.header, style.center)}>
                    <span>Фото</span>
                </div>

                <div style={{ width: '566px' }} className={cx(style.column, style.header, style.start)}>
                    <span>Наименование</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Принято</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Остаток</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Цена</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Количество</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Сумма</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '60px' }} className={cx(style.column, style.header)}></div>
                
            </div>
        </div>

    )
}

export default Head