import React, {useState, useEffect, useRef} from 'react'
import ArticleStore from "./ArticleStore";

const AutoCompleteSearch = (article) => {
	const [searchTerm, updateSearchTerm] = useState('');
	const [searchResults, updateSearchResults] = useState([]);
	const [filteredResults, updateFilteredResults] = useState(Object.entries(ArticleStore.fields));

	const updateSearch = (value) => {

		updateSearchTerm(value);
		updateFilteredResults(searchResults.filter(result => {
			if (!result[1]) {
				return;
			}
			return result[1].includes(value);
		}));
	};

	const searchRef = useRef(null);
	const selectRef = useRef(null);

	useEffect(() => {
		const getSearchResults = () => {
			const searchResultsResponse = Object.entries(ArticleStore.fields);
			updateSearchResults(searchResultsResponse);
		};

		getSearchResults();

		if (article.article) {
			updateSearch(ArticleStore.fields[article.article]);
			selectRef.current.value = ArticleStore.fields[article.article];
			selectRef.current.dataset.id = article.article;
		}
	}, []);

	const handleSelectArticle = (event) => {
		const target = event.target;
		searchRef.current.querySelectorAll('div').forEach((el) => {
			el.classList.remove('active');
		});
		target.classList.add('active');
		selectRef.current.value = target.dataset.artText;
		selectRef.current.dataset.id = target.dataset.art;

	};

	const SearchResults = () => {
		const Message = ({ text }) => (
			<div className="message">
				<div>{text}</div>
			</div>
		);

		const render = () => {
			return filteredResults.map((article, index) => (
				<div key={index} data-art={article[0]} data-art-text={article[1]} onClick={() => {handleSelectArticle(event)}}>
					{article[1]}
				</div>
			))
		};

		if (!searchResults.length) {
			return <Message text="Загрузка результатов поиска" />
		}

		if (!searchTerm) {
			return render();
		}

		return (
			render()
		);
	};

	return (
		<section ref={searchRef}>
			<div>{(searchTerm && searchTerm.length) ? `результаты, по запросу: ${searchTerm}` : null}</div>
			<input className='react-confirm-autocomplete-input'
				   ref={selectRef}
				   type="text" placeholder="Введите текст..."
				   defaultValue={ArticleStore.fields[article.article] || ''}
				   onKeyUp={() => updateSearch(selectRef.current.value)} />
			<div className="react-confirm-article-container">
				<SearchResults />
			</div>
		</section>
	);
}

export default AutoCompleteSearch;