import React, {createContext, useContext} from 'react';
import mainStyle from "../../static/css/mainStyle.module.scss";
import Landing from '../Landing/index.jsx';

const Home = () => {
	return (
		<main className={mainStyle.main}>
			Home
		</main>
	);
};

export default Home;