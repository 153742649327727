import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { NewCustomInput } from '../../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import { useParams } from 'react-router-dom';
import { API_URL, delData, getData, postData } from '../../../../../static/js/request';
import { createNotification } from '../../../../../static/js/commonFunc';

function StopWords() {

    const [value, changeValue] = useState('')

    const [recomendedWords, setRecomendations] = useState([])

    const params = useParams();

    const [stopWords, setStopWords] = useState([]);

    const getStopWords = async () => {
        try {
            const response = await getData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/stop_words/`)
            setRecomendations([...response.recommendations]);
            setStopWords(response.saved);
        } catch (error) {
            console.log(error)
        }
    }

    const addWord = async () => {
        try {
            const response = await postData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/stop_words/`, {
                stop_words: [value]
            })
            createNotification(response);
            setStopWords([...stopWords, { word: value, id: stopWords.length }]);
            changeValue('')
            getStopWords()
        } catch (error) {
            console.log(error)
        }
    }

    const removeWord = async (word) => {
        try {
            const response = await delData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/stop_words/`, {
                stop_word_id: word.id
            })
            createNotification(response);
            setStopWords([...stopWords.filter(item => item.id !== word.id)]);
            getStopWords()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getStopWords()
    }, [])

    const recomendedClick = async (item) => {
        try {
            const response = await postData(API_URL + `/api/v1/answering_machine/constructors/${params.id}/stop_words/`, {
                stop_words: [item.word]
            })
            createNotification(response);
            changeValue('')
            setStopWords([...stopWords, item])
            setRecomendations([...recomendedWords.filter(temp => temp.id !== item.id)])
            getStopWords()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <NewCustomInput placeholder={'Стоп-слово'} value={value} onChange={changeValue} type={'text'} width={'10%'} />
                <button onClick={addWord} disabled={value.trim().length === 0 || stopWords.map(word => word.word).includes(value)} className={styles.add}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M3.94748 13.4475H11.0525V20.5525C11.0525 20.8038 11.1523 21.0448 11.33 21.2225C11.5077 21.4002 11.7487 21.5 12 21.5C12.2513 21.5 12.4923 21.4002 12.67 21.2225C12.8477 21.0448 12.9475 20.8038 12.9475 20.5525V13.4475H20.0525C20.3038 13.4475 20.5448 13.3477 20.7225 13.17C20.9002 12.9923 21 12.7513 21 12.5C21 12.2487 20.9002 12.0077 20.7225 11.83C20.5448 11.6523 20.3038 11.5525 20.0525 11.5525H12.9475V4.44748C12.9475 4.19619 12.8477 3.9552 12.67 3.77751C12.4923 3.59982 12.2513 3.5 12 3.5C11.7487 3.5 11.5077 3.59982 11.33 3.77751C11.1523 3.9552 11.0525 4.19619 11.0525 4.44748V11.5525H3.94748C3.69619 11.5525 3.4552 11.6523 3.27751 11.83C3.09983 12.0077 3 12.2487 3 12.5C3 12.7513 3.09983 12.9923 3.27751 13.17C3.4552 13.3477 3.69619 13.4475 3.94748 13.4475Z" fill="white" />
                    </svg>
                    Добавить
                </button>
                <div className={styles.recomends}>
                    {
                        recomendedWords.length > 0 &&
                        <>
                            <p className={styles.recomendsTitle}>подходящие стоп-слова:</p>
                            {
                                recomendedWords?.map((item, index) => (
                                    <button onClick={() => recomendedClick(item)} key={item.id} className={`${styles.text} ${stopWords.includes(item.word) ? styles.added : ''}`}>{item.word}</button>
                                ))
                            }
                        </>
                    }
                </div>
            </div>
            <div className={styles.words}>
                {
                    stopWords?.map(item => (
                        <button onClick={() => removeWord(item)} key={item.id} className={styles.word}>
                            {item.word}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M8.91615 8.50007L12.8099 4.60608C13.0633 4.35279 13.0633 3.94325 12.8099 3.68997C12.5567 3.43668 12.1471 3.43668 11.8939 3.68997L7.99994 7.58396L4.10614 3.68997C3.85274 3.43668 3.44333 3.43668 3.19005 3.68997C2.93665 3.94325 2.93665 4.35279 3.19005 4.60608L7.08385 8.50007L3.19005 12.3941C2.93665 12.6474 2.93665 13.0569 3.19005 13.3102C3.31628 13.4365 3.48225 13.5 3.6481 13.5C3.81395 13.5 3.9798 13.4365 4.10614 13.3102L7.99994 9.41618L11.8939 13.3102C12.0202 13.4365 12.1861 13.5 12.3519 13.5C12.5178 13.5 12.6836 13.4365 12.8099 13.3102C13.0633 13.0569 13.0633 12.6474 12.8099 12.3941L8.91615 8.50007Z" fill="#FF5E5B" />
                            </svg>
                        </button>
                    ))
                }
            </div>
            <div className={styles.notice}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M6.99936 4.9356C7.14204 4.93519 7.27875 4.87833 7.37964 4.77744C7.48052 4.67656 7.53738 4.53984 7.53779 4.39717V3.85874C7.53779 3.71594 7.48106 3.57899 7.38009 3.47802C7.27912 3.37704 7.14216 3.32031 6.99936 3.32031C6.85657 3.32031 6.71961 3.37704 6.61864 3.47802C6.51767 3.57899 6.46094 3.71594 6.46094 3.85874V4.39717C6.46135 4.53984 6.5182 4.67656 6.61909 4.77744C6.71998 4.87833 6.85669 4.93519 6.99936 4.9356Z" fill="#8496AF" />
                    <path d="M5.92124 7.08955H6.45967V10.1408C6.45967 10.2836 6.5164 10.4205 6.61737 10.5215C6.71834 10.6225 6.8553 10.6792 6.99809 10.6792C7.14089 10.6792 7.27785 10.6225 7.37882 10.5215C7.47979 10.4205 7.53652 10.2836 7.53652 10.1408V6.55112C7.53611 6.40845 7.47925 6.27174 7.37837 6.17085C7.27748 6.06996 7.14077 6.0131 6.99809 6.0127H5.92124C5.77844 6.0127 5.64149 6.06942 5.54051 6.1704C5.43954 6.27137 5.38281 6.40832 5.38281 6.55112C5.38281 6.69392 5.43954 6.83088 5.54051 6.93185C5.64149 7.03283 5.77844 7.08955 5.92124 7.08955Z" fill="#8496AF" />
                    <path d="M7 14C8.38447 14 9.73785 13.5895 10.889 12.8203C12.0401 12.0511 12.9373 10.9579 13.4672 9.67878C13.997 8.3997 14.1356 6.99224 13.8655 5.63437C13.5954 4.2765 12.9287 3.02922 11.9497 2.05026C10.9708 1.07129 9.7235 0.404603 8.36563 0.134506C7.00776 -0.13559 5.6003 0.00303298 4.32121 0.532846C3.04213 1.06266 1.94888 1.95987 1.17971 3.11101C0.410543 4.26215 0 5.61553 0 7C0.00202552 8.8559 0.740174 10.6352 2.05249 11.9475C3.3648 13.2598 5.1441 13.998 7 14ZM7 1.00473C8.18575 1.00473 9.34488 1.35635 10.3308 2.01512C11.3167 2.67388 12.0851 3.61022 12.5389 4.70571C12.9927 5.8012 13.1114 7.00665 12.8801 8.16962C12.6487 9.33259 12.0777 10.4008 11.2393 11.2393C10.4008 12.0778 9.33259 12.6487 8.16962 12.8801C7.00665 13.1114 5.8012 12.9927 4.70571 12.5389C3.61021 12.0851 2.67388 11.3167 2.01511 10.3308C1.35634 9.34488 1.00473 8.18575 1.00473 7C1.00593 5.41033 1.63796 3.88611 2.76203 2.76204C3.8861 1.63797 5.41032 1.00594 7 1.00473Z" fill="#8496AF" />
                </svg>
                <p className={styles.text}>Подсказка: при введении стоп-слова, например, “отказ” бот будет перебирать все однокоренные слова и в разных формах, такие как “отказалась”, “отказался” и тд. Таким образом, если бот встретит это/эти слова в отзыве покупателя, то не будет отвечать на него.</p>
            </div>
        </div>
    );
}

export default StopWords;