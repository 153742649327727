import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import AccountMenu from '../AccountMenu.jsx';
import { NewCustomInput } from '../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import AddModalStaff from './AddStaffModal/AddModalStaff.jsx';
import { API_URL, delData, getData, putData, putDataNew } from '../../../../static/js/request';

function AccountStaff() {
    const [searchValue, changeSearchValue] = useState('');

    const [staff, setStaff] = useState([]);

    const [moreModalIndex, setMoreModalIndex] = useState(null);

    const [addModal, setAddModal] = useState(false);

    const [sended, setSended] = useState(false);

    const [mainOrganization, setOrganization] = useState(null);

    const [allWorks, setAllWorks] = useState([]);

    const [workmodal, setWorkModal] = useState(false);

    const [localActiveList, setLocalActiveList] = useState([]);

    const clickOutside = (e) => {
        const id = e.target.getAttribute('id');

        if (id !== 'noClose') {
            setMoreModalIndex(null);
        }
    };

    const getStaff = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/access/employee/');
            setStaff(response);
        } catch (e) {
        }
    };

    const getOrganisation = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/organisation/');
            setOrganization(response[0]);
        } catch (e) {

        }
    };

    const getPoisitions = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/access/');
            setAllWorks(response);
        } catch (e) {

        }
    };

    const changeStatus = async (status, id) => {
        try {
            const response = await putDataNew(API_URL + '/api/v1/access/employee/', {
                ...staff.find(item => item.id === id),
                status: status,
                mail: staff.find(item => item.id === id).email,
                accesses: staff.find(item => item.id === id).accesses,
            });
            setStaff((state) => {
                return state.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: status,
                        };
                    }
                    return item;
                });
            });
        } catch (e) {
            console.log(e);
        }
    };

    const delWorker = async (id) => {
        try {
            const response = await delData(API_URL + '/api/v1/access/employee/', {
                id,
            });
            getStaff();
        } catch (e) {
            console.log(e);
        }
    };

    const changeWork = async (works, id) => {
        try {
            const response = await putDataNew(API_URL + '/api/v1/access/employee/', {
                ...staff.find(item => item.id === id),
                accesses: works, // Здесь мы передаем массив идентификаторов доступа
            });
            setStaff((state) => {
                return state.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            accesses: works, // Обновляем доступ с новым списком должностей
                        };
                    }
                    return item;
                });
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        setWorkModal(false);
    }, [moreModalIndex]);

    useEffect(() => {
        getStaff();
        getPoisitions();
        getOrganisation();
    }, []);

    useEffect(() => {
        if (moreModalIndex !== null) {
            document.addEventListener('click', clickOutside);
        } else {
            document.removeEventListener('click', clickOutside);
        }
    }, [moreModalIndex]);


    return (
        <div className={styles.wrap}>
            <AccountMenu />
            {
                sended &&
                <div className={styles.sendedModal}>
                    <span className={styles.closeBg} onClick={() => setSended(false)} />
                    <div className={styles.sendedContent}>
                        <button onClick={() => setSended(false)} className={styles.closeBtn}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M13.2826 12.0001L18.7339 6.54851C19.0887 6.1939 19.0887 5.62056 18.7339 5.26595C18.3793 4.91135 17.806 4.91135 17.4514 5.26595L11.9999 10.7176L6.5486 5.26595C6.19384 4.91135 5.62067 4.91135 5.26607 5.26595C4.91131 5.62056 4.91131 6.1939 5.26607 6.54851L10.7174 12.0001L5.26607 17.4517C4.91131 17.8063 4.91131 18.3797 5.26607 18.7343C5.44279 18.9111 5.67514 19 5.90734 19C6.13953 19 6.37172 18.9111 6.5486 18.7343L11.9999 13.2827L17.4514 18.7343C17.6283 18.9111 17.8605 19 18.0927 19C18.3249 19 18.557 18.9111 18.7339 18.7343C19.0887 18.3797 19.0887 17.8063 18.7339 17.4517L13.2826 12.0001Z"
                                    fill="#35373A"
                                />
                            </svg>
                        </button>
                        <h2 className={styles.sendedTitle}>Приглашение отправлено</h2>
                        <p className={styles.sendedText}>Сотруднику отправлено приглашение в <span><a
                            href="https://t.me/busyboard_pro_bot" target={'_blank'}>@TG_Bot</a></span> для подтверждения
                            аккаунта</p>
                    </div>
                </div>
            }
            {addModal && (
                <AddModalStaff
                    allWorks={allWorks}
                    onClose={() => setAddModal(false)}
                    setSended={setSended}
                    orgId={mainOrganization.id}
                    getStaff={getStaff}
                />
            )}
            <div className={styles.content}>
                <div className={styles.top}>
                    <h2 className={styles.title}>Сотрудники</h2>
                    <button onClick={() => setAddModal(true)} className={styles.addStaff}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M3.28957 10.7896H9.21044V16.7104C9.21044 16.9198 9.29362 17.1207 9.44169 17.2687C9.58977 17.4168 9.7906 17.5 10 17.5C10.2094 17.5 10.4102 17.4168 10.5583 17.2687C10.7064 17.1207 10.7896 16.9198 10.7896 16.7104V10.7896H16.7104C16.9198 10.7896 17.1207 10.7064 17.2687 10.5583C17.4168 10.4102 17.5 10.2094 17.5 10C17.5 9.79059 17.4168 9.58976 17.2687 9.44169C17.1207 9.29362 16.9198 9.21043 16.7104 9.21043H10.7896V3.28956C10.7896 3.08016 10.7064 2.87933 10.5583 2.73126C10.4102 2.58319 10.2094 2.5 10 2.5C9.7906 2.5 9.58977 2.58319 9.44169 2.73126C9.29362 2.87933 9.21044 3.08016 9.21044 3.28956V9.21043H3.28957C3.08016 9.21043 2.87933 9.29362 2.73126 9.44169C2.58319 9.58976 2.5 9.79059 2.5 10C2.5 10.2094 2.58319 10.4102 2.73126 10.5583C2.87933 10.7064 3.08016 10.7896 3.28957 10.7896Z"
                                fill="#429EFF"
                            />
                        </svg>
                        Пригласить сотрудника
                    </button>
                </div>
                <NewCustomInput
                    value={searchValue}
                    onChange={changeSearchValue}
                    placeholder="Поиск сотрудников"
                    width={'320px'}
                />
                <div className={styles.table}>
                    <div className={styles.tableTop}>
                        <div className={styles.name}>Фио</div>
                        <div className={styles.status}>Статус</div>
                        <div className={styles.work}>Должность</div>
                        <div className={styles.telegram}>telegram</div>
                        <div className={styles.email}>Электронная почта</div>
                    </div>
                    <div className={styles.tableContent}>
                        {staff?.length > 0 ? staff?.filter(item => item.first_name?.toLowerCase().trim().includes(searchValue?.trim().toLowerCase()) || item.last_name?.toLowerCase().trim().includes(searchValue?.trim().toLowerCase())).map((item) => (
                            <div className={styles.row} key={item.id}>
                                <div className={styles.name}>{item.first_name} {item.middle_name} {item.last_name}</div>
                                <div className={styles.status}>
                                    <p
                                        className={`${styles.value} ${styles[
                                            item.status === 'Заблокирован'
                                                ? 'banned'
                                                : item.status === 'Приглашение отправлено'
                                                    ? 'sended'
                                                    : 'active'
                                        ]
                                            }`}
                                    >
                                        {item.status}
                                    </p>
                                </div>
                                <div className={styles.work}>
                                    {item.accesses && item.accesses.length > 0 ? (
                                        item.accesses.map((access, index) => (
                                            <div className={styles.value} key={index}>{access}</div>
                                        ))
                                    ) : (
                                        <p className={styles.value}>-</p> // Выводим '-' если accesses нет или пустой
                                    )}
                                </div>
                                <div className={styles.telegram}>{item.tg_username ? `@${item.tg_username}` : '-'}</div>
                                <div className={styles.email}>{item.email || '-'}</div>
                                <button
                                    onClick={() => setMoreModalIndex(item.id)}
                                    className={styles.more}
                                    id="noClose"
                                >
                                    <svg
                                        id="noClose"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="4"
                                        height="12"
                                        viewBox="0 0 4 12"
                                        fill="none"
                                    >
                                        <circle
                                            id="noClose"
                                            cx="2.00033"
                                            cy="1.33333"
                                            r="1.33333"
                                            fill="#35373A"
                                        />
                                        <circle
                                            id="noClose"
                                            cx="2.00033"
                                            cy="6.00033"
                                            r="1.33333"
                                            fill="#35373A"
                                        />
                                        <circle
                                            id="noClose"
                                            cx="2.00033"
                                            cy="10.6663"
                                            r="1.33333"
                                            fill="#35373A"
                                        />
                                    </svg>
                                </button>
                                {item.id === moreModalIndex && (
                                    <div className={styles.moreModal} id="noClose">
                                        <div className={styles.optionsBlock} id="noClose">
                                            <button onClick={() => {
                                                setWorkModal(true);
                                                setLocalActiveList(item.accesses);
                                            }} className={styles.option} id="noClose">
                                                <svg
                                                    id="noClose"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                >
                                                    <g id="noClose" clipPath="url(#clip0_4680_34657)">
                                                        <path
                                                            id="noClose"
                                                            d="M4.7998 13.7627C4.65068 13.7625 4.50772 13.7031 4.40228 13.5977C4.29683 13.4922 4.2375 13.3493 4.2373 13.2002V10.0644C4.23784 9.91535 4.29713 9.77252 4.4023 9.6669L12.8331 1.23765C13.0225 1.04807 13.2475 0.897679 13.4951 0.79507C13.7426 0.692462 14.008 0.639648 14.2761 0.639648C14.5441 0.639648 14.8095 0.692462 15.0571 0.79507C15.3047 0.897679 15.5296 1.04807 15.7191 1.23765L16.7623 2.2809C16.9519 2.47035 17.1023 2.6953 17.2049 2.9429C17.3075 3.1905 17.3603 3.45589 17.3603 3.7239C17.3603 3.99192 17.3075 4.25731 17.2049 4.50491C17.1023 4.7525 16.9519 4.97745 16.7623 5.1669L8.3338 13.5977C8.28161 13.6501 8.21954 13.6917 8.15118 13.72C8.08283 13.7483 8.00954 13.7628 7.93555 13.7627H4.7998ZM5.3623 10.2969V12.6377H7.7023L13.6348 6.70515L11.2948 4.36515L5.3623 10.2969ZM14.4306 5.91015L15.9688 4.3719C16.1402 4.19996 16.2365 3.96707 16.2365 3.72428C16.2365 3.48148 16.1402 3.24859 15.9688 3.07665L14.9241 2.03115C14.7522 1.85954 14.5193 1.76315 14.2764 1.76315C14.0336 1.76315 13.8006 1.85954 13.6288 2.03115L12.0898 3.57015L14.4306 5.91015Z"
                                                            fill="#35373A"
                                                        />
                                                        <path
                                                            id="noClose"
                                                            d="M7.20039 4.5H2.90039C1.79582 4.5 0.900391 5.39543 0.900391 6.5V15.1C0.900391 16.2046 1.79582 17.1 2.90039 17.1H11.5004C12.605 17.1 13.5004 16.2043 13.5004 15.0997C13.5004 13.7037 13.5004 12.5142 13.5004 10.8"
                                                            stroke="#35373A"
                                                            strokeLinecap="round"
                                                        />
                                                    </g>
                                                    <defs id="noClose">
                                                        <clipPath id="noClose clip0_4680_34657">
                                                            <rect
                                                                id="noClose"
                                                                width="18"
                                                                height="18"
                                                                rx="3"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                Должность
                                            </button>
                                            <button id="noClose" className={styles.option} onClick={() => {
                                                delWorker(item.id);
                                            }}>
                                                <svg
                                                    id="noClose"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                >
                                                    <path
                                                        id="noClose"
                                                        d="M0.711921 5.07487H1.88576V16.2994C1.88622 16.4519 1.9482 16.5981 2.05817 16.7059C2.16814 16.8138 2.31716 16.8746 2.47268 16.875H13.2337C13.3893 16.8746 13.5383 16.8138 13.6482 16.7059C13.7582 16.5981 13.8202 16.4519 13.8207 16.2994V5.07487H15.2881C15.4437 5.07487 15.593 5.01423 15.7031 4.90629C15.8132 4.79835 15.875 4.65195 15.875 4.4993C15.875 4.34665 15.8132 4.20026 15.7031 4.09232C15.593 3.98438 15.4437 3.92374 15.2881 3.92374H12.4902C12.4694 2.9445 12.0914 2.00499 11.4249 1.27552C10.612 0.431733 9.42828 0 7.89248 0C6.35667 0 5.2019 0.431733 4.46856 1.28548C3.86859 2.03357 3.57548 2.97458 3.64678 3.92374H0.711921C0.634845 3.92374 0.558525 3.93863 0.487316 3.96755C0.416108 3.99648 0.351406 4.03887 0.296905 4.09232C0.242405 4.14577 0.199172 4.20921 0.169677 4.27905C0.140181 4.34888 0.125 4.42372 0.125 4.4993C0.125 4.57489 0.140181 4.64973 0.169677 4.71956C0.199172 4.78939 0.242405 4.85284 0.296905 4.90629C0.351406 4.95973 0.416108 5.00213 0.487316 5.03105C0.558525 5.05998 0.634845 5.07487 0.711921 5.07487V5.07487ZM5.34907 2.02431C5.84804 1.43903 6.69906 1.15137 7.88281 1.15137C9.07623 1.15137 9.97631 1.45847 10.5536 2.05322C11.0179 2.57253 11.284 3.23371 11.3067 3.92398H4.82062C4.7485 3.24825 4.93703 2.57052 5.34907 2.02431V2.02431ZM12.6468 5.07487V15.7236H3.05886V5.07487H12.6468Z"
                                                        fill="#35373A"
                                                    />
                                                    <path
                                                        id="noClose"
                                                        d="M5.99415 14.0927C6.14967 14.0923 6.29869 14.0315 6.40866 13.9237C6.51863 13.8158 6.58061 13.6697 6.58107 13.5172V6.89783C6.58107 6.74518 6.51923 6.59878 6.40916 6.49084C6.29909 6.3829 6.14981 6.32227 5.99415 6.32227C5.83849 6.32227 5.6892 6.3829 5.57913 6.49084C5.46906 6.59878 5.40723 6.74518 5.40723 6.89783V13.5172C5.40768 13.6697 5.46967 13.8158 5.57964 13.9237C5.68961 14.0315 5.83863 14.0923 5.99415 14.0927V14.0927Z"
                                                        fill="#35373A"
                                                    />
                                                    <path
                                                        id="noClose"
                                                        d="M9.71193 14.0927C9.86745 14.0923 10.0165 14.0315 10.1264 13.9237C10.2364 13.8158 10.2984 13.6697 10.2988 13.5172V6.89783C10.2988 6.74518 10.237 6.59878 10.1269 6.49084C10.0169 6.3829 9.86759 6.32227 9.71193 6.32227C9.55627 6.32227 9.40698 6.3829 9.29691 6.49084C9.18684 6.59878 9.125 6.74518 9.125 6.89783V13.5172C9.12546 13.6697 9.18745 13.8158 9.29742 13.9237C9.40739 14.0315 9.55641 14.0923 9.71193 14.0927V14.0927Z"
                                                        fill="#35373A"
                                                    />
                                                </svg>
                                                Удалить
                                            </button>
                                            <button
                                                id="noClose"
                                                className={`${styles.option} ${item.status !== 'Заблокирован'
                                                    ? styles.active
                                                    : styles.banned
                                                    }`}
                                                onClick={() => {
                                                    changeStatus(item.status === 'Заблокирован' ? 'Активный' : 'Заблокирован', item.id);
                                                }}
                                            >
                                                {item.status !== 'Заблокирован' ? (
                                                    <>
                                                        <svg
                                                            id="noClose"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                        >
                                                            <path
                                                                id="noClose"
                                                                d="M9 16.0312C12.8833 16.0312 16.0312 12.8833 16.0312 9C16.0312 5.11675 12.8833 1.96875 9 1.96875C5.11675 1.96875 1.96875 5.11675 1.96875 9C1.96875 12.8833 5.11675 16.0312 9 16.0312Z"
                                                                stroke="#FF5E5B"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                id="noClose"
                                                                d="M4.78125 13.2188L13.7812 4.21875"
                                                                stroke="#FF5E5B"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Заблокировать
                                                    </>
                                                ) : (
                                                    <>
                                                        <svg
                                                            id="noClose"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                        >
                                                            <path
                                                                id="noClose"
                                                                d="M5.625 9.675L7.65 11.7L12.375 6.975M15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9Z"
                                                                stroke="#1AB889"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Разблокировать
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                        {
                                            workmodal &&
                                            <div className={styles.changeWorkBlock}>
                                                <p className={styles.blockTitle}>Изменить должность</p>
                                                <div className={styles.list}>
                                                    {allWorks.map((check) => (
                                                        <div className={styles.checkItem} key={check.id}>
                                                            <button
                                                                className={`${styles.checkbox} ${Array.isArray(localActiveList) && localActiveList.includes(check.name) ? styles.active : ''
                                                                    }`}
                                                                onClick={() => {
                                                                    if (Array.isArray(localActiveList) && localActiveList.includes(check.name)) {
                                                                        // Убираем должность из списка
                                                                        setLocalActiveList(localActiveList.filter(item => item !== check.name));
                                                                    } else {
                                                                        // Добавляем должность в список
                                                                        setLocalActiveList([...(localActiveList || []), check.name]);
                                                                    }
                                                                }}
                                                                id="noClose"
                                                            >
                                                                {Array.isArray(localActiveList) && localActiveList.includes(check.name) && (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="12"
                                                                        height="10"
                                                                        viewBox="0 0 12 10"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M4.33268 9.58301L0.166016 5.44968L1.49102 4.16634L4.33268 6.95801L10.5077 0.833008L11.8327 2.14967L4.33268 9.58301Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                )}
                                                            </button>
                                                            <span id="noClose" className={styles.info}>
                                                                <p id="noClose"
                                                                    className={styles.checkTitle}>{check.name}</p>
                                                                <p id="noClose" className={styles.checkText}>
                                                                    {check.description}
                                                                </p>
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                                <button className={styles.save}
                                                    onClick={() => changeWork(localActiveList, item.id)}>Сохранить
                                                </button>
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        )) : <p className={styles.noStaff}>Добавьте сотрудника</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountStaff;
