import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import MarketplacesSwitch from "../../../../Logistics/ui/MarketplacesSwitch/index.jsx";
import Table from "../../../../../components/custom/Table.jsx";
import HeaderTable from "../../../../../components/custom/HeaderTable.jsx";
import HeaderTableItem from "../../../../../components/custom/HeaderTableItem.jsx";
import BodyTable from "../../../../../components/custom/BodyTable.jsx";
import BodyTableLine from "../../../../../components/custom/BodyTableLine.jsx";
import BodyTableLineItem from "../../../../../components/custom/BodyTableLineItem.jsx";
import { formatNumber2 } from "../../../../../static/js/commonFunc";
import { API_URL, getData } from '../../../../../static/js/request';
import { useParams } from 'react-router-dom';

export const ProjectTable = () => {

    const [allTabs, setAllTabs] = useState([
        {
            id: 0,
            name: 'Текущий месяц'
        },
        {
            id: 1,
            name: 'Последние 6 месяцев'
        }
    ]);

    const [activeTab, setActiveTabs] = useState(0);
    const [info, setInfo] = useState({})

    const [loading, setLoading] = useState(true)

    const [halfValues, setHalfValues] = useState([])

    const params = useParams()

    const getInfo = async () => {
        setLoading(true)
        const response = await getData(API_URL + `/api/v1/projects/${params.id}/table/${activeTab === 1 ? `?range=${activeTab === 0 ? '' : 'half_year'}` : ''}`)
        setInfo(response.data)
        setHalfValues(response.data.res.map((item) => {
            return Object.keys(item).map((temp, index) => {
                if (index > 3 && index < 16) {
                    return {
                        name: Object.keys(item)[index],
                        value: Object.values(item)[index]
                    }
                }

            }).filter(temp => temp)
        }))
        setLoading(false)
    }

    useEffect(() => {
        getInfo()
    }, [activeTab])


    useEffect(() => {
        console.log(halfValues.map(item => {
            return item.map(temp => temp.value)
        }))
    }, [halfValues])



    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <p className={styles.title}>Таблица инвестиций</p>
                <MarketplacesSwitch values={allTabs} active={activeTab} setActive={setActiveTabs} />
            </div>
            {
                loading ?
                    <p>Закгрузка...</p> :
                    <div className={styles.tables}>
                        <div className={styles.mainTable}>
                            <Table>
                                <HeaderTable items={'analyticItem'}>
                                    <HeaderTableItem name={'Артикул WB'} filter />
                                    <HeaderTableItem name={'Поставщик'} filter />
                                </HeaderTable>
                                <div className={`${styles.bodyWrap} ${styles.full}`}>
                                    <BodyTable>
                                        {
                                            info?.res?.map(item => (
                                                <BodyTableLine items={'analyticItem'}>
                                                    <BodyTableLineItem value={<p style={{ textAlign: 'center', paddingRight: '40px' }}>{item.article.art_WB ? item.article.art_WB : item.article.art_OZON}</p>} />
                                                    <BodyTableLineItem value={<p style={{ textAlign: 'center' }}>{item.article_sup.art_WB ? item.article_sup.art_WB : item.article_sup.art_OZON}</p>} />
                                                </BodyTableLine>
                                            ))
                                        }
                                        <BodyTableLine items={'result'}>
                                            <BodyTableLineItem value={<p style={{ textAlign: 'center' }}>Итого</p>} />
                                        </BodyTableLine>
                                    </BodyTable>
                                </div>
                            </Table>
                        </div>
                        <div className={styles.xWrap}>
                            <Table>
                                <HeaderTable items={'analyticItem'}>
                                    <HeaderTableItem additionalClass={'price-header'} name={'Инвестиции'} filter />
                                    <HeaderTableItem additionalClass={'price-header'} name={'Инвестиции\n' + 'ШТ'} filter />
                                    {
                                        activeTab === 0 &&
                                        <>
                                            <HeaderTableItem additionalClass={'price-header'} name={'тЕКУЩИЙ МЕС. ШТ'} filter />
                                            <HeaderTableItem additionalClass={'price-header'} name={'тЕКУЩИЙ МЕС. рУБ'} filter />
                                        </>
                                    }
                                    {
                                        activeTab === 1 &&
                                        halfValues[0].map(item => item.name).map(item => (
                                            <HeaderTableItem additionalClass={'price-header'} name={item} filter />
                                        ))
                                    }
                                    <HeaderTableItem additionalClass={'price-header'} name={'вАЛОВАЯ ПРИБЫЛЬ'} filter />
                                    <HeaderTableItem additionalClass={'price-header'} name={'пРОЦЕНТ ВОЗВРАТА ИНВЕСТИЦИЙ'} filter />
                                    <HeaderTableItem additionalClass={'price-header'} name={'оСТАТКИ ТОВАРА'} filter />
                                    <HeaderTableItem additionalClass={'price-header'} name={'пРОЦЕНТ ВЫПОЛНЕНИЯ'} filter />
                                </HeaderTable>
                                <div className={styles.bodyWrap}>
                                    <BodyTable>
                                        {
                                            info?.res?.map((item, index) => {
                                                return (
                                                    <BodyTableLine items={'analyticItem'}>
                                                        <BodyTableLineItem additionalClass={'price'} value={formatNumber2(item.investment_rub)} />
                                                        <BodyTableLineItem additionalClass={'price'} value={item.investment_pcs} />
                                                        {
                                                            activeTab === 0 &&
                                                            <>
                                                                <BodyTableLineItem additionalClass={'price'} value={item.current_pcs} />
                                                                <BodyTableLineItem additionalClass={'price'} value={formatNumber2(item.current_rub)} />
                                                            </>
                                                        }
                                                        {
                                                            activeTab === 1 &&
                                                            <>
                                                                {
                                                                    Object.values(item).map((temp, index) => {
                                                                        if (index > 3 && index < 16) {
                                                                            return temp
                                                                        }
                                                                    }).filter(temp => temp).map(value => (
                                                                        <BodyTableLineItem additionalClass={'price'} value={value} />
                                                                    ))
                                                                }
                                                            </>
                                                        }
                                                        <BodyTableLineItem additionalClass={'price'} value={formatNumber2(item.gross_profit_rub)} />
                                                        <BodyTableLineItem additionalClass={'price'} value={`${item.percent_return_investment * 100}%`} />
                                                        <BodyTableLineItem additionalClass={'price'} value={item.stock} />
                                                        <BodyTableLineItem additionalClass={'price'} value={`${item.percent_completion * 100}%`} />
                                                    </BodyTableLine>
                                                )
                                            })
                                        }
                                        <BodyTableLine items={'result'}>
                                            <BodyTableLineItem additionalClass={'price'} value={formatNumber2(info?.total?.investment_rub)} />
                                            <BodyTableLineItem additionalClass={'price'} value={info?.total?.investment_pcs} />
                                            {
                                                activeTab === 0 &&
                                                <>
                                                    <BodyTableLineItem additionalClass={'price'} value={info?.total?.current_pcs} />
                                                    <BodyTableLineItem additionalClass={'price'} value={formatNumber2(info?.total?.current_rub)} />
                                                </>
                                            }
                                            {
                                                activeTab === 1 &&
                                                <>
                                                    {
                                                        Object.values(info?.total).map((temp, index) => {
                                                            if (index > 1 && index < 14) {
                                                                return temp
                                                            }
                                                        }).filter(temp => temp).map(value => {
                                                            console.log(value)
                                                            return <BodyTableLineItem additionalClass={'price'} value={value} />
                                                        })
                                                    }
                                                </>
                                            }
                                            <BodyTableLineItem additionalClass={'price'} value={formatNumber2(info?.total?.gross_profit_rub)} />
                                            <BodyTableLineItem additionalClass={'price'} value={`${info?.total?.percent_return_investment * 100}%`} />
                                            <BodyTableLineItem additionalClass={'price'} value={info?.total?.stock} />
                                            <BodyTableLineItem additionalClass={'price'} value={`${info?.total?.percent_completion * 100}%`} />
                                        </BodyTableLine>
                                    </BodyTable>
                                </div>
                            </Table>
                        </div>
                    </div>

            }
        </div>
    );
};
