import { makeAutoObservable } from 'mobx';
import {
    API_URL,
    getData,
    postData,
    postDataFile,
} from '../../static/js/request';
import { confirmAlert } from 'react-confirm-alert';
import React, { useState } from 'react';
import { Store } from 'react-notifications-component';
import { format } from 'date-fns';
import ImportExportButton from '../../components/custom/ButtonOpeningFile.jsx';
import { createNotification } from '../../static/js/commonFunc';

const AddedLoans = (props) => {
    const [state] = useState(() => new LoansStore());

    const [showTemplateButton, setShowTemplateButton] = useState(false);

    const onLenderChange = (event) => {
        setShowTemplateButton(event.target.value === 'Частное лицо');
        state.handleChange(event);
    };

    const handleDownloadTemplate = () => {
    // Реализуйте функцию скачивания файла шаблона здесь.
    };

    return (
        <div>
            <div className="add-loans">
                <div className="add-loans__title">Добавление займа/кредита</div>
                <div className="add-loans__inputs-container">
                    <label>
                        <span>Кто выдал займ</span>
                        <select
                            name="lender"
                            defaultValue={'default'}
                            onChange={(event) => onLenderChange(event)}
                        >
                            <option value={'default'} disabled>
                                Выберите из списка
                            </option>
                            <option value="Частное лицо">Частное лицо</option>
                            <option value="ООО СИПЛФИНАНС">ООО СИПЛФИНАНС</option>
                            <option value="Сбер">Сбер</option>
                            <option value="Тинькоф">Тинькоф</option>
                        </select>
                    </label>

                    <label>
                        <span>Название займа</span>
                        <input
                            type="text"
                            placeholder=""
                            name="name"
                            className="add-loans__input"
                            value={props.loansField.name}
                            onChange={(event) => onLenderChange(event)}
                        />
                    </label>
                </div>

                <div className="add-loans__inputs-container">
                    <label>
                        <span>Выбор организации</span>
                        <select
                            name="organisation_id"
                            defaultValue={'default'}
                            onChange={(event) => onLenderChange(event)}
                        >
                            <option value={'default'} disabled>
                                Выберите организацию
                            </option>
                            {props.organisation.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label>
                        <span>Дата получения денег</span>
                        <input
                            type="date"
                            placeholder=""
                            name="date_provision"
                            className="add-loans__input"
                            value={props.loansField.date_provision}
                            onChange={(event) => onLenderChange(event)}
                        />
                    </label>
                </div>
            </div>
            <div className="add-loans__btn-container">
                <ImportExportButton
                    label={
                        <>
                            <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.0238 3.26834C12.8813 3.15084 12.6925 3.10209 12.51 3.13584L2.51 5.01084C2.21375 5.06584 2 5.32334 2 5.62459V19.3746C2 19.6746 2.21375 19.9334 2.51 19.9884L12.51 21.8634C12.5475 21.8709 12.5875 21.8746 12.625 21.8746C12.7688 21.8746 12.9113 21.8246 13.0238 21.7309C13.1675 21.6121 13.25 21.4346 13.25 21.2496V3.74959C13.25 3.56334 13.1675 3.38709 13.0238 3.26834ZM12 20.4959L3.25 18.8559V6.14334L12 4.50334V20.4959Z"
                                    fill="white"
                                />
                                <path
                                    d="M21.375 5.62463H12.625C12.28 5.62463 12 5.90463 12 6.24963C12 6.59463 12.28 6.87463 12.625 6.87463H20.75V18.1246H12.625C12.28 18.1246 12 18.4046 12 18.7496C12 19.0946 12.28 19.3746 12.625 19.3746H21.375C21.72 19.3746 22 19.0946 22 18.7496V6.24963C22 5.90463 21.72 5.62463 21.375 5.62463Z"
                                    fill="white"
                                />
                                <path
                                    d="M15.125 8.12463H12.625C12.28 8.12463 12 8.40463 12 8.74963C12 9.09463 12.28 9.37463 12.625 9.37463H15.125C15.47 9.37463 15.75 9.09463 15.75 8.74963C15.75 8.40463 15.47 8.12463 15.125 8.12463Z"
                                    fill="white"
                                />
                                <path
                                    d="M15.125 10.6246H12.625C12.28 10.6246 12 10.9046 12 11.2496C12 11.5946 12.28 11.8746 12.625 11.8746H15.125C15.47 11.8746 15.75 11.5946 15.75 11.2496C15.75 10.9046 15.47 10.6246 15.125 10.6246Z"
                                    fill="white"
                                />
                                <path
                                    d="M15.125 13.1246H12.625C12.28 13.1246 12 13.4046 12 13.7496C12 14.0946 12.28 14.3746 12.625 14.3746H15.125C15.47 14.3746 15.75 14.0946 15.75 13.7496C15.75 13.4046 15.47 13.1246 15.125 13.1246Z"
                                    fill="white"
                                />
                                <path
                                    d="M15.125 15.6246H12.625C12.28 15.6246 12 15.9046 12 16.2496C12 16.5946 12.28 16.8746 12.625 16.8746H15.125C15.47 16.8746 15.75 16.5946 15.75 16.2496C15.75 15.9046 15.47 15.6246 15.125 15.6246Z"
                                    fill="white"
                                />
                                <path
                                    d="M18.875 8.12463H17.625C17.28 8.12463 17 8.40463 17 8.74963C17 9.09463 17.28 9.37463 17.625 9.37463H18.875C19.22 9.37463 19.5 9.09463 19.5 8.74963C19.5 8.40463 19.22 8.12463 18.875 8.12463Z"
                                    fill="white"
                                />
                                <path
                                    d="M18.875 10.6246H17.625C17.28 10.6246 17 10.9046 17 11.2496C17 11.5946 17.28 11.8746 17.625 11.8746H18.875C19.22 11.8746 19.5 11.5946 19.5 11.2496C19.5 10.9046 19.22 10.6246 18.875 10.6246Z"
                                    fill="white"
                                />
                                <path
                                    d="M18.875 13.1246H17.625C17.28 13.1246 17 13.4046 17 13.7496C17 14.0946 17.28 14.3746 17.625 14.3746H18.875C19.22 14.3746 19.5 14.0946 19.5 13.7496C19.5 13.4046 19.22 13.1246 18.875 13.1246Z"
                                    fill="white"
                                />
                                <path
                                    d="M18.875 15.6246H17.625C17.28 15.6246 17 15.9046 17 16.2496C17 16.5946 17.28 16.8746 17.625 16.8746H18.875C19.22 16.8746 19.5 16.5946 19.5 16.2496C19.5 15.9046 19.22 15.6246 18.875 15.6246Z"
                                    fill="white"
                                />
                                <path
                                    d="M10.5961 14.5883L6.22108 9.58827C5.99108 9.32702 5.59733 9.30202 5.33858 9.52952C5.07858 9.75702 5.05233 10.152 5.27983 10.4108L9.65483 15.4108C9.77858 15.552 9.95108 15.6245 10.1248 15.6245C10.2711 15.6245 10.4173 15.5733 10.5373 15.4695C10.7973 15.242 10.8236 14.8483 10.5961 14.5883Z"
                                    fill="white"
                                />
                                <path
                                    d="M10.5088 8.88206C10.2363 8.66831 9.84377 8.71956 9.63127 8.99081L5.25627 14.6158C5.04502 14.8883 5.09377 15.2821 5.36627 15.4933C5.48127 15.5821 5.61627 15.6246 5.75002 15.6246C5.93502 15.6246 6.12002 15.5421 6.24252 15.3846L10.6175 9.75956C10.83 9.48581 10.7813 9.09331 10.5088 8.88206Z"
                                    fill="white"
                                />
                            </svg>
                            Загрузить график платежей
                        </>
                    }
                    name={'file'}
                    onSubmitFile={(event) => onLenderChange(event)}
                    buttonClassName="add-loans__btn-container_btn-load"
                />

                {showTemplateButton && (
                    <button
                        onClick={handleDownloadTemplate}
                        className="add-loans__btn-container_btn-save"
                    >
                        Скачать шаблон
                    </button>
                )}
            </div>
        </div>
    );
};

export class LoansStore {
    fields = [];
    organisation = null;
    tableView = null;
    summaryView = null;
    total_paid = null;
    total_remaining = null;
    total_loan_amount = null;
    showData = false;
    loansField = {
        loan_currency: 'RUB',
    };
    formData = '';

    constructor() {
        makeAutoObservable(this);
        this.fillValue();
        this.formData = new FormData();
    }

    resetLoansFields = () => {
        console.log(this.loansField);
        this.loansField = {
            loan_currency: 'RUB',
            name: '',
            lender: '',
            date_provision: '',
            file: null,
            organisation_id: '',
        };
    };

    fillValue = () => {
        getData(API_URL + '/api/v1/organisation/').then((data) => {
            this.organisation = data;
        });

        getData(API_URL + '/api/v1/loan/table/').then((data) => {
            this.tableView = data;
        });

        getData(API_URL + '/api/v1/loan/organisations/').then((data) => {
            if (data.organisations.length >= 1) this.showData = true;
            this.summaryView = data.organisations;
            this.total_paid = data.total_paid;
            this.total_remaining = data.total_remaining;
            this.total_loan_amount = data.total_loan_amount;
        });
    };

    handleChange = (evt) => {
        const target = evt.target;
        const name = target.name;

        if (name === 'date_provision') {
            console.log(target.value);
            const dArr = target.value.split('-');
            this.loansField[name] = dArr[2] + '.' + dArr[1] + '.' + dArr[0];
            return;
        }
        if (name === 'file') {
            const file = target.files[0];
            const fileName = file.name;
            console.log(file);
            console.log(fileName);
            this.formData.append([name], file, fileName);
            this.loansField[name] = file;
            return;
        }
        this.loansField[name] = target.value;
    };

    handleDownloadTemplate = () => {
        console.log(1);
    };

    handleSubmit = (onClose) => {
        const formData = new FormData();
        console.log(formData);
        formData.append('organisation_id', this.loansField['organisation_id']);
        formData.append('name', this.loansField['name']);
        formData.append('lender', this.loansField['lender']);
        formData.append('file', this.loansField['file']);
        formData.append('date_provision', this.loansField['date_provision']);
        formData.append('loan_currency', 'RUB');
        console.log(formData);

    // postDataFile(API_URL + '/api/v1/loans/create/', formData).then(
    //   (response) => {
    //     console.log(response);
    //     onClose();
    //     createNotification(response);
    //   }
    // );
    };

    addedLoans = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body w-800">
                        <React.Fragment>
                            <AddedLoans
                                loansField={this.loansField}
                                organisation={this.organisation}
                                handleSubmit={this.handleSubmit}
                            />
                            <div className="react-confirm-alert-button-group">
                                <button
                                    className="complete"
                                    onClick={() => {
                                        this.handleSubmit(onClose);
                                    }}
                                >
                                    Сохранить
                                </button>
                                <button
                                    className="cancel"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Отменить
                                </button>
                            </div>
                        </React.Fragment>
                    </div>
                );
            },
        });
    };
}
