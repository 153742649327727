import React from 'react';
import ArticleContent from "./ui/ArticleContent/ArticleContent.jsx";

const Article = () => {
    return (
        <div style={{overflowY: 'auto', position: 'relative', width: '100svw', left: '-15px'}}>
            <ArticleContent/>
        </div>
    );
};
export default Article;
