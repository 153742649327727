import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "../../icons/close.jsx";
import Arrow from "../../icons/arrow.jsx";

const SizeButton = ({ action, show = true }) => {

    const [opened, toggle] = useState(false)

    const handleClick = () => {
        toggle(prev => !prev)
        action(prev => !prev)
    }

    return (
        show ?
            null
            :
            <button onClick={() => handleClick()}>
                {
                    opened ?
                        <CloseIcon />
                        :
                        <Arrow />

                }
            </button>
    )

}

export default SizeButton