import React, { useEffect, useRef, useState } from 'react';
import style from './FinancingReport.module.scss';
import { getData, postData, API_URL } from '../../static/js/request';
import { onMainScroll } from '../Advertisement/index.jsx';
import { useLocation } from 'react-router-dom';
import YearSelect from '../Balance/YearSelect/index.jsx';
import ReportTable from './Table.jsx'

const counterFormat = (counter) => {
    const nf = Intl.NumberFormat();
    return nf.format(counter);
};

const FinancingReport = () => {
    const [rerender, setRerender] = useState(false);

    const [listMonthValue, setListMonthValue] = useState([]);
    const [pnlValue, setPnlValue] = useState([]);
    const [pnlDate, setPnlDate] = useState([]);
    const [pnlDataValue, setPnlDataValue] = useState([]);

    const [year, changeYear] = useState(2024);
    const [month, changeMonth] = useState(null);

    const [selectYear, setSelectYear] = useState([]);
    const [selectMonth, setSelectMonth] = useState([]);
    const [selectMP, setSelectMP] = useState([]);

    const [isActive, setIsActive] = useState(false);
    const [isActiveNested, setIsActiveNested] = useState(false);

    const [stickyRefs, setStickyRefs] = useState([]);

    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [allYears, setAllYears] = useState([]);

    const getPnlData = async () => {
        try {
            setLoading(true);
            const data = await getData(API_URL + '/api/v1/report/pnl/');
            setPnlValue(data);
            setPnlDate(data.date);
            setAllYears(data.years);
            setPnlDataValue(data.data);
            setStickyRefs(
                Array(Object.entries(data.data).length)
                    .fill()
                    .map(() => React.createRef())
            );
            setLoading(false);
        } catch (error) { }
    };

    React.useEffect(() => {
        getPnlData();
    }, [location]);

    const getNewData = async(newMonth, newYear) => {

        try {
            setLoading(true);            
            const data = await getData(
                API_URL +
                `/api/v1/report/pnl/?year=${newYear || year}${newMonth
                    ? `&month=${newMonth}`
                    : ''
                }`
            );
            setPnlValue(data);
            setPnlDate(data.date);
            setPnlDataValue(data.data);
            setStickyRefs(
                Array(Object.entries(data.data).length)
                    .fill()
                    .map(() => React.createRef())
            );
            changeMonth(newMonth)
            setLoading(false);
        } catch (error) {
            setRerender(null);
            setLoading(false);
        }

    }

    /*const getNewData = async (evt, newYear) => {

        console.log(evt)

        if (evt?.target.textContent) {
            setRerender(evt.target.textContent);
        }
        if (evt?.target.textContent === rerender) {
            getPnlData();
            setRerender(evt.target);
            return;
        } else {
            try {
                setLoading(true);
                const data = await getData(
                    API_URL +
                    `/api/v1/report/pnl/?year=${newYear || year}${evt && evt.target.textContent
                        ? `&month=${evt.target.textContent}`
                        : ''
                    }`
                );
                setPnlValue(data);
                setPnlDate(data.date);
                setPnlDataValue(data.data);
                setStickyRefs(
                    Array(Object.entries(data.data).length)
                        .fill()
                        .map(() => React.createRef())
                );
                setLoading(false);
            } catch (error) {
                setRerender(null);
                setLoading(false);
            }
        }
    };*/    

    return (
        <>
            <h1 className={style.title}>PnL</h1>
            <main className={style.main}>
                <div className={style.details}>
                    <div>Отчетный год</div>
                    <YearSelect
                        allValues={allYears}
                        width={'120px'}
                        onChange={(year) => {
                            changeYear(year);
                            getNewData(null, year);
                        }}
                        value={year}
                    />
                </div>
                {
                    pnlDataValue && Object.entries(pnlDataValue).length > 0 ?
                        <ReportTable
                            dateList={pnlDate}
                            dataList={pnlDataValue}
                            loading={loading}
                            currentMonth={month}
                            action={(month,year) => getNewData(month,year)} />
                            
                        :
                        <p style={{ marginTop: '30px' }} className={style.empty}>Нет данных</p>
                }
            </main>
        </>
    );
};

export default FinancingReport;
