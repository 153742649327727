import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AnswersConstructor from './ui/AnswersConstructor/index.jsx';
import StopWords from './ui/StopWords/index.jsx';
import KeyWords from './ui/KeyWords/index.jsx';
import Notices from './ui/Notices/index.jsx';
import Settings from './ui/Settings/index.jsx';
import { API_URL, getData, putData } from '../../../static/js/request';
import ChangeTitle from '../../../components/custom/ChangeTitle/index.jsx';

function AnsweringEdit() {
  const params = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [defaultName, setDefaultName] = useState('');

  const [id, setId] = useState();

  const [projectInfo, setProjectInfo] = useState({
    id: 1,
    name: '',
  });

  const tabs = [
    'Конструктор ответа',
    'Стоп-слова',
    'Ключевые слова',
    'Уведомление о негативах',
  ];

  const [activeTab, setActiveTab] = useState('Конструктор ответа');

  const [settings, setSettings] = useState(false);

  const getName = async () => {
    try {
      const response = await getData(
        API_URL + `/api/v1/answering_machine/constructor/${params.id}/name/`
      );
      setProjectInfo({
        name: response.name,
      });
      setDefaultName(response.name);
    } catch (error) {
      consonole.log(error);
    }
  };

  const changeName = async () => {
    try {
      const response = await putData(API_URL + `/api/v1/answering_machine/constructors/`, {
        name: projectInfo.name,
        constructor_id: params.id,
      });
    } catch (error) {
      console.log(response);
    }
  };

  useEffect(() => {
    getName();
    if (params.type === 'stopwords') {
      setActiveTab('Стоп-слова');
      setSettings(false);
    } else if (params.type === 'keywords') {
      setActiveTab('Ключевые слова');
      setSettings(false);
    } else if (params.type === 'notices') {
      setActiveTab('Уведомление о негативах');
      setSettings(false);
    } else if (params.type === 'settings') {
      setSettings(true);
    } else {
      setActiveTab('Конструктор ответа');
      setSettings(false);
    }
    setId(params.id);
  }, [params, location]);

  return (
    <section className={styles.section}>
      <ChangeTitle
        onBlur={() => {
          if (projectInfo.name !== defaultName) {
            changeName();
          }
        }}
        title={projectInfo.name}
        onChange={(text) => setProjectInfo({ ...projectInfo, name: text })}
        arrowFunc={() => {
          if (params.type === 'settings') {
            window.history.back();
          } else {
            navigate('/scheduler/answering/');
          }
        }}
      />
      <div className={styles.content}>
        {settings ? (
          <Settings />
        ) : (
          <>
            <div className={styles.contentTop}>
              <div className={styles.tabs}>
                {tabs.map((item, index) => (
                  <button
                    onClick={() => {
                      navigate(
                        `/scheduler/answering/item/${params.id}/${
                          item === 'Стоп-слова'
                            ? 'stopwords'
                            : item === 'Ключевые слова'
                            ? 'keywords'
                            : item === 'Уведомление о негативах'
                            ? 'notices'
                            : 'constructor'
                        }`
                      );
                    }}
                    className={`${styles.tab} ${
                      item === activeTab ? styles.active : ''
                    }`}
                    key={index}
                  >
                    {item}
                  </button>
                ))}
              </div>
              <button
                onClick={() =>
                  navigate(`/scheduler/answering/item/${params.id}/settings`)
                }
                className={styles.settings}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.8954 9.87455L19.4607 9.56266C19.336 9.18071 19.1815 8.80815 18.999 8.44926L19.7933 7.21381C19.9351 6.99306 19.9039 6.70325 19.7185 6.51782L17.4834 4.28276C17.298 4.09733 17.0082 4.06614 16.7875 4.20799L15.552 5.00224C15.1931 4.8198 14.8206 4.66528 14.4386 4.54053L14.1267 3.10583C14.071 2.84949 13.844 2.66663 13.5817 2.66663H10.4209C10.1586 2.66663 9.93158 2.84949 9.8759 3.10583L9.56401 4.54053C9.18205 4.66528 8.80949 4.8198 8.4506 5.00224L7.21515 4.20799C6.99441 4.06614 6.70459 4.09733 6.51917 4.28276L4.2841 6.51782C4.09867 6.70325 4.06748 6.99306 4.20933 7.21381L5.00358 8.44926C4.82115 8.80815 4.66663 9.18071 4.54187 9.56266L3.10718 9.87455C2.85083 9.93038 2.66797 10.1572 2.66797 10.4196V13.5803C2.66797 13.8427 2.85083 14.0695 3.10718 14.1254L4.54187 14.4373C4.66663 14.8192 4.82115 15.1918 5.00358 15.5507L4.20933 16.7861C4.06748 17.0069 4.09867 17.2967 4.2841 17.4821L6.51917 19.7172C6.70459 19.9026 6.99441 19.9338 7.21515 19.7919L8.4506 18.9977C8.80949 19.1801 9.18205 19.3346 9.56401 19.4594L9.8759 20.8941C9.93158 21.1504 10.1586 21.3333 10.4209 21.3333H13.5817C13.844 21.3333 14.071 21.1504 14.1267 20.8941L14.4386 19.4594C14.8206 19.3346 15.1931 19.1801 15.552 18.9977L16.7875 19.7919C17.0082 19.9338 17.298 19.9027 17.4834 19.7172L19.7185 17.4821C19.9039 17.2967 19.9351 17.0069 19.7933 16.7861L18.999 15.5507C19.1815 15.1918 19.336 14.8192 19.4607 14.4373L20.8954 14.1254C21.1518 14.0695 21.3346 13.8427 21.3346 13.5803V10.4196C21.3346 10.1572 21.1518 9.93038 20.8954 9.87455ZM15.3479 12C15.3479 13.8452 13.8466 15.3466 12.0013 15.3466C10.156 15.3466 8.65468 13.8452 8.65468 12C8.65468 10.1547 10.156 8.65334 12.0013 8.65334C13.8466 8.65334 15.3479 10.1547 15.3479 12Z"
                    fill="#429EFF"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                </svg>
                Настройки
              </button>
            </div>
            {activeTab === 'Конструктор ответа' ? (
              <AnswersConstructor />
            ) : activeTab === 'Стоп-слова' ? (
              <StopWords />
            ) : activeTab === 'Ключевые слова' ? (
              <KeyWords />
            ) : activeTab === 'Уведомление о негативах' ? (
              <Notices />
            ) : null}
          </>
        )}
      </div>
    </section>
  );
}

export default AnsweringEdit;
