import React, { useRef , useState } from "react";
import TableLine from "./tableLine.jsx";
import Sizes from "./sizes.jsx";


const TableRow = ({data, columnsVisibility, index, scrollContainerRef})  => {
    
    const [showSizes, setShowSizes] = useState(false);    

    return (

        <>
            <TableLine data={data} scrollContainerRef={scrollContainerRef} index={index} action={(value) => setShowSizes(value)} columnsVisibility={columnsVisibility} />

            {
                showSizes && <Sizes scrollContainerRef={scrollContainerRef} columnsVisibility={columnsVisibility} />

            }

        </>

    )

}

export default TableRow;