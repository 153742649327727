import React, { useState, useEffect, useRef } from 'react';
import style from '../Financing.module.scss';
import Table from '../../../components/custom/Table.jsx';
import HeaderTable from '../../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../../components/custom/HeaderTableItem.jsx';
import BodyTable from '../../../components/custom/BodyTable.jsx';
import BanksBody from './BanksBody.jsx';
import useOnClickOutside from '../../../core/Hooks/useOnClickOutside';
import { observer } from 'mobx-react-lite';
import YearSelect from '../../Balance/YearSelect/index';
import { ArrowUp } from 'lucide-react';
import { Pagination, PaginationItem } from '@mui/material';
import { Button } from '@/fsd/shared/ui';

const Banks = observer(() => {
    const tabs = ['Все', 'Разнесенные', 'Не разнесенные'];
    const [filterGroup, setFilterGroup] = useState('Все');
    const [isSelectDataVisible, setIsSelectDataVisible] = useState(false);
    const selectDataContainerRef = useRef(null);

    useOnClickOutside(selectDataContainerRef, () =>
        setIsSelectDataVisible(false)
    );

    const [data, setData] = useState([]);

    const [totalPages, setTotalPages] = useState(0);

    const [filterPeriod, setPeriodFilter] = useState('Все время');
    const [page, setPage] = useState(1);

    const handlePageChange = (event, value) => { setPage(value) };

    useEffect(() => {
        (async () => {
            const result = await FetchData()
            const data = await result.json();
            setTotalPages(data.meta.total_pages);
            setData(data.data)
        })();
    }, [page, filterGroup]);

    async function FetchData() {
        return await fetch('https://busyboard-test.ru/api/v1/bank/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                "paginator": {
                    "page_current": page,
                    "page_size": 10
                },
            }),
        });
    };


    return (
        <main>
            <div className={style.top}>
                <div className={style.title}>Банки</div>
                <button
                    onClick={() => navigation('/financing/rules/')}
                    className={style.rulesBtn}
                >
                    Правила
                </button>
            </div>
            <section className={style.section}>
                <div className={style.section__filter}>
                    <div className={style.filter}>
                        Период:
                        <YearSelect
                            value={filterPeriod}
                            onChange={setPeriodFilter}
                            allValues={['Все время', 'Месяц', 'Квартал', 'Год']}
                            width={'150px'}
                        />
                    </div>
                    <div className={style.tabs}>
                        {tabs.map((item) => (
                            <Button
                                key={item}
                                onClick={() => setFilterGroup(item)}
                                className={`${style.tab} ${filterGroup === item ? style.active : ''}`}
                            >
                                {item}
                            </Button>
                        ))}
                    </div>
                </div>
                {
                    data.length > 0 ?
                        <Table>
                            <HeaderTable items="banks">
                                <HeaderTableItem
                                    name="Дата"
                                    filter="true"
                                    filterEvent={() => {
                                        showSelectData();
                                    }}
                                    filterValue={
                                        isSelectDataVisible && (
                                            <div
                                                className={style.selectData}
                                                ref={selectDataContainerRef}
                                            >
                                                <div
                                                    className={style.sortingTitle}
                                                    onClick={() => {
                                                        data.sortByAscendingDate();
                                                        setIsSelectDataVisible(false);
                                                    }}
                                                >
                                                    Сортировка по возрастанию
                                                    <ArrowDown />
                                                </div>
                                                <div
                                                    className={style.sortingTitle}
                                                    onClick={() => {
                                                        data.sortByDescendingDate();
                                                        setIsSelectDataVisible(false);
                                                    }}
                                                >
                                                    Сортировка по убыванию
                                                    <ArrowUp />
                                                </div>
                                            </div>
                                        )
                                    }
                                />
                                <HeaderTableItem style={{ width: '115px' }} name="Приход" filter="true" />
                                <HeaderTableItem style={{ width: '140px' }} name="Расход" filter="true" />
                                <HeaderTableItem style={{ width: '140px' }} name="Банк" filter="true" />
                                <HeaderTableItem style={{ width: '350px' }} name="Контрагент" filter="true" />
                                <HeaderTableItem style={{ width: '300px' }} name="Статьи" filter="true" />
                                <HeaderTableItem style={{ width: '300px' }} name="Описание" filter="true" />
                            </HeaderTable>
                            <BodyTable>
                                <BanksBody state={data} />
                            </BodyTable>
                        </Table> :
                        <p style={{ marginTop: '30px' }} className={style.emptyData}>Нет данных</p>
                }
                <Pagination
                    count={totalPages}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                    renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                '&.Mui-selected': {
                                    backgroundColor: 'rgba(66, 158, 255, 1)',
                                    color: '#fff',
                                },
                                '&.Mui-selected:hover': {
                                    backgroundColor: 'rgba(234, 245, 255, 1)',
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(234, 245, 255, 1)',
                                    color: 'rgba(53, 55, 58, 1)'
                                },
                            }}
                        />
                    )}
                />
            </section>
        </main>
    );
});
export default Banks;
