// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M6pDS1D7vb5DnBo1eq3i {\n  display: flex;\n  justify-content: space-between;\n}\n\n.M6pDS1D7vb5DnBo1eq3i a {\n  text-decoration: none;\n  color: #429EFF;\n}", "",{"version":3,"sources":["webpack://./src/views/Account/Login/Login.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AACA;EACI,qBAAA;EACA,cAAA;AAEJ","sourcesContent":[".loginLinks {\n    display: flex;\n    justify-content: space-between;\n}\n.loginLinks a {\n    text-decoration: none;\n    color: #429EFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginLinks": "M6pDS1D7vb5DnBo1eq3i"
};
export default ___CSS_LOADER_EXPORT___;
