import React, { useRef } from 'react';
import style from '../../Purchase.module.scss';
import Filter from './filter/index.jsx';
import ListTable from './table/index.jsx';

const List = ({
  title,
  presetTable,
  newItemLink,
  filters,
  setFilters,
  columns,
  editColumns,
  data,
  setSortingBy,
  removeItem,
}) => {
  const filterRef = useRef(null);

  return (
    <>
      <h1 className={style.title}>{title}</h1>
      <main className={style.main}>
        <Filter
          presetTable={presetTable}
          filters={filters}
          setFilters={setFilters}
          newItemLink={newItemLink}
          columns={columns}
          editColumns={editColumns}
          filterRef={filterRef}
        />

        {data ? (
          Object.values(columns).every((column) => column.isShow === false) ? (
            <div className={style.empty}>Нет данных</div>
          ) : (
            <ListTable
              data={data}
              columns={columns}
              filterRef={filterRef}
              setSortingBy={setSortingBy}
              itemLink={newItemLink}
              removeItem={removeItem}
            />
          )
        ) : (
          <div className={style.empty}>Нет данных</div>
        )}
      </main>
    </>
  );
};

export default List;
