import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import PresetIcon from '../../../../../shared/ui/icons/Preset.jsx';
import style from '../../../Purchase.module.scss';
import PresetForm from './presetForm.jsx';

const PresetAction = ({ create, remove }) => {
  const [show, toggle] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (elementRef.current && !elementRef.current.contains(e.target)) {
        toggle(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={elementRef} style={{ position: 'relative' }}>
      <button
        onClick={() => toggle((prev) => !prev)}
        className={cx(style.topButton, style.preset, show && style.active)}
      >
        <PresetIcon />
      </button>

      {show && <PresetForm create={create} remove={remove} leftOutset="66px" />}
    </div>
  );
};

export default PresetAction;
