import React, { useEffect, useState } from 'react';
import { Calendar, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ru } from 'date-fns/locale';
import s from './DatePicker.module.scss';
import useDatePickerStore from '../../../store/Setting/datePickerStore';
import dayjs from 'dayjs';
import { postData, putDataNew } from '../../../static/js/request';
import { API_URL } from '../../../static/js/request';
import { Store } from 'react-notifications-component';
import { NewCustomInput } from '../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import styles from '../../Financing/Loans/CreateModal/styles.module.scss';
import usePriceHistoryStore from "../../../store/Setting/priceHistoryStore";

export default function DatePickerEdit({
    handleDatePicker,
    selectedProduct,
    disabled,
    item,
}) {

    const {
        updatePriceHistory,
    } = usePriceHistoryStore(item);

    const {
        startDateInput,
        setStartDateInput,
        endDateInput,
        setEndDateInput,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        price,
        setPrice,
        handleSelect,
        handleInput,
        handleReset,
        handleSubmit,
    } = useDatePickerStore(selectedProduct, updatePriceHistory);



    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    const changeDate = async () => {
        try {
            const response = putDataNew(
                `${API_URL}/api/v1/products/costs/history/`,
                {
                    product_cost_id: item.id,
                    purchase_price: price
                        ? parseFloat(price.replace(',', '.'))
                        : item.purchase_price,
                    start_date: startDate
                        ? dayjs(new Date(startDate)).format('YYYY-MM-DD')
                        : dayjs(new Date(item.start_date)).format('YYYY-MM-DD'),
                }
            );
            updatePriceHistory();
            Store.addNotification(response);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <section className={s.dp_block} onSubmit={handleSubmit}>
            <div className={s.dp_header}>
                <h4>Изменить себестоимость</h4>
                <button onClick={handleReset}>Сбросить</button>
            </div>
            <div className={s.cost_price}>
                <h4>Себестоимость</h4>
                <NewCustomInput
                    type={'text'}
                    value={!price ? parseInt(item.purchase_price).toFixed(0) : price}
                    onChange={setPrice}
                    placeholder="Цена ₽"
                    numeric
                />
            </div>

            <div className={s.calendar_block}>
                <div className={s.period}>
                    <input
                        type="text"
                        placeholder="Начало периода"
                        value={
                            startDateInput
                                ? startDateInput
                                : dayjs(new Date(item.start_date)).format('DD.MM.YYYY')
                        }
                        onChange={(e) =>
                            handleInput(e.target.value, setStartDateInput, setStartDate)
                        }
                    />
                </div>

                <div className={s.calendar}>
                    <Calendar
                        className={`${styles.calendar} calendar`}
                        onChange={(ranges) => {
                            handleSelect(ranges);
                        }}
                        date={startDate}
                        months={1}
                        fixedHeight={true}
                        locale={customLocale}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        direction="horizontal"
                        showDateDisplay={false}
                        defaultValue={startDate}
                    />
                </div>
            </div>

            <button
                disabled={(!price && !item.purchase_price) || price === '0'}
                className={s.submit}
                type="submit"
                onClick={() => {
                    changeDate();
                }}
            >
                Сохранить
            </button>
        </section>
    );
}
