import React, { useEffect, useRef } from "react";
import style from '../../../Purchase.module.scss'
import { Checkbox } from "@mui/material";
import LineSVG from "../../../../../shared/ui/icons/Line.jsx";

const TableSettings = ({ show, showSettings, columns, editColumns, showSettingsButtonRef }) => {

    const tableSettingsRef = useRef(null);

    const allColumnsSelected = Object.values(columns).every(column => column.isShow);

    const toggleColumnVisibility = (column) => {
        editColumns((prev) => ({
            ...prev,
            [column]: {
                ...prev[column],
                isShow: !prev[column].isShow,
            },
        }));
    };

    const toggleSelectAllColumns = () => {
        const newVisibility = !allColumnsSelected;
        const updatedColumnsVisibility = Object.keys(columns).reduce(
            (acc, column) => ({
                ...acc,
                [column]: {
                    ...columns[column],
                    isShow: newVisibility,
                },
            }),
            {}
        );
        editColumns(updatedColumnsVisibility);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tableSettingsRef.current && !tableSettingsRef.current.contains(event.target) && showSettingsButtonRef.current && !showSettingsButtonRef.current.contains(event.target)) {
                showSettings(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSettings]);

    return (

        show

        &&

        <div ref={tableSettingsRef} className={style.tableSettings}>
            <div className={style.header}>Настройка таблицы</div>

            <div className={style.column}>

                <div className={style.head}>
                    <span className={style.label}>Столбцы</span>
                    <LineSVG />
                    <span className={style.checkAll}>Выбрать все
                        <Checkbox
                            checked={allColumnsSelected}
                            onChange={() => toggleSelectAllColumns()}
                            color="primary"
                        />
                    </span>


                </div>

                {Object.values(columns).map((column,index) =>

                    <div key={index} className={style.item}>

                        <Checkbox
                            color="primary"
                            checked={column.isShow}
                            onChange={() => toggleColumnVisibility(column.value)}
                        />
                        <span>{column.label}</span>
                    </div>

                )}

            </div>

        </div>

    )

}

export default TableSettings