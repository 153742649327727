import React from 'react';
import { Store } from 'react-notifications-component';
import ErrorIcon from '../../shared/ui/icons/Error.jsx';
import WaitIcon from '../../shared/ui/icons/Wait.jsx';
import SuccessIcon from '../../shared/ui/icons/Success.jsx';
import style from '../css/popupNotification.module.scss';
import cx from 'classnames';

const getIcon = (status) => {
    switch (status) {
        case 'success':
            return <SuccessIcon />;
        case 'danger':
            return <ErrorIcon />;
        case 'info':
            return <WaitIcon />;
        default:
            break;
    }
};

const getActionText = (value,) => {
    switch (value) {
        case 'create':
            return 'создан';
        case 'update':
            return 'обновлен';
        case 'delete':
            return 'удален';
        default:
            break;

    }
};



export const popupNotificationV2 = (response, type, title) => {
    Store.addNotification({
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        title,
        message: response,
        type: type,
        dismiss: {
            duration: 5000
        },
        content: () => (
            <div className={style.main}>
                <div className={cx(style.icon, style[type])}>
                    {getIcon(type)}
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <strong>{title}</strong>
                    <p>{response}</p>
                </div>

            </div>
        )

    });

};