// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ctem4QAswjP3ZvwZzJlc {\n  padding: 20px;\n  background: #fff;\n  border-radius: 8px;\n  height: 200px;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  align-items: flex-start;\n}\n.Ctem4QAswjP3ZvwZzJlc .mvoHoG0A6q8j_Y0Irg_4 {\n  font-size: 16px;\n  font-weight: 600;\n  color: #35373A;\n  margin-bottom: 10px;\n}\n.Ctem4QAswjP3ZvwZzJlc .WrmJBfgpjG4J9yzRaww8 {\n  font-size: 12px;\n  color: #8496AF;\n  width: 90%;\n}\n.Ctem4QAswjP3ZvwZzJlc .d9fMfgHf61jr_qoNavIq {\n  display: flex;\n  margin-top: auto;\n  align-items: flex-end;\n  justify-content: space-between;\n  width: 100%;\n}\n.Ctem4QAswjP3ZvwZzJlc .d9fMfgHf61jr_qoNavIq .ASOPcDjBZ0HH8ucKQVdP {\n  font-size: 12px;\n  font-weight: 500;\n  color: #8496AF;\n}", "",{"version":3,"sources":["webpack://./src/views/FAQ/ui/InterestingCard/InterestingCard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,UAAA;AAAJ;AAGE;EACE,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,8BAAA;EACA,WAAA;AADJ;AAGI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AADN","sourcesContent":[".content {\n  padding: 20px;\n  background: #fff;\n  border-radius: 8px;\n  height: 200px;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  align-items: flex-start;\n\n  .title {\n    font-size: 16px;\n    font-weight: 600;\n    color: #35373A;\n    margin-bottom: 10px;\n  }\n\n  .text {\n    font-size: 12px;\n    color: #8496AF;\n    width: 90%;\n  }\n\n  .bottom {\n    display: flex;\n    margin-top: auto;\n    align-items: flex-end;\n    justify-content: space-between;\n    width: 100%;\n\n    .count {\n      font-size: 12px;\n      font-weight: 500;\n      color: #8496AF;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Ctem4QAswjP3ZvwZzJlc",
	"title": "mvoHoG0A6q8j_Y0Irg_4",
	"text": "WrmJBfgpjG4J9yzRaww8",
	"bottom": "d9fMfgHf61jr_qoNavIq",
	"count": "ASOPcDjBZ0HH8ucKQVdP"
};
export default ___CSS_LOADER_EXPORT___;
