import { makeAutoObservable } from 'mobx';
import { getCashboxes } from '../../api/getCashboxes';
import React from 'react';
import { API_URL, delCashbox, delData } from '../../static/js/request';
import { Store } from 'react-notifications-component';
import { createNotification } from '../../static/js/commonFunc';

export class CashboxesStore {
  fields = [];

  constructor() {
    makeAutoObservable(this);
    this.fillValue();
  }

  fillValue = () => {
    getCashboxes().then((item) => {
      if (!item) {
        return (this.fields = []);
      }
      this.fields = item;
    });
  };

  removeField = (index) => {
    const fieldId = this.fields[index].id;

    if (!fieldId) {
      return;
    }

    delCashbox(API_URL + `/api/v1/cashbox/${fieldId}/delete/`).then(
      (data) => {
        createNotification(data);
        if (data.ok) {
          this.fields.splice(index, 1);
        }
      }
    );
  };
}
