import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import AccountMenu from '../AccountMenu.jsx';
import {
    API_URL,
    delData,
    getData,
    postData, putData,
} from '../../../../static/js/request';
import ApiRow from './ApiRow/ApiRow.jsx';

const Api = () => {
    const [removeModalActive, setActiveRemoveModal] = useState(false);

    const [allApi, setAllApi] = useState();

    const [deafultApiLength, setDefaultApiLength] = useState(0);

    const [newApi, setNewApi] = useState({});

    const getApi = async () => {
        try {
            const response = await getData(
                API_URL + '/api/v1/organisation/'
            );
            setAllApi(response);
            setDefaultApiLength(response.length);
            setSaveModal(false);
            setNewApi({})
            setApiChanged(false)
        } catch (error) {}
    };

    useEffect(() => {
        getApi();
    }, []);

    const createNewApi = async () => {
        try {
            const response = await postData(
                API_URL + '/api/v1/organisation/create/',
                {
                    ...newApi,
                }
            );
            getApi();
        } catch (error) {}
    };

    const addApi = async () => {
        const newApiCount =
      allApi.filter((temp) => temp.name.includes('Новое API')).length + 1;

        setNewApi({
            name: newApiCount > 1 ? `Новое API${newApiCount}` : 'Новое API',
            marketplace: [],
            banks: [],
            status: false,
        });

        setAllApi((state) => {
            return [
                ...state,
                {
                    name: newApiCount > 1 ? `Новое API${newApiCount}` : 'Новое API',
                    marketplace: [],
                    banks: [],
                    status: false,
                },
            ];
        });

        setSaveModal(true);
    };

    const removeApi = async (id) => {
        if (id) {
            try {
                const response = await delData(
                    API_URL + `/api/v1/organisation/${id}/`
                );
                setAllApi(allApi.filter((temp) => temp.id !== id));
                setSaveModal(false);
            } catch (error) {}
        } else {
            setAllApi(allApi.filter((temp) => temp.id));
            setNewApi({});
            setSaveModal(false);
        }
    };

    const [saveModal, setSaveModal] = useState(false);

    const [apiChanged, setApiChanged] = useState(false);

    useEffect(() => {
        if (deafultApiLength === allApi?.length && !apiChanged) {
            setSaveModal(false);
        } else if (apiChanged) {
            setSaveModal(true);
        }
    }, [allApi, apiChanged]);

    return (
        <>
            <div className={styles.wrap}>
                <AccountMenu />
                <div className={styles.content}>
                    <div className={styles.top}>
                        <h2 className={styles.title}>API</h2>
                        {deafultApiLength === allApi?.length && (
                            <button onClick={() => addApi()} className={styles.addApi}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                >
                                    <path
                                        d="M3.28957 10.7896H9.21044V16.7104C9.21044 16.9198 9.29362 17.1207 9.44169 17.2687C9.58977 17.4168 9.7906 17.5 10 17.5C10.2094 17.5 10.4102 17.4168 10.5583 17.2687C10.7064 17.1207 10.7896 16.9198 10.7896 16.7104V10.7896H16.7104C16.9198 10.7896 17.1207 10.7064 17.2687 10.5583C17.4168 10.4102 17.5 10.2094 17.5 10C17.5 9.79059 17.4168 9.58976 17.2687 9.44169C17.1207 9.29362 16.9198 9.21043 16.7104 9.21043H10.7896V3.28956C10.7896 3.08016 10.7064 2.87933 10.5583 2.73126C10.4102 2.58319 10.2094 2.5 10 2.5C9.7906 2.5 9.58977 2.58319 9.44169 2.73126C9.29362 2.87933 9.21044 3.08016 9.21044 3.28956V9.21043H3.28957C3.08016 9.21043 2.87933 9.29362 2.73126 9.44169C2.58319 9.58976 2.5 9.79059 2.5 10C2.5 10.2094 2.58319 10.4102 2.73126 10.5583C2.87933 10.7064 3.08016 10.7896 3.28957 10.7896Z"
                                        fill="#429EFF"
                                    />
                                </svg>
                                Добавить организацию
                            </button>
                        )}
                    </div>
                    <div className={styles.apis}>
                        {allApi?.map((item, index) => (
                            <ApiRow
                                removeApi={removeApi}
                                allItems={allApi}
                                item={item}
                                key={index}
                                setNewApi={setNewApi}
                                setApiChanged={setApiChanged}
                                saveModal={saveModal}
                                newApi={newApi}
                            />
                        ))}
                    </div>
                </div>
                {saveModal && (
                    <div className={styles.saveModal}>
                        <p className={styles.modalTitle}>Сохранение изменений</p>
                        <div className={styles.buttons}>
                            <button onClick={getApi} className={styles.closeSaveBtn}>
                                Отмена
                            </button>
                            <button onClick={createNewApi} className={styles.saveBtn}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Api;
