import React, { useState, useEffect, useRef } from 'react';
import style from '../Financing.module.scss';
import Table from '../../../components/custom/Table.jsx';
import HeaderTable from '../../../components/custom/HeaderTable.jsx';
import HeaderTableItem from '../../../components/custom/HeaderTableItem.jsx';
import BodyTable from '../../../components/custom/BodyTable.jsx';
import RulesBody from './RulesBody.jsx';
import YearSelect from '../../Balance/YearSelect/index.jsx';
import { getArticles, getContrAgents } from '../../../api/getRules';
import { API_URL, delData, postData, putData, putDataNew } from '../../../static/js/request';
import { createNotification } from '../../../static/js/commonFunc';
import RulesStore from '../../../store/Financing/RulesStore';
import BodyTableLine from '../../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../../components/custom/BodyTableLineItem.jsx';
import { NewCustomInput } from "../../../components/custom/NewCustomInput/NewCustomInput.jsx";

const Rules = () => {

    const [addModal, setAddModal] = useState(false);

    const [operation, setOperation] = useState('Приход');

    const allOperations = ['Контрагент', 'Сумма', 'Назначение платежа'];

    const [firstOperation, setFirstOperation] = useState();
    const [secondOperation, setSecondOperation] = useState();
    const [thirdOperation, setThirdOperation] = useState();

    const [operationsCount, setCount] = useState([1]);

    const [sumValue, setSumValue] = useState('');

    const [allAgents, setAllAgents] = useState([]);

    const [agent, setAgent] = useState();

    const [amount, setAmount] = useState('');

    const [allArticles, setAllArticles] = useState([]);

    const [selectArticle, setSelectArticle] = useState({});

    const [showOperations, setShowOperations] = useState(false);

    const [selectItem, setItem] = useState();

    const [removeModal, setRemoveModal] = useState(false);

    useEffect(() => {
        getContrAgents().then(response => {
            setAllAgents(response.map(item => item.contragentName));
        });
        getArticles().then(response => {
            setAllArticles(response);
        });
    }, []);

    const [equal, setEqual] = useState();

    const [title, setTitle] = useState();

    const createRule = async () => {
        if (title) {
            const response = await putDataNew(API_URL + '/api/v1/bank/rules/' + selectItem.id + '/', {
                operation_type: operation === 'Приход' ? 'income' : 'expense',
                contragent_name_value: agent,
                amount_value: sumValue,
                article: selectArticle.id,
                payment_purpose_value: amount,
                payment_purpose_operator: equal ? equal === 'присутствует' ? 'partly' : 'equal' : null
            });
            createNotification(response);
        } else {
            const response = await postData(API_URL + '/api/v1/bank/rules/', {
                operation_type: operation === 'Приход' ? 'income' : 'expense',
                contragent_name_value: agent,
                amount_value: parseFloat(sumValue.replace(',', '.')),
                article: selectArticle.id,
                payment_purpose_value: amount,
                payment_purpose_operator: equal ? equal === 'присутствует' ? 'partly' : 'equal' : null
            });
            createNotification(response);
        }
        setAddModal(false);
        RulesStore.fillValue();
    };

    const removeRule = async () => {
        const response = await delData(API_URL + `/api/v1/bank/rules/${selectItem.id}/`);
        createNotification(response);
        setAddModal(false);
        setRemoveModal(false);
        RulesStore.fillValue();
    };

    const [operationTableData, setData] = useState([]);

    const getOperations = async () => {
        postData(API_URL + '/api/v1/bank/rules/operations/', {
            operation_type: operation === 'Приход' ? 'income' : 'expense',
            contragent_name_value: agent,
            amount_value: parseFloat(sumValue.replace(',', '.')),
            payment_purpose_value: amount,
            payment_purpose_operator: equal ? equal === 'присутствует' ? 'partly' : 'equal' : null
        }).then(async (response) => {
            const data = await response.json();
            setData([...data]);
            setShowOperations(true);
        });
    };


    useEffect(() => {
        if (selectItem) {
            setOperation(selectItem.operation_type === 'income' ? 'Приход' : 'Расход');
            setFirstOperation(null);
            setSelectArticle(allArticles.filter(item => item.id === selectItem.article)[0]);
            let count = [];
            if (selectItem.contragent_name_value) {
                setFirstOperation('Контрагент');
                count.push(1);
                setAgent(selectItem.contragent_name_value);
            }
            if (selectItem.amount_value) {
                setSecondOperation('Сумма');
                count = [...count, 2];
                setSumValue(parseInt(selectItem.amount_value).toFixed(0));
            }
            if (selectItem.payment_purpose_value) {
                setThirdOperation('Назначение платежа');
                setAmount(selectItem.payment_purpose_value);
                count = [...count, 3];
            }
            if (selectItem.payment_purpose_operator) {
                setThirdOperation('Назначение платежа');
                count = [...count.filter(item => item !== 3), 3];
                setEqual(selectItem.payment_purpose_operator === 'partly' ? 'присутствует' : 'точное равенство');
            }
            if (count.length > 0) {
                setCount(count);
            }
            setAddModal(true);
            setTitle('Редактирование');
        }
    }, [selectItem]);

    useEffect(() => {
        if (!addModal) {
            setItem();
            setFirstOperation(null);
            setAgent('');
            setSecondOperation(null);
            setSumValue('');
            setThirdOperation(null);
            setEqual('');
            setAmount('');
            setCount([1]);
            setTitle();
            setData([]);
            setSelectArticle({});
            setShowOperations(false);
            setOperation('Приход');
        }
    }, [addModal]);

    return (
        <main>

            <div className={style.title}>Правила</div>
            {
                addModal &&
                <div className={style.modal}>
                    <span className={style.bg} onClick={() => setAddModal(false)} />
                    {
                        removeModal &&
                        <div className={style.removeModal}>
                            <span className={style.bg} onClick={() => setRemoveModal(false)} />
                            <div className={style.removeContent}>
                                <button className={style.closeRemove}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M13.2826 12.5001L18.7339 7.04851C19.0887 6.6939 19.0887 6.12056 18.7339 5.76595C18.3793 5.41135 17.806 5.41135 17.4514 5.76595L11.9999 11.2176L6.5486 5.76595C6.19384 5.41135 5.62067 5.41135 5.26607 5.76595C4.91131 6.12056 4.91131 6.6939 5.26607 7.04851L10.7174 12.5001L5.26607 17.9517C4.91131 18.3063 4.91131 18.8797 5.26607 19.2343C5.44279 19.4111 5.67514 19.5 5.90734 19.5C6.13953 19.5 6.37172 19.4111 6.5486 19.2343L11.9999 13.7827L17.4514 19.2343C17.6283 19.4111 17.8605 19.5 18.0927 19.5C18.3249 19.5 18.557 19.4111 18.7339 19.2343C19.0887 18.8797 19.0887 18.3063 18.7339 17.9517L13.2826 12.5001Z" fill="#1E1E1E" />
                                    </svg>
                                </button>
                                <p className={style.removeTitle}>Подтвердите действие</p>
                                <p className={style.removeText}>Вы уверены, что хотите удалить элемент?</p>
                                <div className={style.btns}>
                                    <button onClick={removeRule} className={style.removeBtn}>Удалить</button>
                                    <button onClick={() => setRemoveModal(false)} className={style.backRemove}>Отменить</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={style.content} style={{ overflowY: showOperations ? 'auto' : '' }}>
                        <button className={style.close} onClick={() => setAddModal(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M13.2826 12.5001L18.7339 7.04851C19.0887 6.6939 19.0887 6.12056 18.7339 5.76595C18.3793 5.41135 17.806 5.41135 17.4514 5.76595L11.9999 11.2176L6.5486 5.76595C6.19384 5.41135 5.62067 5.41135 5.26607 5.76595C4.91131 6.12056 4.91131 6.6939 5.26607 7.04851L10.7174 12.5001L5.26607 17.9517C4.91131 18.3063 4.91131 18.8797 5.26607 19.2343C5.44279 19.4111 5.67514 19.5 5.90734 19.5C6.13953 19.5 6.37172 19.4111 6.5486 19.2343L11.9999 13.7827L17.4514 19.2343C17.6283 19.4111 17.8605 19.5 18.0927 19.5C18.3249 19.5 18.557 19.4111 18.7339 19.2343C19.0887 18.8797 19.0887 18.3063 18.7339 17.9517L13.2826 12.5001Z" fill="#1E1E1E" />
                            </svg>
                        </button>
                        <h2 className={style.ModalTitle}>{title ? title : 'Добавление'} правила {title && <span onClick={() => setRemoveModal(true)}>Удалить правило</span>}</h2>
                        <div className={style.selectWrap}>
                            <p className={style.selectTitle}>Тип операции</p>
                            <YearSelect width={'130px'} value={operation} allValues={['Приход', 'Расход']} onChange={setOperation} />
                        </div>
                        <div className={style.operations}>
                            <p className={style.operationTitle}>Если у операции:</p>
                            {
                                <>
                                    {
                                        operationsCount.includes(1) &&
                                        <div className={style.operationItem}>
                                            <YearSelect disabledData={[secondOperation, thirdOperation]} placeholder={'Выберите условие'} width={'280px'} value={firstOperation} onChange={setFirstOperation} allValues={allOperations} />
                                            {
                                                firstOperation === 'Контрагент' ?
                                                    <YearSelect input placeholder={'Выберите контрагента'} width={'280px'} value={agent} onChange={setAgent} allValues={allAgents} /> :
                                                    firstOperation === 'Сумма' ?
                                                        <NewCustomInput
                                                            width={'280px'}
                                                            placeholder={'Введите сумму'}
                                                            value={sumValue}
                                                            onChange={setSumValue}
                                                            numeric
                                                        /> :
                                                        firstOperation === 'Назначение платежа' ?
                                                            <>
                                                                <YearSelect placeholder={'Выберите назначение'} width={'280px'} value={equal} onChange={setEqual} allValues={['точное равенство', 'присутствует']} />
                                                                <input placeholder={'Введите назначение'} className={style.sumValue} value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                            </> :
                                                            null
                                            }
                                            <p className={style.delete} onClick={() => {
                                                if (operationsCount.length > 1) {
                                                    setCount([...operationsCount.filter(num => num !== 1)]);
                                                }
                                                setFirstOperation(null);
                                                setAgent(null);
                                            }}>Удалить</p>
                                        </div>
                                    }
                                    {
                                        operationsCount.includes(2) &&
                                        <div className={style.operationItem}>
                                            <YearSelect disabledData={[firstOperation, thirdOperation]} placeholder={'Выберите условие'} width={'280px'} value={secondOperation} onChange={setSecondOperation} allValues={allOperations} />
                                            {
                                                secondOperation === 'Контрагент' ?
                                                    <YearSelect input placeholder={'Выберите контрагента'} width={'280px'} value={agent} onChange={setAgent} allValues={allAgents} /> :
                                                    secondOperation === 'Сумма' ?
                                                        <NewCustomInput
                                                            width={'280px'}
                                                            placeholder={'Введите сумму'}
                                                            value={sumValue}
                                                            onChange={setSumValue}
                                                            numeric
                                                        /> :
                                                        secondOperation === 'Назначение платежа' ?
                                                            <>
                                                                <YearSelect placeholder={'Выберите условие'} width={'280px'} value={equal} onChange={setEqual} allValues={['точное равенство', 'присутствует']} />
                                                                <input placeholder={'Введите назначение'} className={style.sumValue} value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                            </>
                                                            :
                                                            null
                                            }
                                            <p className={style.delete} onClick={() => {
                                                if (operationsCount.length > 1) {
                                                    setCount([...operationsCount.filter(num => num !== 2)]);
                                                }
                                                setSumValue('');
                                                setSecondOperation(null);
                                            }}>Удалить</p>
                                        </div>
                                    }
                                    {
                                        operationsCount.includes(3) &&
                                        <div className={style.operationItem}>
                                            <YearSelect disabledData={[secondOperation, firstOperation]} placeholder={'Выберите условие'} width={'280px'} value={thirdOperation} onChange={setThirdOperation} allValues={allOperations} />
                                            {
                                                thirdOperation === 'Контрагент' ?
                                                    <YearSelect input placeholder={'Выберите контрагента'} width={'280px'} value={agent} onChange={setAgent} allValues={allAgents} /> :
                                                    thirdOperation === 'Сумма' ?
                                                        <NewCustomInput
                                                            width={'280px'}
                                                            placeholder={'Введите сумму'}
                                                            value={sumValue}
                                                            onChange={setSumValue}
                                                            numeric
                                                        /> :
                                                        thirdOperation === 'Назначение платежа' ?
                                                            <>
                                                                <YearSelect placeholder={'Выберите назначение'} width={'280px'} value={equal} onChange={setEqual} allValues={['точное равенство', 'присутствует']} />
                                                                <input placeholder={'Введите назначение'} className={style.sumValue} value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                            </>
                                                            :
                                                            null
                                            }
                                            <p className={style.delete} onClick={() => {
                                                if (operationsCount.length > 1) {
                                                    setCount([...operationsCount.filter(num => num !== 3)]);
                                                }
                                                setEqual(null);
                                                setAmount('');
                                                setThirdOperation(null);
                                            }}>Удалить</p>
                                        </div>
                                    }
                                    {
                                        operationsCount.length < 3 &&
                                        <button className={style.addOperation} onClick={() => setCount([...operationsCount, !operationsCount.includes(1) ? 1 : !operationsCount.includes(2) ? 2 : 3])}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                <path d="M1.51981 8.14481H6.35519V12.9802C6.35519 13.1512 6.42312 13.3152 6.54405 13.4361C6.66498 13.5571 6.82899 13.625 7 13.625C7.17102 13.625 7.33503 13.5571 7.45595 13.4361C7.57688 13.3152 7.64481 13.1512 7.64481 12.9802V8.14481H12.4802C12.6512 8.14481 12.8152 8.07688 12.9361 7.95595C13.0571 7.83503 13.125 7.67101 13.125 7.5C13.125 7.32899 13.0571 7.16497 12.9361 7.04405C12.8152 6.92312 12.6512 6.85519 12.4802 6.85519H7.64481V2.01981C7.64481 1.8488 7.57688 1.68479 7.45595 1.56386C7.33503 1.44293 7.17102 1.375 7 1.375C6.82899 1.375 6.66498 1.44293 6.54405 1.56386C6.42312 1.68479 6.35519 1.8488 6.35519 2.01981V6.85519H1.51981C1.3488 6.85519 1.18479 6.92312 1.06386 7.04405C0.942937 7.16497 0.875 7.32899 0.875 7.5C0.875 7.67101 0.942937 7.83503 1.06386 7.95595C1.18479 8.07688 1.3488 8.14481 1.51981 8.14481Z" fill="#429EFF" />
                                            </svg>
                                            Добавить условие
                                        </button>
                                    }
                                </>
                            }
                        </div>
                        <div className={style.operations}>
                            <p className={style.operationTitle}>То изменить или разбить на части:</p>
                            {
                                <>
                                    <p className={style.articleTitle}>Статья</p>
                                    <div className={style.operationItem}>
                                        <YearSelect input object disabledData={[secondOperation, thirdOperation]} placeholder={'Выберите статью'} width={'280px'} value={selectArticle.article} onChange={setSelectArticle} allValues={allArticles} />
                                    </div>
                                </>
                            }
                        </div>
                        <div className={style.btns}>
                            <button disabled={!selectArticle.id || (!agent && !amount && !sumValue && !equal)} className={style.send} onClick={createRule}>Сохранить</button>
                            <button className={style.closeButton} onClick={() => setAddModal(false)}>Отмена</button>
                            <button disabled={(!agent && !amount && !sumValue && !equal)} onClick={getOperations} className={style.find}>Найти операции</button>
                        </div>
                        {
                            showOperations &&
                            <div className={style.operationsTable}>
                                <h2 className={style.tableTitle}>Операции, подходящие под условие</h2>
                                {
                                    operationTableData.length > 0 ?
                                        <>
                                            <Table>
                                                <HeaderTable items={'6'}>
                                                    <HeaderTableItem name='Дата' />
                                                    <HeaderTableItem name='Сумма' />
                                                    <HeaderTableItem name='Счет' />
                                                    <HeaderTableItem name='Контрагент' />
                                                    <HeaderTableItem name='Статья' />
                                                    <HeaderTableItem name='Описание' />
                                                </HeaderTable>
                                                <div className={style.tableWrap}>
                                                    <BodyTable>
                                                        {operationTableData.map(item => (
                                                            <BodyTableLine items={'6'}>
                                                                <BodyTableLineItem value={item.executed} />
                                                                <BodyTableLineItem value={item.amount} />
                                                                <BodyTableLineItem value={item.account} />
                                                                <BodyTableLineItem value={<p title={item.contragentName} className={style.colText}>{item.contragentName}</p>} />
                                                                <BodyTableLineItem value={allArticles.filter(article => article.id === item.article)[0]?.article} />
                                                                <BodyTableLineItem value={<p title={item.paymentPurpose} className={style.colText}>{item.paymentPurpose}</p>} />
                                                            </BodyTableLine>
                                                        ))}
                                                    </BodyTable>
                                                </div>
                                            </Table>
                                        </> :
                                        <p className={style.empty}>Ничего не найдено</p>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
            <section className={style.section}>
                <div className={style.addCashboxContainer}>
                    <button className={style.addCashbox} onClick={() => setAddModal(true)}>Добавить правило</button>
                </div>
                {
                    RulesStore.fields && RulesStore.fields.length > 0 ?
                        <Table>
                            <HeaderTable items='3'>

                                <HeaderTableItem additionalClass={'operationType'} name='Тип операции' />
                                <HeaderTableItem name='Условие' />
                                <HeaderTableItem name='Действие' />

                            </HeaderTable>

                            <BodyTable>

                                <RulesBody data={RulesStore} setItem={setItem} />

                            </BodyTable>
                        </Table> :
                        <p style={{ marginTop: '30px' }} className={style.empty}>Нет данных</p>
                }

            </section>
        </main>
    );
};

export default Rules;