import React from "react";
import style from "../../static/css/commonStyle.module.scss";
import cx from "classnames";

export const BodyTableLineItem = (props) => {
	const {
		value,
		event,
		additionalClass,
		additionalClass2,
		id
	} = props;


	const addedClass = () => {
		let preparedClass = '';
		if (additionalClass) {
			preparedClass = additionalClass;
		}
		return preparedClass;
	}
	const addedClass2 = () => {
		let preparedClass = '';
		if (additionalClass2) {
			preparedClass = additionalClass2;
		}
		return preparedClass;
	}

	return (
		<div id={id} className={cx(style.BodyTableLineItem, (style[addedClass()]), (style[addedClass2()]))} onClick={event}>
			{value}
		</div>
	);
};

export default BodyTableLineItem;