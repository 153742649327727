import React, { useRef, useEffect, useState } from "react";
import Product from "./product/product.jsx";
import Result from "./result/result.jsx";
import cx from 'classnames'
import style from '../PlanFact.module.scss'

const TableLine = ({ data, isChild = false, scrollContainerRef, index, action = false, columnsVisibility }) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const elementRef = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        const element = elementRef.current;

        const syncScroll = (source, target) => {
            if (source && target) {
                target.scrollLeft = source.scrollLeft;
            }
        };

        const handleScroll = (e) => {
            if (e.target === scrollContainer) {
                syncScroll(scrollContainer, element);
            } else if (e.target === element) {
                syncScroll(element, scrollContainer);
            }
        };

        if (element && scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element && scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollContainerRef]);

    return (
        <div
            className={cx(style.tableLine, isChild && style.child, isHovered && style.hover)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >

            <Product data={data} action={action} isChild={isChild} />

            {
                Object.values(columnsVisibility).every((value) => value === false) ?
                    <div className={cx(style.tableRow, style.xScroll, style.jcCenter)}>нет данных</div>
                    :
                    data.days
                    &&
                    <div ref={elementRef} className={cx(style.tableRow, style.xScroll)}>
                        {Object.keys(data.days).map((year) =>
                            Object.keys(data.days[year]).map((month) =>
                                Object.keys(data.days[year][month]).map((day) => {
                                    const dayValues = data.days[year][month][day];
                                    return (
                                        <div className={style.tableRow} key={`${year}-${month}-${day}`} >

                                            {columnsVisibility.orders_count && (
                                                <div className={cx(style.tableColumn, style.value, columnsVisibility.orders_sum ? null : style.checked)}>{dayValues.orders_count}</div>
                                            )}

                                            {columnsVisibility.orders_sum && (
                                                <div className={cx(style.tableColumn, style.value, style.checked)}>{dayValues.orders_sum}</div>
                                            )}

                                            {columnsVisibility.sales_count && (
                                                <div className={cx(style.tableColumn, style.value, columnsVisibility.sales_sum ? null : style.checked)}>{dayValues.sales_count}</div>
                                            )}

                                            {columnsVisibility.sales_sum && (
                                                <div className={cx(style.tableColumn, style.value, style.checked)}>{dayValues.sales_sum}</div>
                                            )}

                                            {columnsVisibility.stock && (
                                                <div className={cx(style.tableColumn, style.value)}>{dayValues.stock}</div>
                                            )}


                                        </div>
                                    );
                                })
                            )
                        )}
                    </div>
            }

            <Result data={data.total} />

        </div>
    )

}

export default TableLine;