import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import validator from 'validator';
import { Store } from 'react-notifications-component';

const InputWithLabel = ({
                            isNumber,
                            containerStyle,
                            percentStyle,
                            fieldName,
                            fieldType,
                            name,
                            onInputChange,
                            value,
                            required,
                            ...inputProps
                        }) => {
    const [internalValue, setInternalValue] = useState('');

    useEffect(() => {
        if (value !== undefined) {
            setInternalValue(value);
        }
    }, [value]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        if (isNumber) {
            if (/^\d+$/.test(newValue?.replace(/\s/g, ''))) {
                setInternalValue(newValue);
                onInputChange(newValue, e.target.name);
            }
        } else {
            setInternalValue(newValue);
            onInputChange(newValue, e.target.name);
        }
    };

    const handlePhoneChange = (e) => {
        const newValue = e.target.value;
        setInternalValue(newValue);
        onInputChange(newValue, e.target.name);
    };

    const handleEmailChange = (e) => {
        const newValue = e.target.value;
        setInternalValue(newValue);
        onInputChange(newValue, e.target.name);
    };

    const handleEmailBlur = (e) => {
        if (fieldType === 'email' && !validator.isEmail(e.target.value)) {
            e.target.style.color = 'rgba(232,41,41,0.67)';
            e.target.style.border = '1px solid rgba(232,41,41,0.67)';
            Store.addNotification({
                title: 'Ошибка',
                message: 'Некорректный email',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 3000,
                },
            });
        } else {
            e.target.style.color = '#1E1E1E';
            e.target.style.border = '1px solid var(--color-gray-light, #dce7f1)';
        }
    };

    const handlePhoneFocus = (e) => {
        e.target.setSelectionRange(0, 0);
    };

    return (
        <div className={containerStyle}>
            <label>{fieldName}</label>
            {fieldType === 'phone' ? (
                <InputMask
                    name={name}
                    mask="+7 (999) 999-99-99"
                    value={internalValue}
                    onChange={handlePhoneChange}
                    onFocus={handlePhoneFocus}
                    {...inputProps}
                />
            ) : fieldType === 'email' ? (
                <input
                    name={name}
                    type="text"
                    value={internalValue}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    {...inputProps}
                />
            ) : fieldType === 'percent' ? (
                <div className={percentStyle}>
                    <input
                        name={name}
                        type="text"
                        value={internalValue}
                        onChange={handleInputChange}
                        {...inputProps}
                    />
                </div>
            ) : (
                <input
                    name={name}
                    type="text"
                    value={internalValue}
                    onChange={handleInputChange}
                    {...inputProps}
                />
            )}
        </div>
    );
};

export default InputWithLabel;