import { makeAutoObservable } from 'mobx';
import { getContrAgents, getRules } from '../../api/getRules.js';
import React from 'react';
import {
    API_URL,
    delData,
    getData,
    postData,
    putData,
} from '../../static/js/request';
import { Store } from 'react-notifications-component';
import { confirmAlert } from 'react-confirm-alert';
import AutoCompleteSearch from './AutoComplete';
import style from '../../views/Financing/Financing.module.scss';
import { createNotification } from '../../static/js/commonFunc';

class RulesStore { 
    fields = [];
    addRulesField = {
        operation_type: '',
        amount_value: '',
        contragent_name_value: '',
        payment_purpose_value: '',
        payment_purpose_operator: '',
        article: '',
    };
    viewRulesField;
    contrAgents = {};


    constructor() {
        makeAutoObservable(this);
        this.fillValue();
    }

    fillValue = () => {
        getRules().then((item) => {
            this.fields = item;
        });

        getContrAgents().then((item) => {
            for (const itemKey in item) {
                const value = item[itemKey]['contragentName'];
                this.contrAgents[value] = value;
            }
        });
    };

    viewRules = (id) => {
        getData(API_URL + `/api/v1/rules/${id}/`).then((response) => {
            this.viewRulesField = response;
            this.addRules(true, true, id);
        });
    };

    artRef = (ref) => {
        this.refArt = ref;
    };

    handleChange = (evt, fields) => {
        const target = evt.target;
        const name = target.name;
        fields[name] = target.value;
    };

    removeRules = (close, id) => {
        delData(API_URL + `/api/v1/rules/${id}/`).then((response) => {
            createNotification(response);
            this.fields.splice(id, 1);
            close();
        });
    };

    handleSubmit = (fields, rulesId, onClose) => {
        fields.article = this.refArt.querySelector('input').dataset.id;
        console.log(fields);
        if (rulesId) {
            putData(API_URL + `/api/v1/rules/${rulesId}/`, fields).then((data) => {
                onClose();
                createNotification(data);
            });
        } else {
            postData(API_URL + '/api/v1/rules/', fields).then((data) => {
                onClose();
                console.log(data);
                createNotification(data);
            });
        }
    };

    addRules = (view, remove, rulesId) => {
        const fields = view ? this.viewRulesField : this.addRulesField;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body w-800">
                        <h2>Добавление правила</h2>
                        <div className="add-rules">
                            <label className="add-rules__fields-item flex fd-c w-100">
                                <span>Тип операции</span>
                                <select
                                    name="operation_type"
                                    defaultValue={fields.operation_type || 'default'}
                                    onChange={(event) => this.handleChange(event, fields)}
                                >
                                    <option value={'default'} disabled>
                                        Выберите операцию
                                    </option>
                                    <option value="income">Приход</option>
                                    <option value="expense">Расход</option>
                                </select>
                            </label>
                            <div className="add-rules__section">
                                <div className="add-rules__section_title">Если у операции:</div>
                                <span className="add-rules__section_annotation">
                                    заполните необходимые поля
                                </span>

                                <div className="add-rules__section_fields">
                                    <label className="add-rules__fields-item w-100">
                                        <input type="text" value="Контрагент" readOnly={true} />
                                    </label>
                                    <label className="add-rules__fields-item w-100">
                                        <select
                                            name="contragent_name_value"
                                            defaultValue={
                                                this.contrAgents[fields.contragent_name_value] ||
                        'default'
                                            }
                                            onChange={(event) => this.handleChange(event, fields)}
                                        >
                                            <option value={'default'} disabled>
                                                Выберите контрагента
                                            </option>
                                            {Object.entries(this.contrAgents).map(
                                                (contrAgent, index) => (
                                                    <option value={contrAgent[0]} key={index}>
                                                        {contrAgent[0]}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </label>
                                </div>
                                <div className="add-rules__section_fields">
                                    <label className="add-rules__fields-item w-100">
                                        <input type="text" value="Сумма" readOnly={true} />
                                    </label>
                                    <label className="add-rules__fields-item w-100">
                                        <input
                                            type="number"
                                            name="amount_value"
                                            defaultValue={fields.amount_value || ''}
                                            onChange={(event) => this.handleChange(event, fields)}
                                        />
                                    </label>
                                </div>
                                <div className="add-rules__section_fields">
                                    <label className="add-rules__fields-item w-100">
                                        <select
                                            name="payment_purpose_operator"
                                            defaultValue={fields.payment_purpose_operator || 'equal'}
                                            onChange={(event) => this.handleChange(event, fields)}
                                        >
                                            <option value="equal">Назначение платежа (=)</option>
                                            <option value="partly">Назначение платежа (~)</option>
                                        </select>
                                    </label>
                                    <label className="add-rules__fields-item w-100">
                                        <input
                                            type="text"
                                            name="payment_purpose_value"
                                            defaultValue={fields.payment_purpose_value || ''}
                                            onChange={(event) => this.handleChange(event, fields)}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="add-rules__section">
                                <div className="add-rules__section_title">
                                    То изменить или разбить на части:
                                </div>
                                <label
                                    className="add-rules__fields-item flex fd-c w-100"
                                    ref={this.artRef}
                                >
                                    <span>Статья</span>
                                    <AutoCompleteSearch article={fields.article || ''} />
                                </label>
                            </div>
                        </div>

                        <div className="react-confirm-alert-button-group">
                            <button className="cancel" onClick={onClose}>
                                Отменить
                            </button>
                            <button
                                className="complete"
                                onClick={() => {
                                    this.handleSubmit(fields, rulesId, onClose);
                                }}
                            >
                                Подтвердить
                            </button>
                            {remove ? (
                                <button
                                    className="remove"
                                    onClick={() => {
                                        this.removeRules(onClose, rulesId);
                                    }}
                                >
                                    Удалить
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            },
        });
    };
}

export default new RulesStore();
