import React from 'react';
import styles from './styles.module.scss';

// // Определяем интерфейс для элемента списка
// interface ValueItem {
//     id: number;
//     name: string;
// }
//
// // Определяем интерфейс для пропсов компонента Switcher
// interface SwitcherProps {
//     active: number;
//     setActive: (id: number) => void;
//     values: ValueItem[]; // Используем интерфейс для типизации values
// }


function Switcher({ active, setActive, values }) {
    return (
        <div className={styles.switch}>
            {
                values.map(item => (
                    <button key={item.id} style={{
                        overflow: 'hidden',
                        width: 'auto',
                        padding: '14px 20px',
                        background: active === item.id ? '#fff' : '',
                    }} onClick={() => setActive(item.id)}
                            className={`${styles.market} ${active === item.id ? styles.active : ''}`}>{item.name}</button>
                ))
            }
        </div>
    );
}

export default Switcher;