import React from 'react';
import styles from './styles.module.scss';

const YearSelector = ({ selectYear, setSelectYear, years }) => {
    return (
        <div className={styles.calendarWrap}>
            <div className={styles.months}>
                {years.map((item) => (
                    <button
                        key={item}
                        className={selectYear === item ? styles.active : ''}
                        onClick={() => setSelectYear(item)}
                    >
                        {item}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default YearSelector;