import React, {useState, useEffect} from 'react';
import FinancingNav from "./FinancingNav";

const Financing = () => {
    return (
        <main>
          <FinancingNav />
        </main>
    );
};

export default Financing;