import React from "react"

const FilterIcon = () => {
    return(
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z"
                                fill="#8496AF"/>
                        </svg>
    )
}

export default FilterIcon