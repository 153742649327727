import { confirmAlert } from 'react-confirm-alert';
import React from 'react';
import { Store } from 'react-notifications-component';

export const componentConfirmAlert = (store, index) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body remove-win">
                    <div className="react-confirm-alert-body__remove-title">
                        Подтвердите действие
                    </div>
                    <div className="react-confirm-alert-body__remove-subtitle">
                        Вы уверены, что хотите удалить элемент?
                    </div>

                    <div className="react-confirm-alert-button-group">
                        <button
                            className="remove react-confirm-alert-button-group__remove-win"
                            onClick={() => {
                                onClose();
                                store.removeField(index);
                            }}
                        >
                            Удалить
                        </button>
                        <button
                            className="cancel react-confirm-alert-button-group__remove-win"
                            onClick={onClose}
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            );
        },
    });
};

export const formatNumber = (number) => {
    if (number === null || number === undefined) return; // Проверка на null или undefined
    if (number === 0) return '0,00'; // Обработка случая, когда number равен 0

    const suffixes = ['', 'тыс.', 'млн.', 'млрд.']; // Суффиксы для тысяч, миллионов и т.д.
    let suffixIndex = 0;

    // Если число больше или равно 1000, то продолжаем делить на 1000 и увеличиваем индекс суффикса
    while (number >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
    }

    // Форматируем число
    let formattedNumber;
    if (suffixIndex === 0) {
        // Если число меньше 1000, используем запятую
        formattedNumber = number.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',');
    } else {
        // Если число больше или равно 1000, используем точку
        formattedNumber = number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return `${formattedNumber} ${suffixes[suffixIndex]}`;
};

const getPriceNoSymbolCurrency = (value) => {
    const IntlCurrencyNoSymbolFormat = new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: 2,
    });

    value = String(value).replace(/\s/g, '');
    value = IntlCurrencyNoSymbolFormat.format(value);
    value = value.replace(',', '.');

    return value;
};

const getNumbers = (value) => {
    try {
        value = value.replace(',', '.');
        value = value.replace(/^\.+/g, '0.');
        value = value.replace(/^([^\.]*\.)|\./g, '$1');
        value = value.replace(/[^.\s\d]+/g, '');
        return value;
    } catch (err) {
        return value;
    }
};

export const formatNumber2 = (number) => {
    if (typeof number === 'string') {
        number = number.replace(',', '.');
    }
    let str = parseFloat(number).toFixed(2).toString();
    let parts = str.split('.');
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    let formattedNumber =
    parts.length > 1 ? integerPart + ',' + parts[1] : integerPart;
    if (formattedNumber === '0') return '-';
    return formattedNumber;
};

export const applyPriceNoSymbolCurrency = (value) => {
    if (!value) {
        return;
    }
    return formatNumber2(getNumbers(value));
};

export const defaultOptions = {
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: 5000,
    },
};

export const createNotification = async (response) => {
    const status = response.ok;
    const resp = await response.json();
    console.log(resp);
    if (!resp.message) {
        Store.addNotification({
            ...defaultOptions,
            title: 'Оповещение',
            message: 'Ожидание ответа сервера',
            type: 'info',
        });
    } else if (status) {
        Store.addNotification({
            ...defaultOptions,
            title: 'Сохранено',
            message: resp.message,
            type: 'success',
        });
    } else {
        Store.addNotification({
            ...defaultOptions,
            title: 'Ошибка',
            message: resp.message,
            type: 'danger',
        });
    }
};
