import React, {Component} from 'react';
import style from './footer.module.scss';
import mainStyle from '../../../static/css/mainStyle.module.scss';

class Footer extends Component {
    render() {
        return (
            <div className={mainStyle.footer}>

            </div>
        );
    }
}

export default Footer;