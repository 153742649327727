import React from 'react';
import styles from './Faq.module.scss';
import Header from "./ui/Header/Header.jsx";
import MainContent from "./ui/MainContent/MainContent.jsx";

const Faq = () => {
    return (
        <div className={styles.faq} style={{overflowY: 'auto'}}>
            <MainContent/>
        </div>
    );
};
export default Faq;
