import React from 'react';
import styles from './styles.module.scss';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { formatNumber2 } from '../../../../../static/js/commonFunc';

export const PercentCard = ({ name_widget, percent, start_point, end_point }) => {
    return (
        <div className={styles.card}>
            <div className={styles.cardInfo}>
                <p className={styles.cardTitle}>{name_widget}</p>
                <div className={styles.row}>
                    <p>{start_point.name}:</p>
                    <p>{start_point.value.toString().includes('.') ? start_point.value : formatNumber2(start_point.value)}</p>
                </div>
                <div className={styles.row}>
                    <p>{end_point.name}:</p>
                    <p>{end_point.value.toString().includes('.') ? end_point.value : formatNumber2(end_point.value)}</p>
                </div>
            </div>
            <div style={{ width: '120px' }}>
                <CircularProgressbar styles={{
                    path: {
                        stroke: `#1AB889`,
                        transform: 'rotate(0.50turn)',
                        transformOrigin: 'center center',
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                    },
                    text: {
                        fill: '#000',
                        fontSize: '16px',
                        fontWeight: '700'
                    },
                }} strokeWidth={10} value={percent * 100} text={`${percent * 100}%`} />
            </div>
        </div>
    )
}
