import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './styles.module.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

import { GraphTooltip } from './GraphTooltip.jsx';

export function Payback({ data }) {

    Array.prototype.max = function () {
        return Math.max.apply(null, this);
    };

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipData, setTooltipData] = useState(null);
    const [tooltipPos, setTooltipPos] = useState(null);

    const chartRef = useRef(null);

    useEffect(() => {
        console.log()
    }, [data])

    const customTooltip = useCallback((context) => {
        if (context.tooltip.opacity === 0) {
            setTooltipVisible(false);
            return;
        }

        const chart = chartRef.current;
        const canvas = chart.canvas;
        if (canvas) {
            setTooltipVisible(true);

            const left = context.tooltip.x;
            const top = context.tooltip.y;

            const tooltipPosLocal = tooltipPos?.left;

            if (tooltipPosLocal != left + 40) {
                setTooltipPos({ top: top, left: left + 40 });
                setTooltipData({ ...context.tooltip });
            }
        }
    });

    const getArrays = () => {
        if (data) {
            const pluses = [];
            const minuses = [];
            data.map(arr => {
                let tempMinus = 0;
                let tempPlus = 0;
                arr.value.map(item => {
                    if (item.category === 'income') {
                        tempPlus += item.value
                    } else if (item.category === 'expense') {
                        tempMinus -= item.value
                    } else {
                        tempPlus += item.value
                    }
                })
                minuses.push(tempMinus)
                pluses.push(tempPlus)
            })
            return { pluses: pluses, minuses: minuses }
        }
    }

    const plugin = {
        id: 'corsair',
        defaults: {
            width: 1,
            color: '#C1C4C7',
            dash: [3, 3],
        },
        afterDraw: (chart, args, opts) => {
            if (chart.tooltip?._active?.length) {
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.setLineDash(opts.dash);
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = '#C1C4C7';
                ctx.stroke();
                ctx.restore();
            }
        }
    };

    const options = {
        plugins: {
            title: {
                display: false,
                text: '',
            },
            legend: {
                display: false
            },

            tooltip: {
                enabled: false,
                external: customTooltip
            },
            datalabels: {
                display: false
            }
        },

        interaction: {
            mode: 'index',
            intersect: false,
        },

        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                position: 'right',
            },
        },
    };

    const labels = data?.map(item => `${item.date.month ? item.date.month.length > 4 ? item.date.month.substring(0, 3) : item.date.month : item.date.mounth.length > 4 ? item.date.mounth.substring(0, 3) : item.date.mounth}`);

    const chartdata = {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Dataset 2',
                data: data?.map(item => item.payback),
                tension: 0.5,
                pointRadius: 0,
                backgroundColor: '#4AB88C3B',
                fill: {
                    target: 'origin',
                    above: '#4AB88C3B',   // Area will be red above the origin
                    below: 'rgba(255, 143, 143, 0.32)'    // And blue below the origin
                },
                animation: false,
                pointHitRadius: 0,
            },
            {
                type: 'bar',
                label: 'Dataset 1',
                data: { ...getArrays() }.pluses,
                borderRadius: 8,
                backgroundColor: '#59D0A5',
                pointHitRadius: 0,
                hoverBackgroundColor: '#59D0A5',
                animation: false,

            },
            {
                type: 'bar',
                label: 'Dataset 2',
                data: { ...getArrays() }.minuses,
                borderRadius: 8,
                backgroundColor: '#FF6A7C',
                pointHitRadius: 0,
                hoverBackgroundColor: '#FF6A7C',
                animation: false,

            },
        ],

    };

    return (
        <>
            <div className={styles.graphWrap} >
                <Chart type='bar' height={445} options={options} data={chartdata} ref={chartRef} plugins={[plugin]} />
                <GraphTooltip
                    labels={data?.map(item => `${item.date.month ? item.date.month : item.date.mounth}`)}
                    allData={data}
                    data={tooltipData}
                    position={tooltipPos}
                    visibility={tooltipVisible}
                />
            </div>
        </>
    );
}