import React, { useEffect, useState } from 'react';
import List from '../../view/list/index.jsx';
import DataArray from './data.json'
import { API_URL, postData } from '../../../../static/js/request.js';

const AcceptanceList = () => {

    const [columns, editColumns] = useState({
        id: { label: '№', isShow: true, value: 'id', type: 'id', noSort: true, width: "80px" },
        purchase: { label: '№ Заказа', isShow: true, value: 'purchase', type: 'id', noSort: true, width: "120px" },
        created_at: { label: 'Дата', isShow: true, value: 'created_at', type: 'date', width: "150px" },
        warehouse: { label: 'Склад', isShow: true, value: 'warehouse', type: 'object', width: "200px" },
        counterparty: { label: 'Контрагент', isShow: true, value: 'counterparty', type: 'object', width: "325px" },
        organisation: { label: 'Организация', isShow: true, value: 'organisation', type: 'object', width: "240px" },
        sum: { label: 'Сумма', isShow: true, value: 'sum', type: 'pay', width: "140px" },
        status: { label: 'Статус', isShow: true, value: 'status', type: 'status', width: "180px" },
        comment: { label: 'Комментарий', isShow: true, value: 'comment', type: 'string', width: "325px" }
    })

    const [filters, setFilters] = useState(['Фильтр первый', 'Фильтр второй', 'Фильтр третий'])

    const [sortingBy, setSortingBy] = useState({
        key: '',
        value: ''
    })

    const [data, setData] = useState(null)

    const getData = async () => {

        try {

            const requestData = {
                sorted: sortingBy.key ? [{ key: sortingBy.key, value: sortingBy.value }] : []
            };

            await postData(API_URL + `/api/v1/purchase/acceptance/list/`, requestData)
                .then((response) => response.json())
                .then((response) => {

                    setData(response.data.length>0 ? response.data : DataArray)

                });

        } catch (error) {
            console.log("Не удалось получить данные", error)
        }
    }

    useEffect(() => {
        getData()
    }, [sortingBy])

    return (
        <List
            title="Приемки"
            newItemLink={{ name: "Создать приемку", value: "/purchase/acceptance/item/" }}
            filters={filters}
            columns={columns}
            editColumns={(value) => editColumns(value)}
            data={data}
            setSortingBy={(item) => setSortingBy(item)}
        />
    )

}

export default AcceptanceList