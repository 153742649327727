import React from "react";
import style from "../../static/css/commonStyle.module.scss";
import cx from "classnames";

export const Table = (props) => {
	return (
		<div className={style.table}>
			{props.children}
		</div>
	);
};

export default Table;