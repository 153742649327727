import { API_URL, getData, postData } from '../static/js/request';

export const getFinancing = (period = 'Все время', group = 'Все', page_current = 1, page_size = 1000) => {
    let data = {
        'period': period,
        'group': group,
        'paginator': {
            'page_current': page_current,
            'page_size': page_size,
        },
    };
    let post_period;
    if (period === 'Все время') {
        post_period = 'ALL';
    } else if (period === 'Год') {
        post_period = 'year';
    } else if (period === 'Месяц') {
        post_period = 'month';
    } else {
        post_period = 'quarter';
    }
    data.period = post_period;
    let post_group;

    if (group === 'Все') {
        post_group = 'all';
    } else if (group === 'Разнесенные') {
        post_group = 'with_article';
    } else if (group === 'Не разнесенные') {
        post_group = 'without_article';
    }
    data.group = post_group;

    // TODO: добавить обработку и отправку paginator, sorted, filters

    return postData(
        API_URL +
        `/api/v1/bank/`,
        data,
    );
};
