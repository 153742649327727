import React, { useState, useEffect } from "react";
import styles from './styles.module.scss';
import MarketplacesSwitch from "../Logistics/ui/MarketplacesSwitch/index.jsx";
import AnswerRow from "./ui/AnswerRow/index.jsx";
import CreateAnswerModal from "./ui/CreateAnswerModal/index.jsx";
import { API_URL, getData, delData } from "../../static/js/request";



function Answering() {

    const [activeMp, setActiveMp] = useState(1);

    const [createModal, setCreateModal] = useState(false);

    const [blocks, setBlocks] = useState(null);

    const getAnswers = async () => {
        try {
            const response = await getData(API_URL + `/api/v1/answering_machine/constructors/?mp=${activeMp === 1 ? 'WB' : 'OZON'}`);
            setBlocks(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const deleteRow = async (item) => {
        try {
            const response = await delData(API_URL + `/api/v1/answering_machine/constructors/`, {
                constructor_id: item.id
            })
            setBlocks([...blocks.filter(temp => temp.id !== item.id )])
            createNotification(response)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getAnswers()
    }, [activeMp])

    return ( 
        <section className={styles.section}>
            {
                createModal &&
                <CreateAnswerModal getData={getAnswers} onClose={() => setCreateModal(false)}/>
            }
            <div className={styles.top}>
                <h2 className={styles.title}>Автоответчик</h2>
                <MarketplacesSwitch all={false} setActive={setActiveMp} active={activeMp}/>
            </div>
            <div className={styles.content}>
                <div className={styles.contentTop}>
                    <p className={styles.contentTitle}>Список конструкторов</p>
                    <button onClick={() => setCreateModal(true)} className={styles.add}>Добавить</button>
                </div>  
                <div className={styles.titleRow}>
                    <p className={styles.name}>Название</p>
                    {
                        blocks &&
                        blocks[0]?.constructor_blocks.map(title => (
                            <p key={title.name} className={styles.col}>{title.name}</p>
                        ))
                    }
                </div>
                <div className={styles.scrollWrap}>
                    {
                        blocks?.length > 0 ? 
                        blocks.map(item => (
                            <AnswerRow onRemove={() => deleteRow(item)} key={item.id} item={item}/>
                        )) :
                        <p className={styles.empty}>Добавьте конструктор</p>
                    }
                </div>
            </div>
        </section>
     );
}

export default Answering;